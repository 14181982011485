export const TAG_ENDPOINT_LIST_TAGS = (workspaceId, page) =>
  `/workspaces/${workspaceId}/tags?page=${page}`
export const TAG_ENDPOINT_LIST_TAGS_BY_IDS = (workspaceId, ids) =>
  `/workspaces/${workspaceId}/tags?filter[id]=in:[${ids}]`
export const TAG_ENDPOINT_CREATE_TAG = (workspaceId) =>
  `/workspaces/${workspaceId}/tags`
export const TAG_ENDPOINT_UPDATE_TAG = (workspaceId, tagId) =>
  `/workspaces/${workspaceId}/tags/${tagId}`
export const TAG_ENDPOINT_DELETE_TAG = (workspaceId, tagId) =>
  `/workspaces/${workspaceId}/tags/${tagId}`
export const TAG_ENDPOINT_SEARCH_TAGS = (workspaceId, page, query) =>
  `/workspaces/${workspaceId}/tags?page=${page}&filter[title]=ct:${query}`
