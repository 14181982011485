<template>
  <div class="base-select">
    <base-field
      :name="name"
      :label="label"
      :validation-id="validationId"
      :validation-rules="validationRules"
      :validation-mode="validationMode"
      v-bind="fieldProps"
    >
      <buefy-select v-model="passedValue" v-bind="$attrs" :size="size">
        <slot />
      </buefy-select>
    </base-field>
  </div>
</template>

<script>
import BuefySelect from 'buefy/src/components/select/Select'
import BaseField from '@/app/util-modules/ui/field/BaseField'

export default {
  name: 'BaseSelect',
  components: {
    BuefySelect,
    BaseField,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      validator: (value) =>
        ['is-small', 'is-medium', 'is-large'].indexOf(value) !== -1,
      default: 'is-medium',
    },
    label: {
      type: String,
      default: '',
    },
    validationId: {
      type: String,
      default: '',
    },
    validationRules: {
      type: [Object, String],
      default: '',
    },
    validationMode: {
      type: [Object, String],
      validator: (value) =>
        ['aggressive', 'passive', 'lazy', 'eager'].indexOf(value) !== -1,
      default: 'aggressive',
    },
    fieldProps: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
        if (this.value !== passedValue && passedValue)
          this.$emit('changed', this.value !== passedValue)
      },
    },
  },
}
</script>
