import { shortname } from '@/utils/store'

import * as actionNamesObj from './notification.actions.names'
import * as mutationNamesObj from '../mutations/notification.mutations.names'

import api from '../../network/notification.api'
import { USER_MUTATION_ADD_USERS_DATA } from '@/app/core/user/store/mutations/user.mutations.names'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.NOTIFICATION_ACTION_LIST_NOTIFICATIONS]: async (
    { commit, rootState },
    page
  ) => {
    const response = await api.listNotifications(
      rootState.workspace.workspace.id,
      page
    )

    commit(mutationNames.NOTIFICATION_MUTATION_UPDATE_PAGE, page)
    commit(
      page === 1
        ? mutationNames.NOTIFICATION_MUTATION_SET_NOTIFICATIONS
        : mutationNames.NOTIFICATION_MUTATION_ADD_NOTIFICATIONS,
      response.notifications
    )
    commit(mutationNames.NOTIFICATION_MUTATION_SET_META, response.meta)

    if (response.included.user) {
      commit(USER_MUTATION_ADD_USERS_DATA, response.included.user, {
        root: true,
      })
    }
  },
  [actionNames.NOTIFICATION_ACTION_LIST_NOTIFICATIONS_NEXT_PAGE]: ({
    state,
    dispatch,
  }) => {
    if (
      state.meta?.total_pages &&
      state.meta.total_pages !== state.currentPage
    ) {
      dispatch(
        actionNames.NOTIFICATION_ACTION_LIST_NOTIFICATIONS,
        state.currentPage + 1
      )
    }
  },
  [actionNames.NOTIFICATION_ACTION_DELETE_NOTIFICATION]: async (
    { commit, rootState, state },
    { notificationId, type }
  ) => {
    await api.deleteNotification(
      rootState.workspace.workspace.id,
      notificationId,
      type
    )

    commit(
      mutationNames.NOTIFICATION_MUTATION_DELETE_NOTIFICATION,
      notificationId
    )
    commit(mutationNames.NOTIFICATION_MUTATION_SET_META, {
      total_count: state.meta.total_count - 1,
    })
  },
  [actionNames.NOTIFICATION_ACTION_DELETE_ALL_NOTIFICATIONS]: async ({
    commit,
    rootState,
  }) => {
    await api.deleteAllNotifications(rootState.workspace.workspace.id)

    commit(mutationNames.NOTIFICATION_MUTATION_SET_NOTIFICATIONS, [])
    commit(mutationNames.NOTIFICATION_MUTATION_SET_META, {
      total_count: 0,
    })
  },
}
