export const USER_ACTION_GET_CURRENT_USER = 'user/ACTION_GET_CURRENT_USER'
export const USER_PUBLIC_ACTION_GET_CURRENT_USER_BASE_ATTRIBUTES =
  'user/PUBLIC_ACTION_GET_CURRENT_USER_BASE_ATTRIBUTES'
export const USER_ACTION_GET_USER_FEATURES = 'user/ACTION_GET_USER_FEATURES'
export const USER_ACTION_LOGOUT = 'user/ACTION_LOGOUT'
export const USER_ACTION_SWITCH_ROLE = 'user/ACTION_SWITCH_ROLE'
export const USER_ACTION_UPDATE_USER_LOCALE = 'user/ACTION_UPDATE_USER_LOCALE'
export const USER_ACTION_LIST_ROLES = 'user/ACTION_LIST_ROLES'
export const USER_ACTION_UPDATE_CHECKIN_STATE =
  'user/ACTION_UPDATE_CHECKIN_STATE'
export const USER_PUBLIC_ACTION_LIST_PREFERENCES =
  'user/PUBLIC_ACTION_LIST_PREFERENCES'
export const USER_PUBLIC_ACTION_UPDATE_PREFERENCES =
  'user/PUBLIC_ACTION_UPDATE_PREFERENCES'
export const USER_PUBLIC_ACTION_LIST_SUGGESTED_FOLLOWERS =
  'user/PUBLIC_ACTION_LIST_USER_SUGGESTED_FOLLOWERS'
export const USER_PUBLIC_ACTION_CLEAR_SUGGESTED_FOLLOWERS =
  'user/PUBLIC_ACTION_CLEAR_SUGGESTED_FOLLOWERS'
export const USER_PUBLIC_ACTION_GET_RECORDS_SUMMARY =
  'user/PUBLIC_ACTION_GET_RECORDS_SUMMARY'
export const USER_PUBLIC_ACTION_GET_VACATIONS_SUMMARY =
  'user/PUBLIC_ACTION_GET_VACATIONS_SUMMARY'
export const USER_PUBLIC_ACTION_GET_CURRENT_WORKSPACE =
  'user/PUBLIC_ACTION_GET_CURRENT_WORKSPACE'
