<template>
  <div>
    <base-date-range-picker-dropdown
      v-if="!$isSmallDevice"
      v-model="dateRange"
      v-bind="$attrs"
      :is-open.sync="isOpen"
      :editable="$attrs.editable"
      :shortcut-selected="shortcutSelectedName"
      :text-date-range="textDateRange"
      :shortcuts="shortcuts"
      :date-creator="dateCreator"
      :is-selecting-custom-range="isSelectingCustomRange"
      :text-selecting-date-range="textSelectingDateRange"
      :custom-focused-date="customFocusedDate"
      :has-selected-custom-range="hasSelectedCustomRange"
      :data-id-prefix="dataIdPrefix"
      @apply="apply()"
      @apply-shortcut="applyShortcut($event)"
      @range-start="rangeStart($event)"
      @range-end="rangeEnd($event)"
    >
      <template v-slot:trigger>
        <slot name="trigger" />
      </template>
    </base-date-range-picker-dropdown>
    <base-date-range-picker-bottom-sheet
      v-if="$isSmallDevice"
      v-model="dateRange"
      v-bind="$attrs"
      :is-open.sync="isOpen"
      :shortcut-selected="shortcutSelectedName"
      :text-date-range="textDateRange"
      :shortcuts="shortcuts"
      :date-creator="dateCreator"
      :is-selecting-custom-range="isSelectingCustomRange"
      :text-selecting-date-range="textSelectingDateRange"
      :custom-focused-date="customFocusedDate"
      :has-selected-custom-range="hasSelectedCustomRange"
      :data-id-prefix="`${dataIdPrefix}.base_date_range_picker`"
      @apply="apply()"
      @apply-shortcut="applyShortcut($event)"
      @range-start="rangeStart($event)"
      @range-end="rangeEnd($event)"
      @reset="reset()"
    />
  </div>
</template>

<script>
import BaseDateRangePickerDropdown from './BaseDateRangePickerDropdown'
import BaseDateRangePickerBottomSheet from './BaseDateRangePickerBottomSheet'
import shortcutsMixin from './shortcuts.mixin'

export default {
  name: 'BaseDateRangePicker',
  components: {
    BaseDateRangePickerDropdown,
    BaseDateRangePickerBottomSheet,
  },
  mixins: [shortcutsMixin],
  props: {
    defaultIsOpen: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Array,
      default: () => null,
    },
    dateCreator: {
      type: Function,
      default: () => new Date(),
    },
    customCurrentDate: {
      type: Date,
      default: () => new Date(),
    },
    getShortcutsMethod: {
      type: Function,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      appliedDateRange: this.defaultValue,
      dateRange: this.defaultValue,
      dateRangeStart: null,
      isSelectingCustomRange: false,
      hasSelectedCustomRange: false,
      isOpen: this.defaultIsOpen,
    }
  },
  computed: {
    textDateRange() {
      if (!this.appliedDateRange) {
        return this.$t('daterangepicker.label_select_range')
      } else {
        return this.formatRange(this.appliedDateRange)
      }
    },
    textSelectingDateRange() {
      if (this.dateRangeStart) {
        return `${this.formatDate(this.dateRangeStart)} - `
      } else if (this.dateRange) {
        return this.formatRange(this.dateRange)
      } else {
        return this.textDateRange
      }
    },
  },
  watch: {
    appliedDateRange(range) {
      if (range) {
        this.customFocusedDate = range[0]
      }

      this.$emit('input', range)
    },
    isOpen(newIsOpen) {
      if (!newIsOpen) {
        this.dateRange = this.appliedDateRange
      }
    },
  },
  methods: {
    rangeStart(date) {
      this.dateRange = null
      this.customFocusedDate = date
      this.dateRangeStart = date
      this.isSelectingCustomRange = true
    },
    rangeEnd(date) {
      this.customFocusedDate = date
      this.dateRangeStart = null
      this.isSelectingCustomRange = false
      this.hasSelectedCustomRange = true
    },
    applyShortcut(shortcut) {
      this.appliedDateRange = shortcut.range
      this.dateRange = this.appliedDateRange
      this.shortcutSelected = shortcut.id
      this.hasSelectedCustomRange = false
      this.isOpen = false
    },
    apply() {
      this.appliedDateRange = this.dateRange
      this.shortcutSelected = this.findMatchingShortcut(
        this.shortcuts,
        this.appliedDateRange
      )
      this.hasSelectedCustomRange = false
      this.isOpen = false
    },
    reset() {
      this.isSelectingCustomRange = false
      this.dateRangeStart = null
    },
    getShortcuts(date) {
      return this.getShortcutsMethod(date)
    },
  },
}
</script>

<style lang="scss">
.base-date-range-picker {
  .datepicker-body .datepicker-cell {
    &.is-within-hovered-range.is-first-hovered {
      @apply bg-green-300  #{!important};
    }
    &.is-selectable:not(.is-within-selected):not(.is-first-selected):not(.is-last-selected):not(.is-within-hovered-range) {
      &:hover {
        @apply bg-coal-80 text-coal #{!important};
      }
    }

    &.is-last-hovered,
    &.is-first-hovered {
      @apply text-white-400  #{!important};
    }

    &.is-last-selected,
    &.is-last-hovered {
      &::after {
        @apply hidden #{!important};
      }
    }

    &.is-first-selected,
    &.is-first-hovered.is-within-hovered-range {
      &:first-child::before {
        @apply hidden #{!important};
      }
    }

    &.is-first-selected,
    &.is-last-selected {
      @apply text-white-400 bg-green-300  #{!important};
    }

    &.is-first-selected:not(.is-last-selected),
    &.is-first-hovered.is-within-hovered-range:not(.is-last-hovered) {
      @apply rounded-tl-md rounded-bl-md rounded-tr-none rounded-br-none #{!important};
    }

    &.is-last-selected:not(.is-first-selected),
    &.is-last-hovered:not(.is-first-hovered) {
      @apply rounded-tl-none rounded-bl-none rounded-tr-md rounded-br-md #{!important};
    }

    &.is-within-selected:not(.is-first-selected):not(.is-last-selected),
    &.is-first-selected:not(.is-last-selected),
    &.is-last-selected:not(.is-first-selected) {
      &::after,
      &:first-child::before {
        @apply bg-green-100;
      }
    }

    &.is-within-hovered:not(.is-first-hovered):not(.is-last-hovered),
    &.is-first-hovered.is-within-hovered-range:not(.is-last-hovered),
    &.is-last-hovered:not(.is-first-hovered) {
      &::after,
      &:first-child::before {
        @apply bg-coal-80;
      }
    }

    &.is-within-selected:not(.is-first-selected):not(.is-last-selected),
    &.is-first-selected:not(.is-last-selected),
    &.is-within-hovered:not(.is-first-hovered):not(.is-last-hovered),
    &.is-first-hovered.is-within-hovered-range:not(.is-last-hovered),
    &.is-last-hovered:not(.is-first-hovered),
    &.is-last-selected:not(.is-first-selected) {
      @apply relative;

      &::after,
      &:first-child::before {
        content: '';
        @apply block absolute top-0 bottom-0 w-2;
      }

      &::after {
        @apply left-full;
      }

      &:first-child::before {
        @apply right-full rounded-tl-md rounded-bl-md;
      }

      &:last-child::after {
        @apply rounded-tr-md rounded-br-md;
      }
    }

    &.is-within-selected:not(.is-first-selected):not(.is-last-selected) {
      @apply bg-green-100 text-coal  #{!important};
    }
    &.is-within-hovered:not(.is-first-hovered):not(.is-last-hovered) {
      @apply bg-coal-80  #{!important};
    }
  }

  .base-date-picker {
    .datepicker-header {
      border-bottom: 0;
    }

    &::after {
      content: '';
      @apply block h-px bg-coal-100 absolute -left-2 -right-4;
    }
  }
}

.base-date-range-picker.is-mobile {
  .dropdown-content,
  .dropdown-menu {
    @apply w-full shadow-none border-0 rounded-none #{!important};
  }

  .datepicker-table {
    @apply w-full  #{!important};
  }

  .base-date-picker::after {
    top: 42px;
  }
}

.base-date-range-picker:not(.is-mobile) {
  .shortcuts-col {
    width: 280px;
  }

  .datepicker-col {
    width: 300px;
  }

  > .dropdown-menu > .dropdown-content {
    @apply border border-coal-100 p-0;

    .dropdown-content {
      @apply shadow-none p-0 border-0 #{!important};
    }
  }

  .base-date-picker::after {
    top: 43px;
  }
}
</style>
