import axios from '@/utils/vendors/axios'

import { WORKSPACE_ENDPOINT_LIST_WORKSPACES } from './workspace.endpoints'

import {
  Workspace,
  WorkspaceList,
  WorkspaceUpdateRequest,
} from '../models/workspace.model'

export default {
  async listWorkspaces(accountId) {
    const response = await axios.get(
      WORKSPACE_ENDPOINT_LIST_WORKSPACES(accountId)
    )
    return new WorkspaceList(response.data.data)
  },

  async updateWorkspace(workspaceId, name) {
    const { data } = await axios.request(
      new WorkspaceUpdateRequest({ workspaceId, name })
    )
    return new Workspace(data.data)
  },
}
