export const FEATURES_ENDPOINT_LIST_FEATURES = (workspaceId) =>
  `/workspaces/${workspaceId}/features`
export const USER_ENDPOINT_GET_CURRENT_USER = '/users/me'
export const USER_ENDPOINT_GET_WORKSPACE_CURRENT_USER = (workspaceId) =>
  `/workspaces/${workspaceId}/users/me`
export const USER_ENDPOINT_SWITCH_ROLE = (workspaceId) =>
  `/workspaces/${workspaceId}/users/switch_role`
export const USER_ENDPOINT_UPDATE_USER = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}`
export const USER_ENDPOINT_LIST_ROLES = (workspaceId) =>
  `/workspaces/${workspaceId}/roles`
export const USER_ENDPOINT_GET_PREFERENCES = (userId) =>
  `/users/${userId}/preferences`
export const USER_ENDPOINT_UPDATE_PREFERENCES = ({ userId, preferenceId }) =>
  `/users/${userId}/preferences/${preferenceId}`
export const USER_ENDPOINT_GET_SUGGESTED_FOLLOWERS = (workspaceId) =>
  `/workspaces/${workspaceId}/suggested_followers`
export const USER_ENDPOINT_GET_RECORDS_SUMMARY = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/records/summary`
export const USER_ENDPOINT_GET_VACATIONS_SUMMARY = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/vacations/summary`
