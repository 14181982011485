<template>
  <div
    class="flex items-center justify-between h-14 flex-shrink-0 px-8 border-b border-coal-150"
  >
    <div>
      <h2 class="text-2xl font-semibold">
        {{ title }}
      </h2>
    </div>
    <div>
      <button type="button" class="text-coal-450" @click="$emit('close')">
        <base-icon size="1.5rem" icon="cross-close/cross-close-24" />
      </button>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseCardModalHeader',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
