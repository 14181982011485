<template>
  <component
    :is="componentButton"
    :class="classes"
    type="is-filled"
    size="is-small"
    @click="$emit('click-button-new')"
  >
    <base-icon class="mr-2" size="1rem" icon="plus/plus-16" />
    {{ textButtonNew }}
  </component>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'DropdownTopbarButtonNew',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    typeButtonNew: {
      type: String,
      required: true,
    },
    textButtonNew: {
      type: String,
      required: true,
    },
  },
  computed: {
    componentButton() {
      return this.typeButtonNew === 'filled' ? 'base-button' : 'button'
    },
    classes() {
      const baseClass = 'w-full flex  items-center justify-center  text-center'
      return this.typeButtonNew === 'basic'
        ? `${baseClass} py-2 text-coal-450 cursor-pointer hover:text-green-400 px-1`
        : baseClass
    },
  },
}
</script>
