/* eslint @intlify/vue-i18n/no-dynamic-keys: */
// disabling this rule because of the way we have
// vee-validate error messages configured.
// we are using vee-validate translation keys
import { extend, configure } from 'vee-validate'
import { required, email, min, min_value } from 'vee-validate/dist/rules'
import VueI18n from '../i18n/index'

export function defaultMessageHandler(field, values) {
  // override the field name.
  values._field_ = VueI18n.t(`validation_fields.${field}`)

  return VueI18n.t(`validation.${values._rule_}`, values)
}

export default function init() {
  configure({
    defaultMessage: defaultMessageHandler,
  })

  // Install required rule and message.
  extend('required', required)

  // Install email rule and message.
  extend('email', email)

  // Install min rule and message.
  extend('min', min)

  // Install min_value rule and message.
  extend('min_value', min_value)
}
