<template>
  <div class="flex w-full items-center">
    <base-time-picker
      v-model="range.startTime"
      :duration="duration"
      :errors="[]"
      :size="size"
    />
    <div class="mx-1 flex items-center font-medium text-coal-550 text-lg">
      &mdash;
    </div>
    <base-time-picker
      v-model="range.endTime"
      :duration="duration"
      :errors="[]"
      :size="size"
    />
  </div>
</template>

<script>
import BaseTimePicker from '../time-picker/BaseTimePicker'

export default {
  name: 'BaseTimeRangePicker',
  components: {
    BaseTimePicker,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        startTime: null,
        endTime: null,
      }),
    },
    duration: {
      type: Boolean,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      range: this.value,
    }
  },
  watch: {
    range() {
      this.$emit('input', this.range)
    },
  },
}
</script>
