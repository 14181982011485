import store from '@/store'
import {
  ACTION_STATUS_MUTATION_TRACK_ACTION,
  ACTION_STATUS_MUTATION_SET_ACTION_OK,
  ACTION_STATUS_MUTATION_SET_ACTION_FAILED,
} from '@/app/util-modules/action-status/store/mutations/action-status.mutations.names'

export default {
  before: (action, { actionStatus }) => {
    const options = actionStatus.whitelist.find(
      (item) => item.action === action.type
    )
    if (options) {
      store.commit(ACTION_STATUS_MUTATION_TRACK_ACTION, {
        action,
        options,
      })
    }
  },
  after: (action, { actionStatus }) => {
    if (actionStatus.data[action.type]) {
      const options = actionStatus.whitelist.find(
        (item) => item.action === action.type
      )
      store.commit(ACTION_STATUS_MUTATION_SET_ACTION_OK, { action, options })
    }
  },
  error: (action, { actionStatus }, error) => {
    if (actionStatus.data[action.type]) {
      const options = actionStatus.whitelist.find(
        (item) => item.action === action.type
      )
      store.commit(ACTION_STATUS_MUTATION_SET_ACTION_FAILED, {
        action,
        options,
        error,
      })
    }
  },
}
