<template>
  <transition-group name="fade-group" tag="div">
    <slot />
  </transition-group>
</template>

<script>
export default {
  name: 'FadeGroupTransition',
  beforeUpdate() {
    if (this.$slots.default) {
      this.$slots.default = this.$slots.default.map((node, index) => {
        node.key = `${node.tag}-${index}`
        return node
      })
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .fade-group {
  &-enter-active,
  &-leave-active {
    @apply transition-all duration-300;
  }

  &-enter,
  &-leave-to {
    @apply opacity-0;
  }
}
</style>
