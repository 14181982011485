class ValidatePasswordTokenRequest {
  constructor(form) {
    this.data = {
      type: 'user',
      attributes: {
        reset_password_token: form.token,
      },
    }
  }
}

class ValidatePasswordTokenResponse {}

export { ValidatePasswordTokenRequest, ValidatePasswordTokenResponse }
