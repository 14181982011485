<template>
  <base-filter
    :field="field"
    :label="label"
    :serialized-value="serializedValue"
    :available-fields="availableFields"
    :invalid="invalid"
    :display-value="displayValue"
    :default-value="defaultValue"
    @apply="apply($event)"
    @replace="$emit('replace', $event)"
    @remove="$emit('remove', $event)"
    @close="resetForm()"
  >
    <template v-slot:form>
      <div class="mb-3">
        <div class="label">
          <span>
            {{ $t('filterbar.fields.operator.label') }}
          </span>
        </div>
        <base-dropdown class="filter-duration-dropdown">
          <template v-slot:trigger>
            <base-input-field
              :value="displayDurationValue"
              :placeholder="$t('filterbar.fields.operator.label')"
              :name="$t('filterbar.fields.operator.name')"
              type="text"
              icon-right="arrow"
              :size="$isSmallDevice ? 'is-large' : 'is-small'"
              readonly
            >
            </base-input-field>
          </template>
          <base-dropdown-text-item
            v-for="availableOperator in availableOperators"
            :key="availableOperator.id"
            :value="availableOperator.id"
            @click="handleSelectOption(availableOperator)"
          >
            {{ runOrReturn(availableOperator.label) }}
          </base-dropdown-text-item>
        </base-dropdown>
      </div>
      <div class="mb-3">
        <base-time-picker
          v-if="form.operator !== 'between'"
          v-model="form.query"
          :label="$t('filterbar.fields.duration.label')"
          :size="$isSmallDevice ? 'is-large' : 'is-small'"
          :errors="[]"
          :data-id-prefix="$t('filterbar.fields.duration.label')"
          duration
        />
      </div>
      <div class="mb-3">
        <base-time-range-picker
          v-if="form.operator === 'between'"
          v-model="form.query"
          :label="$t('filterbar.fields.range.label')"
          :size="$isSmallDevice ? 'is-large' : 'is-small'"
          duration
        />
      </div>
    </template>
  </base-filter>
</template>

<script>
import BaseFilter from './BaseFilter'
import BaseTimePicker from '../time-picker/BaseTimePicker'
import BaseTimeRangePicker from '../time-range-picker/BaseTimeRangePicker'
import {
  formatDurationFilter,
  formatDurationRangeFilter,
} from '@/utils/date-time.utils'
import { durationFilter } from '@/utils/filterbar.utils'
import { runOrReturn } from '@/utils/helpers'
import cloneDeep from 'lodash/cloneDeep'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'

export default {
  name: 'BaseFilterDuration',
  components: {
    BaseDropdownTextItem,
    BaseInputField,
    BaseDropdown,
    BaseFilter,
    BaseTimePicker,
    BaseTimeRangePicker,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    availableFields: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: Object,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        selectedDuration: null,
        operator: 'between',
        query: {
          startTime: {
            hours: 0,
            minutes: 0,
          },
          endTime: {
            hours: 1,
            minutes: 0,
          },
        },
        ...this.defaultValue?.form,
      },
      availableOperators: durationFilter.operators,
      appliedValue: null,
    }
  },
  computed: {
    displayDurationValue() {
      return this.form.selectedDuration || this.$t('filter_operators.not_set')
    },
    filterParam() {
      if (this.invalid) {
        return null
      }
      const { operator, query } = this.form
      switch (operator) {
        case 'between':
          return {
            [this.field]: formatDurationRangeFilter(query),
          }
        default:
          return {
            [this.field]: `${operator}_${formatDurationFilter(query)}`,
          }
      }
    },
    serializedValue() {
      return {
        field: this.field,
        form: cloneDeep(this.form),
        filter: this.filterParam,
      }
    },
    invalid() {
      return this.form.operator === 'between'
        ? Object.keys(this.form.query.startTime).length !== 2 ||
            Object.keys(this.form.query.endTime).length !== 2
        : Object.keys(this.form.query).length !== 2
    },
    displayValue() {
      return runOrReturn(durationFilter.getDisplayedValue(this.appliedValue))
    },
  },
  watch: {
    'form.operator': function (newOperator, oldOperator) {
      if (newOperator === 'between') {
        this.form.query = {
          startTime: { ...this.form.query },
          endTime: { ...this.form.query },
        }
      } else if (oldOperator === 'between') {
        const { startTime } = this.form.query
        this.form.query = startTime
          ? {
              ...startTime,
            }
          : {}
      }
    },
  },
  mounted() {
    this.form.selectedDuration = runOrReturn(this.availableOperators[0].label)
  },
  methods: {
    runOrReturn,
    resetForm() {
      if (this.appliedValue) {
        this.form = { ...this.appliedValue.form }
      }
      this.$emit('close')
    },
    apply(applied) {
      this.appliedValue = this.serializedValue
      if (applied.emit) {
        this.$emit('apply', this.appliedValue)
      }
    },
    handleSelectOption(args) {
      this.form.operator = args.id
      this.form.selectedDuration = this.runOrReturn(args.label)
    },
  },
}
</script>

<style scoped>
.filter-duration-dropdown {
  @apply block;
}
.filter-duration-dropdown >>> .dropdown-menu {
  @apply w-full;
}
.filter-duration-dropdown >>> .control svg {
  @apply transform rotate-90;
}
.filter-duration-dropdown.is-active >>> .control svg {
  @apply transform -rotate-90;
}
</style>
