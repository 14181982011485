<template>
  <component
    :is="tagName"
    :data-id="dataIdAttr"
    class="group flex items-center relative"
    @click="emitSort()"
  >
    <span
      :title="value"
      class="md:uppercase md:text-sm"
      :class="[sortingLabelClass, { truncate: truncateValue }]"
    >
      {{ value }}
    </span>
    <span
      v-if="sortable"
      :class="[
        'flex items-center ml-1.5 table-header-sorting-icon',
        sorting ? 'text-blue-400' : 'hidden group-hover:flex',
      ]"
    >
      <base-icon
        icon="arrow/arrow-16"
        :rotation="sortingIconRotation"
        size="0.75rem"
      />
    </span>
  </component>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseTableHeaderCell',
  components: {
    BaseIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    sorting: {
      type: Number,
      default: null,
    },
    dataIdAttr: {
      type: String,
      default: '',
    },
    truncateValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sortable() {
      return this.sorting != null
    },
    tagName() {
      return this.sortable ? 'button' : 'div'
    },
    sortingLabelClass() {
      return this.sorting ? 'text-blue-400 font-bold' : 'text-coal font-bold'
    },
    sortingIconRotation() {
      return this.sorting < 0 ? -90 : 90
    },
  },
  methods: {
    emitSort() {
      if (!this.sortable) {
        return
      }
      let direction = 0

      if (this.sorting > 0) {
        direction = -1
      } else if (this.sorting == 0) {
        direction = 1
      }
      this.$emit('sort', direction)
    },
  },
}
</script>
