<template>
  <validation-observer
    ref="observer"
    v-slot="{ failed, valid }"
    class="w-full"
    :class="{
      'has-default-messages':
        actionStatus.isFailed || actionStatus.successMessage,
    }"
  >
    <form ref="form" :class="customClasses">
      <slot name="header" />

      <slot v-if="!hideMessages" name="messages">
        <base-form-validation-messages :action-status="actionStatus" />
      </slot>

      <slot
        name="content"
        :failed="failed"
        :valid="valid"
        :disabled="isSubmitButtonDisabled(actionStatus.errorMessages, failed)"
        :submit-count="submitCount"
        :handle-submit="handleSubmit"
        :handle-input-change="handleInputChange"
      />

      <slot name="footer" />
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BaseFormValidationMessages from './BaseFormValidationMessages'

export default {
  name: 'BaseFormValidation',
  components: {
    ValidationObserver,
    BaseFormValidationMessages,
  },
  provide: function () {
    return {
      actionStatusPropGetter: () => this.actionStatus,
    }
  },
  props: {
    actionStatus: {
      type: Object,
      required: true,
    },
    customClasses: {
      type: [Object, String],
      default: () => ({}),
    },
    hideMessages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitCount: 0,
      inputChanged: false,
    }
  },
  methods: {
    async handleSubmit() {
      return new Promise((resolve, reject) => {
        this.submitCount++
        this.$refs.observer.validate().then((success) => {
          if (!success) {
            reject()
          }

          // Reset input change tracking after submit
          this.inputChanged = false
          resolve()
        })
      })
    },
    handleInputChange(value) {
      if (this.submitCount > 0) {
        this.inputChanged = value
        return this.inputChanged
      }
    },
    isSubmitButtonDisabled(errors, frontendErrors) {
      if (!errors && !frontendErrors) {
        // if no errors, don't disable
        return false
      } else if (errors && frontendErrors) {
        // if frontend and server errors
        return true
      } else if (errors) {
        // if server errors,
        return !this.inputChanged
      } else if (frontendErrors) {
        // if frontend Errors
        return true
      }
    },
  },
}
</script>
