import Vue from 'vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { ACTION_STATUS_GETTER_STATUSES } from '@/app/util-modules/action-status/store/getters/action-status.getters.names'
import {
  ACTION_STATUS_MUTATION_WHITELIST_ACTIONS,
  ACTION_STATUS_MUTATION_REMOVE_ACTIONS,
} from '@/app/util-modules/action-status/store/mutations/action-status.mutations.names'

export default function install() {
  Vue.mixin({
    name: 'ActionTrackerMixin',
    beforeCreate() {
      if (this.$options.trackedActions) {
        this.$actions = useActionTracker(this.$options.trackedActions, false)
      }
    },
    destroyed() {
      if (this.$options.trackedActions) {
        store.commit(
          ACTION_STATUS_MUTATION_REMOVE_ACTIONS,
          this.$options.trackedActions
        )
      }
    },
  })
}

export function useActionTracker(actions, cleanup = true) {
  const handler = {
    get(target, prop) {
      if (
        typeof prop === 'string' &&
        !target[prop] &&
        process.env.VUE_APP_MODE === 'development'
      ) {
        /* eslint-disable-next-line no-console */
        console.warn(`${prop} action is not in trackedActions option`)
        return
      }

      const result = store.getters[ACTION_STATUS_GETTER_STATUSES](target)
      return result[prop]
    },
  }

  store.commit(ACTION_STATUS_MUTATION_WHITELIST_ACTIONS, actions)

  if (cleanup) {
    onUnmounted(() => {
      store.commit(ACTION_STATUS_MUTATION_REMOVE_ACTIONS, actions)
    })
  }

  return new Proxy(actions, handler)
}
