<template>
  <div
    class="min-w-max w-full border-t py-2 px-4 md:py-2.5 md:px-2 h-full border-coal-100 bg-coal-80 flex items-center"
  >
    <table-footer-item-wrapper
      v-for="(col, index) in columns"
      :key="`footer-cell-${index}`"
      :column="col"
    />
  </div>
</template>

<script>
import TableFooterItemWrapper from './BaseTableFooterItemWrapper'

export default {
  name: 'BaseTableFooter',
  components: {
    TableFooterItemWrapper,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
