








import { defineComponent, computed } from '@vue/composition-api'
import WorkspaceList from '../workspace-list/WorkspaceList.vue'
import store from '@/store'
import { WORKSPACE_ACTION_SWITCH_WORKSPACE } from '@/app/core/workspace/store/actions/workspace.actions.names'

export default defineComponent({
  name: 'WorkspaceContainer',
  components: {
    WorkspaceList,
  },

  setup() {
    const workspacesList = computed(
      () => store.state['workspace']['workspacesList']
    )

    const currentSelectedWorkspace = computed(() => {
      return store.state['workspace']['workspace']
    })

    const switchWorkspace = (location: { id: string }) => {
      if (currentSelectedWorkspace.value.id !== location.id)
        store.dispatch(WORKSPACE_ACTION_SWITCH_WORKSPACE, location)
    }

    return {
      workspacesList,
      currentSelectedWorkspace,

      switchWorkspace,
    }
  },
})
