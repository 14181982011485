<template>
  <buefy-input
    v-model="passedValue"
    :class="className"
    v-bind="$attrs"
    :type="customType"
    :size="size"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :icon="customIconLeft"
    :icon-right="customIconRight"
    :icon-right-clickable="iconRightClickable"
    @icon-right-click="handleIconRightClick()"
    v-on="$listeners"
  />
</template>

<script>
import BuefyInput from 'buefy/src/components/input/Input'

export default {
  name: 'BaseInput',
  components: { BuefyInput },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator: (value) =>
        ['is-small', 'is-medium', 'is-large'].indexOf(value) !== -1,
      default: 'is-medium',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconType: {
      type: String,
      default: 'is-stroke',
      validator: (value) => ['is-filled', 'is-stroke'].indexOf(value) !== -1,
    },
    iconRight: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        if (this.value !== passedValue && passedValue)
          this.$emit('changed', this.value !== passedValue)
      },
    },
    iconSize() {
      switch (this.size) {
        case 'is-large':
          return 32
        case 'is-small':
          return 16
        case 'is-medium':
        default:
          return 24
      }
    },
    iconLeftName() {
      if (this.icon) {
        return `${this.icon}/${this.icon}-${this.iconSize}`
      }

      return null
    },
    iconRightName() {
      if (this.iconRight) {
        return `${this.iconRight}/${this.iconRight}-${this.iconSize}`
      }

      return null
    },
    className() {
      if (this.type === 'search') {
        return 'text-coal-450'
      }

      return {
        [`input-icon-${this.iconType}`]: this.iconType,
        'text-coal-850': true,
      }
    },
    searchIcon() {
      return `search/search-${this.iconSize}`
    },
    crossIcon() {
      return `cross-close/cross-close-${this.iconSize}`
    },
    customIconLeft() {
      return this.type === 'search' ? this.searchIcon : this.iconLeftName
    },
    customIconRight() {
      return this.type === 'search'
        ? this.value
          ? this.crossIcon
          : undefined
        : this.iconRightName
    },
    iconRightClickable() {
      return this.type === 'search'
    },
    customType() {
      return this.type === 'search' ? 'text' : this.type
    },
  },
  methods: {
    handleIconRightClick() {
      if (this.type === 'search') {
        this.$emit('input', '')
        this.passedValue = ''
      }
    },
  },
}
</script>
