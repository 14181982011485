<template>
  <bottom-sheet
    :header-title="$t('topbar.account.account')"
    @cancel-click="$emit('cancel-click')"
  >
    <div class="flex flex-col items-center py-5 border-b border-color-coal">
      <div class="pb-1 text-2xl font-semibold text-center mb-px">
        {{ user.attributes.username }}
      </div>
      <span class="text-coal-450 mb-2.5">
        {{ $t('topbar.account.label_account_id') }}: {{ user.account.id }}
      </span>
      <base-badge v-if="userRole" :title="userRole.localized_name" />
    </div>

    <user-menu-bottom-sheet-role-switch
      @switch-role-start="$emit('cancel-click')"
    />

    <bottom-sheet-list-group :title="$t('topbar.account.settings')">
      <router-link
        v-if="profileRoute"
        :to="profileRoute"
        data-id="topbar.account.profile"
        @click.native="hideMobileSidebar()"
      >
        <bottom-sheet-list-item
          icon="user/user-16"
          :label="$t('topbar.account.profile')"
          :secondary-icon="false"
        />
      </router-link>
      <!--bottom-sheet-list-item
        icon="add-ons/add-ons-16"
        :label="$t('topbar.account.subscriptions_addons')"
        :secondary-icon="false"
      >
        <template v-slot:right>
          <base-badge
            type="is-warning"
            :title="$t('topbar.account.badge_new')"
          />
        </template>
      </bottom-sheet-list-item>

      <bottom-sheet-list-item
        icon="settings/settings-16"
        :label="$t('topbar.account.account_settings')"
        :secondary-icon="false"
      /-->
    </bottom-sheet-list-group>

    <bottom-sheet-list-group :title="$t('topbar.account.language')">
      <bottom-sheet-list-item @click.native="$emit('change-language')">
        <user-menu-locale
          :language-name="languageName"
          :flag-code="flagCode"
          :is-using-company-language="isUsingCompanyLanguage"
        />
        <div class="ml-auto flex items-center">
          <base-icon icon="arrow/arrow-16" size="1rem" />
        </div>
      </bottom-sheet-list-item>
    </bottom-sheet-list-group>

    <bottom-sheet-list-group :title="$t('topbar.account.red_zone')">
      <!--bottom-sheet-list-item
        icon="help-information/help-information-16"
        icon-type="is-filled"
        secondary-icon="link-hyperlink/link-hyperlink-16"
        :label="$t('topbar.account.help_center')"
      /-->

      <action-logout>
        <bottom-sheet-list-item
          icon="exit-log-out/exit-log-out-16"
          :secondary-icon="false"
          :label="$t('topbar.account.log_out')"
        />
      </action-logout>
    </bottom-sheet-list-group>
  </bottom-sheet>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import BottomSheetListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem'
import ActionLogout from '@/app/util-modules/ui/action-logout/ActionLogout'
import BottomSheetListGroup from '@/app/util-modules/ui/bottom-sheet/BottomSheetListGroup'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge'
import UserMenuBottomSheetRoleSwitch from './UserMenuBottomSheetRoleSwitch'
import UserMenuLocale from '@/app/core/user/components/user-menu/UserMenuLocale'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

import { mapGetters, mapMutations } from 'vuex'
import { BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR } from '@/store/mutations/store.mutations.names'
import {
  USER_GETTER_USER,
  USER_GETTER_HIGHEST_ROLE,
} from '../../store/getters/user.getters.names'

export default {
  name: 'UserMenuBottomSheetMain',
  components: {
    BottomSheet,
    BottomSheetListItem,
    ActionLogout,
    BottomSheetListGroup,
    BaseIcon,
    UserMenuLocale,
    BaseBadge,
    UserMenuBottomSheetRoleSwitch,
  },
  props: {
    languageName: {
      type: String,
      required: true,
    },
    flagCode: {
      type: String,
      required: true,
    },
    isUsingCompanyLanguage: {
      type: Boolean,
      default: false,
    },
    profileRoute: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      user: USER_GETTER_USER,
      userRole: USER_GETTER_HIGHEST_ROLE,
    }),
  },
  methods: mapMutations({
    hideMobileSidebar: BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR,
  }),
}
</script>
