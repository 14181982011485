import BaseRequest from '@/utils/api/base-request'
import { USER_ENDPOINT_LIST_ROLES } from '../network/user.endpoints'

type RoleName =
  | 'employee'
  | 'working_area_admin'
  | 'workspace_admin'
  | 'account_admin'

export interface Role {
  id: string
  type: string
  name: RoleName
  localized_name: string
}

export class RoleListRequest extends BaseRequest {
  constructor({ workspaceId }: { workspaceId: RecordId }) {
    super()
    super.url = USER_ENDPOINT_LIST_ROLES(workspaceId)
  }
}
