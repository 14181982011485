import BaseSnackbar from './BaseSnackbar'

import { VueInstance } from 'buefy/src/utils/config'
import { use, registerComponentProgrammatic } from 'buefy/src/utils/plugins'
import store from '@/store'

let localVueInstance

const SnackbarProgrammatic = {
  open(params) {
    let parent
    if (typeof params === 'string') {
      params = {
        message: params,
      }
    }

    if (params.parent) {
      parent = params.parent
      delete params.parent
    }

    if (Array.isArray(params.message)) {
      delete params.message
    }
    const vm =
      typeof window !== 'undefined' && window.Vue
        ? window.Vue
        : localVueInstance || VueInstance

    const SnackbarComponent = vm.extend(BaseSnackbar)
    const component = new SnackbarComponent({
      parent,
      el: document.createElement('div'),
      propsData: params,
      store,
    })

    return component
  },
  openAfterReload(params) {
    const queue = this.getReloadQueue()

    queue.push(params)

    localStorage.setItem('reloadQueuedToasts', JSON.stringify(queue))
  },
  getReloadQueue() {
    const reloadQueuedToasts = localStorage.getItem('reloadQueuedToasts')
    return reloadQueuedToasts ? JSON.parse(reloadQueuedToasts) : []
  },
  resetReloadQueue() {
    localStorage.removeItem('reloadQueuedToasts')
  },
  showQueuedAfterReload() {
    const queue = this.getReloadQueue()
    queue.forEach(this.open)
    this.resetReloadQueue()
  },
}

const Plugin = {
  install(Vue) {
    localVueInstance = Vue
    registerComponentProgrammatic(Vue, 'snackbar', SnackbarProgrammatic)
  },
}

use(Plugin)

export default Plugin

export { SnackbarProgrammatic }
