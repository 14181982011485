<template>
  <div class="p-4 -mt-0.5 bg-white-400 border-t border-coal-100">
    <component
      :is="componentButton"
      :class="classes"
      type="is-filled"
      size="is-large"
      @click="$emit('click-button-new')"
    >
      <base-icon class="mr-2" size="1.25rem" icon="plus/plus-16" />
      {{ textButtonNew }}
    </component>
  </div>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BottomSheetTopbarButtonNew',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    typeButtonNew: {
      type: String,
      required: true,
    },
    textButtonNew: {
      type: String,
      required: true,
    },
  },
  computed: {
    componentButton() {
      return this.typeButtonNew === 'filled' ? 'base-button' : 'button'
    },
    classes() {
      const baseClass = 'w-full flex  items-center justify-center  text-center'
      return this.typeButtonNew === 'basic'
        ? `${baseClass} text-lg text-coal-450 cursor-pointer hover:text-green-300 px-1`
        : baseClass
    },
  },
}
</script>
