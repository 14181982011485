<template>
  <div class="flex min-w-0">
    <module-menu-bottom-sheet
      v-if="showSmallMenu"
      class="flex min-w-0"
      :modules="modules"
    >
      <template v-slot:trigger="{ trigger }">
        <tabs-topbar-back-button
          v-if="backButtonRoute"
          :route="backButtonRoute"
          :data-id-prefix="`${dataIdPrefix}.tabs_topbar`"
        />
        <tabs-topbar-trigger
          :label="selectedTabLabel"
          :active="false"
          @click.native="trigger()"
        />
      </template>

      <template v-slot:list>
        <div class="px-4">
          <bottom-sheet-list-item
            v-for="tab in tabs"
            :key="tab.id"
            :icon="tab.smallIcon"
            :icon-type="tab.smallIconType"
            :secondary-icon="false"
            :label="renderTabLabel(tab.label)"
            :route="getRouteLink(tab.route)"
          />
        </div>
      </template>
    </module-menu-bottom-sheet>

    <base-dropdown
      v-else-if="showMediumMenu"
      class="tabs-topbar-dropdown flex min-w-0"
    >
      <template v-slot:trigger="{ active }">
        <tabs-topbar-trigger :label="selectedTabLabel" :active="active" />
      </template>

      <base-dropdown-text-item
        v-for="tab in tabs"
        :key="tab.id"
        class="w-32"
        @click="gotoModulePage(tab.route)"
      >
        {{ renderTabLabel(tab.label) }}
      </base-dropdown-text-item>
    </base-dropdown>

    <div v-else class="flex items-center">
      <div class="tabs">
        <ul>
          <li v-if="backButtonRoute">
            <tabs-topbar-back-button
              :route="backButtonRoute"
              :data-id-prefix="`${dataIdPrefix}.tabs_topbar`"
            />
          </li>

          <tabs-topbar-item-tab
            v-for="tab in tabs"
            :key="tab.id"
            :tab="tab"
            :class="{
              'is-active': tab.id === selectedTab.id && $isLargeDevice,
              'is-single-tab': tabs.length === 1,
            }"
            :data-id-prefix="`${dataIdPrefix}.tabs_topbar.${tab.id}`"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TabsTopbarItemTab from './TabsTopbarItemTab'
import TabsTopbarTrigger from './TabsTopbarTrigger'
import TabsTopbarBackButton from './TabsTopbarBackButton'
import ModuleMenuBottomSheet from '@/app/core/module/components/module-menu/ModuleMenuBottomSheet'
import BottomSheetListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'
import { runOrReturn } from '@/utils/helpers'
import router from '@/router'

export default {
  name: 'TabsTopbar',
  components: {
    TabsTopbarItemTab,
    TabsTopbarTrigger,
    TabsTopbarBackButton,
    ModuleMenuBottomSheet,
    BottomSheetListItem,
    BaseDropdown,
    BaseDropdownTextItem,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
    backButtonRoute: {
      type: Object,
      default: () => null,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedTab() {
      return (
        this.tabs.find((tab) => tab.route?.name === this.$route?.name) ||
        this.tabs[0]
      )
    },
    selectedTabLabel() {
      return runOrReturn(this.selectedTab.label)
    },
    showMediumMenu() {
      return this.tabs.length > 1 && this.$isMediumDevice
    },
    showSmallMenu() {
      return this.tabs.length > 1 && this.$isSmallDevice
    },
  },
  methods: {
    renderTabLabel(label) {
      return runOrReturn(label)
    },
    getRouteLink(route) {
      return router.resolve(route).href
    },
    gotoModulePage(route) {
      this.$router.push(route)
    },
  },
}
</script>

<style scoped>
.module-label {
  @apply text-xl md:text-lg;
}
.tabs {
  @apply mb-0;
}
.tabs-topbar-dropdown >>> .dropdown-trigger {
  @apply flex min-w-0;
}

.is-single-tab >>> .router-link-active {
  @apply cursor-default module-label flex min-w-0 items-center font-semibold text-coal;
}
</style>
