import { computed, ComputedRef } from '@vue/composition-api'
import {
  BASE_GETTER_IS_SMALL_DEVICE,
  BASE_GETTER_IS_MEDIUM_DEVICE,
  BASE_GETTER_IS_LARGE_DEVICE,
} from '@/store/getters/store.getters.names'
import store from '@/store'

type DeviceSizes = {
  $isSmallDevice: ComputedRef<boolean>
  $isMediumDevice: ComputedRef<boolean>
  $isLargeDevice: ComputedRef<boolean>
}
type ElementSize = 'is-small' | 'is-medium' | 'is-large'

export default function useDeviceSize(): DeviceSizes {
  const sizes = {
    $isSmallDevice: computed(() => store.getters[BASE_GETTER_IS_SMALL_DEVICE]),
    $isMediumDevice: computed(
      () => store.getters[BASE_GETTER_IS_MEDIUM_DEVICE]
    ),
    $isLargeDevice: computed(() => store.getters[BASE_GETTER_IS_LARGE_DEVICE]),
  }

  return sizes
}

export function useElementSize(): ComputedRef<ElementSize> {
  const sizes = useDeviceSize()

  return computed(() => {
    if (sizes.$isLargeDevice.value) {
      return 'is-large'
    } else if (sizes.$isMediumDevice.value) {
      return 'is-medium'
    } else {
      return 'is-small'
    }
  })
}
