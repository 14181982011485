import { shortname } from '@/utils/store'
import * as actions from './summary.actions.names'
import * as mutations from '../mutations/summary.mutations.names'

import api from '../../network/summary.api'

const actionNames = shortname(actions)
const mutationNames = shortname(mutations)

export default {
  [actionNames.SUMMARY_ACTION_GET_SUMMARY]: async (
    { commit },
    { workspaceId, resource, scopes }
  ) => {
    const response = await api.getSummary({ workspaceId, resource, scopes })

    commit(mutationNames.SUMMARY_MUTATION_SET_SUMMARY, {
      resource,
      summary: response.summary,
    })
  },
}
