import state from './summary.state'
import mutations from './mutations/summary.mutations'
import actions from './actions/summary.actions'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
}
