import BaseRequest from '@/utils/api/base-request'
import { USER_ENDPOINT_GET_RECORDS_SUMMARY } from '../network/user.endpoints'

export class GetUserRecordsSummaryRequest extends BaseRequest {
  constructor(workspaceId: RecordId, userId: RecordId) {
    super()
    super.url = USER_ENDPOINT_GET_RECORDS_SUMMARY(workspaceId, userId)
    super.method = 'get'
  }
}
