<template>
  <ul class="flex flex-col items-center">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'AppSidebarMenuList',
}
</script>
