<template>
  <buefy-button
    :class="className"
    :native-type="nativeType"
    :type="type"
    :loading="loading"
    :expanded="expanded"
    :inverted="inverted"
    :size="size"
    :icon-left="iconLeftName"
    :icon-right="iconRightOrDropdown"
    :tag="tag"
    :disabled="disabled"
    v-on="$listeners"
  >
    <slot />
  </buefy-button>
</template>

<script>
import BuefyButton from 'buefy/src/components/button/Button'

export default {
  name: 'BaseButton',
  components: { 'buefy-button': BuefyButton },
  props: {
    type: {
      type: String,
      validator: (value) =>
        [
          'is-filled',
          'is-filled-red',
          'is-filled-grey',
          'is-filled-white',
          'is-outlined',
          'is-outlined-grey',
          'is-outlined-white',
        ].indexOf(value) !== -1,
      default: 'is-filled-grey',
    },
    nativeType: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
    },
    expanded: {
      type: Boolean,
    },
    inverted: {
      type: Boolean,
    },
    size: {
      type: String,
      validator: (value) =>
        ['is-small', 'is-medium', 'is-large'].indexOf(value) !== -1,
      default: 'is-medium',
    },
    iconLeft: {
      type: String,
      default: undefined,
    },
    iconRight: {
      type: String,
      default: undefined,
    },
    iconType: {
      type: String,
      default: 'is-stroke',
      validator: (value) => ['is-filled', 'is-stroke'].indexOf(value) !== -1,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    truncated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className() {
      return {
        'base-button-dropdown': this.dropdown,
        truncated: this.truncated,
        [`button-icon-${this.iconType}`]: this.iconType,
      }
    },
    iconSize() {
      switch (this.size) {
        case 'is-small':
          return 16
        case 'is-medium':
        case 'is-large':
          return 24
        default:
          return 24
      }
    },
    dropdownIcon() {
      return `arrow/arrow-${this.iconSize}`
    },
    iconLeftName() {
      if (this.iconLeft) {
        return `${this.iconLeft}/${this.iconLeft}-${this.iconSize}`
      }

      return null
    },
    iconRightName() {
      if (this.iconRight) {
        return `${this.iconRight}/${this.iconRight}-${this.iconSize}`
      }

      return null
    },
    iconRightOrDropdown() {
      return this.dropdown ? this.dropdownIcon : this.iconRightName
    },
  },
}
</script>
