import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import { shortname } from '@/utils/store.js'
import * as mutationNamesObj from './action-status.mutations.names'

const mutationNames = shortname(mutationNamesObj)

const makeActionsCollection = (actions) => {
  const items = []

  for (const value of Object.values(actions)) {
    if (typeof value === 'string') {
      items.push({ action: value })
    } else {
      items.push(value)
    }
  }
  return items
}

export default {
  [mutationNames.ACTION_STATUS_MUTATION_WHITELIST_ACTIONS]: (
    state,
    actions
  ) => {
    state.whitelist = state.whitelist.concat(makeActionsCollection(actions))
  },
  [mutationNames.ACTION_STATUS_MUTATION_TRACK_ACTION]: (
    state,
    { action, options }
  ) => {
    const data = {
      isLoading: true,
      isOk: null,
      isFailed: null,
      response: null,
      error: null,
      payload: null,
    }

    Object.defineProperties(data, {
      successMessage: {
        configurable: true,
        get() {
          return this.response?.data?.meta?.success
        },
      },
      hasErrorMessages: {
        configurable: true,
        get() {
          if (!this.error?.response?.data?.errors) return false
          if (this.error.response.status === 404) return false
          return this.error.response.data.errors.length > 0
        },
      },
      errorMessages: {
        configurable: true,
        get() {
          if (!this.hasErrorMessages) return []

          return this.error.response.data.errors
            .filter((err) => err.source?.pointer === 'base')
            .map((err) => err.title)
        },
      },
      validationMessages: {
        configurable: true,
        get() {
          if (!this.hasErrorMessages) return new Map()

          return this.error.response.data.errors.reduce((all, err) => {
            if (err.source && err.source.pointer !== 'base') {
              all.set(err.source.pointer, err.title)
            }
            return all
          }, new Map())
        },
      },
    })

    // store payload with tracked action if it's opted in
    if (options.includePayload) {
      data.payload = cloneDeep(action.payload)
    }

    if (options.trackByKey) {
      if (!state.data[action.type]) {
        Vue.set(state.data, action.type, { instances: {} })
      }
      const payloadKey = action.payload[options.trackByKey]
      Vue.set(state.data[action.type].instances, payloadKey, data)
    } else {
      Vue.set(state.data, action.type, data)
    }
  },
  [mutationNames.ACTION_STATUS_MUTATION_SET_ACTION_OK]: (
    state,
    { action, options }
  ) => {
    const data = {
      isLoading: false,
      isOk: true,
      isFailed: false,
      error: null,
    }
    let ref = state.data[action.type]

    if (options.trackByKey) {
      const payloadKey = action.payload[options.trackByKey]
      ref = state.data[action.type].instances[payloadKey]
    }
    Object.assign(ref, data)
  },
  [mutationNames.ACTION_STATUS_MUTATION_SET_ACTION_OK_RESPONSE]: (
    state,
    { action, options }
  ) => {
    let ref = state.data[action.type]

    if (options.trackByKey) {
      const payloadKey = action.payload[options.trackByKey]
      ref = state.data[action.type].instances[payloadKey]
    }
    ref.response = action.response
  },
  [mutationNames.ACTION_STATUS_MUTATION_SET_ACTION_FAILED]: (
    state,
    { action, options, error }
  ) => {
    const data = {
      isLoading: false,
      isOk: false,
      isFailed: true,
      response: null,
      error,
    }
    let ref = state.data[action.type]

    if (options.trackByKey) {
      const payloadKey = action.payload[options.trackByKey]
      ref = state.data[action.type].instances[payloadKey]
    }
    Object.assign(ref, data)
  },
  [mutationNames.ACTION_STATUS_MUTATION_REMOVE_ACTIONS]: (state, actions) => {
    const collection = makeActionsCollection(actions)

    collection.forEach((item) => Vue.delete(state.data, item.action))

    state.whitelist = state.whitelist.filter(
      (whitelistItem) =>
        !collection.some((item) => whitelistItem.action === item.action)
    )
  },
}
