import difference from 'lodash/difference'
import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import { AreaState } from '../area.state'

import * as actionNamesObj from './area.actions.names'
import * as mutationNamesObj from '../mutations/area.mutations.names'

import {
  EMPLOYEE_PUBLIC_ACTION_ATTACH_AREA,
  EMPLOYEE_PUBLIC_ACTION_DETACH_AREA,
} from '@/app/modules/employee/store/actions/employee.actions.names'

import api from '../../network/area.api'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.AREA_ACTION_LIST_AREAS]: async (
    { commit, rootState }: ActionContext<AreaState, any>,
    page: any
  ) => {
    const response = await api.listAreas(rootState.workspace.workspace.id, page)

    commit(mutationNames.AREA_MUTATION_UPDATE_PAGE, page)
    commit(
      page === 1
        ? mutationNames.AREA_MUTATION_SET_AREAS
        : mutationNames.AREA_MUTATION_ADD_AREAS,
      response.areas
    )
    commit(mutationNames.AREA_MUTATION_SET_META, response.meta)
  },
  [actionNames.AREA_ACTION_LIST_AREAS_NEXT_PAGE]: ({
    state,
    dispatch,
  }: ActionContext<AreaState, any>) => {
    if (state.meta.total_pages !== state.currentPage) {
      dispatch(actionNames.AREA_ACTION_LIST_AREAS, state.currentPage + 1)
    }
  },
  [actionNames.AREA_ACTION_CREATE_AREA]: async (
    { commit, rootState }: ActionContext<AreaState, any>,
    form: { userIds: [] }
  ) => {
    const response = await api.createArea(
      rootState.workspace.workspace.id,
      form
    )
    commit(mutationNames.AREA_MUTATION_ADD_AREA, response.area)
  },
  [actionNames.AREA_ACTION_UPDATE_AREA]: async (
    { state, commit, dispatch, rootState }: ActionContext<AreaState, any>,
    form: any
  ) => {
    let userIdChanges = null

    if (form.userIds) {
      userIdChanges = getUserIdChanges(
        state.data[form.id],
        form.userIds.map((user: any) => user.id)
      )
    }

    const response = await api.updateArea(
      rootState.workspace.workspace.id,
      form
    )
    commit(mutationNames.AREA_MUTATION_UPDATE_AREA, response.area)

    if (userIdChanges) {
      const { addedUserIds, removedUserIds } = userIdChanges

      dispatch(
        EMPLOYEE_PUBLIC_ACTION_ATTACH_AREA,
        { employeeIds: addedUserIds, areaId: form.id },
        { root: true }
      )
      dispatch(
        EMPLOYEE_PUBLIC_ACTION_DETACH_AREA,
        { employeeIds: removedUserIds, areaId: form.id },
        { root: true }
      )
    }
  },
  [actionNames.AREA_ACTION_DELETE_AREA]: async (
    { commit, rootState }: ActionContext<AreaState, any>,
    areaId: string
  ) => {
    const response = await api.deleteArea(
      rootState.workspace.workspace.id,
      areaId
    )
    commit(mutationNames.AREA_MUTATION_DELETE_AREA, response.area.id)
  },
  [actionNames.AREA_ACTION_GET_AREA_DETAILS]: async (
    { rootState }: ActionContext<AreaState, any>,
    areaId: string
  ) => {
    return api.getAreaDetails(rootState.workspace.workspace.id, areaId)
  },

  [actionNames.AREA_PUBLIC_ACTION_LIST_BY_ID]: async (
    { commit, state, rootState }: ActionContext<AreaState, any>,
    ids = []
  ) => {
    const existingIds = [...state.list, ...state.loadingAreasIds]
    const newIds = ids.filter((id) => !existingIds.includes(id))

    if (newIds.length) {
      commit(mutationNames.AREA_MUTATION_ADD_LOADING_AREAS_IDS, newIds)

      const response = await api.listAreasByIds(
        rootState.workspace.workspace.id,
        newIds
      )
      commit(mutationNames.AREA_MUTATION_ADD_AREAS, response.data)
      commit(mutationNames.AREA_MUTATION_REMOVE_LOADING_AREAS_IDS, newIds)
      commit(mutationNames.AREA_MUTATION_SET_META, response.meta)
    }
  },
}

function getUserIdChanges(area: { relationships: any }, userIds: any) {
  const currentUserIds = area.relationships.users.map((u: any) => u.id)

  return {
    addedUserIds: difference(userIds, currentUserIds),
    removedUserIds: difference(currentUserIds, userIds),
  }
}
