import { State, RecordGeneric, ResourceLink, RecordRelationship } from './types'
import { isRecordTypeIndexed, getRecordIndex } from './indexing'

// restructures given jsonapi record by flattening it via flattenRecord()
// util and adding relationships getters using linkRelationship() util
export function restructureRecord(
  record: RecordGeneric,
  state: State
): RecordGeneric {
  const flatRecord = flattenRecord(record)

  Object.keys(record.relationships || {}).forEach((relName) => {
    linkRelationship(state, flatRecord, relName)
  })

  return flatRecord
}

// takes raw jsonapi record and returns restructured version, which moves all
// `attributes` one level up:
// > flattenRecord({id: '1', type: 'type', relationships: {}, attributes: {key: 'val'}})
// > {id: '1', _type: 'type', _relationships: {}, key: 'val'}
export function flattenRecord(record: RecordGeneric): RecordGeneric {
  const { id, ...attributes } = record.attributes || {}

  return {
    id: record.id,
    _type: record.type,
    _relationships: record.relationships,
    ...attributes,
  }
}

// defines custom getters for each relationship of given record. The getter
// tries to read related records from store. For example, time tracking notes
// will be read from state.records.notes.
// > const record = {id: '1', type: 'type', _relationships: {rel: {data: {id: '12'}}}}
// > linkRelationship(state, record, 'rel')
// `record.rel` is now reference to a record under state.records.rel with ID 12.
// when records are one-to-many, `record.rel` will equal to array of references.
export function linkRelationship(
  state: State,
  record: RecordGeneric,
  relName: string
) {
  Object.defineProperty(record, relName, {
    enumerable: true,
    configurable: true,
    get() {
      const resLink = record._relationships[relName].data

      if (Array.isArray(resLink)) {
        let results: RecordGeneric[] = []

        if (resLink.length) {
          const type = resLink[0]?.type
          const relRecords = state.records[type]

          if (relRecords) {
            // external relations are getter functions
            const collection: RecordGeneric[] =
              typeof relRecords === 'function' ? relRecords() : relRecords

            results = getReslinksIndexes(state, type, resLink, collection).map(
              (index: number) => collection[index]
            )
          }
        }
        return results
      } else if (resLink != null) {
        const type = resLink.type
        const relRecords = state.records[type]
        let result = null

        if (relRecords) {
          const isIndexed = isRecordTypeIndexed(state, type)

          // external relations are getter functions
          const collection: RecordGeneric[] =
            typeof relRecords === 'function' ? relRecords() : relRecords

          if (isIndexed) {
            const index = getRecordIndex(state, type, resLink.id)
            result = collection[index]
          } else {
            result = collection.find((relItem) => relItem.id === resLink.id)
          }
        }
        return result
      } else {
        return null
      }
    },
  })
}

// creates object from jsonapi `included` array. Key represents relationship
// name and value is array of data. Result will be similar to following:
// {
//   rel1: [item, item],
//   rel2: [item, item, item],
// }
export function extractIncluded(included: RecordGeneric[] = []) {
  const obj: Record<string, RecordGeneric[]> = {}

  included.forEach((item) => {
    if (!obj[item.type]) {
      obj[item.type] = []
    }
    obj[item.type].push(item)
  })

  return obj
}

// extracts relationships' IDs of all given records into single Map with
// relationship name as a key and Set of IDs as a value:
// Map{
//   apples: Set{'1', '2'},
//   oranges: Set{'11', '22'}
// }
// second param can be used to specify only necessary relations:
// extractRelationships(records, ['oranges'])
export function extractRelationships(
  records: Array<{ relationships: RecordRelationship[] }> = [],
  includeRels: string[] = []
): Map<string, Set<RecordId>> {
  const map = new Map()
  const includeAllRels = includeRels.length === 0

  for (const { relationships } of records) {
    if (!relationships) {
      continue
    }

    // go through each relationship of the record
    for (const [relName, { data }] of Object.entries(relationships)) {
      if (!data) {
        continue
      }
      // when relations specified explicitly, we should skip non-matching ones
      if (!includeAllRels && !includeRels.includes(relName)) {
        continue
      }

      if (!map.has(relName)) {
        map.set(relName, new Set())
      }

      const relNameSet = map.get(relName)

      // relationships can be one-to-many and one-to-one as well
      if (Array.isArray(data)) {
        for (const { id } of data) {
          relNameSet.add(id)
        }
      } else {
        relNameSet.add(data.id)
      }
    }
  }
  return map
}

// checks if `input` is valid ID accepted by backend API
export function isValidId(input: RecordId): boolean {
  return typeof input === 'string' && input !== '0' && /^[0-9]+$/.test(input)
}

// internal function used by linkRelationship() util to get array of indexes of
// related records via `resLinks`
function getReslinksIndexes(
  state: State,
  type: RecordType,
  resLinks: ResourceLink[],
  relRecords: RecordGeneric[]
): number[] {
  const isIndexed = isRecordTypeIndexed(state, type)
  const indexes = []

  for (const { id } of resLinks) {
    let index = -1

    if (isIndexed) {
      index = getRecordIndex(state, type, id)
    } else {
      index = relRecords.findIndex((relItem) => relItem.id === id)
    }
    if (index !== -1) {
      indexes.push(index)
    }
  }

  return indexes.sort((a, b) => a - b)
}

export function checkTypeExists(state: State, type: RecordType) {
  if (!state.records[type]) {
    throw new Error(`Unknown record type: ${type}`)
  }
}

export function checkTypeWritable(state: State, type: RecordType) {
  if (typeof state.records[type] === 'function') {
    throw new Error(`${type} record type is a read-only getter`)
  }
}

// separates array of records to ones with ID and ones without
// used internally in jsonapi mutation utils
export function classifyRecords(records: RecordGeneric[]) {
  const withId = []
  const withoutId = []

  for (const m of records) {
    if (typeof m.id === 'undefined') {
      withoutId.push(m)
    } else {
      withId.push(m)
    }
  }

  return {
    withId,
    withoutId,
  }
}
