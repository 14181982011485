<template>
  <div>
    <base-button
      :data-id="`${dataIdPrefix}.base_date_range_picker_bottom_sheet.open_bottom_sheet`"
      @click.native="openBottomSheet()"
    >
      <base-icon
        icon="calendar/calendar-16"
        size="1rem"
        class="mr-2 relative bottom-px"
      />
      <span v-if="shortcutSelected">{{ shortcutSelected }}</span>
      <span v-if="!shortcutSelected">{{ textDateRange }}</span>
    </base-button>
    <portal v-if="passedIsOpen" to="bottom-sheet">
      <bottom-sheet
        :header-title="textSelectingDateRange"
        :data-id="`${dataIdPrefix}.base_date_range_picker_bottom_sheet.close_bottom_sheet`"
        @cancel-click="closeBottomSheet()"
      >
        <template v-slot:header-right>
          <button
            v-if="hasSelectedCustomRange"
            class="justify-self-end text-right text-lg text-green"
            :data-id="`${dataIdPrefix}.base_date_range_picker_bottom_sheet.apply`"
            @click="$emit('apply')"
          >
            {{ $t('daterangepicker.button_apply') }}
          </button>
        </template>
        <div>
          <div class="border-b border-coal-100 mb-4">
            <div class="base-date-range-picker is-mobile">
              <base-date-picker
                v-model="dateRange"
                :range="true"
                :inline="true"
                :date-creator="$attrs['date-creator']"
                :custom-focused-date="customFocusedDate"
                :data-id-prefix="`${dataIdPrefix}.base_date_range_picker_bottom_sheet`"
                classes-carousel-arrow-prev="ml-1"
                classes-carousel-arrow-next="mr-1"
                @range-start="$emit('range-start', $event)"
                @range-end="$emit('range-end', $event)"
              >
                <template v-slot:header>
                  <slot name="header" />
                </template>
              </base-date-picker>
            </div>
          </div>
          <mobile-action-bar class="hidden">
            <base-button
              v-for="shortcut in shortcuts"
              :key="shortcut.id"
              :data-id="`${dataIdPrefix}.base_date_range_picker.shortcuts.${shortcut.id}`"
              @click="$emit('apply-shortcut', shortcut)"
            >
              <div class="flex items-center">
                <span class="font-medium mr-2">
                  {{ shortcut.name }}
                </span>
                <span v-if="shortcut.label" class="font-medium text-coal-550">{{
                  shortcut.label
                }}</span>
              </div>
            </base-button>
          </mobile-action-bar>
        </div>
      </bottom-sheet>
    </portal>
  </div>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseDatePicker from '../date-picker/BaseDatePicker'
import MobileActionBar from '@/app/util-modules/ui/mobile-action-bar/MobileActionBar'

export default {
  name: 'BaseDateRangePickerBottomSheet',
  components: {
    BottomSheet,
    BaseIcon,
    BaseButton,
    BaseDatePicker,
    MobileActionBar,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: false,
      default: null,
    },
    shortcutSelected: {
      type: String,
      required: false,
      default: '',
    },
    textDateRange: {
      type: String,
      required: false,
      default: '',
    },
    shortcuts: {
      type: Array,
      required: true,
    },
    isSelectingCustomRange: {
      type: Boolean,
      required: true,
    },
    hasSelectedCustomRange: {
      type: Boolean,
      required: true,
    },
    textSelectingDateRange: {
      type: String,
      required: false,
      default: '',
    },
    customFocusedDate: {
      type: Date,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    dateRange: {
      get() {
        return this.value
      },
      set(dateRange) {
        this.$emit('input', dateRange)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
  methods: {
    openBottomSheet() {
      this.passedIsOpen = true
    },
    closeBottomSheet() {
      this.passedIsOpen = false
      this.$emit('reset')
    },
  },
}
</script>
