<template>
  <div>
    <notification-list
      :is-open.sync="isOpen"
      :notifications="notifications"
      :is-loading="$actions.listNotifications.isLoading"
      :data-id-prefix="`${dataIdPrefix}.notification_container`"
      @delete-all="deleteAll()"
      @delete="deleteSingle($event)"
      @scroll-end="
        !$actions.listNotifications.isLoading && listNotificationsNextPage()
      "
    />
  </div>
</template>

<script>
import NotificationList from './NotificationList'

import { mapActions, mapGetters } from 'vuex'
import {
  NOTIFICATION_ACTION_LIST_NOTIFICATIONS,
  NOTIFICATION_ACTION_LIST_NOTIFICATIONS_NEXT_PAGE,
  NOTIFICATION_ACTION_DELETE_NOTIFICATION,
  NOTIFICATION_ACTION_DELETE_ALL_NOTIFICATIONS,
} from '../../store/actions/notification.actions.names'
import { NOTIFICATION_GETTER_NOTIFICATIONS_LIST } from '../../store/getters/notification.getters.names'

export default {
  name: 'NotificationContainer',
  components: {
    NotificationList,
  },
  props: {
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  trackedActions: {
    listNotifications: NOTIFICATION_ACTION_LIST_NOTIFICATIONS,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      storeNotifications: NOTIFICATION_GETTER_NOTIFICATIONS_LIST,
    }),
    notifications() {
      return this.storeNotifications.map((notification) => ({
        id: notification.id,
        type: notification.type,
        title: notification.attributes.title,
        message: notification.attributes.message,
        category: notification.entity.type,
        createdAt: notification.attributes.createdAt,
        username: notification.user.attributes?.username || '',
      }))
    },
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.listNotifications(1)
      }
    },
  },
  created() {
    this.listNotifications(1)
  },
  methods: mapActions({
    listNotifications: NOTIFICATION_ACTION_LIST_NOTIFICATIONS,
    listNotificationsNextPage: NOTIFICATION_ACTION_LIST_NOTIFICATIONS_NEXT_PAGE,
    deleteSingle: NOTIFICATION_ACTION_DELETE_NOTIFICATION,
    deleteAll: NOTIFICATION_ACTION_DELETE_ALL_NOTIFICATIONS,
  }),
}
</script>
