import { shortname } from '@/utils/store.js'
import * as getterNamesObj from './user.getters.names'
import { USER_TYPE_ROLE_ADMIN } from '../../constants/user.constants'
import { USER_SUGGESTED_FOLLOWERS_TYPE } from '@/app/core/user/models/user-suggested-followers.model'
import { TIME_TRACKING_MODULE } from '@/app/modules/time-tracking/constants'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.USER_GETTER_USER]: (state) => state.user,

  [getterNames.USER_GETTER_HIGHEST_ROLE]: (state) => {
    const userRoles = state.user.relationships.roles?.data

    if (userRoles && userRoles.length) {
      const highest = [...userRoles]
        .sort((a, b) => parseInt(a.id) - parseInt(b.id))
        .pop()

      return state.roles.find(({ id }) => id === highest.id)
    }
    return null
  },

  [getterNames.USER_GETTER_IS_ROLE_ADMIN]: (state, getters) => {
    const highestRole = getters[getterNames.USER_GETTER_HIGHEST_ROLE]
    return highestRole && highestRole.name.includes('admin')
  },

  [getterNames.USER_GETTER_IS_ASSUMED_ROLE_ADMIN]: (state) =>
    state.user.attributes.assumed_role === USER_TYPE_ROLE_ADMIN,

  [getterNames.USER_GETTER_FEATURE_NAMES]: (state) =>
    state.userFeatures ? Object.keys(state.userFeatures) : [],

  [getterNames.USER_GETTER_FEATURE]: (state) => (featureName) =>
    state.userFeatures[featureName],

  [getterNames.USER_GETTER_FEATURE_PERMISSIONS]: (state) => (featureName) =>
    state.userFeatures[featureName]?.attributes.permissions || {},

  [getterNames.USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE]:
    (state, getters) => (featureName) => {
      const isAdmin = getters[getterNames.USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
      const permissions =
        getters[getterNames.USER_GETTER_FEATURE_PERMISSIONS](featureName)

      return isAdmin ? permissions.read_index : permissions.visible
    },

  [getterNames.USER_GETTER_INDEX_READABLE_FEATURES]: (state, getters) => {
    const featureNames = getters[getterNames.USER_GETTER_FEATURE_NAMES]
      .filter((key) =>
        getters[getterNames.USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](key)
      )
      .map((featureName) =>
        getters[getterNames.USER_GETTER_FEATURE](featureName)
      )
    return featureNames
  },

  [getterNames.USER_GETTER_GET_USER_DETAILS]: (state) => (userId) =>
    state.data[userId],

  [getterNames.USER_PUBLIC_GETTER_ROLE_LIST]: (state) => {
    return state.roles
  },

  [getterNames.USER_GETTER_PREVIEW_BANNER]: (state) => {
    return state.previewBanner
  },

  [getterNames.USER_PUBLIC_GETTER_PREFERENCE]: (state) => (preferenceKey) =>
    state.records.preference.find((p) => p.key === preferenceKey),

  [getterNames.USER_PUBLIC_GETTER_SUGGESTED_FOLLOWERS]: (state) =>
    state.records[USER_SUGGESTED_FOLLOWERS_TYPE],

  [getterNames.USER_PUBIC_GETTER_SETTINGS_VISIBLE]: (state, getters) => {
    const isAdmin = getters[getterNames.USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    if (!isAdmin) return false

    const userRole = getters[getterNames.USER_GETTER_HIGHEST_ROLE].name
    if (userRole === 'account_admin') return true

    const necessaryRole =
      state.user.account.attributes.necessary_role_to_edit_companies

    if (userRole === 'workspace_admin' && necessaryRole === 'workspace_admin')
      return true
    else return false
  },

  [getterNames.USER_PUBLIC_GETTER_TRACKING_CONTROLS_POPUP]: (
    _state,
    getters
  ) => {
    const hasTimeTrackingModule =
      getters[getterNames.USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](
        TIME_TRACKING_MODULE
      )

    const isTrackingBrowserTimeClock =
      getters[getterNames.USER_GETTER_USER].attributes.time_tracking_method ===
      'via_browser_time_clock'

    // Backend checkbox called "Browser punch clock" and
    // "Browser clock runs via action" respectively
    const { browser_punch_clock, with_actions } =
      getters[getterNames.USER_GETTER_USER].account.attributes

    // Backend checkbox called "Enable beta link to switch frontends"
    const newPapershiftAccess =
      getters[getterNames.USER_GETTER_FEATURE]('General').attributes.permissions
        .new_papershift_access

    return (
      hasTimeTrackingModule &&
      !with_actions &&
      browser_punch_clock &&
      isTrackingBrowserTimeClock &&
      newPapershiftAccess
    )
  },

  [getterNames.USER_PUBLIC_GETTER_IS_METABASE_REPORTS_ENABLED]: (
    state,
    getters
  ) => {
    const isAssumedAdmin =
      getters[getterNames.USER_GETTER_IS_ASSUMED_ROLE_ADMIN]

    const isMetabaseReportsEnabled =
      getters[getterNames.USER_GETTER_USER].account.attributes
        .metabase_reporting

    return isMetabaseReportsEnabled && isAssumedAdmin
  },
}
