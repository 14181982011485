<template>
  <bottom-sheet-topbar
    v-model="passedValue"
    icon="tag/tag-16"
    :selected-count.sync="passedSelectedCount"
    :items="items"
    :title="
      editMode
        ? $t('tag.bottom_sheet.option_edit')
        : $t('tag.bottom_sheet.title')
    "
    :is-open.sync="passedIsOpen"
    :text-button-new="$t('tag.bottom_sheet.button_new_tag')"
    :searchable="searchable"
    :search-placeholder="$t('tag.bottom_sheet.search_placeholder')"
    :type-button-new="typeButtonNew"
    :more-options="moreOptions"
    :edit-mode="editMode"
    :is-loading="isLoading"
    :has-search-query="hasSearchQuery"
    :can-create="permissions.create"
    :data-id-prefix="`${dataIdPrefix}.tag_bottom_sheet`"
    @selected-option="handleSelection($event)"
    @disable-edit-mode="disableEditMode()"
    @edit="openEditForm($event)"
    @click-button-new="$emit('create')"
    @scroll-end="$emit('scroll-end')"
    @typing="$emit('typing', $event)"
  />
</template>

<script>
import BottomSheetTopbar from '@/app/util-modules/ui/bottom-sheet-topbar/BottomSheetTopbar'

export default {
  name: 'TagBottomSheet',
  components: {
    BottomSheetTopbar,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
    },
    typeButtonNew: {
      type: String,
      required: false,
      default: 'Add',
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    hasSearchQuery: {
      type: Boolean,
      required: false,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editMode: false,
    }
  },
  computed: {
    passedSelectedCount: {
      get() {
        return this.selectedCount
      },
      set(selectedCount) {
        this.$emit('update:selectedCount', selectedCount)
      },
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
    moreOptions() {
      if (this.editMode || !this.permissions.update) {
        return []
      }
      return [
        {
          id: 'edit',
          label: this.$t('tag.bottom_sheet.option_edit'),
          icon: 'edit-pencil/edit-pencil-16',
        },
      ]
    },
  },
  methods: {
    handleSelection(option) {
      if (option.id === 'edit') {
        this.editMode = true
      }
    },
    disableEditMode() {
      this.editMode = false
    },
    openEditForm(area) {
      this.editMode = false
      this.$emit('edit', area)
    },
  },
}
</script>
