interface BaseCalendarEvent {
  statusClass: string
  title: string
  startDate: string
  endDate: string
}

interface BaseCalendarHeaderDay {
  dayName: string
  dayNumber: string
}

interface BaseCalendarResource {
  name: string
}

export const baseCalendarEventTemplate = ({
  statusClass,
  title,
  startDate,
  endDate,
}: BaseCalendarEvent): string => `
  <div class="relative h-full rounded ${statusClass}">
    <div class="flex justify-center items-center rounded h-full">
      <div class="event-content select-none">
        <div class="uppercase font-bold mb-1">${title}</div>
        <div class="flex whitespace-nowrap">
          <span>${startDate}</span>
          <span class="mx-1">${startDate && endDate && '&nbsp;-&nbsp;'}</span>
          <span>${endDate}</span>
        </div>
      </div>
      <div class="absolute w-full h-full rounded"></div>
    </div>
  </div>
`

export const baseCalendarEventPartDayTemplate = ({
  statusClass,
}: {
  statusClass: string
}): string => `
  <div class="relative h-full rounded ${statusClass}">
  <div class="flex justify-center items-center rounded h-full">
  </div>
</div>
`

export const baseCalendarHeaderDayTemplate = ({
  dayName,
  dayNumber,
}: BaseCalendarHeaderDay): string => `
  <div class="md-date-header h-full flex flex-col items-center justify-evenly font-semibold text-xs">
    <div class="md-date-header-day-name uppercase">${dayName}</div>
    <div class="md-date-header-day-number">${dayNumber}</div>
  </div>
`

export const baseCalendarResourceTemplate = ({
  name,
}: BaseCalendarResource): string => `
  <div class="b-tooltip is-primary is-right is-medium w-full is-multiline" multilined="true" >
    <span class="resource-text-content">${name}</span>
  </div>
`
