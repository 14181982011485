import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import App from './App.vue'
import i18n from './utils/vendors/i18n'
import init from './utils/vendors'

// Global Exception Handler
import './utils/exceptions/vue-exception-handler'

// Service worker registration
import './utils/register-service-worker'

// Main entry of global styles
import '@/assets/style/main.scss'

Vue.use(VueCompositionAPI)

init.vendors()
init.monitoring()
init.plugins()
init.modules().then(() => {
  new Vue({
    name: 'MainInstance',
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
})
