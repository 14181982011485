import * as getterNamesObj from './notification.getters.names'
import { shortname } from '@/utils/store'

import { USER_GETTER_GET_USER_DETAILS } from '@/app/core/user/store/getters/user.getters.names'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.NOTIFICATION_GETTER_NOTIFICATIONS_LIST]: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    return state.list.map((id) => {
      const notification = state.data[id]
      const userDetails =
        (notification.user &&
          rootGetters[USER_GETTER_GET_USER_DETAILS](notification.user.id)) ||
        {}
      return {
        ...notification,
        user: userDetails,
      }
    })
  },
}
