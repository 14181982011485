// import app from "../main";
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import i18n from '@/utils/vendors/i18n'
import { AUTH_ACTION_LOGOUT } from '@/app/core/auth/store/actions/auth.actions.names'
import {
  AUTH_GETTER_TOKEN,
  AUTH_GETTER_IS_AUTHENTICATED,
} from '@/app/core/auth/store/getters/auth.getters.names'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { HTTP_HEADER_CLIENT } from '@/constants'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

service.interceptors.request.use(
  (serviceConfig) => {
    // app.$Progress.start();

    serviceConfig.headers['Content-Type'] = 'application/vnd.api+json'
    serviceConfig.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    serviceConfig.headers.common.crossDomain = true
    serviceConfig.headers['Client'] = HTTP_HEADER_CLIENT

    const authToken = store.getters[AUTH_GETTER_TOKEN]

    const isAdminView = store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]

    if (authToken && serviceConfig.requestName !== 'sign-in') {
      serviceConfig.headers.common['Authorization'] = `Bearer ${authToken}`
    }

    if (!isAdminView) {
      serviceConfig.headers.common['Employee-View'] = true
    }

    return serviceConfig
  },
  () => {
    // app.$Progress.fail();
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.config.requestName !== 'sign-in' // no need to logout on sign-in requests
      ) {
        if (!store.getters[AUTH_GETTER_IS_AUTHENTICATED]) {
          return Promise.reject(error)
        }

        store.dispatch(AUTH_ACTION_LOGOUT)

        if (
          router.currentRoute.name !== 'auth.login' &&
          store.state.auth.cookieSignInStatus !== 'in-progress'
        ) {
          router.push({
            name: 'auth.login',
          })
        }

        Snackbar.open({
          type: 'is-warning',
          message: i18n.t('auth.logged_out'),
        })
      } else if (error.response.status !== 422) {
        error.response.data.errors?.forEach((err) => {
          Snackbar.open({
            type: 'is-danger',
            message: err.title,
          })
        })
      }
    }

    return Promise.reject(error)
  }
)

export default service
