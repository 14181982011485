<template>
  <button
    class="module-label flex min-w-0 items-center font-semibold text-coal"
  >
    <div class="mr-2 truncate">
      {{ label }}
    </div>
    <base-icon
      icon="arrow/arrow-12"
      :rotation="active ? -90 : 90"
      size="0.875rem"
      type="is-stroke"
    />
  </button>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'TabsTopbarTrigger',
  components: {
    BaseIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
