import { MbscEventClickEvent } from '@mobiscroll/javascript'

export interface MbscResource {
  id: string
  name: string
}

export interface CurrentEventInfo {
  id?: string
  isOpen: boolean
  target: HTMLElement | null
  eventActiveClassList: string[]
  resourceName: string
  datePeriod: string
  canUpdate?: boolean
}

type confirmationStatusType = 'rejected' | 'pending' | 'confirmed'

export interface MbscEvent extends MbscEventClickEvent {
  id: string
  start: string | Date
  end: string | Date
  title: string
  allDay: string | boolean
  confirmationStatus: confirmationStatusType
  event: { [k: string]: string }
  domEvent: MouseEvent
  canUpdate?: string
}

export interface EventData {
  original?: any
  title?: string | undefined
  start: string
  end: string
  confirmationStatus?: confirmationStatusType
}

interface TimelineGridScroll {
  (
    baseCalendarElement: HTMLElement,
    TIMELINE_GRID_SCROLL_CLASS: string,
    callback: (timeLineGrid: HTMLElement) => void
  ): void
}

export const popupOnPosition = (
  event: MbscEvent,
  inst: any,
  TIMELINE_GRID_SCROLL_CLASS: string
): boolean => {
  const anchor = inst.props.anchor as HTMLElement
  const popup = inst._popup as HTMLElement

  const MARGIN = 10
  const EMPLOYEE_COLUMN_WIDTH = 200

  const timelineGrid = document.querySelector(
    TIMELINE_GRID_SCROLL_CLASS
  ) as HTMLElement

  const gridRect = timelineGrid.getBoundingClientRect()
  const gridTop = gridRect.top
  const gridBottom = gridRect.bottom
  const gridLeft = gridRect.left
  const gridRight = gridRect.right

  const anchorRect = anchor.getBoundingClientRect()
  const anchorTop = anchorRect.top
  const anchorBottom = anchorRect.bottom
  const anchorLeft = anchorRect.left
  const anchorRight = anchorRect.right

  const maxTopSpace = anchorTop - gridTop
  const maxBottomSpace = gridBottom - anchorBottom
  const maxLeftSpace = anchorLeft - gridLeft
  const maxRightSpace = gridRight - anchorRight

  const popupWidth = popup.offsetWidth
  const popupHeight = popup.offsetHeight

  let popupTop: number = anchorTop
  let popupLeft: number = anchorRight + MARGIN

  if (popupWidth >= maxRightSpace) {
    popupLeft = anchorLeft - popupWidth - MARGIN
  }

  if (popupHeight >= maxBottomSpace) {
    popupTop = gridBottom - popupHeight - MARGIN
  }

  if (popupHeight >= maxTopSpace) {
    popupTop = anchorBottom
  }

  if (
    popupWidth >= maxRightSpace &&
    popupWidth >= maxLeftSpace - EMPLOYEE_COLUMN_WIDTH
  ) {
    if (popupHeight >= maxBottomSpace) {
      popupTop = anchorTop - popupHeight
      popupLeft = anchorLeft - MARGIN
    } else {
      popupTop = anchorBottom
      popupLeft = anchorLeft - MARGIN
    }
  }

  popup.style.top = `${popupTop}px`
  popup.style.left = `${popupLeft}px`

  return false
}

export const isEventOverlapping = (
  resource: string | number,
  startDate: string,
  endDate: string,
  data: MbscEvent[]
) => {
  const overlappingEvents = data.filter(
    (event: MbscEvent) =>
      event.resource === resource &&
      new Date(event.end) > new Date(startDate) &&
      new Date(event.start) < new Date(endDate)
  )

  return overlappingEvents.length > 1
}

const isTextOverflowing = (element: any) => {
  const parent = element.parentNode
  return parent && element.scrollWidth > parent.clientWidth
}

export const updateResourceTooltip = (
  TIMELINE_RESOURCE_TEXT_CONTENT: string,
  TOOLTIP_CONTENT_CLASS: string
) => {
  const resourceElements = document.querySelectorAll(
    TIMELINE_RESOURCE_TEXT_CONTENT
  )
  for (const element of resourceElements) {
    if (isTextOverflowing(element)) {
      const tooltipContent = document.createElement('div')
      tooltipContent.classList.add(TOOLTIP_CONTENT_CLASS)
      tooltipContent.textContent = element.textContent
      if (element.parentNode) {
        element.parentNode.insertBefore(tooltipContent, element)
      }
    }
  }
}

export const getVisibleMonth = (
  element: HTMLElement,
  TIMELINE_HEADER_MONTH_CLASS: string
): string[] => {
  const monthElements = element.getElementsByClassName(
    TIMELINE_HEADER_MONTH_CLASS
  )
  const visibleMonths: string[] = []
  for (const monthElement of monthElements) {
    const rect = monthElement.getBoundingClientRect()
    if (
      rect.left < element.offsetWidth &&
      rect.right > 0 &&
      monthElement.textContent
    ) {
      visibleMonths.push(monthElement.textContent)
    }
  }

  return visibleMonths
}

export const getTimelineGridScroll: TimelineGridScroll = (
  baseCalendarElement,
  TIMELINE_GRID_SCROLL_CLASS,
  callback
) => {
  const timeLineGrid = baseCalendarElement.querySelector(
    TIMELINE_GRID_SCROLL_CLASS
  ) as HTMLElement
  timeLineGrid.addEventListener('scroll', () => callback(timeLineGrid))
}

export const removeTimelineGridScroll: TimelineGridScroll = (
  baseCalendarElement,
  TIMELINE_GRID_SCROLL_CLASS,
  callback
) => {
  const timeLineGrid = baseCalendarElement.querySelector(
    TIMELINE_GRID_SCROLL_CLASS
  ) as HTMLElement
  timeLineGrid.removeEventListener('scroll', () => callback(timeLineGrid))
}
