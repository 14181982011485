<template>
  <div class="ml-1.5">
    <base-tooltip
      :label="tooltip"
      :multilined="$attrs['tooltip-multilined']"
      :size="$attrs['tooltip-size']"
      :position="$attrs['tooltip-position'] || 'is-top'"
      :active="showTooltip"
    >
      <slot />
    </base-tooltip>
  </div>
</template>

<script>
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip'

export default {
  name: 'BaseGroupSelectionToolbarActionItem',
  components: {
    'base-tooltip': BaseTooltip,
  },
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showTooltip() {
      if (this.hideTooltip) {
        return false
      }

      return this.tooltip !== ''
    },
  },
}
</script>
