<template>
  <div
    class="actionbar max-w-full-vw h-full flex space-x-2 flex-nowrap hidden-scrollbar overflow-y-hidden overflow-x-scroll"
    :style="cssProps"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MobileActionBar',
  props: {
    spacing: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    cssProps() {
      return {
        '--spacing': `${this.spacing / 4}rem`,
      }
    },
  },
}
</script>

<style scoped>
.actionbar::before,
.actionbar::after {
  min-width: var(--spacing);
  content: '';
}

.actionbar > :first-child {
  margin-left: unset;
}
</style>
