<template>
  <transition
    :appear="$isSmallDevice"
    name="sidebar-transition"
    :duration="$isSmallDevice ? 300 : 0"
  >
    <div
      v-if="!$isSmallDevice || baseMobileSidebarOpen"
      class="z-index-sidebar w-full h-full flex fixed sidebar-width md:static md:flex-shrink-0"
    >
      <div
        class="overlay md:hidden absolute inset-0 w-full"
        @click="hideMobileSidebar()"
      />
      <div
        class="sidebar z-index-sidebar relative flex flex-col w-10/12 sidebar-width md:fixed h-full bg-green-350"
      >
        <app-sidebar-header />
        <app-sidebar-apps @module-navigation="onModuleNavigation($event)" />
        <app-sidebar-footer-items class="mt-auto" />
        <tracking-controls
          v-if="
            $isSmallDevice &&
            (isTrackingControlsEnabled || showTrackingControlsPopup)
          "
          :show-popup="showTrackingControlsPopup"
          :disabled="
            $actions.updateTracking.isLoading ||
            $actions.fetchActiveTracking.isLoading
          "
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import emitter from '@/app/util-modules/emitter'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE,
  USER_PUBLIC_GETTER_TRACKING_CONTROLS_POPUP,
} from '@/app/core/user/store/getters/user.getters.names'
import {
  BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR,
  BASE_MUTATION_INCREMENT_OVERLAY,
  BASE_MUTATION_DECREMENT_OVERLAY,
} from '@/store/mutations/store.mutations.names'
import {
  TIME_TRACKING_ACTION_SHOW_ACTIVE,
  TIME_TRACKING_ACTION_UPDATE_ACTIONS,
} from '@/app/modules/time-tracking/store/actions/time-tracking.actions.names'
import AppSidebarHeader from '@/app/util-modules/ui/app-sidebar/AppSidebarHeader'
import AppSidebarApps from '@/app/util-modules/ui/app-sidebar/AppSidebarApps'
import AppSidebarFooterItems from '@/app/util-modules/ui/app-sidebar/AppSidebarFooterItems.vue'

const TrackingControls = () =>
  import('@/app/util-modules/ui/tracking-controls/TrackingControls')

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarHeader,
    AppSidebarApps,
    AppSidebarFooterItems,
    TrackingControls,
  },
  trackedActions: {
    fetchActiveTracking: TIME_TRACKING_ACTION_SHOW_ACTIVE,
    updateTracking: TIME_TRACKING_ACTION_UPDATE_ACTIONS,
  },
  computed: {
    ...mapState(['baseMobileSidebarOpen']),
    ...mapGetters({
      showTrackingControlsPopup: USER_PUBLIC_GETTER_TRACKING_CONTROLS_POPUP,
    }),
    isTrackingControlsEnabled() {
      const hasTimeTrackingModule = this.$store.getters[
        USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE
      ](TIME_TRACKING_MODULE_NAME)

      const { use_browser_punch_clock } = this.$store.getters[
        USER_GETTER_FEATURE_PERMISSIONS
      ](TIME_TRACKING_MODULE_NAME)

      if (!use_browser_punch_clock) return false
      return hasTimeTrackingModule
    },
  },
  watch: {
    baseMobileSidebarOpen(open) {
      // increment/decrement overlay count in vuex store,
      // when mobile sidebar toggled
      if (open) {
        this.addOverlayCount()
      } else {
        this.removeOverlayCount()
      }
    },
  },
  methods: {
    ...mapMutations({
      hideMobileSidebar: BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR,
      addOverlayCount: BASE_MUTATION_INCREMENT_OVERLAY,
      removeOverlayCount: BASE_MUTATION_DECREMENT_OVERLAY,
    }),
    onModuleNavigation(moduleName) {
      emitter.emit('app-sidebar.module-navigation', moduleName)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.custom-dropdown-is-top-right .dropdown-menu {
  top: 0;
  left: 100%;
  padding: 0;
}
.custom-dropdown-is-bottom-right .dropdown-menu {
  bottom: 0;
  left: 100%;
  padding: 0;
}

.overlay {
  background-color: $modal-background-background-color;
}

.sidebar-transition-enter-active,
.sidebar-transition-leave-active {
  .overlay,
  .sidebar {
    @apply transition-all duration-300;
  }
}
.sidebar-transition-enter,
.sidebar-transition-leave-to {
  .overlay {
    @apply opacity-0;
  }
  .sidebar {
    @apply -left-full;
  }
}
.sidebar-transition-enter-to,
.sidebar-transition-leave {
  .overlay {
    @apply opacity-100;
  }
  .sidebar {
    @apply left-0;
  }
}

.z-index-sidebar {
  z-index: $z-index-sidebar;
}
.sidebar-width {
  @screen md {
    width: $app-sidebar-width;
  }
}
</style>
