<template>
  <div>
    <bottom-sheet-topbar-trigger
      :count="selectedCount"
      :icon="icon"
      :data-id-prefix="`${dataIdPrefix}.bottomsheet_topbar`"
      @click.native="openBottomSheet()"
    />

    <portal v-if="isOpen" to="bottom-sheet">
      <bottom-sheet
        :header-title="title"
        :header-left-actions="bottomSheetActions.left"
        :header-right-actions="bottomSheetActions.right"
        :header-more-options="moreOptions"
        @cancel-click="closeBottomSheet()"
        @selected-option="$emit('selected-option', $event)"
        @apply="applyChanges()"
        @scroll-end="$emit('scroll-end')"
      >
        <base-loading :active="isLoading" />
        <treeselect
          v-model="currentValue"
          :items="items"
          :searchable="!editMode"
          :hide-last-divider="editMode"
          classes-searchbar="mt-2 mb-2 px-3"
          size-searchbar="is-large"
          size-checkbox="is-medium"
          position-checkbox="is-right"
          classes-item="text-lg pl-4 py-4"
          :disabled="editMode"
          @change-selected-count="currentSelectedCount = $event"
          @click-searchbar="overlay = true"
        >
          <template v-if="editMode" v-slot:right="{ item }">
            <div v-if="item.editable">
              <button class="mr-4 text-coal-450" @click="$emit('edit', item)">
                <base-icon icon="edit-pencil/edit-pencil-24" size="1.5rem" />
              </button>
            </div>
          </template>
          <template v-slot:divider>
            <div class="border-t border-coal-100" />
          </template>
          <template v-slot:item-divider>
            <div class="ml-4 border-t border-coal-100" />
          </template>
          <template v-slot:no-matching-items>
            <div class="text-center text-coal-450 p-3">
              {{ $t('topbar.bottom_sheet.no_matches') }}
            </div>
          </template>
          <template v-slot:no-items>
            <slot name="no-items">
              <div class="text-center text-coal-450 p-3">
                {{ $t('topbar.bottom_sheet.no_items') }}
              </div>
            </slot>
          </template>
        </treeselect>
        <div v-if="canCreate" class="bg-white -bottom-3 sticky">
          <bottom-sheet-topbar-button-new
            v-if="!editMode"
            :type-button-new="typeButtonNew"
            :text-button-new="textButtonNew"
            @click.native="$emit('click-button-new')"
          />
        </div>
      </bottom-sheet>
    </portal>

    <portal v-if="overlay" to="mobile-overlay">
      <base-overlay-search
        v-model="currentValue"
        :options="searchItems"
        :placeholder="searchPlaceholder"
        field="label"
        @typing="$emit('typing', $event)"
        @click-button-done="handleOverlayDone()"
        @scroll-end="$emit('scroll-end')"
      />
    </portal>
  </div>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import Treeselect from '@/app/util-modules/ui/treeselect/Treeselect'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BottomSheetTopbarTrigger from './BottomSheetTopbarTrigger'
import BottomSheetTopbarButtonNew from './BottomSheetTopbarButtonNew'

import BaseOverlaySearch from '@/app/util-modules/ui/overlay-search/BaseOverlaySearch'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'

export default {
  name: 'BottomSheetTopbar',
  components: {
    BottomSheet,
    BottomSheetTopbarTrigger,
    BottomSheetTopbarButtonNew,
    BaseOverlaySearch,
    Treeselect,
    BaseIcon,
    BaseLoading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    textButtonNew: {
      type: String,
      required: true,
    },
    typeButtonNew: {
      type: String,
      required: false,
      default: 'basic',
    },
    moreOptions: {
      type: Array,
      required: false,
      default: () => undefined,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    hasSearchQuery: {
      type: Boolean,
      required: false,
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overlay: false,
      currentSelectedCount: this.selectedCount,
      currentValue: this.value,
    }
  },
  computed: {
    searchItems() {
      if (this.overlay && this.hasSearchQuery && !this.isLoading) {
        return this.items
      } else {
        return []
      }
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
    bottomSheetActions() {
      const actions = {}

      if (this.editMode) {
        actions.left = []
      }
      actions.right = [
        {
          title: this.editMode
            ? this.$t('topbar.bottom_sheet.button_done')
            : this.$t('topbar.bottom_sheet.button_apply'),
          class: 'font-semibold text-green-400',
          event: 'apply',
        },
      ]
      return actions
    },
  },
  methods: {
    openBottomSheet() {
      this.currentValue = this.value
      this.passedIsOpen = true
    },
    closeBottomSheet() {
      this.$emit('disable-edit-mode')
      this.passedIsOpen = false
    },
    applyChanges() {
      if (this.editMode) {
        this.$emit('disable-edit-mode')
        return
      }

      this.passedIsOpen = false
      this.$emit('update:selectedCount', this.currentSelectedCount)
      this.$emit('input', this.currentValue)
    },
    handleOverlayDone() {
      this.$emit('typing', '')
      this.overlay = false
    },
  },
}
</script>

<style scoped>
.hide-divider {
  height: 1px;
  margin-top: -1px;
  background: white;
}
</style>
