const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout'
  )

export default [
  {
    path: '*',
    meta: {
      layout: DefaultLayout,
    },
    component: () => import('../pages/NotFoundPage'),
  },
]
