import axios from '@/utils/vendors/axios'
import {
  GetCurrentUserRequest,
  GetCurrentUserResponse,
} from '../models/get-current-user.model.js'
import {
  GetWorkspaceCurrentUserRequest,
  GetWorkspaceCurrentUserResponse,
} from '../models/get-workspace-current-user.model.js'
import { FeatureList } from '@/app/util-modules/feature/models/feature.model'
import { UpdateUserRequest } from '../models/user.model'

import { RoleListRequest } from '../models/role.model'
import {
  GetUserPreferencesRequest,
  UpdateUserPreferencesRequest,
} from '../models/user-preferences.model'
import { GetUserSuggestedFollowersRequest } from '../models/user-suggested-followers.model'

import {
  FEATURES_ENDPOINT_LIST_FEATURES,
  USER_ENDPOINT_SWITCH_ROLE,
} from './user.endpoints'

import { GetUserRecordsSummaryRequest } from '../models/get-records-summary'
import { GetUserVacationsSummaryRequest } from '../models/get-vacations-summary'

export default {
  async listUserFeatures(workspaceId) {
    const response = await axios.get(
      FEATURES_ENDPOINT_LIST_FEATURES(workspaceId)
    )
    return new FeatureList(response.data.data)
  },

  async getCurrentUser() {
    const response = await axios.request(new GetCurrentUserRequest())

    return new GetCurrentUserResponse(response)
  },

  async getWorkspaceCurrentUser(workspaceId) {
    const response = await axios.request(
      new GetWorkspaceCurrentUserRequest({ workspaceId })
    )

    return new GetWorkspaceCurrentUserResponse(response)
  },

  async switchRole(workspaceId) {
    await axios.get(USER_ENDPOINT_SWITCH_ROLE(workspaceId))
  },

  async updateUser(workspaceId, userId, data) {
    const response = await axios.request(
      new UpdateUserRequest({ workspaceId, userId, data })
    )
    return response.data
  },

  listRoles(workspaceId) {
    return axios.request(new RoleListRequest({ workspaceId }))
  },

  getUserPreferences(userId) {
    return axios.request(new GetUserPreferencesRequest(userId))
  },
  updateUserPreferences({ userId, preferenceId, value }) {
    return axios.request(
      new UpdateUserPreferencesRequest({ userId, preferenceId, value })
    )
  },
  getUserSuggestedFollowersRequest({ workspaceId, pagination, filter }) {
    return axios.request(
      new GetUserSuggestedFollowersRequest({ workspaceId, pagination, filter })
    )
  },

  getUserRecordsSummary({ workspaceId, userId }) {
    return axios.request(new GetUserRecordsSummaryRequest(workspaceId, userId))
  },
  getUserVacationsSummary({ workspaceId, userId }) {
    return axios.request(
      new GetUserVacationsSummaryRequest(workspaceId, userId)
    )
  },
}
