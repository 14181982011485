import i18n from '@/utils/vendors/i18n/index'
import { formatTimeFilter } from '@/utils/date-time.utils'
import { runOrReturn } from '@/utils/helpers'

const getOperatorLabel = (target, availableOperators) => {
  return runOrReturn(
    availableOperators.find((operator) => operator.id === target).label
  )
}

// time filter
function getTimeDisplayText(operator, query, prefix) {
  if (operator === 'between') {
    const { startTime, endTime } = query

    return `${prefix} ≥ ${formatTimeFilter(startTime)} ≤ ${formatTimeFilter(
      endTime
    )}`
  } else {
    const operatorLabel = operator === 'gte' ? '≥' : '≤'
    return `${prefix} ${operatorLabel} ${formatTimeFilter(query)}`
  }
}

const timeFilterDisplayedValue = (appliedValue) => {
  if (!appliedValue) {
    return ''
  }

  const { startTimeOperator, endTimeOperator, startTimeQuery, endTimeQuery } =
    appliedValue.form

  const stack = []

  if (startTimeOperator !== 'not_set') {
    stack.push(
      getTimeDisplayText(
        startTimeOperator,
        startTimeQuery,
        i18n.t('filters.time_start_prefix')
      )
    )
  }

  if (endTimeOperator !== 'not_set') {
    stack.push(
      getTimeDisplayText(
        endTimeOperator,
        endTimeQuery,
        i18n.t('filters.time_end_prefix')
      )
    )
  }

  return stack.join(', ')
}

export const timeFilter = {
  operators: [
    { label: () => i18n.t('filter_operators.not_set'), id: 'not_set' },
    { label: () => i18n.t('filter_operators.earlier_than'), id: 'lte' },
    { label: () => i18n.t('filter_operators.later_than'), id: 'gte' },
    { label: () => i18n.t('filter_operators.is_between'), id: 'between' },
  ],
  getDisplayedValue: timeFilterDisplayedValue,
  switchOperator: function (queryFieldName) {
    return function (newOperator, oldOperator) {
      const value = this.form[queryFieldName]
      if (newOperator === 'between') {
        this.form[queryFieldName] = {
          startTime: { ...value },
          endTime: { ...value },
        }
      } else if (oldOperator === 'between') {
        const { startTime } = value
        this.form[queryFieldName] = startTime
          ? {
              ...startTime,
            }
          : {}
      }
    }
  },
}

// string filter
const stringFilterDisplayedValue = (
  appliedValue,
  availableOperators = stringFilter.operators
) => {
  if (!appliedValue) {
    return ''
  }
  const { query, operator } = appliedValue.form

  return `${getOperatorLabel(operator, availableOperators)} "${query}"`
}

export const stringFilter = {
  operators: [
    { label: () => i18n.t('filter_operators.contains'), id: 'ct' },
    { label: () => i18n.t('filter_operators.does_not_contain'), id: '!ct' },
    { label: () => i18n.t('filter_operators.exactly'), id: 'eq' },
  ],
  getDisplayedValue: stringFilterDisplayedValue,
}

// duration filter
const durationFilterDisplayedValue = (
  appliedValue,
  availableOperators = durationFilter.operators
) => {
  if (!appliedValue) {
    return ''
  }

  const { query, operator } = appliedValue.form

  if (operator === 'between') {
    const { startTime, endTime } = query

    return `≥ ${formatTimeFilter(startTime)} ≤ ${formatTimeFilter(endTime)}`
  } else {
    const operatorLabel = getOperatorLabel(operator, availableOperators)
    return `${operatorLabel} ${formatTimeFilter(query)}`
  }
}

export const durationFilter = {
  operators: [
    { label: () => i18n.t('filter_operators.is_between'), id: 'between' },
    { label: '=', id: 'eq' },
    { label: '>', id: 'gt' },
    { label: '<', id: 'lt' },
    { label: '≥', id: 'gte' },
    { label: '≤', id: 'lte' },
  ],
  getDisplayedValue: durationFilterDisplayedValue,
}

// select filter
const selectFilterDisplayedValue = (appliedValue, params) => {
  if (!appliedValue) {
    return ''
  }

  const {
    form: { items },
  } = appliedValue
  return items
    .map((item) =>
      runOrReturn(params.options.find((option) => option.value === item).label)
    )
    .join(', ')
}

export const selectFilter = {
  getDisplayedValue: selectFilterDisplayedValue,
}

// radio filter
const radioFilterDisplayedValue = (appliedValue, params) => {
  if (!appliedValue) {
    return ''
  }
  const option = params.options.find(
    (opt) => opt.value === appliedValue.form.value
  )
  return runOrReturn(option?.label || '')
}

export const radioFilter = {
  getDisplayedValue: radioFilterDisplayedValue,
}

export default {
  durationFilter,
  selectFilter,
  radioFilter,
  timeFilter,
  stringFilter,
}
