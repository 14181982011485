<script>
export default {
  name: 'IconLoader',
  props: {
    icons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      importedIcons: null,
      loaded: false,
    }
  },
  watch: {
    async icons() {
      this.importedIcons = await this.loadIcons()
    },
  },
  async created() {
    this.importedIcons = await this.loadIcons()
    this.loaded = true
  },
  methods: {
    loadIcons() {
      const imports = this.icons.map((icon) =>
        import(
          /* webpackChunkName: "base-icons-[request]" */
          `@/assets/icons/svg/${icon}.svg`
        )
      )
      return Promise.all(imports)
    },
  },
  render() {
    return this.$scopedSlots.default({
      icons: this.importedIcons,
      loaded: this.loaded,
    })
  },
}
</script>
