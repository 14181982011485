import Vue from 'vue'

Vue.config.errorHandler = (err) => {
  // Not to report Axios failed requests to Sentry
  if (typeof err !== 'undefined' && err.isAxiosError) {
    err.name = 'SafeAxiosError'
    return
  }

  throw err
}
