<template>
  <div>{{ value }}</div>
</template>

<script>
export default {
  name: 'BaseTableRowCell',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
}
</script>
