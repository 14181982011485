import Vue from 'vue'
import VueRouter from 'vue-router'
import coreRoutes from './routes'

import interceptors from './interceptors'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  ...coreRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

interceptors.forEach((interceptor) => router.beforeEach(interceptor))

router.onError((error) => {
  const pattern = /Loading chunk/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
