export const BASE_GETTER_WINDOW_WIDTH = 'BASE_GETTER_WINDOW_WIDTH'
export const BASE_GETTER_DEVICE_SIZE = 'BASE_GETTER_DEVICE_SIZE'
export const BASE_GETTER_IS_SMALL_DEVICE = 'BASE_GETTER_IS_SMALL_DEVICE'
export const BASE_GETTER_IS_MEDIUM_DEVICE = 'BASE_GETTER_IS_MEDIUM_DEVICE'
export const BASE_GETTER_IS_LARGE_DEVICE = 'BASE_GETTER_IS_LARGE_DEVICE'
export const BASE_GETTER_AUTH_LAYOUT_IS_SMALL_DEVICE =
  'BASE_GETTER_AUTH_LAYOUT_IS_SMALL_DEVICE'
export const BASE_GETTER_AUTH_LAYOUT_IS_LARGE_DEVICE =
  'BASE_GETTER_AUTH_LAYOUT_IS_LARGE_DEVICE'
export const BASE_GETTER_OLD_MODULES_URLS = 'BASE_GETTER_OLD_MODULES_URLS'
