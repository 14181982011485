
























































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import { formatDate, formatTime } from '@/utils/date-time.utils'
import { isSameDay } from 'date-fns'

export default defineComponent({
  name: 'BaseCalendarEventPopup',
  components: {
    BaseButton,
  },
  props: {
    popupInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isApprovedLoading = ref(false)
    const isRejectLoading = ref(false)

    const handleActionApproved = () => {
      isApprovedLoading.value = true
      emit('approve-action-click', props.popupInfo)
    }

    const handleActionReject = () => {
      isRejectLoading.value = true
      emit('reject-action-click', props.popupInfo)
    }

    const popUpInfoDatePeriod = computed(() => {
      if (
        !props.popupInfo.allDay &&
        isSameDay(
          props.popupInfo.originalStartDate,
          props.popupInfo.originalEndDate
        )
      ) {
        return formatDate(new Date(props.popupInfo.originalStartDate))
      } else if (isSameDay(props.popupInfo.start, props.popupInfo.end)) {
        return formatDate(new Date(props.popupInfo.start))
      }
      return props.popupInfo.datePeriod
    })

    const displaySpecifiedHours = computed(() => {
      if (
        !props.popupInfo.allDay &&
        props.popupInfo.originalStartDate &&
        props.popupInfo.originalEndDate
      ) {
        const start = formatTime(props.popupInfo.originalStartDate)
        const end = formatTime(props.popupInfo.originalEndDate)
        return `| ${start} - ${end}`
      }
      return ''
    })

    watch(
      () => props.popupInfo,
      () => {
        isApprovedLoading.value = false
        isRejectLoading.value = false
      }
    )

    return {
      handleActionApproved,
      handleActionReject,
      isApprovedLoading,
      isRejectLoading,
      displaySpecifiedHours,
      popUpInfoDatePeriod,
    }
  },
})
