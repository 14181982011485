class RegisterRequest {
  constructor(form) {
    this.data = {
      type: 'user',
      attributes: {
        username: form.username,
        email: form.email,
        password: form.password,
      },
    }
  }
}

class RegisterResponse {
  constructor(response) {
    const [tokenType, tokenValue] = response.headers.authorization.split(' ')

    this.tokenType = tokenType
    this.token = tokenValue
    this.user = response.data.data
  }
}

export { RegisterRequest, RegisterResponse }
