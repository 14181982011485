<template>
  <base-dropdown
    class="more-options"
    :is-open.sync="isOpen"
    v-bind="$attrs"
    :mobile-modal="false"
  >
    <div slot="trigger">
      <base-button
        icon-right="more"
        icon-type="is-filled"
        type="is-filled-grey"
        class="base-more-options-trigger"
        :data-id="dataIdPrefix && `${dataIdPrefix}.more_options`"
      />
    </div>
    <button
      v-for="option in options"
      :key="option.id"
      type="button"
      class="flex w-full items-center hover:bg-coal-40 px-2 p-1.5 rounded cursor-pointer"
      :class="option.classes"
      :data-id="dataIdPrefix && `${dataIdPrefix}.more_options.${option.id}`"
      @click="handleClick(option)"
    >
      <base-icon class="mr-2" :icon="option.icon" />
      {{ option.label }}
    </button>
  </base-dropdown>
</template>

<script>
import BaseDropdown from 'buefy/src/components/dropdown/Dropdown'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseMoreOptions',
  components: {
    BaseDropdown,
    BaseButton,
    BaseIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    // TODO: remove default and set as required
    dataIdPrefix: {
      type: String,
      default: 'base_more_options',
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    handleClick(option) {
      this.isOpen = false
      this.$emit('selected', option)
    },
  },
}
</script>

<style scoped>
.more-options >>> .dropdown-content {
  @apply my-2 w-48;
}
.more-options >>> .base-more-options-trigger {
  @apply text-coal-450;
}
</style>
