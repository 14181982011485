<template>
  <div class="base-time-picker-container w-full">
    <div v-if="!$isSmallDevice || duration" class="flex w-full items-center">
      <base-input
        v-model="hour"
        type="number"
        :min="hourLimit.min"
        :max="hourLimit.max"
        :step="hourInterval"
        name="base-time-picker-hour"
        class="base-time-picker control w-full"
        placeholder="hh"
        :size="size"
        :disabled="disabled"
        :data-id="`${dataIdPrefix}.base_time_picker.input_hour`"
        @input.native="handleInput($event, 'hour')"
      />
      <div class="separator mx-2 font-medium text-coal-550 text-lg"> : </div>
      <base-input
        v-model="minute"
        type="number"
        :min="minuteLimit.min"
        :max="minuteLimit.max"
        :step="minuteInterval"
        name="base-time-picker-minute"
        class="base-time-picker control w-full"
        placeholder="mm"
        :size="size"
        :disabled="disabled"
        :data-id="`${dataIdPrefix}.base_time_picker.input_minute`"
        @input.native="handleInput($event, 'minute')"
      />
    </div>
    <div v-else-if="$isSmallDevice" class="w-full">
      <b-timepicker
        v-model="mobileTime"
        class="w-full mobile-time-picker"
        :size="size"
        mobile-native
        :reset-on-meridian-change="false"
        :locale="locale"
        :data-id="`${dataIdPrefix}.base_time_picker.mobile_time`"
      />
    </div>
  </div>
</template>

<script>
import BaseInput from '@/app/util-modules/ui/input/BaseInput'
import BTimepicker from 'buefy/src/components/timepicker/Timepicker'
import i18n from '@/utils/vendors/i18n'

import { setHours, setMinutes, startOfDay } from 'date-fns'
import { isValidDate } from '@/utils/date-time.utils'

export default {
  name: 'BaseTimePicker',
  components: { BTimepicker, BaseInput },
  props: {
    value: {
      type: Object,
      required: true,
    },
    hourInterval: {
      type: Number,
      default: 1,
      validator: (value) => [1, 2, 3, 4, 6, 8, 12].indexOf(value) !== -1,
    },
    minuteInterval: {
      type: Number,
      default: 1,
      validator: (value) =>
        [1, 2, 3, 4, 5, 6, 10, 15, 20, 30].indexOf(value) !== -1,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'is-medium',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hour: '',
      minute: '',
      hourLimit: {
        min: 0,
        max: 23,
      },
      minuteLimit: {
        min: 0,
        max: 59,
      },
    }
  },
  computed: {
    locale() {
      return i18n.locale
    },
    mobileTime: {
      get() {
        return setMinutes(
          setHours(startOfDay(new Date()), this.passedValue.hours),
          this.passedValue.minutes
        )
      },
      set(mobileTime) {
        if (!isValidDate(mobileTime)) {
          this.passedValue = {
            hours: 0,
            minutes: 0,
          }
        } else {
          const hours = mobileTime.getHours()
          const minutes = mobileTime.getMinutes()

          this.hour = hours.toString().padStart(2, 0)
          this.minute = minutes.toString().padStart(2, 0)
          this.passedValue = {
            hours,
            minutes,
          }
        }
      },
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
        if (this.value !== passedValue && passedValue)
          this.$emit('change', this.value !== passedValue)
      },
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (typeof newValue !== 'undefined') {
          this.setHour(newValue.hours)
          this.setMinute(newValue.minutes)
        }
      },
    },
  },
  mounted() {
    if (typeof this.value !== 'undefined') {
      if (typeof this.value.hours !== 'undefined') {
        this.setHour(this.value.hours)
      }
      if (typeof this.value.minutes !== 'undefined') {
        this.setMinute(this.value.minutes)
      }
    }
  },
  methods: {
    setHour(hour) {
      this.hour = hour.toString().padStart(2, 0)
    },
    setMinute(minute) {
      this.minute = minute.toString().padStart(2, 0)
    },
    isWithinRange(value, range) {
      return value >= range.min && value <= range.max
    },
    isValidHour(hour) {
      return this.isWithinRange(hour, this.hourLimit)
    },
    isValidMinute(minute) {
      return this.isWithinRange(minute, this.minuteLimit)
    },
    handleHourChange(value) {
      if (this.isValidHour(value)) {
        this.passedValue = {
          hours: value,
          minutes: parseInt(this.minute, 10),
        }
      } else {
        this.setHour(this.value.hours)
      }
    },
    handleMinuteChange(value) {
      if (this.isValidMinute(value)) {
        this.passedValue = {
          hours: parseInt(this.hour, 10),
          minutes: value,
        }
      } else {
        this.setMinute(this.value.minutes)
      }
    },
    handleInput(event, type) {
      const value =
        event.target.value === '' ? 0 : parseInt(event.target.value, 10)

      if (type === 'hour') {
        this.handleHourChange(value)
      } else {
        this.handleMinuteChange(value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.base-time-picker-container {
  @apply flex-1;
  .base-time-picker {
    .input {
      &.is-danger {
        @apply pr-0;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
::v-deep.base-time-picker input::-webkit-outer-spin-button,
::v-deep.base-time-picker input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep .base-time-picker input[type='number'] {
  -moz-appearance: textfield;
}

::v-deep
  .mobile-time-picker
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  background: none;
}
</style>
