<template>
  <div class="relative">
    <buefy-input
      ref="input"
      class="base-input-number"
      :class="{ 'with-controls': !hideControls }"
      v-bind="$attrs"
      :value="value"
      :size="size"
      :data-id="dataId"
      :controls="false"
      :long-press="false"
      type="number"
      @onchange.native="$emit('input', $event)"
      v-on="$listeners"
    />

    <div
      v-if="!hideControls"
      class="controls absolute flex flex-col right-0 top-0 px-3 py-1"
    >
      <base-icon
        class="cursor-pointer text-green-400 hover:text-green-500"
        icon="arrow/arrow-16"
        size="1rem"
        :rotation="-90"
        @click.native="increaseValue()"
      />

      <base-icon
        class="cursor-pointer text-green-400 hover:text-green-500"
        icon="arrow/arrow-16"
        size="1rem"
        :rotation="90"
        @click.native="decreaseValue()"
      />
    </div>
  </div>
</template>

<script>
import BuefyInput from 'buefy/src/components/input/Input'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseInputNumber',
  components: {
    BuefyInput,
    BaseIcon,
  },
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    size: {
      type: String,
      validator: (value) => ['is-small', 'is-medium'].indexOf(value) !== -1,
      default: 'is-medium',
    },
    dataId: {
      type: String,
      default: '',
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    increaseValue() {
      const inputEl = this.$refs.input.$el.getElementsByTagName('input')[0]
      inputEl.stepUp()
      this.$emit('input', inputEl.value)
    },
    decreaseValue() {
      const inputEl = this.$refs.input.$el.getElementsByTagName('input')[0]
      inputEl.stepDown()
      this.$emit('input', inputEl.value)
    },
  },
}
</script>

<style scoped>
.base-input-number >>> input.input {
  @apply text-left;
}

/* Remove native input number controls */
.base-input-number >>> input.input::-webkit-outer-spin-button,
.base-input-number >>> input.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.base-input-number >>> input.input[type='number'] {
  -moz-appearance: textfield;
}

.base-input-number.with-controls >>> input.input {
  @apply pr-7;
}

.controls {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
