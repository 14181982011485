<template>
  <base-modal>
    <!-- md:px-0 px-4: Maintain 16px Margin On Mobile -->
    <div class="md:px-0 px-4">
      <div class="modal-card dialog">
        <base-dialog-header :title="title" @close="$emit('close')" />
        <div class="px-4 md:px-6 pb-8">
          <slot />
        </div>
        <div
          class="px-4 md:px-6 py-4 border-t border-coal-150 w-full h-18 flex items-center flex-shrink-0"
        >
          <base-dialog-footer
            :loading="loading"
            :disabled="submitDisabled"
            :button-submit-text="buttonSubmitText"
            :more-options="footerMoreOptions"
            :hide-button-cancel="hideButtonCancel"
            :button-type="buttonType"
            @close="$emit('close')"
            @done="$emit('done')"
          >
            <template v-slot:footer-actions>
              <slot name="footer-actions" />
            </template>
          </base-dialog-footer>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseDialogHeader from './BaseDialogHeader'
import BaseDialogFooter from './BaseDialogFooter'

export default {
  name: 'BaseDialog',
  components: {
    BaseModal,
    BaseDialogHeader,
    BaseDialogFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonSubmitText: {
      type: String,
      default: '',
    },
    footerMoreOptions: {
      type: Array,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    hideButtonCancel: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'is-filled',
    },
  },
}
</script>

<style scoped>
::v-deep .animation-content .modal-card {
  @apply m-auto;
}
.dialog {
  max-width: 400px;
  @apply relative md:w-auto w-full rounded-md md:rounded bg-white-400;
}
</style>
