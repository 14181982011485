













































import { defineComponent, PropType } from '@vue/composition-api'
// as of now, buefy does not provide types for all components
// @ts-ignore
import BuefyCollapse from 'buefy/src/components/collapse/Collapse.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'

interface CollapseItem {
  id: string
  title?: string
  content?: string
}

export default defineComponent({
  name: 'BaseCollapse',
  components: { BuefyCollapse, BaseIcon, BaseButton },
  props: {
    item: {
      type: Object as PropType<CollapseItem>,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    headerActionLabel: {
      type: String,
      default: '',
    },
  },
})
