class SignInRequest {
  constructor(form) {
    this.data = {
      type: 'user',
      attributes: {
        email: form.email,
        password: form.password,
      },
    }
  }
}

class SignInResponse {
  constructor(response) {
    this.token = response.data.data.attributes.access_token
  }
}

export { SignInRequest, SignInResponse }
