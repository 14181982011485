import User, { USER_TYPE } from './user.model'
import { USER_ENDPOINT_GET_WORKSPACE_CURRENT_USER } from '../network/user.endpoints'
import BaseRequest from '@/utils/api/base-request'

export class GetWorkspaceCurrentUserRequest extends BaseRequest {
  constructor({ workspaceId }) {
    super()
    super.url = USER_ENDPOINT_GET_WORKSPACE_CURRENT_USER(workspaceId)
    super.method = 'get'
    super.type = USER_TYPE
  }
}

export class GetWorkspaceCurrentUserResponse {
  constructor(response) {
    this.user = new User(response.data.data)
  }
}
