






import {
  defineComponent,
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import {
  eventcalendar,
  setOptions,
  locale,
  registerComponent,
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
} from '@mobiscroll/javascript'
import {
  baseShiftplanHeaderDayTemplate,
  baseShiftplanResourceTemplate,
  baseShiftplanEventTemplate,
  ShiftAttributes,
  baseShiftplanHeaderTemplate,
  baseShiftplanResourceHeader,
} from './BaseShiftplanTemplates'
import i18n, { supportedLanguages } from '@/utils/vendors/i18n'
import { TranslateResult } from 'vue-i18n'

import '@mobiscroll/javascript/dist/css/mobiscroll.min.css'

import { getISOWeek, isMonday, isSunday, getYear } from 'date-fns'
import { formatDateFilter } from '@/utils/date-time.utils'

type Language = {
  id: string
  name: () => TranslateResult
}

interface MbscResource {
  id: string
  name: string
  color: string
}

export default defineComponent({
  name: 'BaseShiftplan',
  props: {
    id: {
      type: String,
      required: true,
    },
    resources: {
      type: Array as () => {
        id: string
        name: string
      }[],
      default: () => [],
    },
    data: {
      type: Array as () => any[],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    registerComponent(CalendarNav)
    registerComponent(CalendarNext)
    registerComponent(CalendarPrev)
    registerComponent(CalendarToday)

    let baseShiftplanInstance = ref<any>(null)
    const currentDate = ref<Date>(new Date())
    const FULLYSTAFFED = 'fully-staffed'
    const UNDERSTAFFED = 'under-staffed'
    const ACTIVE_WEEK_NUMBER_ELEMENT_ID = 'active-week-number'
    let activeWeekNumber = ref(0)
    let activeStartOfWeekMonday = ref<Date>(new Date())
    let activeEndOfWeekSunday = ref<Date>(new Date())
    const MONTH_NAME_FORMAT = 'MMMM'

    const shiftplanId = computed(() => `${props.id}-base-calendar`)

    const renderDay = (args: { date: Date }) => {
      const { date } = args
      if (isSunday(date)) {
        activeEndOfWeekSunday.value = date
      }
      if (isMonday(date)) {
        activeStartOfWeekMonday.value = date
      }
      activeWeekNumber.value = getISOWeek(date)
      const htmlString = baseShiftplanHeaderDayTemplate({
        date: date,
        currentDate: currentDate.value,
      })
      return htmlString
    }

    const renderHeader = () => {
      const htmlString = baseShiftplanHeaderTemplate()
      return htmlString
    }

    const renderResourceHeader = () => {
      const htmlString = baseShiftplanResourceHeader(
        formatDateFilter(activeStartOfWeekMonday.value, MONTH_NAME_FORMAT),
        formatDateFilter(activeEndOfWeekSunday.value, MONTH_NAME_FORMAT),
        getYear(activeEndOfWeekSunday.value)
      )
      return htmlString
    }

    const renderResource = (resource: MbscResource) => {
      const htmlString = baseShiftplanResourceTemplate({
        name: resource.name,
        color: resource.color,
      })
      return htmlString
    }

    const renderScheduleEvent = (event: ShiftAttributes) => {
      if (event.original.placeholder) {
        return `<div class="shift-card-placeholder ${
          event.original.color
            ? 'shift-card-placeholder-area-color'
            : 'shift-card-placeholder-no-area-color'
        }"></div>`
      } else {
        const staffedStatusClass =
          event.original.users.length >= event.original.numberOfEmployees
            ? FULLYSTAFFED
            : UNDERSTAFFED

        const areaFooterColor = event.original.color

        const htmlString = baseShiftplanEventTemplate(
          event,
          staffedStatusClass,
          areaFooterColor
        )
        return htmlString
      }
    }

    const currentLanguage = computed(() => {
      return supportedLanguages.find(
        (language: Language) => language.id === i18n.locale
      )
    })

    const onInit = (
      event: { [k: string]: string },
      inst: { [k: string]: any }
    ) => {
      emit('week-range-change', [inst._firstDay, inst._lastDay - 1])
    }

    const updateActiveWeekNumberElement = (activeWeekNumber: number) => {
      const activeWeekNumberElement = document.getElementById(
        ACTIVE_WEEK_NUMBER_ELEMENT_ID
      )
      if (activeWeekNumberElement) {
        activeWeekNumberElement.innerHTML = `${activeWeekNumber}`
      }
    }

    watch(
      () => currentLanguage.value,
      async () => {
        await setOptions({
          locale:
            locale[
              currentLanguage.value?.id === 'ch'
                ? 'de'
                : currentLanguage.value?.id || 'en'
            ],
        })
        updateActiveWeekNumberElement(activeWeekNumber.value)
      },
      {
        immediate: true,
      }
    )

    watch(
      () => props.resources,
      () => {
        setOptions({
          resources: props.resources,
        })
      },
      {
        immediate: true,
      }
    )

    watch(
      () => props.data,
      () => {
        setOptions({
          data: props.data,
        })
      },
      {
        immediate: true,
      }
    )

    watch(
      () => activeWeekNumber.value,
      () => {
        updateActiveWeekNumberElement(activeWeekNumber.value)
      },
      {
        immediate: true,
      }
    )

    watch(
      () => activeStartOfWeekMonday.value && activeEndOfWeekSunday.value,
      () => {
        setOptions({
          resources: [{ id: 1, name: '' }], // set default resource to trigger renderResourceHeader
        })
        emit('week-range-change', [
          activeStartOfWeekMonday.value,
          activeEndOfWeekSunday.value,
        ])
      }
    )

    onMounted(() => {
      setOptions({
        theme: 'windows',
        themeVariant: 'light',
      })
      baseShiftplanInstance.value = eventcalendar(`#${shiftplanId.value}`, {
        actionableEvents: false,
        showEventTooltip: false,
        firstDay: 1,
        view: {
          timeline: {
            type: 'week',
            // weekNumbers: true,
            rowHeight: 'equal',
            eventList: true,
            startDay: 1,
            endDay: 0,
          },
        },
        renderDay,
        renderHeader,
        renderResourceHeader,
        renderResource,
        renderScheduleEvent,
        // onInit,
      })
    })

    onUnmounted(() => {
      setOptions({
        resources: [],
        data: [],
      })
      baseShiftplanInstance.value.destroy()
      baseShiftplanInstance.value = null
    })

    return {
      currentDate,
      shiftplanId,
      baseShiftplanInstance,
      activeStartOfWeekMonday,
      activeEndOfWeekSunday,

      renderDay,
      renderHeader,
      renderResourceHeader,
      renderResource,
      renderScheduleEvent,
      onInit,
    }
  },
})
