<template>
  <portal v-if="isOpen" to="bottom-sheet">
    <bottom-sheet-form-wrapper
      :header-right-actions="bottomSheetActions"
      :header-more-options="moreOptions"
      @handle-selection="$emit('handle-selection', $event)"
      @done="$emit('update-tag')"
      @close="$emit('close')"
    >
      <div class="p-4">
        <slot />
      </div>
    </bottom-sheet-form-wrapper>
  </portal>
</template>

<script>
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'

export default {
  name: 'TagBottomSheetFormEdit',
  components: {
    BottomSheetFormWrapper,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    bottomSheetActions() {
      return [
        {
          title: this.$t('tag.form_edit.button_submit'),
          loading: this.isLoading,
          class: 'justify-self-end text-green',
          event: 'update',
        },
      ]
    },
  },
}
</script>
