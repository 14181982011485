import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import state from './store.state'
import getters from './getters/store.getters'
import mutations from './mutations/store.mutations'
import actions from './actions/store.actions'
import { subscribeActions } from './store.subscribers'

import auth from '@/app/core/auth/store/auth.store'
import dashboard from '@/app/core/dashboard/store/dashboard.store'
import user from '@/app/core/user/store/user.store'
import tag from '@/app/core/tag/store/tag.store'
import area from '@/app/core/area/store/area.store'
import workspace from '@/app/core/workspace/store/workspace.store'
import actionStatus from '@/app/util-modules/action-status/store/action-status.store'
import monitoring from '@/app/util-modules/monitoring/store/monitoring.store'
import notification from '@/app/core/notification/store/notification.store'
import summary from '@/app/util-modules/summary/store/summary.store'

Vue.use(Vuex)

const plugins = [
  createPersistedState({
    key: 'vuex-persist',
    paths: [
      'user.user',
      'user.roles',
      'workspace.workspace',
      'workspace.workspacesList',
      'tag.selected',
      'area.selected',
    ],
  }),
]

const isDebugEnv =
  process.env.VUE_APP_MODE === 'development' ||
  process.env.VUE_APP_MODE === 'staging'

if (isDebugEnv && process.env.VUE_APP_DEBUG_VUEX === 'true') {
  plugins.push(createLogger())
}

export type RootState = Record<string, any>

const store = new Vuex.Store<RootState>({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth,
    dashboard,
    user,
    tag,
    area,
    actionStatus,
    monitoring,
    workspace,
    notification,
    summary,
  },
  strict: isDebugEnv,
  plugins,
})

subscribeActions(store)

export default store
