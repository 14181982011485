<template>
  <portal v-if="isOpen" to="bottom-sheet">
    <bottom-sheet-form-wrapper
      :header-right-actions="bottomSheetActions"
      :header-more-options="moreOptions"
      @handle-selection="$emit('handle-selection', $event)"
      @done="$emit('update-area', formData)"
      @close="$emit('close')"
    >
      <div class="p-4">
        <area-form
          v-model="formData"
          :loading="status.isLoading"
          :action-status="preloadActionStatus"
          :area="modelArea"
          :users="users"
          vertical
        />
      </div>
    </bottom-sheet-form-wrapper>
  </portal>
</template>

<script>
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'

import AreaForm from '../area-form/AreaForm'

export default {
  name: 'AreaBottomSheetFormEdit',
  components: {
    BottomSheetFormWrapper,
    AreaForm,
  },
  props: {
    modelArea: {
      type: Object,
      required: false,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    preloadActionStatus: {
      type: Object,
      default: () => ({}),
    },
    // TODO: Integrate the status properly according to design
    status: {
      type: Object,
      required: true,
      default: () => ({
        isLoading: false,
      }),
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    bottomSheetActions() {
      return [
        {
          title: this.$t('area.form_edit.button_submit'),
          loading: this.status.isLoading,
          class: 'justify-self-end text-green',
          event: 'update',
        },
      ]
    },
  },
}
</script>
