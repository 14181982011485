export const TAG_MUTATION_SET_TAGS = 'tag/MUTATION_SET_TAGS'
export const TAG_MUTATION_ADD_TAGS = 'tag/MUTATION_ADD_TAGS'
export const TAG_MUTATION_SET_META = 'tag/MUTATION_SET_META'
export const TAG_MUTATION_UPDATE_PAGE = 'tag/MUTATION_UPDATE_PAGE'
export const TAG_MUTATION_ADD_TAG = 'tag/MUTATION_ADD_TAG'
export const TAG_MUTATION_UPDATE_TAG = 'tag/MUTATION_UPDATE_TAG'
export const TAG_MUTATION_DELETE_TAG = 'tag/MUTATION_DELETE_TAG'
export const TAG_MUTATION_SET_SELECTED_TAGS = 'tag/MUTATION_SET_SELECTED_TAGS'
export const TAG_MUTATION_ADD_TAGS_DATA = 'tag/MUTATION_ADD_TAGS_DATA'
export const TAG_MUTATION_ADD_LOADING_TAGS_IDS =
  'tag/MUTATION_ADD_LOADING_TAGS_IDS'
export const TAG_MUTATION_REMOVE_LOADING_TAGS_IDS =
  'tag/MUTATION_REMOVE_LOADING_TAGS_IDS'
