<template>
  <li class="topbar-item-tab">
    <router-link
      :to="tab.route"
      :data-id="`${dataIdPrefix}.tabs_topbar_item_tab`"
    >
      {{ label }}
    </router-link>
  </li>
</template>

<script>
import { runOrReturn } from '@/utils/helpers'

export default {
  name: 'TabsTopbarItemTab',
  props: {
    tab: {
      type: Object,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    label() {
      return runOrReturn(this.tab.label)
    },
  },
}
</script>

<style scoped>
.topbar-item-tab {
  @apply mr-6;
}
.topbar-item-tab a {
  @apply h-15 py-4 font-medium text-lg hover:text-blue-400 text-coal-550;
}
.topbar-item-tab.is-active a {
  @apply text-blue-400 font-bold;
}
</style>
