import { render, staticRenderFns } from "./BaseIcon.vue?vue&type=template&id=2d7f72de&scoped=true&"
import script from "./BaseIcon.vue?vue&type=script&lang=js&"
export * from "./BaseIcon.vue?vue&type=script&lang=js&"
import style0 from "./BaseIcon.vue?vue&type=style&index=0&id=2d7f72de&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_lh6vo7dceoovl5fyh3epe3vcle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d7f72de",
  null
  
)

export default component.exports