import state from './tag.state'
import mutations from './mutations/tag.mutations'
import actions from './actions/tag.actions'
import getters from './getters/tag.getters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
