<template>
  <base-field :name="$attrs.name" :label="$attrs.label">
    <base-dropdown ref="dropdown" v-bind="$attrs">
      <template v-slot:trigger="{ active }">
        <div
          :class="`select-box w-full ${size}`"
          :style="{ borderColor: selected }"
        >
          <div class="flex items-center -mt-0.5">
            <div
              v-if="selected"
              class="w-6 h-6 rounded-full"
              :style="`background-color: ${selected}`"
            />
            <div v-else class="text-coal-550">
              {{ $t('color_picker.no_color') }}
            </div>

            <base-icon
              class="ml-auto"
              :rotation="!active ? 90 : -90"
              icon="arrow/arrow-12"
            />
          </div>
        </div>
      </template>

      <div class="grid w-max grid-cols-6 gap-3 py-2">
        <div
          v-for="(color, index) in options"
          :key="index"
          class="dropdown-color-option w-6 h-6 hover:bg-coal-40 rounded cursor-pointer"
          @click="selectOption(color)"
        >
          <div
            v-if="color"
            class="w-6 h-6 rounded flex items-center justify-center"
            :style="`background-color: ${color}`"
          >
            <base-icon
              v-if="color === selected"
              class="text-white"
              icon="check-tick/check-tick-16"
            />
          </div>
          <div v-else class="relative">
            <div class="w-6 h-6 rounded border-2 border-coal-150" />
            <div class="strike" />
          </div>
        </div>
      </div>
    </base-dropdown>
  </base-field>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseField from '@/app/util-modules/ui/field/BaseField'

export default {
  name: 'BaseDropdownColor',
  components: {
    BaseDropdown,
    BaseIcon,
    BaseField,
  },
  props: {
    options: {
      type: Array,
      default: () => [
        null,
        '#A6DED0',
        '#34BA9C',
        '#2C9F85',
        '#42C978',
        '#37AC66',
        '#409AD7',
        '#3482B5',
        '#3D5797',
        '#995FB2',
        '#8C4CA9',
        '#8F4386',
        '#F3D57C',
        '#EFC241',
        '#EF9A3B',
        '#E27E3B',
        '#CF5526',
        '#E34E47',
        '#BC3B36',
        '#932A24',
        '#BDC3C7',
        '#96A5A6',
        '#808C8D',
        '#6D7A88',
      ],
    },
    size: {
      type: String,
      default: 'is-medium',
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: this.value,
    }
  },
  methods: {
    selectOption(option) {
      this.$refs.dropdown.toggle()
      this.selected = option
      this.$emit('input', option)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '@/assets/style/mixins';

::v-deep .dropdown {
  @apply block;

  .background {
    display: none;
  }
}

.select-box {
  @extend %select-box;

  border-width: 2px;
}

.strike {
  @apply absolute inset-0 rounded;

  background: linear-gradient(
    to left top,
    transparent 45.5%,
    $error-400 47.5%,
    $error-400 52.5%,
    transparent 54.5%
  );
}
</style>
