<template>
  <base-group-message
    v-if="actionStatus.errorMessages && actionStatus.errorMessages.length"
    type="is-danger"
    :aria-close-label="
      $t('base_form_validation.base_group_message_close_label')
    "
    class="text-center"
    :messages="actionStatus.errorMessages"
    :has-icon="true"
  />

  <base-message
    v-else-if="actionStatus.successMessage"
    type="is-success"
    :aria-close-label="$t('base_form_validation.base_message_close_label')"
    class="text-center"
  >
    {{ actionStatus.successMessage }}
  </base-message>
</template>

<script>
import BaseGroupMessage from '@/app/util-modules/ui/message/BaseGroupMessage'
import BaseMessage from '@/app/util-modules/ui/message/BaseMessage'

export default {
  name: 'BaseFormValidationMessages',
  components: {
    BaseMessage,
    BaseGroupMessage,
  },
  props: {
    actionStatus: {
      type: Object,
      required: true,
    },
  },
}
</script>
