import { isValidSearchQuery } from '@/utils/helpers'

export default {
  name: 'BasePaginatedSearchMixin',
  data() {
    return {
      searchContext: {
        options: [],
        lastQuery: '',
        lastQueryTotalPages: null,
        lastPage: 0,
        loading: false,
      },
    }
  },
  methods: {
    async search({ query, page, filter }) {
      try {
        this.searchContext.loading = true
        this.searchContext.lastQuery = query
        this.searchContext.lastPage = page

        if (page === 1) {
          this.searchContext.lastQueryTotalPages = null
          this.searchContext.options = []
        }

        const response = await this.searchStrategy.search(
          isValidSearchQuery(query) ? query : null,
          page,
          filter
        )

        this.searchContext.lastQueryTotalPages = response.totalPages
        this.searchContext.options = this.searchContext.options.concat(
          ...response.results
        )
      } finally {
        this.searchContext.loading = false
      }
    },

    async searchNextPage({ filter } = { filter: { status_eq: 'active' } }) {
      if (
        this.searchContext.lastQueryTotalPages ===
          this.searchContext.lastPage ||
        this.searchContext.loading
      ) {
        return
      }

      await this.search({
        query: this.searchContext.lastQuery,
        page: this.searchContext.lastPage + 1,
        filter,
      })
    },
  },
}
