const AuthLayout = () =>
  import(
    /* webpackChunkName: "AuthLayout" */
    '@/app/util-modules/ui/layouts/AuthLayout'
  )

const CheckinLayout = () =>
  import(
    /* webpackChunkName: "CheckinLayout" */
    '@/app/util-modules/ui/layouts/CheckinLayout'
  )

export default [
  {
    path: '/login',
    name: 'auth.login',
    component: () =>
      import(
        /* webpackChunkName: "auth" */
        '../pages/AuthPageSignIn/AuthPageSignIn.vue'
      ),
    meta: { requiresAuth: false, layout: AuthLayout },
  },
  {
    path: '/forgot-password',
    name: 'auth.forgot-password',
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */
        '../pages/AuthPageForgotPassword/AuthPageForgotPassword.vue'
      ),
    meta: { requiresAuth: false, layout: AuthLayout },
  },
  {
    path: '/change-password/:token',
    name: 'auth.change-password',
    component: () =>
      import(
        /* webpackChunkName: "change-password" */
        '../pages/AuthPageChangePassword/AuthPageChangePassword.vue'
      ),
    meta: { requiresAuth: false, layout: AuthLayout },
  },
  {
    path: '/register',
    component: () => import('../pages/AuthPageRegister/AuthPageRegister'),
    children: [
      {
        path: '/',
        name: 'auth.register',
        components: {
          step: () =>
            import(
              '../components/auth-form/auth-form-register/AuthFormRegisterStepBasic'
            ),
        },
        meta: { requiresAuth: false, layout: AuthLayout },
      },
      {
        path: 'finish',
        name: 'auth.register.company',
        components: {
          step: () =>
            import(
              '../components/auth-form/auth-form-register/AuthFormRegisterStepCompany'
            ),
        },
        meta: {
          requiresAuth(to, from) {
            // when navigated from auth.register route,
            // user will not be fully authenticated,
            // but we let the navigation, because at
            // this point registration is done and
            // auth token is available
            return from.name !== 'auth.register'
          },
          layout: AuthLayout,
        },
      },
    ],
  },
  {
    path: '/join-workspace/:token',
    name: 'auth.checkin-generic-invite',
    component: () =>
      import(
        /* webpackChunkName: "checkin-page" */
        '../pages/AuthCheckinGenericInvitePage/AuthCheckinGenericInvitePage.vue'
      ),
    meta: { requiresAuth: false, layout: CheckinLayout },
  },
  {
    path: '/join/:token',
    name: 'auth.checkin-email-invite',
    component: () =>
      import(
        /* webpackChunkName: "checkin-page" */
        '../pages/AuthCheckinEmailInvitePage/AuthCheckinEmailInvitePage.vue'
      ),
    meta: { requiresAuth: false, layout: CheckinLayout },
  },
  {
    path: '/checkin',
    name: 'auth.checkin',
    component: () =>
      import(
        /* webpackChunkName: "checkin-page" */
        '../pages/AuthCheckinFormPage/AuthCheckinFormPage.vue'
      ),
    meta: { requiresAuth: true, layout: CheckinLayout },
  },
  {
    path: '/checkin-completed',
    name: 'auth.checkin-completed',
    component: () =>
      import(
        /* webpackChunkName: "checkin-page" */
        '../pages/AuthCheckinCompletedPage/AuthCheckinCompletedPage.vue'
      ),
    meta: { requiresAuth: true, layout: CheckinLayout },
  },
]
