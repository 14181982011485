<template>
  <portal v-if="isOpen" to="modal">
    <base-modal has-modal-card @close="$emit('close')">
      <base-card-modal-form
        :loading="isLoading"
        :title="$t('tag.form_create.title')"
        :button-submit-text="$t('tag.form_create.button_submit')"
        @close="$emit('close')"
        @submit="$emit('create-tag')"
        @selected="$emit('handle-selection', $event)"
      >
        <slot />
      </base-card-modal-form>
    </base-modal>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'

export default {
  name: 'TagModalFormCreate',
  components: {
    BaseModal,
    BaseCardModalForm,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
