<template>
  <!-- TODO: match this component with design system and create stories -->
  <div class="flex gap-1">
    <div v-for="(item, index) in items" :key="index">
      <list-circle-color-item :item="item" :class="itemClass">
        <slot name="item" :item="item" />
      </list-circle-color-item>

      <template v-if="index + 1 !== items.length && separator">
        {{ separator }}
      </template>
    </div>
  </div>
</template>

<script>
import ListCircleColorItem from './ListCircleColorItem'
export default {
  name: 'ListCircleColor',
  components: { ListCircleColorItem },
  props: {
    items: {
      type: Array,
      required: true,
      validator: (items) => items.every((item) => item.label),
    },
    separator: {
      type: [String, Boolean],
      default: ', ',
    },
    itemClass: {
      type: String,
      default: '',
    },
  },
}
</script>
