<template>
  <base-dropdown
    ref="dropdown"
    :is-open.sync="passedIsOpen"
    v-bind="$attrs"
    :scrollable="true"
    :max-height="400"
    :data-id="`${dataIdPrefix}.notification_list_dropdown`"
    @scroll-end="$emit('scroll-end')"
  >
    <div slot="trigger" slot-scope="{ active }">
      <notification-list-trigger :active="active" />
    </div>

    <base-loading :active="isLoading" />

    <template v-if="notifications.length">
      <div class="sticky pt-2 top-0 z-4 bg-white">
        <notification-list-header
          data-id-prefix="notification_list_dropdown"
          @delete-all="$emit('delete-all')"
        />
        <hr class="dropdown-divider" />
      </div>

      <notification-list-item
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        aria-role="listitem"
        @delete="$emit('delete', $event)"
      />
    </template>
    <notification-list-empty
      v-if="notifications.length === 0 && !isLoading"
      class="w-48 py-6"
    />
  </base-dropdown>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import NotificationListItem from './NotificationListItem'
import NotificationListHeader from './NotificationListHeader'
import NotificationListEmpty from './NotificationListEmpty'
import NotificationListTrigger from './NotificationListTrigger'

import { NOTIFICATION_ACTION_LIST_NOTIFICATIONS } from '../../store/actions/notification.actions.names'

export default {
  name: 'NotificationListDropdown',
  components: {
    BaseDropdown,
    BaseLoading,
    NotificationListItem,
    NotificationListHeader,
    NotificationListEmpty,
    NotificationListTrigger,
  },
  trackedActions: {
    listNotifications: NOTIFICATION_ACTION_LIST_NOTIFICATIONS,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Array,
      default() {
        return []
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
  watch: {
    isOpen(isOpen) {
      if (!isOpen) {
        this.$refs.dropdown.$el.querySelector('.dropdown-content').scrollTop = 0
      }
    },
  },
}
</script>

<style scoped>
.dropdown >>> .dropdown-content {
  @apply px-2 pt-0 pb-3;
}
.dropdown-divider {
  @apply mt-3 mb-3 mx-2;
}
</style>
