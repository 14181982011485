<template>
  <base-dropdown
    v-bind="$attrs"
    :is-open.sync="passedIsOpen"
    class="base-date-range-picker"
    :data-id="`${dataIdPrefix}.base_date_range_picker.base_dropdown`"
  >
    <template v-slot:trigger="{ active }">
      <slot name="trigger">
        <div class="pb-2" :data-id="`${dataIdPrefix}.trigger`">
          <base-icon
            icon="calendar/calendar-16"
            size="1rem"
            class="mr-2 relative bottom-px"
          />
          <span class="mr-1">{{ shortcutSelectedComputed }}</span>
          <span class="text-coal-550">{{ textDateRange }}</span>
          <base-icon
            class="icon ml-4"
            :rotation="!active ? 90 : -90"
            icon="arrow/arrow-16"
          />
        </div>
      </slot>
    </template>
    <base-date-range-picker-menu
      v-model="dateRange"
      :shortcuts="shortcuts"
      :is-selecting-custom-range="isSelectingCustomRange"
      :custom-focused-date="customFocusedDate"
      :has-selected-custom-range="hasSelectedCustomRange"
      :text-selecting-date-range="textSelectingDateRange"
      :data-id-prefix="`${dataIdPrefix}.base_date_range_picker`"
      @apply-shortcut="$emit('apply-shortcut', $event)"
      @apply="$emit('apply', $event)"
      @range-start="$emit('range-start', $event)"
      @range-end="$emit('range-end', $event)"
    />
  </base-dropdown>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseDateRangePickerMenu from '@/app/util-modules/ui/date-range-picker-menu/BaseDateRangePickerMenu'

export default {
  name: 'BaseDateRangePickerDropdown',
  components: {
    BaseDropdown,
    BaseIcon,
    BaseDateRangePickerMenu,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: false,
      default: null,
    },
    shortcutSelected: {
      type: String,
      required: false,
      default: '',
    },
    textDateRange: {
      type: String,
      required: false,
      default: '',
    },
    shortcuts: {
      type: Array,
      required: true,
    },
    isSelectingCustomRange: {
      type: Boolean,
      required: true,
    },
    hasSelectedCustomRange: {
      type: Boolean,
      required: true,
    },
    textSelectingDateRange: {
      type: String,
      required: false,
      default: '',
    },
    customFocusedDate: {
      type: Date,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    dateRange: {
      get() {
        return this.value
      },
      set(dateRange) {
        this.$emit('input', dateRange)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
    shortcutSelectedComputed() {
      return this.shortcutSelected
    },
  },
}
</script>
