<template>
  <div
    class="base-card-modal modal-card relative max-w-full rounded-md bg-white-400"
    v-on="$listeners"
  >
    <slot name="header">
      <base-card-modal-header :title="title" @close="$emit('close')" />
    </slot>

    <div
      ref="base-card-modal-body"
      :class="{ 'hidden-scrollbar': !hideScrollbar }"
      class="base-card-modal-body px-8 pt-6 pb-10"
    >
      <p v-if="subTitle" class="text-coal-550 font-medium mb-6">
        {{ subTitle }}
      </p>
      <slot />
    </div>

    <slot name="footer">
      <base-card-modal-footer
        :loading="loading"
        :disabled="submitDisabled"
        :button-submit-text="buttonSubmitText"
        :more-options="footerMoreOptions"
        :hide-button-cancel="hideButtonCancel"
        :data-id-prefix="dataIdPrefix"
        @close="$emit('close')"
        @done="$emit('done')"
        @selected="$emit('selected', $event)"
      />
    </slot>
  </div>
</template>

<script>
import BaseCardModalHeader from './BaseCardModalHeader'
import BaseCardModalFooter from './BaseCardModalFooter'

export default {
  name: 'BaseCardModal',
  components: {
    BaseCardModalHeader,
    BaseCardModalFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    buttonSubmitText: {
      type: String,
      default: '',
    },
    footerMoreOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    hideButtonCancel: {
      type: Boolean,
      default: false,
    },
    // TODO: remove default and set as required
    dataIdPrefix: {
      type: String,
      default: 'base_card_modal',
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
    hideScrollbar: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    scrollTop(newValue) {
      this.scrollToOffset(newValue)
    },
  },
  methods: {
    scrollToOffset(offsetTop) {
      this.$refs['base-card-modal-body'].scrollTop = offsetTop
    },
  },
}
</script>

<style scoped>
.base-card-modal-body {
  @apply overflow-y-auto;
}
</style>
