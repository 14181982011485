import * as mutationNames from './store.mutations.names'

export default {
  [mutationNames.BASE_MUTATION_UPDATE_WINDOW_WIDTH](state, width) {
    state.baseWindowWidth = width
  },
  [mutationNames.BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR](state) {
    state.baseMobileSidebarOpen = !state.baseMobileSidebarOpen
  },
  [mutationNames.BASE_MUTATION_INCREMENT_OVERLAY](state) {
    if (++state.baseOverlayCount) {
      // disable page scroll when overlay shown
      document.documentElement.classList.add('is-clipped')
    }
  },
  [mutationNames.BASE_MUTATION_DECREMENT_OVERLAY](state) {
    if (state.baseOverlayCount === 0) {
      return
    }
    if (--state.baseOverlayCount === 0) {
      // enable page scrolling back
      document.documentElement.classList.remove('is-clipped')
    }
  },
}
