import Area, { AreasList } from '../models/area.model'
import { AreaMeta } from '../store/area.state'

interface ResponseData {
  data: {
    data: any
    meta: AreaMeta
  }
}

export class ListAreasResponse {
  areas: Area[]
  meta: AreaMeta

  constructor(response: ResponseData) {
    this.areas = new AreasList(response.data.data).areas
    this.meta = response.data.meta
  }
}
