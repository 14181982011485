import {
  BASE_GETTER_WINDOW_WIDTH,
  BASE_GETTER_DEVICE_SIZE,
  BASE_GETTER_IS_SMALL_DEVICE,
  BASE_GETTER_IS_MEDIUM_DEVICE,
  BASE_GETTER_IS_LARGE_DEVICE,
  BASE_GETTER_AUTH_LAYOUT_IS_SMALL_DEVICE,
  BASE_GETTER_AUTH_LAYOUT_IS_LARGE_DEVICE,
  BASE_GETTER_OLD_MODULES_URLS,
} from './store.getters.names'

import { screens } from '@/utils/tailwind.utils'

export default {
  [BASE_GETTER_WINDOW_WIDTH]: (state) => state.baseWindowWidth,
  [BASE_GETTER_DEVICE_SIZE]: (state, getters) => {
    if (getters[BASE_GETTER_WINDOW_WIDTH] >= screens.lg.min) return 'lg'
    else if (getters[BASE_GETTER_WINDOW_WIDTH] >= screens.md.min) return 'md'
    else return 'sm'
  },
  [BASE_GETTER_IS_SMALL_DEVICE]: (state, getters) => {
    return getters[BASE_GETTER_DEVICE_SIZE] === 'sm'
  },
  [BASE_GETTER_IS_MEDIUM_DEVICE]: (state, getters) => {
    return getters[BASE_GETTER_DEVICE_SIZE] === 'md'
  },
  [BASE_GETTER_IS_LARGE_DEVICE]: (state, getters) => {
    return getters[BASE_GETTER_DEVICE_SIZE] === 'lg'
  },
  [BASE_GETTER_AUTH_LAYOUT_IS_SMALL_DEVICE]: (state, getters) => {
    return (
      getters[BASE_GETTER_DEVICE_SIZE] === 'sm' ||
      getters[BASE_GETTER_DEVICE_SIZE] === 'md'
    )
  },
  [BASE_GETTER_AUTH_LAYOUT_IS_LARGE_DEVICE]: (state, getters) => {
    return getters[BASE_GETTER_DEVICE_SIZE] === 'lg'
  },

  [BASE_GETTER_OLD_MODULES_URLS]: (state) => ({
    Calendar: `https://app.papershift.com/companies/${state.workspace.workspace.id}`,
    WorkspaceUser: `https://app.papershift.com/companies/${state.workspace.workspace.id}/users`,
    Absence: `https://app.papershift.com/companies/${state.workspace.workspace.id}/absences`,
    Payroll: `https://app.papershift.com/companies/${state.workspace.workspace.id}/users/${state.user.user.id}?active_tab=payroll`,
    EmployeeReport: `https://app.papershift.com/companies/${state.workspace.workspace.id}/employee_reports`,
    File: `https://app.papershift.com/accounts/${state.user.user.id}/settings?tab=file-templates`,
    WorkspaceSettings: `https://app.papershift.com/companies/${state.workspace.workspace.id}/settings?tab=master-data`,
    Recurrence: `https://app.papershift.com/companies/${state.workspace.workspace.id}/recurrences`,
    Event: `https://app.papershift.com/companies/${state.workspace.workspace.id}/tasks`,
  }),
}
