export class SearchEmployeeAreaResponse {
  constructor(response) {
    this.results = response.data.data.map((item) => {
      const type = item.attributes.resource_type.toLowerCase()
      const id = item.attributes.resource_id

      return {
        id: `${type}-${id}`,
        originalId: id,
        type,
        title: item.attributes.title,
      }
    })
  }
}
