<template>
  <div class="base-input-tag-container">
    <base-field
      :label="$attrs.label"
      :validation-rules="$attrs['validation-rules']"
      :validation-mode="$attrs['validation-mode']"
      :name="$attrs.name"
    >
      <b-tag-input
        ref="taginput"
        v-model="passedValue"
        class="base-input-tag"
        :class="className"
        :data="relevantOptions"
        :open-on-focus="true"
        :name="$attrs.name"
        :placeholder="$attrs.placeholder"
        :keep-first="false"
        :type="$attrs.type"
        :field="field"
        :icon-right="iconName"
        :check-infinite-scroll="true"
        :append-to-body="$attrs['append-to-body']"
        :dropdown-position="$attrs.direction"
        :size="size"
        ellipsis
        autocomplete
        attached
        :loading="loading"
        :readonly="readonly"
        :maxtags="$attrs.maxtags"
        :has-counter="$attrs['has-counter']"
        @typing="$emit('typing', $event)"
        @add="$emit('add', $event)"
        @infinite-scroll="$emit('infinite-scroll', $event)"
      >
        <template v-slot:empty>
          {{ $t('input_tag.no_results') }}
        </template>
        <template v-slot:selected="{ tags }">
          <b-tag
            v-for="(tag, index) in tags"
            :key="tag.id"
            :type="type"
            :close-type="closeType"
            :size="size"
            :rounded="rounded"
            attached
            :tabstop="false"
            :disabled="disabled"
            ellipsis
            closable
            :title="tag[field]"
            @close="$refs.taginput.removeTag(index, $event)"
          >
            <slot name="tag" :tag="tag">
              {{ tag[field] }}
            </slot>
          </b-tag>
          <b-tag
            v-if="$isSmallDevice && value.length !== 0 && readonly"
            class="add-tag"
            @click.native="$emit('click-button-trigger-add')"
          >
            <base-icon class="text-coal-300" icon="plus/plus-16" size="1rem" />
          </b-tag>
        </template>
        <template v-slot:tag="props">
          <div class="flex items-center">
            <base-icon
              class="mr-2"
              :icon="formatDropDownIcon(props.tag._type || props.tag.type)"
            />
            <div class="has-ellipsis">
              {{ props.tag.title }}
            </div>
          </div>
        </template>
        <template v-slot="props">
          <div class="flex items-center">
            <div class="inline-flex items-center text-coal font-medium mr-2">
              <base-icon
                :icon="
                  formatDropDownIcon(props.option._type || props.option.type)
                "
              />
            </div>
            <div class="text-coal font-medium">
              {{ props.option[`${field}`] }}
            </div>
          </div>
        </template>
      </b-tag-input>
    </base-field>
  </div>
</template>

<script>
import BaseField from '@/app/util-modules/ui/field/BaseField'
import BTagInput from 'buefy/src/components/taginput/Taginput'
import BTag from 'buefy/src/components/tag/Tag'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseInputTag',
  components: { BaseField, BTagInput, BaseIcon, BTag },
  props: {
    value: {
      type: Array,
      required: true,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    field: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
      validator: (options) => {
        return options.every(
          (option) =>
            ((typeof option.id === 'string' || typeof option.id === 'number') &&
              typeof option.title === 'string') ||
            typeof option.name === 'string'
        )
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: undefined,
    },
    closeType: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'is-medium',
    },
    filterOptionsQuery: {
      type: String,
      default: '',
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    iconSize() {
      switch (this.size) {
        case 'is-large':
          return 32
        case 'is-small':
          return 16
        case 'is-medium':
        default:
          return 24
      }
    },
    iconName() {
      if (this.dropdown) {
        return this.dropdownIcon
      } else if (!this.icon) {
        return
      }

      return `${this.icon}/${this.icon}-${this.iconSize}`
    },
    dropdownIcon() {
      return `arrow/arrow-${this.iconSize}`
    },
    className() {
      return {
        'base-input-tag-dropdown': this.dropdown,
        readonly: this.readonly,
      }
    },
    queryFilteredOptions() {
      return this.options.filter(
        (option) =>
          option[this.field]
            .toLowerCase()
            .indexOf(this.filterOptionsQuery.toLowerCase()) > -1
      )
    },
    relevantOptions() {
      const selectedOptions = this.value.map((option) => option.id)

      return this.queryFilteredOptions.filter(
        (option) => selectedOptions.indexOf(option.id) === -1
      )
    },
  },
  methods: {
    formatDropDownIcon(icon) {
      return `${icon}/${icon}-16`
    },
  },
}
</script>

<style lang="scss" scoped>
.base-input-tag-container::v-deep {
  .base-input-tag {
    &.base-input-tag-dropdown {
      .icon {
        @apply h-full;
        svg {
          @apply transform rotate-90;
        }
      }
    }
    &.base-input-tag-dropdown .is-focused {
      .icon {
        @apply h-full;
        svg {
          @apply transform -rotate-90;
        }
      }
    }
    .taginput-container {
      @apply h-full p-0;
      .dropdown-menu {
        .dropdown-content {
          max-height: 8rem;
        }
      }
      .tags {
        @apply m-1 h-8;
        .tag {
          @apply flex text-base bg-coal-80 h-8 py-1.5 pl-3 pr-0.5;
          &.is-delete {
            @apply w-6;
            &::before {
              height: 2px;
              @apply bg-gray-500 rounded w-3;
            }
            &::after {
              width: 2px;
              @apply bg-gray-500 rounded h-3;
            }
          }
        }
      }
      .add-tag {
        @apply my-1 text-base cursor-pointer hover:bg-coal-100 bg-coal-80 h-8 py-1.5 pl-3 pr-3;
      }
      .input {
        @apply mb-0 h-10;
      }

      &.is-large {
        .autocomplete.control {
          @apply h-12 flex items-center;
        }
      }
    }
    &.readonly .autocomplete.control .dropdown-menu {
      @apply hidden;
    }
    &.readonly .autocomplete.control:not(:first-child) {
      @apply hidden;
    }
  }
}
</style>
