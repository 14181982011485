import Vue from 'vue'
import { shortname } from '@/utils/store'
import { AreaMeta, AreaState } from '../area.state'

import { normalizeCollection } from '@/utils/helpers'
import * as mutationNamesObj from './area.mutations.names'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.AREA_MUTATION_SET_AREAS]: (
    state: AreaState,
    areas: AreaMeta
  ) => {
    const { list, data } = normalizeCollection(areas)

    state.list = list
    state.data = { ...state.data, ...data }
  },
  [mutationNames.AREA_MUTATION_ADD_AREAS]: (
    state: AreaState,
    areas: AreaMeta
  ) => {
    const { list, data } = normalizeCollection(areas)

    for (const id of list) {
      // replace existing items, instead of duplicating
      if (state.data[id]) {
        state.data[id] = data[id]
      } else {
        Vue.set(state.data, id, data[id])
        state.list.push(id)
      }
    }
  },
  [mutationNames.AREA_MUTATION_UPDATE_PAGE]: (state: AreaState, page: any) => {
    state.currentPage = page
  },
  [mutationNames.AREA_MUTATION_SET_META]: (
    state: AreaState,
    meta: AreaMeta
  ) => {
    state.meta = { ...meta }
  },
  [mutationNames.AREA_MUTATION_ADD_AREA]: (state: AreaState, area: any) => {
    state.data = {
      ...state.data,
      [area.id]: area,
    }
    state.list = [...state.list, area.id]
  },
  [mutationNames.AREA_MUTATION_UPDATE_AREA]: (state: AreaState, area: any) => {
    state.data = {
      ...state.data,
      [area.id]: area,
    }
  },
  [mutationNames.AREA_MUTATION_DELETE_AREA]: (
    state: AreaState,
    removedAreaId: any
  ) => {
    state.list = state.list.filter((areaId) => areaId !== removedAreaId)
    state.selected = Array.isArray(state.selected)
      ? state.selected.filter(({ id }) => id !== removedAreaId)
      : []
    const { [removedAreaId]: removed, ...data } = state.data

    state.data = data
  },
  [mutationNames.AREA_MUTATION_SET_SELECTED_AREAS]: (
    state: AreaState,
    selected: []
  ) => {
    state.selected = [...selected]
  },
  [mutationNames.AREA_MUTATION_ADD_AREAS_DATA]: (
    state: AreaState,
    areas: []
  ) => {
    const newData = areas.reduce((areasData: any, area: any) => {
      areasData[area.id] = area
      return areasData
    }, {})
    state.data = { ...state.data, ...newData }
  },

  [mutationNames.AREA_MUTATION_ADD_LOADING_AREAS_IDS]: (
    state: AreaState,
    ids: []
  ) => {
    state.loadingAreasIds.push(...ids)
  },

  [mutationNames.AREA_MUTATION_REMOVE_LOADING_AREAS_IDS]: (
    state: AreaState,
    ids: string[]
  ) => {
    state.loadingAreasIds = state.loadingAreasIds.filter(
      (id) => !ids.includes(id)
    )
  },
}
