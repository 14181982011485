import { shortname } from '@/utils/store.js'
import { loadSegmentScript } from './monitoring.actions.utils'
import * as actionNamesObj from './monitoring.actions.names'

const actionNames = shortname(actionNamesObj)

export default {
  [actionNames.MONITORING_ACTION_SEGMENT_INIT]: () => {
    window.analytics._writeKey = process.env.VUE_APP_SEGMENT_AUTH_TOKEN

    if (window.analytics.load)
      window.analytics.load(process.env.VUE_APP_SEGMENT_AUTH_TOKEN, {
        integrations: { All: !0 },
      })
    else
      loadSegmentScript({
        integrations: { All: !0 },
      })
  },
  [actionNames.MONITORING_ACTION_SEGMENT_TRACK]: ({ rootState }) => {
    window.analytics.identify(rootState.user.user.id, {
      email: rootState.user.user.attributes.email,
      using_better_papershift: true,
    })

    if (rootState.user.user.account.attributes) {
      window.analytics.group(rootState.user.user.account.id, {
        name: rootState.user.user.account.attributes.name,
      })
    }
  },
  [actionNames.MONITORING_ACTION_SEGMENT_PAGE]: (_, data) => {
    window.analytics.page(data.path)
  },
  [actionNames.MONITORING_ACTION_TRACK_ALL]: ({ dispatch }) => {
    dispatch(actionNames.MONITORING_ACTION_SEGMENT_TRACK)
  },
  [actionNames.MONITORING_ACTION_INIT_ALL]: ({ dispatch }) => {
    dispatch(actionNames.MONITORING_ACTION_SEGMENT_INIT)
  },
}
