<template>
  <div :class="wrapperClass" :style="wrapperStyle">
    <span
      v-if="showFooterText"
      class="text-lg md:text-base text-coal md:text-coal font-bold"
      :class="column.footerTextClass"
    >
      {{ footerText }}
    </span>
  </div>
</template>

<script>
import { runOrReturn } from '@/utils/helpers'

export default {
  name: 'BaseTableFooterItemWrapper',
  props: {
    column: {
      type: Object,
      required: true,
    },
  },
  computed: {
    wrapperClass() {
      let classes = null
      if (this.column.cell?.class) {
        classes = runOrReturn(this.column.cell.class, this.column)
      }

      if (this.column.footerClass) {
        classes += ` ${runOrReturn(this.column.footerClass, this.column)}`
      }

      return classes
    },
    wrapperStyle() {
      return this.column.width
        ? { width: runOrReturn(this.column.width, this.column) }
        : null
    },
    showFooterText() {
      return 'footer' in this.column
    },
    footerText() {
      return this.showFooterText
        ? runOrReturn(this.column.footer, this.column)
        : null
    },
  },
}
</script>
