<template>
  <div>
    <slot name="trigger" :trigger="open" />

    <portal v-if="isOpen" to="bottom-sheet">
      <bottom-sheet
        :header-title="$t('modules.bottom_sheet.title')"
        @cancel-click="isOpen = false"
      >
        <slot name="list" />
      </bottom-sheet>
    </portal>
  </div>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'

export default {
  name: 'ModuleMenuBottomSheet',
  components: {
    BottomSheet,
  },
  props: {
    modules: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    cardClass() {
      return this.modules.length > 1 ? 'w-3/5' : 'w-full'
    },
  },
  methods: {
    open() {
      this.isOpen = true
    },
  },
}
</script>
