export const AUTH_ACTION_LOGOUT = 'auth/ACTION_LOGOUT'
export const AUTH_ACTION_SIGN_IN = 'auth/ACTION_SIGN_IN'
export const AUTH_ACTION_REGISTER = 'auth/ACTION_REGISTER'
export const AUTH_ACTION_REGISTER_COMPANY = 'auth/ACTION_REGISTER_COMPANY'
export const AUTH_ACTION_POST_SIGN_IN = 'auth/ACTION_POST_SIGN_IN'
export const AUTH_ACTION_RESTORE_PASSWORD = 'auth/ACTION_RESTORE_PASSWORD'
export const AUTH_ACTION_CHANGE_PASSWORD = 'auth/ACTION_CHANGE_PASSWORD'
export const AUTH_ACTION_VALIDATE_PASSWORD_TOKEN =
  'auth/ACTION_VALIDATE_PASSWORD_TOKEN'
export const AUTH_ACTION_GET_COMPANY_INDUSTRIES =
  'auth/ACTION_GET_COMPANY_INDUSTRIES'
export const AUTH_CHECKIN_ACTION_CREATE_USER = 'auth/CHECKIN_ACTION_CREATE_USER'
export const AUTH_CHECKIN_ACTION_UPDATE_USER = 'auth/CHECKIN_ACTION_UPDATE_USER'
