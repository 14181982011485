import BaseRequest from '@/utils/api/base-request'
import { USER_ENDPOINT_GET_VACATIONS_SUMMARY } from '../network/user.endpoints'

export class GetUserVacationsSummaryRequest extends BaseRequest {
  constructor(workspaceId: RecordId, userId: RecordId) {
    super()
    super.url = USER_ENDPOINT_GET_VACATIONS_SUMMARY(workspaceId, userId)
    super.method = 'get'
  }
}
