<template>
  <portal v-if="isOpen" to="modal">
    <base-modal has-modal-card @close="$emit('close')">
      <base-card-modal-form
        :title="$t('area.form_edit.title')"
        :button-submit-text="$t('area.form_edit.button_submit')"
        :footer-more-options="moreOptions"
        :loading="status.isLoading"
        @submit="$emit('update-area', formData)"
        @close="$emit('close')"
        @selected="$emit('handle-selection', $event)"
      >
        <area-form
          v-model="formData"
          :action-status="preloadActionStatus"
          :area="modelArea"
          :users="users"
        />
      </base-card-modal-form>
    </base-modal>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import AreaForm from '../area-form/AreaForm'
export default {
  name: 'AreaModalFormEdit',
  components: {
    BaseModal,
    BaseCardModalForm,
    AreaForm,
  },
  props: {
    modelArea: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    preloadActionStatus: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: Object,
      required: true,
      default: () => ({
        isLoading: false,
      }),
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {},
    }
  },
}
</script>
