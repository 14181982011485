import { formatDateFilter } from '@/utils/date-time.utils'
import { DATE_FORMAT_DAY_MONTH } from '@/constants'
import { MbscCalendarEventData } from '@mobiscroll/javascript'
import i18n from '@/utils/vendors/i18n/index'

const TIME_FORMAT = 'HH:mm'

export interface User {
  id: RecordId
  username: string
}

export interface ShiftAttributes extends MbscCalendarEventData {
  id: RecordId
  start: string
  end?: string
  original: {
    users: []
    numberOfEmployees: number
    periods: {
      start: string
      end: string
    }
    color: string
    isPast: boolean
    placeholder?: boolean
  }
}

export const baseShiftplanHeaderDayTemplate = ({
  date,
  currentDate,
}: {
  date: Date
  currentDate: Date
}) => {
  const todayClass =
    date.toDateString() === currentDate.toDateString()
      ? 'active-current-date'
      : ''
  return `
  <div class="shiftplan-header-day-template font-medium font-sans text-center rounded bg-coal-80 p-0.5 text-sm h-6 text-coal ${todayClass}">${formatDateFilter(
    date,
    DATE_FORMAT_DAY_MONTH
  )}</div>`
}

export const baseShiftplanResourceTemplate = ({
  name,
  color,
}: {
  name: string
  color: string
}): string => `
  <div class="flex flex-1 items-center justify-between">
    ${
      color
        ? `<div class="flex items-center">
    <div
      class="w-3 h-3 rounded-full mr-2"
      style="background-color: ${color}"
    ></div>
  </div>`
        : ''
    }
  <div class="flex flex-1 items-center">
    <div class="flex-1 text-coal font-sans font-semibold text-lg leading-6 capitalize truncate">${name}</div>
  </div>

</div>
`

export const baseShiftplanEventTemplate = (
  event: ShiftAttributes,
  staffedStatusClass: string,
  areaFooterColor: string
): string => `
  <div class="shift-card-wrapper shift-card-wrapper-${staffedStatusClass}">
${event.original.isPast ? `<div class="shift-past-event"></div>` : ''}
  <div class="shift-card-header">
   <div class="shift-card-header-time">${formatDateFilter(
     event.original.periods.start,
     TIME_FORMAT
   )} – ${formatDateFilter(event.original.periods.end, TIME_FORMAT)}</div>
   <div class="shift-card-header-employees">${event.original.users.length}/${
  event.original.numberOfEmployees
}</div>
  </div>
  <div class="shift-body">
${
  event.original.users.length
    ? employeeListTemplate(event.original.users, event.original.isPast)
    : ''
}
  </div>
  ${
    areaFooterColor
      ? `<div class="shift-card-footer" style="border-color: ${areaFooterColor}">`
      : ''
  }
  </div>
  </div>
  `

export const isTextOverflowing = (containerID: string, textID: string) => {
  const container = document.getElementById(containerID)
  const textElement = document.getElementById(textID)

  const clientWidth = container?.clientWidth
  const scrollWidth = textElement?.scrollWidth
  if (clientWidth && scrollWidth) {
    return scrollWidth > clientWidth
  }
  return false
}

export const employeeUsernameTooltipTemplate = (
  username: string,
  id: string,
  isEventPast: boolean
): string => {
  const containerID = `${id}-container`
  const textID = `${id}-text`

  return `
<span id="${containerID}" class="shift-card-employee-list-item-content">
<span id="${textID}" class="shift-card-employee-list-item-content-text shift-card-employee-list-item-truncate  ${
    isEventPast ? 'shift-card-employee-list-item-opacity' : ''
  }">${username}</span>
${
  isTextOverflowing(containerID, textID)
    ? `
<div class="shift-card-employee-item-tooltip b-tooltip is-black is-primary is-right is-medium is-multiline" multilined="true">
<span class="tooltip-content" >${username}</span>
</div>
`
    : ''
}
</span>
 `
}

export const generateEmployeeToolTipListTemplate = (
  employees: User[]
): string =>
  employees
    .map(
      (employee) => `<li class="tooltip-list-item">${employee.username}</li>`
    )
    .join('\n')

export const employeeListTemplate = (
  employees: User[],
  isEventPast: boolean
): string => {
  employees.sort((a, b) => a.username.localeCompare(b.username))

  const limitedEmployees = employees.slice(0, 3)
  const remainingEmployees = employees.slice(3)
  const remainingCount = Math.max(0, employees.length - 3)

  const employeeList = limitedEmployees
    .map((employee, index) => {
      if (index === 2 && employees.length > 3) {
        return `
        <li class="shift-card-employee-list-item shift-card-employee-list-item-last">
        ${employeeUsernameTooltipTemplate(
          employee.username,
          employee.id,
          isEventPast
        )}
        <span class="shift-card-employee-list-tooltip b-tooltip is-white is-primary is-right is-medium is-multiline" multilined="true">
        <ul class="shift-card-employee-list-tooltip-content tooltip-content">
         ${generateEmployeeToolTipListTemplate(remainingEmployees)}
        </ul>
        <span class="shift-card-employee-list-count-badge ${
          isEventPast ? 'shift-card-employee-list-count-badge-is-past' : ''
        }">
        +${remainingCount}
        </span>
      </span>
        </li>`
      } else {
        return `<li class="shift-card-employee-list-item">
        ${employeeUsernameTooltipTemplate(
          employee.username,
          employee.id,
          isEventPast
        )}
        </li>`
      }
    })
    .join('\n')
  return `
  <ul class="shift-card-employee-list">
    ${employeeList}
  </ul>
  `
}

export const baseShiftplanHeaderTemplate = () => `
  <button mbsc-calendar-prev class="header-button-prev"></button>
  <div class="active-week-container">
    <div class="active-week-text">${i18n.t(
      'shifts_calendar.week'
    )} <span id="active-week-number">0</span></div>
    <div mbsc-calendar-nav class="header-calendar-nav"></div>
    </div>
  <button mbsc-calendar-next class="header-button-next"></button>
  <button mbsc-calendar-today></button>
  `

export const baseShiftplanResourceHeader = (
  firstDayMonth: string,
  lastDayMonth: string,
  lastDayYear: number
) => {
  if (firstDayMonth !== lastDayMonth) {
    return `<div class="resource-header-month-year">${firstDayMonth} – ${lastDayMonth} ${lastDayYear}</div>`
  }
  return `<div class="resource-header-month-year">${lastDayMonth} ${lastDayYear}</div>`
}
