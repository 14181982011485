import BasePaginatedSearchMixin from '@/app/util-modules/ui/paginated-search/BasePaginatedSearch.mixin'

import { mapState } from 'vuex'
import api from '@/app/core/tag/network/tag.api'

export default {
  name: 'TagPaginatedSearchMixin',
  mixins: [BasePaginatedSearchMixin],
  computed: {
    ...mapState({
      workspaceId: (state) => state.workspace.workspace.id,
    }),
    searchStrategy() {
      const workspaceId = this.workspaceId
      return {
        search: async function (query, page) {
          return await api.searchTags(workspaceId, {
            query,
            page,
          })
        },
      }
    },
  },
}
