<template>
  <div class="base-filter">
    <base-dropdown
      v-if="!$isSmallDevice"
      :is-open.sync="isOpen"
      class="base-filter-container"
    >
      <template v-slot:trigger>
        <base-filter-trigger
          :label="label"
          :display-value="displayValue"
          :dynamic-display-value="dynamicDisplayValue"
          @remove="$emit('remove', { field, new: isNew })"
        />
      </template>

      <div class="w-64">
        <div class="mb-3">
          <base-filter-parameter
            :parameter-name="label"
            :field="field"
            :available-fields="availableFields"
            @input="handleEvent($event)"
          />
        </div>
        <slot name="form" />
        <div class="border-t border-coal-100 pt-3">
          <base-button
            :disabled="applied || invalid"
            type="is-filled"
            size="is-small"
            expanded
            @click="apply()"
          >
            {{ $t('filterbar.button_apply') }}
          </base-button>
        </div>
      </div>
    </base-dropdown>
    <div v-if="$isSmallDevice">
      <base-filter-trigger
        :label="label"
        :display-value="displayValue"
        @click.native="isOpen = true"
        @remove="$emit('remove', { field, new: isNew })"
      />
      <portal v-if="isOpen" to="bottom-sheet">
        <bottom-sheet
          :header-title="$t('filterbar.bottom_sheet.title')"
          @cancel-click="isOpen = false"
        >
          <template v-slot:header-right>
            <button
              v-if="!applied && !invalid"
              class="justify-self-end text-right text-lg text-green"
              @click="apply()"
            >
              {{ $t('filterbar.button_apply') }}
            </button>
          </template>
          <div class="p-4">
            <div class="mb-3">
              <base-filter-parameter
                :parameter-name="label"
                :field="field"
                :available-fields="availableFields"
                @input="$emit('replace', { old: field, new: $event })"
              />
            </div>
            <slot name="form" />
          </div>
        </bottom-sheet>
      </portal>
    </div>
  </div>
</template>

<script>
import BaseDropdown from '../dropdown/BaseDropdown'
import BaseButton from '../button/BaseButton'

import BaseFilterTrigger from './BaseFilterTrigger'
import BaseFilterParameter from './BaseFilterParameter'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'

export default {
  name: 'BaseFilter',
  components: {
    BaseFilterTrigger,
    BaseFilterParameter,
    BaseDropdown,
    BaseButton,
    BottomSheet,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    availableFields: {
      type: Array,
      required: true,
    },
    invalid: {
      type: Boolean,
      required: true,
    },
    serializedValue: {
      type: Object,
      required: true,
    },
    displayValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: Object,
      required: false,
      default: null,
    },
    dynamicDisplayValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const hasValidDefaultValue = !!this.defaultValue && !this.invalid

    return {
      isOpen: hasValidDefaultValue ? false : true,
      isNew: hasValidDefaultValue ? false : true,
      mounted: false,
      applied: hasValidDefaultValue ? true : false,
    }
  },
  watch: {
    isOpen(isOpen) {
      if (!isOpen && this.mounted && this.isNew) {
        this.$emit('remove', { field: this.field, new: true })
      }

      if (!isOpen) {
        this.$emit('close')
      }
    },
    serializedValue: {
      handler() {
        this.applied = false
      },
      deep: true,
    },
    field() {
      this.applied = false
    },
  },
  created() {
    if (!!this.defaultValue && !this.invalid) {
      this.$emit('apply', { emit: false })
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.mounted = true
      }, 0)
    })
  },
  methods: {
    apply() {
      this.isOpen = false
      this.isNew = false
      this.applied = true

      this.$emit('apply', { emit: true })
    },
    handleEvent(e) {
      this.$emit('replace', { old: this.field, new: e })
    },
  },
}
</script>

<style lang="scss">
.base-filter {
  > .base-filter-container {
    &.dropdown {
      > .dropdown-menu {
        > .dropdown-content {
          @apply p-4;
        }
      }
    }
  }
}
</style>
