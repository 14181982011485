<template>
  <div>
    <treeselect-searchbox
      v-if="searchable"
      :classes="classesSearchbar"
      :size="sizeSearchbar"
      :placeholder-search="placeholderSearch"
      @input="$emit('typing', $event)"
      @click.native="$emit('click-searchbar')"
    />
    <slot v-if="searchable" name="divider" />
    <treeselect-list
      :classes-item="classesItem"
      :size-checkbox="sizeCheckbox"
      :items="treeWithSearchApplied"
      :position-checkbox="positionCheckbox"
      :disabled="disabled"
      :field="field"
      :data-id-prefix="dataIdPrefix"
      @toggle="toggle($event)"
    >
      <template v-slot:label="{ item }">
        <slot name="label" :item="item" />
      </template>
      <template v-slot:right="{ item }">
        <slot name="right" :item="item" />
      </template>
      <template v-slot:right-hover="{ item }">
        <slot name="right-hover" :item="item" />
      </template>
      <template v-slot:item-divider>
        <slot name="item-divider" />
      </template>
    </treeselect-list>
    <div v-if="hideLastDivider" class="hide-divider" />
    <slot
      v-if="noSearchResults"
      name="no-matching-items"
      :query="filterQuery"
    />
    <slot v-if="emptyTree" name="no-items" />
  </div>
</template>

<script>
import TreeselectList from './TreeselectList'
import TreeselectSearchbox from './TreeselectSearchbox'

export default {
  name: 'Treeselect',
  components: {
    TreeselectList,
    TreeselectSearchbox,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    field: {
      type: String,
      default: 'label',
    },
    placeholderSearch: {
      type: String,
      required: false,
      default: 'Search',
    },
    classesSearchbar: {
      type: String,
      required: false,
      default: '',
    },
    classesItem: {
      type: String,
      required: false,
      default: '',
    },
    sizeSearchbar: {
      type: String,
      required: false,
      default: 'is-medium',
    },
    sizeCheckbox: {
      type: String,
      required: false,
      default: 'is-small',
    },
    positionCheckbox: {
      type: String,
      required: false,
      default: 'is-left',
    },
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideLastDivider: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterQuery: {
      type: String,
      default: '',
    },
    // TODO: remove default and set as required
    dataIdPrefix: {
      type: String,
      default: 'treeselect',
    },
  },
  data() {
    return {
      indeterminate: {},
    }
  },
  computed: {
    emptyTree() {
      return this.items.length === 0
    },
    flatTreeWithoutMeta() {
      return this.flattenTree(this.items, true)
    },
    treeWithMeta() {
      return this.addMeta(this.items)
    },
    searchableTree() {
      return this.getSearchableTree(this.treeWithMeta)
    },
    flatTreeWithMeta() {
      return this.flattenTree(this.treeWithMeta)
    },
    treeMatchingQuery() {
      return this.searchableTree.filter(
        (item) =>
          item[this.field]
            .toLowerCase()
            .indexOf(this.filterQuery.toLowerCase()) > -1
      )
    },
    treeWithSearchApplied() {
      return this.filterQuery.length > 0
        ? this.treeMatchingQuery
        : this.treeWithMeta
    },
    noSearchResults() {
      return this.filterQuery.length > 0
        ? this.treeWithSearchApplied.length === 0
        : false
    },
    isValueArray() {
      return Array.isArray(this.value)
    },
    hiddenSelection() {
      return this.isValueArray
        ? this.value.filter((item) => {
            const id = typeof item === 'string' ? item : item.id

            return !this.flatTreeWithoutMeta[id]
          })
        : []
    },
    visibleSelection() {
      return this.isValueArray
        ? this.value.filter((item) => {
            const id = typeof item === 'string' ? item : item.id

            return this.flatTreeWithoutMeta[id]
          })
        : []
    },
    selected: {
      get() {
        return this.transformSelectionToObject(this.visibleSelection)
      },
      set(selected) {
        this.$emit(
          'input',
          this.transformSelectionToArray(selected).concat(
            ...this.hiddenSelection
          )
        )
      },
    },
    selectedCount() {
      return this.isValueArray
        ? this.value.filter((item) => {
            const config = this.flatTreeWithMeta[item.id]?.countAsSelected

            return typeof config === 'undefined' || config === true
          }).length
        : 0
    },
  },
  watch: {
    value: {
      handler() {
        this.setupIndeterminateState()
      },
      immediate: true,
    },
    items: {
      handler() {
        this.setupIndeterminateState()
      },
      immediate: true,
    },
    selectedCount: {
      handler(count) {
        this.$emit('change-selected-count', count)
      },
      immediate: true,
    },
  },
  mounted() {
    const selection = this.transformSelectionToArray(this.selected)

    if (selection.length > 0) {
      this.$emit('input', selection)
    }
  },
  methods: {
    transformSelectionToObject(selectionArr) {
      return selectionArr.reduce((selectionObj, item) => {
        const id = typeof item === 'object' ? item.id : item
        selectionObj[id] = true
        return selectionObj
      }, {})
    },
    transformSelectionToArray(selectionObj) {
      const itemIds = Object.keys(selectionObj).filter((id) => selectionObj[id])
      return itemIds.map((itemId) => this.flatTreeWithoutMeta[itemId])
    },
    toggle(itemId) {
      const newSelection = !this.selected[itemId]

      let selected = {
        ...this.selected,
        [itemId]: newSelection,
      }

      let indeterminate = {
        ...this.indeterminate,
        [itemId]: false,
      }

      this.setSelection(selected, indeterminate, itemId, newSelection)

      this.selected = selected
      this.indeterminate = indeterminate
    },
    setSelection(selected, indeterminate, itemId, newSelection) {
      const item = this.flatTreeWithMeta[itemId]

      this.setChildSelection(selected, item.children, newSelection)
      this.setParentSelection(selected, indeterminate, item.parentId)
    },
    setChildSelection(selected, children, selection) {
      if (children) {
        children.forEach((item) => {
          selected[item.id] = selection
          this.setChildSelection(selected, item.children, selection)
        })
      }
    },
    setParentSelection(selected, indeterminate, parentId) {
      if (parentId !== 'ROOT') {
        const parentItem = this.flatTreeWithMeta[parentId]
        const parentSelectedCount = this.countSelected(
          selected,
          parentItem.children
        )
        const parentTotalCount = parentItem.children.length

        indeterminate[parentItem.id] =
          parentSelectedCount !== 0 && parentSelectedCount !== parentTotalCount

        selected[parentItem.id] =
          parentSelectedCount === 0
            ? false
            : parentSelectedCount === parentTotalCount
            ? true
            : null

        this.setParentSelection(selected, indeterminate, parentItem.parentId)
      }
    },
    countSelected(selected, children) {
      return children.reduce((count, item) => {
        return selected[item.id] ? count + 1 : count
      }, 0)
    },
    flattenTree(items, removeChildrenRef = false) {
      return items.reduce((flattenedTree, { children, ...item }) => {
        const flattedChildren = children ? this.flattenTree(children) : {}

        flattenedTree[item.id] = {
          ...item,
          children: !removeChildrenRef ? children : undefined,
        }
        return {
          ...flattenedTree,
          ...flattedChildren,
        }
      }, {})
    },
    addMeta(items, parentId = 'ROOT') {
      return items.map((item) => ({
        ...item,
        children: item.children ? this.addMeta(item.children, item.id) : null,
        selected: this.selected[item.id] || false,
        indeterminate: this.indeterminate[item.id] || false,
        parentId,
      }))
    },
    getSearchableTree(items) {
      return items.reduce((searchableItems, item) => {
        const childSearchableItems = item.children
          ? this.getSearchableTree(item.children)
          : []

        if (item.searchable === false) {
          return [...searchableItems, ...childSearchableItems]
        } else {
          return [...searchableItems, item, ...childSearchableItems]
        }
      }, [])
    },
    getSelectedCount(items) {
      return items.reduce((selectedCount, item) => {
        const childCount = item.children
          ? this.getSelectedCount(item.children)
          : 0

        if (!item.selected || item.countAsSelected === false) {
          return selectedCount + childCount
        } else {
          return selectedCount + childCount + 1
        }
      }, 0)
    },
    setupIndeterminateState() {
      let selected = {
        ...this.selected,
      }
      let indeterminate = {}

      this.visibleSelection.forEach((item) => {
        this.setSelection(
          selected,
          indeterminate,
          typeof item === 'string' ? item : item.id,
          true
        )
      })

      this.indeterminate = indeterminate
    },
  },
}
</script>

<style scoped>
.hide-divider {
  height: 1px;
  margin-top: -1px;
  background: white;
}
</style>
