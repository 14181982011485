<template>
  <span class="inline-flex items-center gap-1 pt-0.5 w-full">
    <span
      v-if="item.color"
      :style="{ backgroundColor: item.color }"
      class="w-3 h-3 rounded-full flex-shrink-0"
    />
    <slot :item="item">
      <span class="label-text">{{ item.label }}</span>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'ListCircleColorItem',
  props: {
    item: {
      type: Object,
      required: true,
      validator: (item) => item.label,
    },
  },
}
</script>
