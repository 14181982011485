<template>
  <tabs-topbar
    :tabs="installedModules"
    data-id-prefix="dashboard_tabs_topbar"
    :modules="noninstalledModules"
  />
</template>

<script>
import TabsTopbar from '@/app/util-modules/ui/tabs-topbar/TabsTopbar'

export default {
  name: 'DashboardTabsTopbar',
  components: {
    'tabs-topbar': TabsTopbar,
  },
  data() {
    return {
      modules: [
        {
          smallIcon: 'calendar/calendar-16',
          label: this.$t('modules.roster.label'),
          route: '',
          installed: true,
        },
        {
          smallIcon: 'absences/absences-16',
          label: this.$t('modules.absences.label'),
          route: '',
          installed: true,
        },
        {
          largeIcon: 'speech-bubble/speech-bubble-32',
          largeIconType: 'is-filled',
          bgClass: 'bg-warning-400',
          iconClass: 'text-warning-300',
          label: this.$t('modules.availabilities.label'),
          description: this.$t('modules.availabilities.description'),
          route: '',
          installed: false,
        },
        {
          largeIcon: 'calendar-events/calendar-events-32',
          bgClass: 'bg-error-400',
          iconClass: 'text-error-300',
          label: this.$t('modules.events.label'),
          description: this.$t('modules.events.description'),
          route: '',
          installed: false,
        },
      ],
    }
  },
  computed: {
    installedModules() {
      return this.modules.filter((m) => m.installed)
    },
    noninstalledModules() {
      return this.modules.filter((m) => !m.installed)
    },
  },
}
</script>
