<template>
  <div class="control md:min-w-22 lg:min-w-32">
    <button type="button" class="input is-small">
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center">
          <base-icon class="relative" :icon="icon" />
          <div class="label">
            <span class="hidden lg:inline-block ml-2">
              {{ label }}
            </span>
            <span
              v-if="typeof count === 'number'"
              class="hidden lg:inline-block ml-0.5"
              data-id="dropdown-topbar-counter"
            >
              ({{ count }})
            </span>
            <span
              v-if="typeof count === 'number'"
              class="hidden md:inline-block lg:hidden ml-2 mr-1.5"
              data-id="dropdown-topbar-counter"
            >
              {{ count }}
            </span>
          </div>
        </div>
        <base-icon
          class="icon ml-1"
          :rotation="!active ? 90 : -90"
          icon="arrow/arrow-16"
        />
      </div>
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'DropdownTopbarTrigger',
  components: {
    BaseIcon,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  @apply font-medium;
}
</style>
