<template>
  <base-message :type="type" v-bind="$attrs" v-on="$listeners">
    <div
      v-for="(message, i) in messages"
      :key="i"
      class="flex items-center justify-center"
    >
      {{ message }}
    </div>
  </base-message>
</template>

<script>
import BaseMessage from '@/app/util-modules/ui/message/BaseMessage'

export default {
  name: 'BaseGroupMessage',
  components: {
    BaseMessage,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: undefined,
    },
    hasIcon: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
}
</script>
