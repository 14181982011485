import Tag from './tag.model'

export class UpdateTagRequest {
  constructor(form) {
    this.data = {
      id: form.id,
      type: 'tag',
      attributes: {
        title: form.name,
        color: form.color,
      },
    }
  }
}

export class UpdateTagResponse {
  constructor(response) {
    this.tag = new Tag(response.data.data)
  }
}
