<template>
  <div class="control">
    <button
      type="button"
      class="input is-small"
      :data-id-prefix="`${dataIdPrefix}.bottomsheet_topbar_trigger`"
    >
      <div class="flex items-center gap-x-2">
        <base-icon class="relative" :icon="icon" />
        <span
          v-if="typeof count === 'number'"
          data-id="bottom.sheet.topbar.tirgger.count_display"
          >{{ count }}</span
        >
      </div>
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BottomSheetTopbarTrigger',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';
@import '~bulma/sass/form/shared';

.selectbox {
  @extend %input;

  display: block;
  max-width: 100%;
  font-size: 1em;
  cursor: pointer;
  outline: none;
}
</style>
