import { typesCheck } from '@/utils/exceptions/types-exception-handler'

export class FeatureList {
  features = []

  constructor(list) {
    this.features = list.reduce((accumulator, currentVal) => {
      const feat = new Feature(currentVal)
      accumulator[feat.attributes.hint] = feat
      return accumulator
    }, {})
  }
}

class Feature {
  id = ''
  type = ''
  attributes = FeatureAttributes

  constructor({ id, type, attributes }) {
    this.id = typesCheck.isString(id)
    this.type = typesCheck.isString(type)
    this.attributes = new FeatureAttributes(typesCheck.isObject(attributes))
  }
}

class FeatureAttributes {
  hint = ''
  permissions = {}

  constructor({ permissions, hint }) {
    this.hint = typesCheck.isString(hint)
    this.permissions = typesCheck.isObjectBooleans(permissions)
  }
}
