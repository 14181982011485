<template>
  <validation-provider
    v-slot="field"
    tag="div"
    :vid="validationId"
    :name="name"
    :rules="validationRules"
    :mode="validationMode"
    class="relative"
  >
    <span
      v-if="labelRight"
      class="absolute right-0 top-0 font-medium text-coal-550"
      >{{ labelRight }}</span
    >

    <buefy-field
      class="base-field"
      :message="getErrors(field) || hint"
      :type="{ 'is-danger': hasErrors }"
      :label="label"
      v-bind="$attrs"
    >
      <template v-slot:label>
        <slot name="label" :label="label" />
      </template>
      <slot v-bind="field" />
    </buefy-field>
  </validation-provider>
</template>

<script>
import BuefyField from 'buefy/src/components/field/Field'
import { ValidationProvider } from 'vee-validate'

function isNotEmptyList(input) {
  return Array.isArray(input) && input.length
}

export default {
  name: 'BaseField',
  components: { BuefyField, ValidationProvider },
  inject: {
    actionStatusPropGetter: {
      default: () => () => {},
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    validationId: {
      type: String,
      default: '',
    },
    validationRules: {
      type: [Object, String],
      default: '',
    },
    validationMode: {
      type: [Object, String],
      validator: (value) =>
        ['aggressive', 'passive', 'lazy', 'eager'].indexOf(value) !== -1,
      default: 'aggressive',
    },
    hint: {
      type: String,
      default: '',
    },
    externalErrors: {
      type: Array,
      default: () => [],
    },
    labelRight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasErrors: false,
    }
  },
  methods: {
    getErrors(field) {
      if (isNotEmptyList(field?.errors)) {
        this.hasErrors = true
        return field.errors
      }
      if (isNotEmptyList(this.externalErrors)) {
        this.hasErrors = true
        return this.externalErrors
      }

      const serverValidationMessages =
        this.actionStatusPropGetter()?.validationMessages

      if (serverValidationMessages?.has(this.name)) {
        this.hasErrors = true
        return serverValidationMessages.get(this.name)
      }

      this.hasErrors = false
      return null
    },
  },
}
</script>
