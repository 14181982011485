import Vue from 'vue'
import { State, RecordGeneric, ResourceLink } from './types'
import { logError } from '@/utils/helpers'
import { linkRelationship, checkTypeExists, checkTypeWritable } from './utils'
import { getRecordIndex } from './indexing'

// creates or modifies relationship (relName) of a record by given parentType
// and parentId. The `children` records will be linked to the record
export function attachRelationships(
  state: State,
  {
    parentType,
    parentId,
    relName,
    children,
  }: {
    parentType: RecordType
    parentId: RecordId
    relName: string
    children: RecordGeneric[]
  }
) {
  try {
    checkTypeExists(state, parentType)
    checkTypeWritable(state, parentType)

    const parentIndex = getRecordIndex(state, parentType, parentId)
    if (parentIndex === -1) {
      return
    }

    const stateRecords = <RecordGeneric[]>state.records[parentType]
    const parentRecord = stateRecords[parentIndex]

    if (!parentRecord._relationships[relName]) {
      Vue.set(parentRecord._relationships, relName, { data: [] })
    }

    const newResLinks: ResourceLink[] = []
    const existingResLinksIds: Record<RecordId, boolean> = {}
    const existingResLinks = parentRecord._relationships[relName].data

    existingResLinks.forEach((link: ResourceLink) => {
      existingResLinksIds[link.id] = true
    })
    children.forEach(({ type, id }) => {
      if (!existingResLinksIds[id]) {
        newResLinks.push({ type, id })
      }
    })

    Vue.set(parentRecord._relationships[relName], 'data', [
      ...existingResLinks,
      ...newResLinks,
    ])

    if (!Object.prototype.hasOwnProperty.call(parentRecord, relName)) {
      linkRelationship(state, parentRecord, relName)
    }
  } catch (err) {
    logError(err)
  }
}

// removes linked children from a parent record
export function detachRelationships(
  state: State,
  {
    parentType,
    parentId,
    relName,
    children,
  }: {
    parentType: RecordType
    parentId: RecordId
    relName: string
    children: RecordGeneric[]
  }
) {
  try {
    checkTypeExists(state, parentType)
    checkTypeWritable(state, parentType)

    const parentIndex = getRecordIndex(state, parentType, parentId)
    if (parentIndex === -1) {
      return
    }

    const stateRecords = <RecordGeneric[]>state.records[parentType]
    const parentRecord = stateRecords[parentIndex]

    if (!parentRecord._relationships[relName]) {
      return
    }

    const newResLinks: ResourceLink[] = []
    const childrenIds: Record<RecordId, boolean> = {}

    children.forEach(({ id }) => {
      childrenIds[id] = true
    })

    parentRecord._relationships[relName].data.forEach((link: ResourceLink) => {
      if (!childrenIds[link.id]) {
        newResLinks.push(link)
      }
    })

    parentRecord._relationships[relName].data = newResLinks
  } catch (err) {
    logError(err)
  }
}
