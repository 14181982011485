<template>
  <div class="preview-banner">
    <base-banner :body-message="message">
      <template v-slot:right>
        <div class="flex items-center">
          <button
            class="has-text-primary cursor-pointer mr-5 font-semibold"
            @click="$emit('minimize')"
          >
            {{ $t('preview.minimize') }}
          </button>
          <a :href="oldAppTimeTrackingUrl">
            <base-button
              type="is-filled"
              size="is-small"
              @click="trackSwitchEvent()"
            >
              {{ $t('preview.close') }}
            </base-button>
          </a>
        </div>
      </template>
    </base-banner>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseBanner from '@/app/util-modules/ui/banner/BaseBanner.vue'
import { GET_USER_ENV, ROUTE_NAMES } from '@/constants'

export default {
  name: 'PreviewBanner',
  components: {
    BaseButton,
    BaseBanner,
  },
  computed: {
    message() {
      return this.$isSmallDevice
        ? this.$t('preview.mobile_message')
        : this.$t('preview.desktop_message')
    },
    ...mapState({
      workspaceId: (state) => state.workspace.workspace.id,
    }),
    oldAppTimeTrackingUrl() {
      const env = GET_USER_ENV
      if (this.$route.meta.feature === ROUTE_NAMES.TIME_TRACKING) {
        return `https://${env}.papershift.com/companies/${this.workspaceId}/time_trackings?reset_preview_cookie=true`
      } else if (this.$route.meta.feature === ROUTE_NAMES.ABSENCES) {
        return `https://${env}.papershift.com/companies/${this.workspaceId}/absences?reset_preview_cookie=true`
      } else if (this.$route.meta.feature === ROUTE_NAMES.WORKSPACE_USER) {
        return `https://${env}.papershift.com/companies/${this.workspaceId}/users?reset_preview_cookie=true`
      } else if (this.$route.meta.feature === ROUTE_NAMES.SHIFT_CALENDAR) {
        return `https://${env}.papershift.com/companies/${this.workspaceId}?reset_preview_cookie=true`
      } else if (this.$route.meta.feature === ROUTE_NAMES.ABSENCES_CALENDAR) {
        return `https://${env}.papershift.com/companies/${this.workspaceId}?reset_preview_cookie=true`
      } else {
        return `https://${env}.papershift.com/companies/${this.workspaceId}/time_trackings?reset_preview_cookie=true`
      }
    },
  },
  methods: {
    trackSwitchEvent() {
      window.analytics.track('Switched to Old App')
    },
  },
}
</script>

<style lang="scss">
.preview-banner {
  a {
    @apply mr-20;
  }
}
</style>
