class GetCompanyIndustriesResponse {
  constructor(response) {
    this.data = response.data.data.map(({ attributes, ...rest }) => ({
      ...attributes,
      ...rest,
    }))
  }
}

export { GetCompanyIndustriesResponse }
