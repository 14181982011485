<template>
  <app-sidebar-menu-list>
    <app-sidebar-menu-list-item v-if="isReportsVisible">
      <app-sidebar-menu-list-item-content
        :tooltip="$t('sidebar.reports')"
        :label="$t('sidebar.reports')"
        icon="reports/reports-26"
        icon-hover="reports/reports-26-hover"
        icon-hover-type="is-filled"
        data-id="sidebar.reports"
        :route="{ name: `${REPORT_MODULE_NAME}.index` }"
      />
    </app-sidebar-menu-list-item>

    <app-sidebar-menu-list-item v-if="isSettingsVisible" class="mb-2 md:mb-0">
      <app-sidebar-menu-list-item-content
        :tooltip="$t('sidebar.workspace_settings')"
        :label="$t('sidebar.workspace_settings')"
        icon="settings/settings-26"
        icon-hover="settings/settings-26-hover"
        icon-hover-type="is-filled"
        data-id="sidebar.workspace_settings"
        icon-size="1.5rem"
        :route="{ name: `${SETTINGS_MODULE_NAME}.index` }"
      />
    </app-sidebar-menu-list-item>

    <app-sidebar-menu-list-item v-if="!previewBanner.visible && $isLargeDevice">
      <base-tooltip :label="$t('preview.close')" position="is-right">
        <base-toggle
          class="base-toggle ml-2"
          :value="!previewBanner.visible"
          size="is-medium"
          @input="handleSwitch()"
        />
      </base-tooltip>
    </app-sidebar-menu-list-item>

    <div
      v-if="!isAdminView"
      class="flex w-full p-3 gap-4 md:mb-8 md:gap-1 md:flex-col md:p-0"
    >
      <summary-item
        v-if="hasTimeTrackingSummary"
        :label="$t('sidebar.time_tracking_summary_title')"
        :route-name="`${PROFILE_MODULE_NAME}.details.records`"
        icon="time-tracking/time-tracking-26-hover"
        icon-type="is-filled"
      >
        {{ $t('sidebar.time_tracking_summary', { hours: totalOvertime }) }}
      </summary-item>

      <summary-item
        v-if="hasAbsenceSummary"
        :label="absenceSummary.title"
        :route-name="absenceSummary.link"
        icon="absences/absences-26"
      >
        {{ absenceSummary.content }}
      </summary-item>
    </div>

    <upgrade-button v-if="$isSmallDevice && hasFreeSubscription" />
  </app-sidebar-menu-list>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import round from 'lodash/round'
import AppSidebarMenuList from '@/app/util-modules/ui/app-sidebar/AppSidebarMenuList'
import AppSidebarMenuListItem from '@/app/util-modules/ui/app-sidebar/AppSidebarMenuListItem'
import AppSidebarMenuListItemContent from '@/app/util-modules/ui/app-sidebar/AppSidebarMenuListItemContent'
import UpgradeButton from './AppSidebarFooterItemsUpgradeButton.vue'
import SummaryItem from './AppSidebarFooterItemsSummaryItem.vue'
import i18n from '@/utils/vendors/i18n'
import store from '@/store'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import { MODULE_NAME as SETTINGS_MODULE_NAME } from '@/app/modules/settings/settings.module'
import { MODULE_NAME as REPORT_MODULE_NAME } from '@/app/modules/reports/reports.module'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
  USER_PUBIC_GETTER_SETTINGS_VISIBLE,
  USER_GETTER_PREVIEW_BANNER,
  USER_PUBLIC_GETTER_IS_METABASE_REPORTS_ENABLED,
} from '@/app/core/user/store/getters/user.getters.names'
import {
  USER_PUBLIC_ACTION_GET_RECORDS_SUMMARY,
  USER_PUBLIC_ACTION_GET_VACATIONS_SUMMARY,
} from '@/app/core/user/store/actions/user.actions.names'
import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'
import { GET_USER_ENV, ROUTE_NAMES } from '@/constants'
import router from '@/router'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'

export default defineComponent({
  name: 'AppSidebarFooterItems',
  components: {
    BaseTooltip,
    BaseToggle,
    AppSidebarMenuList,
    AppSidebarMenuListItem,
    AppSidebarMenuListItemContent,
    UpgradeButton,
    SummaryItem,
  },
  setup() {
    const userFeaturePermissions = (moduleName) =>
      store.getters[USER_GETTER_FEATURE_PERMISSIONS](moduleName)
    const totalOvertimeInSec = computed(
      () => store.state.user.user.attributes.total_overtime_in_sec
    )
    const remainingVacationDays = computed(
      () => store.state.user.user.attributes.remaining_vacation_days_this_year
    )
    const isAbsenceInHours = computed(
      () => store.state.user.user.attributes.hour_holidays
    )
    const isAdminView = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )
    const hasFreeSubscription = computed(
      () => userFeaturePermissions(PROFILE_MODULE_NAME).free_subscription
    )

    const workspaceId = computed(() => store.state.workspace.workspace.id)

    const previewBanner = computed(
      () => store.getters[USER_GETTER_PREVIEW_BANNER]
    )

    const hasAbsenceSummary = computed(
      () => userFeaturePermissions(ABSENCE_MODULE_NAME).read_index
    )
    const isAbsenceVisible = computed(
      () => userFeaturePermissions(ABSENCE_MODULE_NAME).visible
    )
    const isSettingsVisible = computed(() => {
      return store.getters[USER_PUBIC_GETTER_SETTINGS_VISIBLE]
    })
    const isReportsVisible = computed(
      () =>
        userFeaturePermissions(REPORT_MODULE_NAME).read_index &&
        store.getters[USER_PUBLIC_GETTER_IS_METABASE_REPORTS_ENABLED]
    )
    const hasTimeTrackingSummary = computed(
      () =>
        userFeaturePermissions(TIME_TRACKING_MODULE_NAME).read_index &&
        userFeaturePermissions('General').hide_records_for_employees !== true
    )
    const totalOvertime = computed(() => {
      if (!totalOvertimeInSec.value) return 0
      return round(totalOvertimeInSec.value / 60 / 60, 2)
    })
    const absenceSummary = computed(() => {
      const link = isAbsenceVisible.value
        ? `${ABSENCE_MODULE_NAME}.index`
        : `${PROFILE_MODULE_NAME}.details`

      if (isAbsenceInHours.value) {
        return {
          link,
          title: i18n.t('sidebar.absences_summary_title_hours'),
          content: i18n.t('sidebar.absences_summary_hours', {
            value: remainingVacationDays.value,
          }),
        }
      } else {
        return {
          link,
          title: i18n.t('sidebar.absences_summary_title_days'),
          content: i18n.t('sidebar.absences_summary_days', {
            value: remainingVacationDays.value,
          }),
        }
      }
    })

    const handleSwitch = () => {
      const env = GET_USER_ENV
      if (router.currentRoute.meta.feature === ROUTE_NAMES.TIME_TRACKING) {
        window.location.href = `https://${env}.papershift.com/companies/${workspaceId.value}/time_trackings?reset_preview_cookie=true`
      } else if (router.currentRoute.meta.feature === ROUTE_NAMES.ABSENCES) {
        window.location.href = `https://${env}.papershift.com/companies/${workspaceId.value}/absences?reset_preview_cookie=true`
      } else if (
        router.currentRoute.meta.feature === ROUTE_NAMES.WORKSPACE_USER
      ) {
        window.location.href = `https://${env}.papershift.com/companies/${workspaceId.value}/users?reset_preview_cookie=true`
      } else if (
        router.currentRoute.meta.feature === ROUTE_NAMES.SHIFT_CALENDAR
      ) {
        window.location.href = `https://${env}.papershift.com/companies/${workspaceId.value}?reset_preview_cookie=true`
      } else if (
        router.currentRoute.meta.feature === ROUTE_NAMES.ABSENCES_CALENDAR
      ) {
        window.location.href = `https://${env}.papershift.com/companies/${workspaceId.value}?reset_preview_cookie=true`
      } else {
        window.location.href = `https://${env}.papershift.com/companies/${workspaceId.value}/time_trackings?reset_preview_cookie=true`
      }
    }

    const loadRecordsSummary = () =>
      store.dispatch(USER_PUBLIC_ACTION_GET_RECORDS_SUMMARY)
    const loadVacationsSummary = () =>
      store.dispatch(USER_PUBLIC_ACTION_GET_VACATIONS_SUMMARY)

    if (hasTimeTrackingSummary.value) loadRecordsSummary()
    loadVacationsSummary()

    return {
      isAdminView,
      isSettingsVisible,
      isReportsVisible,
      hasTimeTrackingSummary,
      totalOvertime,
      hasAbsenceSummary,
      previewBanner,
      handleSwitch,
      absenceSummary,
      hasFreeSubscription,
      ABSENCE_MODULE_NAME,
      PROFILE_MODULE_NAME,
      SETTINGS_MODULE_NAME,
      REPORT_MODULE_NAME,
    }
  },
})
</script>

<style lang="scss">
.switch.base-toggle.is-outlined input[type='checkbox'] + .check {
  @apply opacity-100 bg-white;
}
.switch.base-toggle.is-outlined input[type='checkbox']:hover + .check {
  @apply opacity-100 bg-white;
}
.switch.base-toggle.is-outlined input[type='checkbox']:checked + .check {
  @apply bg-white;
}
.switch.base-toggle.is-outlined input[type='checkbox']:focus + .check {
  @apply opacity-100 bg-white border-2;
}
.switch.base-toggle.is-outlined input[type='checkbox']:checked:hover + .check {
  @apply opacity-100 bg-white border-2;
}
.switch.base-toggle.is-outlined:hover input[type='checkbox']:checked + .check {
  background: white;
}
</style>
