export const USER_GETTER_USER = 'user/GETTER_USER'
export const USER_GETTER_FEATURE = 'user/GETTER_FEATURE'
export const USER_GETTER_FEATURE_PERMISSIONS = 'user/GETTER_FEATURE_PERMISSIONS'
export const USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE =
  'user/GETTER_IS_FEATURE_VISIBLE_FOR_ROLE'
export const USER_GETTER_INDEX_READABLE_FEATURES =
  'user/GETTER_INDEX_READABLE_FEATURES'
export const USER_GETTER_FEATURE_NAMES = 'user/GETTER_FEATURES'
export const USER_GETTER_GET_USER_DETAILS = 'user/GETTER_USER_GET_USER_DETAILS'
export const USER_GETTER_HIGHEST_ROLE = 'user/GETTER_HIGHEST_ROLE'
export const USER_GETTER_PREVIEW_BANNER = 'user/USER_GETTER_PREVIEW_BANNER'
export const USER_GETTER_IS_ROLE_ADMIN = 'user/GETTER_IS_ASSUMED_ROLE_ADMIN'
export const USER_GETTER_IS_ASSUMED_ROLE_ADMIN = 'user/GETTER_IS_ROLE_ADMIN'
export const USER_PUBLIC_GETTER_ROLE_LIST = 'user/PUBLIC_GETTER_ROLE_LIST'
export const USER_PUBLIC_GETTER_PREFERENCE = 'user/PUBLIC_GETTER_PREFERENCE'
export const USER_PUBLIC_GETTER_SUGGESTED_FOLLOWERS =
  'user/PUBLIC_GETTER_SUGGESTED_FOLLOWERS'
export const USER_PUBIC_GETTER_SETTINGS_VISIBLE =
  'user/PUBIC_GETTER_SETTINGS_VISIBLE'
export const USER_PUBLIC_GETTER_TRACKING_CONTROLS_POPUP =
  'user/PUBLIC_GETTER_TRACKING_CONTROLS_POPUP'
export const USER_PUBLIC_GETTER_IS_METABASE_REPORTS_ENABLED =
  'user/PUBLIC_GETTER_IS_METABASE_REPORTS_ENABLED'
