<template>
  <buefy-dropdown
    ref="dropdown"
    :position="position"
    :aria-role="ariaRole"
    :close-on-click="closeOnClick"
    v-bind="$attrs"
    class="filter-dropdown"
    v-on="$listeners"
    @active-change="internalActiveChange($event)"
  >
    <template v-slot:trigger="{ active }">
      <slot name="trigger" :active="active" />
    </template>
    <div>
      <slot />
    </div>
  </buefy-dropdown>
</template>

<script>
import BuefyDropdown from 'buefy/src/components/dropdown/Dropdown'
import { isEndOfScroll } from '@/utils/helpers'

export default {
  name: 'BaseDropdown',
  components: {
    BuefyDropdown,
  },
  props: {
    position: {
      type: String,
      default: 'is-bottom-right',
    },
    ariaRole: {
      type: String,
      default: 'list',
    },
    closeOnClick: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isDropdownActive: false,
    }
  },
  watch: {
    isOpen: 'externalActiveChange',
  },
  mounted() {
    if (typeof this.isOpen !== 'undefined') {
      this.externalActiveChange(this.isOpen)
    }

    if (this.$listeners['scroll-end']) {
      this.getDropdownContentElem().addEventListener(
        'scroll',
        this.notifyScrollEnd
      )
    }
  },
  beforeDestroy() {
    if (this.$listeners['scroll-end']) {
      this.getDropdownContentElem().removeEventListener(
        'scroll',
        this.notifyScrollEnd
      )
    }
  },
  methods: {
    toggle() {
      this.$refs.dropdown.toggle()
    },
    externalActiveChange(isActive) {
      if (isActive !== this.isDropdownActive) {
        this.toggle()
        this.isDropdownActive = isActive
      }
    },
    internalActiveChange(isActive) {
      this.isDropdownActive = isActive
      this.$emit('update:isOpen', isActive)
    },
    getDropdownContentElem() {
      return this.$refs.dropdown.$el.querySelector('.dropdown-content')
    },
    notifyScrollEnd(e) {
      if (isEndOfScroll(e.target)) {
        this.$emit('scroll-end')
      }
    },
  },
}
</script>
