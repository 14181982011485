import { typesCheck } from '@/utils/exceptions/types-exception-handler'

export default class UserAttributes {
  constructor({
    email,
    username,
    abbrev,
    home_office,
    staff_number,
    locale,
    avatar,
    avatar_medium_url,
    avatar_thumb_url,
    birthday,
    role,
    assumed_role,
    checkin_complete,
    time_tracking_method,
    working_sessions_creator,
    can_view_absences_of_others,
    remaining_vacation_days_this_year,
    total_overtime_in_sec,
    hour_holidays,
  }) {
    this.email = typesCheck.isString(email)
    this.username = typesCheck.isString(username)
    this.abbrev = typesCheck.isString(abbrev)
    this.home_office = typesCheck.isBoolean(home_office)
    this.staff_number = typesCheck.isString(staff_number)
    this.locale = typesCheck.isString(locale)
    this.avatar = typesCheck.isString(avatar)
    this.avatar_medium_url = typesCheck.isString(avatar_medium_url)
    this.avatar_thumb_url = typesCheck.isString(avatar_thumb_url)
    this.birthday = typesCheck.isString(birthday)
    this.role = typesCheck.isString(role)
    this.assumed_role = typesCheck.isString(assumed_role)
    this.checkin_complete = checkin_complete
    this.time_tracking_method = time_tracking_method
    this.working_sessions_creator = typesCheck.isBoolean(
      working_sessions_creator
    )
    this.can_view_absences_of_others = typesCheck.isBoolean(
      can_view_absences_of_others
    )
    this.remaining_vacation_days_this_year = typesCheck.isNumber(
      remaining_vacation_days_this_year
    )
    this.total_overtime_in_sec = typesCheck.isNumber(total_overtime_in_sec)
    this.hour_holidays = typesCheck.isBoolean(hour_holidays)
  }
}
