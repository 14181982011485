<template>
  <div>
    <area-list
      v-model="computedSelected"
      :is-open.sync="isListOpen"
      :items="selectItems"
      :selected-count.sync="selectedCount"
      :searchable="isSearchable"
      :type-button-new="typeButtonNew"
      :is-loading="isListLoading"
      :has-search-query="isSearching"
      :can-create="canCreate"
      :data-id-prefix="`${dataIdPrefix}.area_container`"
      @edit="openEditForm($event)"
      @create="openCreateForm()"
      @scroll-end="handleScrollEnd()"
      @typing="handleTyping($event)"
    />
    <area-form-create
      v-if="!editingId && isFormOpen"
      :is-open="isFormOpen"
      :model-area="duplicatedArea"
      @close="closeForm()"
    />
    <area-form-edit
      v-if="editingId && isFormOpen"
      :is-open="isFormOpen"
      :area-id="editingId"
      @duplicate="openDuplicateForm($event)"
      @close="closeForm()"
      @updated="handleAreaUpdate($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { AREA_ID_NO_AREAS, AREA_ID_ALL_AREAS } from '../../models/area.model'
import { AREA_GETTER_AREAS_LIST } from '@/app/core/area/store/getters/area.getters.names'

import {
  AREA_ACTION_LIST_AREAS,
  AREA_ACTION_LIST_AREAS_NEXT_PAGE,
} from '../../store/actions/area.actions.names'

import { AREA_MUTATION_SET_SELECTED_AREAS } from '../../store/mutations/area.mutations.names'

import AreaList from '../area-list/AreaList'
import AreaFormCreate from '../area-form-create/AreaFormCreate'
import AreaFormEdit from '../area-form-edit/AreaFormEdit'
import { isValidSearchQuery } from '@/utils/helpers'
import router from '@/router'

import AreaPaginatedSearchMixin from '../area-search/AreaPaginatedSearch.mixin'

import debounce from 'lodash/debounce'
import { DEFAULT_SEARCH_DEBOUNCE_TIME, ROUTE_NAMES } from '@/constants'

export default {
  name: 'AreaContainer',
  components: {
    AreaList,
    AreaFormEdit,
    AreaFormCreate,
  },
  mixins: [AreaPaginatedSearchMixin],
  props: {
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isListOpen: false,
      isFormOpen: false,
      editingId: null,
      duplicatedArea: {
        area: null,
        users: [],
      },
      searchQuery: '',
      selectedCount: 0,
    }
  },
  trackedActions: {
    listAreas: AREA_ACTION_LIST_AREAS,
    listAreasNextPage: AREA_ACTION_LIST_AREAS_NEXT_PAGE,
  },
  computed: {
    ...mapGetters({
      areas: AREA_GETTER_AREAS_LIST,
    }),
    ...mapState({
      selected: (state) => state.area.selected,
      permissions: (state) => ({
        create: state.area.meta.can_create_working_areas,
      }),
    }),
    isListLoading() {
      return this.searchContext.loading || this.$actions.listAreas.isLoading
    },
    isSearching() {
      return isValidSearchQuery(this.searchQuery)
    },
    searchAreas() {
      return this.searchContext.options
    },
    selectAreaItems() {
      return this.mapAttributes(this.areas)
    },
    isTimeTrackingModule() {
      return router.currentRoute.meta.feature === ROUTE_NAMES.TIME_TRACKING
    },
    selectItems() {
      if (this.isSearching) return this.mapAttributes(this.searchAreas)

      let selectAreas = []

      if (this.isTimeTrackingModule)
        selectAreas.push({
          id: AREA_ID_NO_AREAS,
          label: this.$t('topbar.area.with_no_areas'),
          searchable: false,
          countAsSelected: true,
          editable: false,
        })

      if (this.selectAreaItems.length > 1) {
        selectAreas.push({
          id: AREA_ID_ALL_AREAS,
          label: this.$t('topbar.area.all_areas'),
          countAsSelected: false,
          searchable: false,
          children: this.selectAreaItems,
          editable: false,
        })
      } else {
        selectAreas.push(
          ...this.selectAreaItems.map((item) => ({
            ...item,
            disabled: false,
          }))
        )
      }

      return selectAreas
    },
    isSearchable() {
      return this.areas.length > 8
    },
    typeButtonNew() {
      return this.areas.length <= 1 ? 'filled' : 'basic'
    },
    computedSelected: {
      get() {
        if (this.$actions.listAreas.isLoading) {
          return []
        }
        if (this.areas.length === 0) {
          return [AREA_ID_NO_AREAS]
        } else if (this.selected && this.selected.length) {
          if (this.isTimeTrackingModule) return this.selected
          else
            return this.selected.filter((area) => area.id !== AREA_ID_NO_AREAS)
        } else {
          return []
        }
      },
      set(selected) {
        this.setSelected(selected)
      },
    },
    canCreate() {
      return this.permissions.create
    },
  },
  created() {
    this.listAreas(1)
  },
  methods: {
    ...mapMutations({
      setSelected: AREA_MUTATION_SET_SELECTED_AREAS,
    }),
    ...mapActions({
      listAreas: AREA_ACTION_LIST_AREAS,
      listAreasNextPage: AREA_ACTION_LIST_AREAS_NEXT_PAGE,
    }),
    openForm() {
      this.isListOpen = false
      this.isFormOpen = true
    },
    closeForm() {
      this.isFormOpen = false
    },
    openCreateForm() {
      this.openForm()
      this.editingId = null
    },
    openEditForm(area) {
      this.openForm()
      this.editingId = area.id
    },
    openDuplicateForm(duplicatedData) {
      this.editingId = null

      this.duplicatedArea = {
        ...duplicatedData,
        area: {
          ...duplicatedData.area,
          attributes: {
            ...duplicatedData.area.attributes,
            name: `${duplicatedData.area.attributes.name} (copy)`,
          },
        },
      }
    },
    mapAttributes(areas) {
      return areas.map((area) => ({
        id: area.id,
        label: area.attributes.name,
        active: area.attributes.active,
        editable: area.attributes.permissions.update,
        color: area.attributes.color,
      }))
    },
    handleScrollEnd() {
      if (this.isSearching) {
        this.searchNextPage()
      } else {
        if (!this.$actions.listAreas.isLoading) {
          this.listAreasNextPage()
        }
      }
    },
    handleTyping: debounce(async function (query) {
      this.searchQuery = query

      if (isValidSearchQuery(query)) {
        this.search({ query, page: 1 })
      }
    }, DEFAULT_SEARCH_DEBOUNCE_TIME),
    handleAreaUpdate(editData) {
      if (this.isSearching) {
        const editedSearchArea = this.searchAreas.find(
          (area) => area.id === editData.id
        )

        editedSearchArea.attributes.name = editData.name
        editedSearchArea.attributes.color = editData.color
      }
    },
  },
}
</script>
