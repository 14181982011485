import { MODULE_NAME } from '../../area.module'

export const AREA_MUTATION_SET_AREAS = `${MODULE_NAME}/MUTATION_SET_AREAS`
export const AREA_MUTATION_ADD_AREAS = `${MODULE_NAME}/MUTATION_ADD_AREAS`
export const AREA_MUTATION_SET_META = `${MODULE_NAME}/MUTATION_SET_META`
export const AREA_MUTATION_UPDATE_PAGE = `${MODULE_NAME}/MUTATION_UPDATE_PAGE`
export const AREA_MUTATION_ADD_AREA = `${MODULE_NAME}/MUTATION_ADD_AREA`
export const AREA_MUTATION_DELETE_AREA = `${MODULE_NAME}/MUTATION_DELETE_AREA`
export const AREA_MUTATION_UPDATE_AREA = `${MODULE_NAME}/MUTATION_UPDATE_AREA`
export const AREA_MUTATION_SET_SELECTED_AREAS = `${MODULE_NAME}/MUTATION_SET_SELECTED_AREAS`
export const AREA_MUTATION_ADD_AREAS_DATA = 'area/MUTATION_ADD_AREAS_DATA'
export const AREA_MUTATION_ADD_LOADING_AREAS_IDS = `${MODULE_NAME}/MUTATION_ADD_LOADING_AREAS_IDS`
export const AREA_MUTATION_REMOVE_LOADING_AREAS_IDS = `${MODULE_NAME}/MUTATION_REMOVE_LOADING_AREAS_IDS`
