














import { defineComponent, ref } from '@vue/composition-api'
import WorkspaceDropdown from '../workspace-dropdown/WorkspaceDropdown.vue'
import WorkspaceBottomSheet from '../workspace-bottom-sheet/WorkspaceBottomSheet.vue'

export default defineComponent({
  name: 'WorkspaceList',
  components: { WorkspaceDropdown, WorkspaceBottomSheet },

  props: {
    workspacesList: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  setup() {
    const isBottomSheetListWorkspaceOpen = ref(false)

    return {
      isBottomSheetListWorkspaceOpen,
    }
  },
})
