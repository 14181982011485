export const USER_MUTATION_SET_USER = 'user/MUTATION_SET_USER'
export const USER_MUTATION_SET_USER_FEATURES = 'user/MUTATION_SET_USER_FEATURES'

export const USER_MUTATION_SWITCH_TIME_FORMAT =
  'user/MUTATION_SWITCH_TIME_FORMAT'
export const USER_MUTATION_ADD_USERS_DATA = 'user/MUTATION_ADD_USERS_DATA'
export const USER_MUTATION_SET_ROLE_LIST = 'user/MUTATION_SET_ROLE_LIST'
export const USER_MUTATION_RESET_STATE = 'user/MUTATION_RESET_STATE'
export const USER_MUTATION_SET_RECORDS = 'user/MUTATION_SET_RECORDS'
export const USER_MUTATION_SET_PREVIEW_BANNER =
  'user/USER_MUTATION_SET_PREVIEW_BANNER'
export const USER_MUTATION_APPEND_RECORDS = 'user/MUTATION_APPEND_RECORDS'
export const USER_MUTATION_REPLACE_RECORD = 'user/MUTATION_REPLACE_RECORD'
export const USER_MUTATION_CLEAR_RECORD = 'user/MUTATION_CLEAR_RECORD'
export const USER_MUTATION_SET_SUGGESTED_FOLLOWERS_META =
  'user/MUTATION_SET_USER_SUGGESTED_FOLLOWERS_META'
export const USER_MUTATION_RESET_SUGGESTED_FOLLOWERS_META =
  'user/MUTATION_RESET_SUGGESTED_FOLLOWERS_META'
