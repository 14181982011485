class RegisterCompanyRequest {
  constructor(form) {
    this.data = {
      type: 'account',
      attributes: {
        name: form.companyName,
        phone: form.companyPhone,
        business: form.companyIndustry,
        size: form.companySize,
      },
    }
  }
}

class RegisterCompanyResponse {
  constructor(response) {
    this.account = response.data.data
  }
}

export { RegisterCompanyRequest, RegisterCompanyResponse }
