<template>
  <virtual-list
    ref="virtualList"
    :data-key="rowKey"
    :data-sources="rows"
    :data-component="rowComponent"
    :extra-props="extraProps"
    :page-mode="pageMode"
    :keeps="bufferSize"
    :estimate-size="rowHeight"
    header-class="table-header-container sticky top-15"
    :footer-class="`sticky h-10 w-full ${previewAppClassName}`"
    wrap-class="virtual-list-wrapper"
    v-on="$listeners"
  >
    <template v-slot:header>
      <slot name="header">
        <base-table-header :columns="headerColumns" />
      </slot>

      <base-loading v-if="isLoading" class="base-loading" :active="true" />

      <div
        v-if="isEmpty && !isLoading"
        class="no-row-message w-full text-center text-coal-300 text-lg font-medium"
        :style="noRowsStyle"
      >
        {{ noRowsMessage }}
      </div>
    </template>

    <template v-slot:footer>
      <slot v-if="hasFooter && !isLoading && !isEmpty" name="footer">
        <base-table-footer :columns="columns" />
      </slot>
    </template>
  </virtual-list>
</template>

<script>
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import VirtualList from 'vue-virtual-scroll-list'
import BaseTableHeader from './BaseTableHeader.vue'
import BaseTableRow from './BaseTableRow.vue'
import BaseTableFooter from './table-footer/BaseTableFooter'

export default {
  name: 'BaseTable',
  components: {
    VirtualList,
    BaseLoading,
    BaseTableHeader,
    BaseTableFooter,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    rows: {
      type: Array,
      default() {
        return []
      },
    },
    rowKey: {
      type: String,
      default: 'id',
    },
    rowComponent: {
      type: Object,
      default() {
        return BaseTableRow
      },
    },
    pageMode: {
      type: Boolean,
      default: false,
    },
    bufferSize: {
      type: Number,
      default: 30,
    },
    rowHeight: {
      type: Number,
      default: 50,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabledRowsIds: {
      type: Array,
      default: () => [],
    },
    noRowsMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      noRowMessagePadding: 0,
    }
  },
  computed: {
    extraProps() {
      return {
        columns: this.columns,
        disabledIds: this.disabledRowsIds,
      }
    },
    headerColumns() {
      return this.columns.filter((c) => c.header)
    },
    noRowsStyle() {
      if (!this.noRowMessagePadding) {
        return {}
      }

      return {
        marginTop: `calc(50vh - ${this.noRowMessagePadding}px)`,
        marginBottom: `calc(50vh - ${this.noRowMessagePadding}px)`,
      }
    },
    loaderStyle() {
      return {
        height: `calc(90vh - ${this.noRowMessagePadding}px)`,
      }
    },
    previewAppClassName() {
      return this.$route.query.preview ? 'bottom-12' : 'bottom-0'
    },
    hasFooter() {
      return this.columns.some((row) => row.footer)
    },
    isEmpty() {
      return !this.rows.length
    },
  },
  watch: {
    $isSmallDevice() {
      this.setElPadding()
    },
  },
  mounted() {
    this.setElPadding()
  },
  methods: {
    updatePageModeFront() {
      return this.$refs.virtualList.updatePageModeFront()
    },
    getCurrentScrollOffset() {
      return this.$refs.virtualList.getOffset()
    },
    setElPadding() {
      const el = this.$el.querySelector('.table-header-container')

      if (el) {
        const elBoundingClientRect = el.getBoundingClientRect()
        this.noRowMessagePadding = elBoundingClientRect.top
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .virtual-list-wrapper {
  background-image: linear-gradient($coal-100 20%, transparent 0),
    linear-gradient($coal-100 20%, transparent 0),
    linear-gradient($coal-100 20%, transparent 0),
    linear-gradient($coal-100 20%, transparent 0);
  background-repeat: repeat-y;
  background-position: 0% 50%, 34% 50%, 68% 50%, 102% 50%;
  background-size: 22% 64px, 22% 64px, 22% 64px, 22% 64px;
}
::v-deep .virtual-list-wrapper [role='listitem'] {
  background: white;

  .base-table-row-container {
    @apply flex;
  }

  &:nth-child(odd) {
    .base-table-row-container {
      @apply bg-coal-30 hover:bg-coal-40;
    }
  }

  &:nth-child(even) {
    .base-table-row-container {
      @apply bg-white hover:bg-coal-40;
    }
  }
  .base-table-row-container.checked {
    @apply bg-blue-100;

    &:hover {
      @apply bg-blue-200;
    }
  }

  &:last-child {
    .base-table-row-container {
      @apply border-none;
    }
  }
}

::v-deep .table-header-container {
  @apply z-1;

  &::before {
    z-index: -2;
    content: '';
    @apply absolute left-0 w-full h-full bg-white;
  }
}

::v-deep .base-table-row-container .base-table-row-overlay {
  @apply absolute inset-0 bg-white-300 z-10;
}

.base-loading {
  height: 90vh;
}
</style>
