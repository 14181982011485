import {
  setRecords,
  replaceRecord,
  appendRecords,
  clearRecords,
} from '@/utils/jsonapi/records.mutations'
import { shortname, resetState } from '@/utils/store'
import { makeEmptyState } from '../user.state'
import * as mutationNamesObj from '../mutations/user.mutations.names'
import { TIME_FORMAT_TYPE } from '@/constants'
import VueCookies from '@/utils/vendors/vue-cookies'

const mutationNames = shortname(mutationNamesObj)

export default {
  // TODO: find out why passing the util directly doesn't work
  [mutationNames.USER_MUTATION_SET_RECORDS]: (state, data) =>
    setRecords(state, data),

  [mutationNames.USER_MUTATION_APPEND_RECORDS]: (state, data) =>
    appendRecords(state, data),

  [mutationNames.USER_MUTATION_REPLACE_RECORD]: (state, data) =>
    replaceRecord(state, data),

  [mutationNames.USER_MUTATION_CLEAR_RECORD]: (state, data) =>
    clearRecords(state, data),

  [mutationNames.USER_MUTATION_SET_USER]: (state, data) => {
    state.user = data
  },

  [mutationNames.USER_MUTATION_SET_PREVIEW_BANNER]: (state, data) => {
    state.previewBanner = data
    VueCookies.set('preview_banner', data)
  },

  [mutationNames.USER_MUTATION_SET_USER_FEATURES]: (state, data) => {
    state.userFeatures = data
  },

  [mutationNames.USER_MUTATION_SWITCH_TIME_FORMAT]: (state) => {
    let timeFormat = TIME_FORMAT_TYPE.STANDARD
    if (
      !state.user.attributes.timeFormat ||
      state.user.attributes.timeFormat === TIME_FORMAT_TYPE.STANDARD
    ) {
      timeFormat = TIME_FORMAT_TYPE.DECIMAL
    }

    state.user = {
      ...state.user,
      attributes: {
        ...state.user.attributes,
        timeFormat,
      },
    }
  },

  [mutationNames.USER_MUTATION_ADD_USERS_DATA]: (state, users) => {
    const newData = users.reduce((usersData, user) => {
      usersData[user.id] = user
      return usersData
    }, {})
    state.data = { ...state.data, ...newData }
  },

  [mutationNames.USER_MUTATION_SET_ROLE_LIST]: (state, roles) => {
    state.roles = roles
  },

  [mutationNames.USER_MUTATION_RESET_STATE]: (state) => {
    resetState(state, makeEmptyState())
  },

  [mutationNames.USER_MUTATION_SET_SUGGESTED_FOLLOWERS_META]: (
    state,
    payload
  ) => {
    state.suggestedFollowersMeta = payload
  },

  [mutationNames.USER_MUTATION_RESET_SUGGESTED_FOLLOWERS_META]: (state) => {
    state.suggestedFollowersMeta = {
      current_page: 0,
      page_items: 20,
      total_pages: 0,
      total_count: 0,
    }
  },
}
