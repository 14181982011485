<template>
  <base-loading
    v-if="action.loading"
    :active="true"
    size="1.25rem"
    class="bottom-sheet-header-action-loading"
  />
  <button
    v-else
    class="text-lg"
    :class="action.class"
    :disabled="action.disabled"
    type="button"
    @click="$emit(action.event)"
  >
    <base-icon v-if="action.icon" v-bind="action.icon" class="mb-1" />
    {{ action.title }}
  </button>
</template>

<script>
export default {
  name: 'BottomSheetHeaderAction',
  components: {
    BaseLoading: /* istanbul ignore next */ () =>
      import('@/app/util-modules/ui/loading/BaseLoading'),
    BaseIcon: /* istanbul ignore next */ () =>
      import('@/app/util-modules/ui/icon/BaseIcon'),
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.bottom-sheet-header-action-loading {
  @apply relative w-6 h-6 border-green-400;
}
</style>
