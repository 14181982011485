import BaseRequest, { BaseRequestPagination } from '@/utils/api/base-request'
import { USER_ENDPOINT_GET_SUGGESTED_FOLLOWERS } from '../network/user.endpoints'

export const USER_SUGGESTED_FOLLOWERS_TYPE = 'user'

export class GetUserSuggestedFollowersRequest extends BaseRequest {
  constructor({
    workspaceId,
    pagination,
    filter,
  }: {
    workspaceId: string
    pagination: BaseRequestPagination
    filter: {
      username: string
      followers: string[]
    }
  }) {
    super()
    const baseUrl = USER_ENDPOINT_GET_SUGGESTED_FOLLOWERS(workspaceId)
    if (filter && filter.followers) {
      const selectedIdsFilter = filter.followers
        .map((id: RecordId) => `filter[id_not_in][]=${id}`)
        .join('&')

      super.url = `${baseUrl}?${selectedIdsFilter}`
    } else {
      super.url = baseUrl
    }

    super.pagination = pagination

    super.filter = {
      ...(filter &&
        filter.username && {
          username: `ct:${filter.username}`,
        }),
    }
  }
}
