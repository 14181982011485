import { runOrReturn } from '@/utils/helpers'
import BaseTable from './BaseTable'
import BaseTableRowCell from './BaseTableRowCell'
import SlideUpTransition from '../transitions/SlideUpTransition'

export default {
  name: 'BaseTableRow',
  components: {
    SlideUpTransition,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    source: {
      type: Object,
      default() {
        return {}
      },
    },
    disabledIds: {
      type: Array,
      default: () => [],
    },
  },
  render(h) {
    const cells = this.columns.map((col) => {
      const data = {
        on: {},
        props: {},
      }

      // pre-process events
      for (const [event, handler] of Object.entries(col.cell?.events || {})) {
        data.on[event] = (...args) => handler(this.source, ...args)
      }

      // pre-process props
      for (const [key, value] of Object.entries(col.cell?.props || {})) {
        data.props[key] = runOrReturn(value, this.source)
      }

      if (col.width) {
        data.style = {
          width: runOrReturn(col.width, this.source),
        }
      }

      if (col.cell?.['class']) {
        data.class = runOrReturn(col.cell.class, this.source)
      }

      return h(col.cell?.element || BaseTableRowCell, data)
    })

    const tableEventListeners = {
      click: () => this.baseTable.$emit('row-click', this.source),
    }

    if (this.disabledIds.includes(this.source.id)) {
      cells.push(h('div', { class: 'base-table-row-overlay' }))
    }

    return h(
      'div',
      {
        class: this.source._hideRecordFromTable ? 'overflow-hidden' : null,
      },
      [
        h(SlideUpTransition, [
          h(
            'div',
            {
              class: `
                relative items-center h-16 py-2.5 px-4 md:px-2
                border-b border-coal-100
                base-table-row-container ${this.isChecked}
              `,
              on: this.baseTable ? tableEventListeners : {},
              directives: [
                {
                  name: 'show',
                  value: !this.source._hideRecordFromTable,
                },
              ],
            },
            cells
          ),
        ]),
      ]
    )
  },
  computed: {
    isChecked() {
      return this.source.checked ? 'checked' : null
    },
  },
  created() {
    let parent = this.$parent || this.$root

    // find BaseTable
    while (parent && !this.baseTable) {
      if (parent.$options.name === BaseTable.name) {
        this.baseTable = parent
      } else {
        parent = parent.$parent
      }
    }
  },
}
