<template>
  <div>
    <portal to="loader">
      <base-loading v-if="isLoading" :active="true" :is-full-page="true" />
    </portal>

    <user-menu-dropdown
      v-if="!$isSmallDevice"
      :profile-route="profileRoute"
      :data-id-prefix="`${dataIdPrefix}.user_menu`"
    />

    <template v-else>
      <base-avatar
        size="is-medium"
        :username="user.attributes.username"
        :img-url="userAvatarUrl"
        :text="true"
        img-class="w-8 h-8"
        avatar-class="bg-green-100 text-green-300"
        @click.native="toggleBottomSheet()"
      />

      <portal v-if="isBottomSheetOpen" to="bottom-sheet">
        <user-menu-bottom-sheet
          :profile-route="profileRoute"
          @cancel-click="toggleBottomSheet()"
        />
      </portal>
    </template>
  </div>
</template>

<script>
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'
import BaseAvatar from '@/app/util-modules/ui/avatar/BaseAvatar.vue'
import UserMenuDropdown from './UserMenuDropdown.vue'
import UserMenuBottomSheet from './UserMenuBottomSheet.vue'

import { mapGetters } from 'vuex'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import { WORKSPACE_ACTION_SWITCH_WORKSPACE } from '@/app/core/workspace/store/actions/workspace.actions.names'
import { USER_ACTION_SWITCH_ROLE } from '../../store/actions/user.actions.names'
import {
  USER_GETTER_USER,
  USER_GETTER_FEATURE,
} from '../../store/getters/user.getters.names'
import { omitDefaultAvatarUrl } from '@/utils/helpers'

export default {
  name: 'UserMenu',
  components: {
    BaseLoading,
    BaseAvatar,
    UserMenuDropdown,
    UserMenuBottomSheet,
  },
  trackedActions: {
    roleSwitch: USER_ACTION_SWITCH_ROLE,
    locationSwitch: WORKSPACE_ACTION_SWITCH_WORKSPACE,
  },
  props: {
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    isBottomSheetOpen: false,
  }),
  computed: {
    ...mapGetters({ user: USER_GETTER_USER, userFeature: USER_GETTER_FEATURE }),
    profileRoute() {
      if (this.userFeature(PROFILE_MODULE_NAME)) {
        return { name: `${PROFILE_MODULE_NAME}.details` }
      }
      return null
    },
    userAvatarUrl() {
      return omitDefaultAvatarUrl(this.user.attributes.avatar_thumb_url)
    },
  },
  created() {
    this.$watch(
      () =>
        this.$actions.roleSwitch.isLoading ||
        this.$actions.locationSwitch.isLoading,
      (isLoading) => {
        if (isLoading) this.isLoading = true
      }
    )
  },
  methods: {
    toggleBottomSheet() {
      this.isBottomSheetOpen = !this.isBottomSheetOpen
    },
  },
}
</script>
