<template>
  <div
    class="px-4 py-2 border-t border-b border-coal-150 bg-coal-40 text-sm uppercase text-coal-550"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'ListHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
