export const AREA_ENDPOINT_LIST_AREAS = (workspaceId: RecordId, page: number) =>
  `/workspaces/${workspaceId}/areas?page=${page}&permissions=create,update,destroy`
export const AREA_ENDPOINT_LIST_AREAS_BY_IDS = (
  workspaceId: RecordId,
  ids: string[]
) =>
  `/workspaces/${workspaceId}/areas?filter[id]=in:[${ids}]&permissions=create,update,destroy`
export const AREA_ENDPOINT_CREATE_AREA = (workspaceId: RecordId) =>
  `/workspaces/${workspaceId}/areas?permissions=create,update,destroy`
export const AREA_ENDPOINT_UPDATE_AREA = (
  workspaceId: RecordId,
  areaId: string
) =>
  `/workspaces/${workspaceId}/areas/${areaId}?permissions=create,update,destroy`
export const AREA_ENDPOINT_DELETE_AREA = (
  workspaceId: RecordId,
  areaId: string
) => `/workspaces/${workspaceId}/areas/${areaId}`
export const AREA_ENDPOINT_GET_AREA_DETAILS = (
  workspaceId: RecordId,
  areaId: string
) =>
  `/workspaces/${workspaceId}/areas/${areaId}?include=users&permissions=create,update,destroy`
export const AREA_ENDPOINT_SEARCH_AREAS = (
  workspaceId: RecordId,
  page: number,
  query: string
) =>
  `/workspaces/${workspaceId}/areas?page=${page}&filter[name]=ct:${query}&permissions=create,update,destroy`
