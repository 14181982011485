import axios from '@/utils/vendors/axios'

import {
  NOTIFICATION_ENDPOINT_LIST_NOTIFICATIONS,
  NOTIFICATION_ENDPOINT_DELETE_NOTIFICATION,
  NOTIFICATION_ENDPOINT_BULK_ACTIONS,
} from './notification.endpoints'
import { ListNotificationResponse } from '../models/list-notification.model'

export default {
  async listNotifications(workspaceId, page) {
    const response = await axios.get(
      NOTIFICATION_ENDPOINT_LIST_NOTIFICATIONS(workspaceId, page)
    )
    return new ListNotificationResponse(response)
  },
  async deleteNotification(workspaceId, notificationId, type) {
    await axios.delete(
      NOTIFICATION_ENDPOINT_DELETE_NOTIFICATION(
        workspaceId,
        notificationId,
        type
      )
    )
  },
  async deleteAllNotifications(workspaceId) {
    await axios.post(NOTIFICATION_ENDPOINT_BULK_ACTIONS(workspaceId), {
      data: {
        type: 'batch_action',
        attributes: {
          action: 'destroy',
          all: true,
        },
      },
    })
  },
}
