<template>
  <buefy-checkbox
    :value="value"
    :indeterminate="indeterminate"
    :size="size"
    :data-id="dataIdAttr"
    v-bind="$attrs"
    @click.native="clickHandler($event)"
    v-on="$listeners"
  >
    <slot />
  </buefy-checkbox>
</template>

<script>
import BuefyCheckbox from 'buefy/src/components/checkbox/Checkbox'

export default {
  name: 'BaseCheckbox',
  components: { 'buefy-checkbox': BuefyCheckbox },
  props: {
    value: {
      type: [Boolean, Array, Object],
      default: false,
    },
    size: {
      type: String,
      validator: (value) => ['is-small', 'is-medium'].indexOf(value) !== -1,
      default: 'is-medium',
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    stopPropagation: {
      type: Boolean,
      default: false,
    },
    dataIdAttr: {
      type: String,
      default: '',
    },
  },
  methods: {
    clickHandler(event) {
      if (this.stopPropagation) {
        event.stopPropagation()
      }
      this.$emit('click', event)
    },
  },
}
</script>
