<template>
  <div class="z-index-topbar sticky top-0 bg-white-400 md:px-6 w-full">
    <div
      class="md:h-15 pt-px px-4 md:px-0 h-16 flex items-center justify-between border-0 border-b border-coal-150 border-solid"
    >
      <div class="flex min-w-0 items-center">
        <base-icon
          class="md:hidden mr-4"
          icon="menu-hamburger/menu-hamburger-24"
          size="1.5rem"
          @click.native="$emit('show-mobile-sidebar')"
        />
        <div class="flex min-w-0 mr-4">
          <slot name="topbar-left" />
        </div>
      </div>

      <div class="flex items-center h-8">
        <slot name="tracking-controls" />

        <slot name="topbar-right" />

        <div class="mr-2">
          <slot name="tag-container" />
        </div>

        <div class="mr-2">
          <slot name="area-container" />
        </div>

        <div class="md:mr-5 lg:mr-6">
          <slot name="workspace-container" />
        </div>

        <upgrade-button
          v-if="!$isSmallDevice && hasFreeSubscription"
          class="mr-6"
        />

        <!-- TODO: Hiding unused links https://app.asana.com/0/1175527195735767/1200978105052501/f -->
        <!-- <a
        :href="messageHref"
        class="hidden md:block text-coal-450 py-1 mr-4"
      >
        <base-icon
          icon="speech-bubble/speech-bubble-24"
          size="1.5rem"
          type="is-filled"
        />
      </a> -->
        <slot name="notification-container" />
        <slot name="user-menu" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import { USER_GETTER_FEATURE_PERMISSIONS } from '@/app/core/user/store/getters/user.getters.names'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'AppTopbar',
  components: {
    BaseIcon,
    UpgradeButton: () => import('./AppTopbarUpgradeButton.vue'),
  },
  computed: {
    // ...mapState({
    //   workspaceId: (state) => state.workspace.workspace.id,
    // }),
    // messageHref() {
    //   return `https://app.papershift.com/companies/${this.workspaceId}/messages`
    // },
    ...mapGetters({
      userFeaturePermissions: USER_GETTER_FEATURE_PERMISSIONS,
    }),
    hasFreeSubscription() {
      return this.userFeaturePermissions(PROFILE_MODULE_NAME).free_subscription
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.z-index-topbar {
  z-index: $z-index-topbar;
}
</style>
