/**
 * Returns vuex name without namespace.
 * Accepts String or Object of Strings.
 *
 * shortname('namespace/name') -> 'name'
 * shortname({
 *   NAMESPACE_NAME: 'namespace/name'
 * }) -> {NAMESPACE_NAME: 'name'}
 */
export function shortname(name) {
  if (typeof name === 'string') {
    const slashIndex = name.lastIndexOf('/')
    return name.slice(slashIndex + 1)
  }

  if (typeof name === 'object' && name != null) {
    return Object.fromEntries(
      Object.entries(name).map(([k, v]) => {
        return [k, shortname(v)]
      })
    )
  }

  throw TypeError('invalid `name` param')
}

// resets `state` object recursively using given defaultState
// properties non-existing in defaultState left untouched
export function resetState(state, defaultState) {
  for (const [key, value] of Object.entries(defaultState)) {
    if (Array.isArray(value)) {
      state[key] = []

      for (let elem of value) {
        state[key].push(elem)
      }
    } else if (typeof value === 'object' && value !== null) {
      if (Object.keys(value).length === 0) {
        state[key] = {}
      } else {
        resetState(state[key], value)
      }
    } else {
      state[key] = value
    }
  }
}
