<template>
  <div :class="!vertical ? 'flex' : 'block'">
    <div :class="{ 'w-9/12': !vertical }">
      <base-input-field
        v-model="passedValue.name"
        validation-rules="tag_name|required"
        :label="$t('tag.form.field_label_name')"
        name="tag_name"
        type="text"
        :placeholder="$t('tag.form.field_placeholder_name')"
      />
    </div>

    <div :class="!vertical ? 'ml-3 w-3/12' : 'mt-6'">
      <base-dropdown-color
        v-model="passedValue.color"
        name="tag_color"
        :position="$isSmallDevice ? 'is-top-left' : 'is-bottom-right'"
        :append-to-body="!$isSmallDevice"
        :label="$t('tag.form.field_label_color')"
      />
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField'
import BaseDropdownColor from '@/app/util-modules/ui/dropdown-color/BaseDropdownColor'
import i18n from '@/utils/vendors/i18n'

extend('tag_name', (value) => {
  if (value.includes('<')) return i18n.t('tag.form.field_validation_message')
  else return true
})

export default {
  name: 'TagForm',
  components: {
    BaseInputField,
    BaseDropdownColor,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
  },
}
</script>
