<template>
  <base-dropdown-text-item aria-role="listitem">
    <template slot="left">
      <base-icon :icon="icon" :type="iconType" size="1rem" />
    </template>

    <span>{{ label }}</span>

    <template slot="right">
      <slot name="right" />
    </template>
  </base-dropdown-text-item>
</template>

<script>
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'UserMenuDropdownItem',
  components: {
    BaseDropdownTextItem,
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      validator: (value) => ['is-filled', 'is-stroke'].indexOf(value) !== -1,
      default: 'is-stroke',
    },
  },
}
</script>
