import { typesCheck } from '@/utils/exceptions/types-exception-handler'

class NotificationAttributes {
  constructor({ title, message, created_at }) {
    this.message = typesCheck.isString(message)
    this.title = typesCheck.isString(title)
    this.createdAt = new Date(typesCheck.isString(created_at))
  }
}

export default class Notification {
  constructor({ id, type, attributes, relationships }) {
    this.id = id
    this.type = type
    this.attributes = new NotificationAttributes(attributes)
    this.user = relationships.user.data
    this.entity = relationships.entity.data
  }
}
