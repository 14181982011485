// Might need consider remove the base User component
export default [
  {
    path: '/user/add',
    name: 'user.add',
    component: () =>
      import(/* webpackChunkName: "user" */ '../pages/UserAdd.vue'),
  },
  {
    path: '/user/list',
    name: 'user.list',
    component: import(/* webpackChunkName: "user" */ '../pages/UserList.vue'),
  },
]
