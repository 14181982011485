import { Tabs } from 'buefy'
import ConfigComponent from 'buefy/src/utils/ConfigComponent'
import { registerComponentProgrammatic } from 'buefy/src/utils/plugins'
import BaseIconAdapterBuefy from '@/app/util-modules/ui/icon/BaseIconAdapterBuefy'
import BaseSnackbarPlugin from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'

export const config = {
  defaultIconComponent: BaseIconAdapterBuefy,
  defaultIconPack: 'shift',
  customIconPacks: {
    shift: {
      iconPrefix: '',
      internalIcons: {
        check: 'checkmark', // TODO
        information: 'information', // TODO
        'check-circle': 'checkmark-circle-outline', // TODO
        alert: 'warning/warning-16', // TODO
        'alert-circle': 'warning/warning-16', // TODO
        'arrow-up': 'arrow/arrow-16',
        'chevron-right': 'arrow/arrow-16,is-stroke',
        'chevron-left': 'arrow/arrow-16,is-stroke,-180',
        'chevron-down': 'arrow/arrow-16,is-stroke,180',
        eye: 'eye/eye-24',
        'eye-off': 'eye-off/eye-off-24,is-filled',
        'menu-down': 'arrow-dropdown', // TODO
        'menu-up': 'arrow-dropup', // TODO
        'close-circle': 'cross-close/cross-close-16',
      },
    },
  },
}

export { ConfigComponent, registerComponentProgrammatic }

export default [Tabs, BaseSnackbarPlugin]
