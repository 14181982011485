import { typesCheck } from '@/utils/exceptions/types-exception-handler'

// special tag IDs used to distinguish from real tags
export const TAG_ID_NO_TAGS = 'nil'
export const TAG_ID_ALL_TAGS = 'all_tags'

class TagAttributes {
  constructor({ title, active, external_id, color }) {
    this.title = typesCheck.isString(title)
    this.active = typesCheck.isBoolean(active)
    this.external_id = typesCheck.isString(external_id)
    this.color = typesCheck.isString(color)
  }
}

export class TagsList {
  tags = []

  constructor(tagsList) {
    this.tags = tagsList.map((tag) => new Tag(tag))
  }
}

export class Tag {
  id = ''
  type = ''
  attributes = {}

  constructor(tag) {
    this.id = tag.id
    this.type = tag.type
    this.attributes = new TagAttributes(tag.attributes)
  }
}

export default Tag
