import UserAttributes from './user-attributes.model'
import { USER_ENDPOINT_UPDATE_USER } from '../network/user.endpoints'
import BaseRequest from '@/utils/api/base-request'

export const USER_TYPE = 'user'

export default class User {
  id = ''
  attributes = {}

  constructor(user, account, workspaces) {
    this.id = user.id
    this.type = USER_TYPE
    this.attributes = new UserAttributes(user.attributes)
    this.relationships = user.relationships

    if (account) {
      this.account = account
    }

    if (workspaces) {
      this.workspaces = workspaces
    }
  }
}

export class UpdateUserRequest extends BaseRequest {
  constructor({ workspaceId, userId, data }) {
    super()
    super.url = USER_ENDPOINT_UPDATE_USER(workspaceId, userId)
    super.method = 'patch'
    super.type = USER_TYPE

    super.attributes = data
  }
}
