// import VueI18nPhraseInContextEditor from 'vue-i18n-phrase-in-context-editor';
import VueI18n from 'vue-i18n'
import Vue from 'vue'
import axios from '@/utils/vendors/axios'
import { localeChanged } from 'vee-validate'
import { APP_LANG, APP_LANG_FALLBACK } from '@/constants'
import { enUS, de, es, nl, enGB } from 'date-fns/locale'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: APP_LANG,
  fallbackLocale: APP_LANG_FALLBACK,
  silentFallbackWarn: true,
})

export default i18n

export const supportedLanguages = [
  {
    id: 'de',
    name: () => i18n.t('languages.german'),
    flagCode: 'de',
    locale: de,
    veeValidateCode: 'de',
  },
  {
    id: 'ch',
    name: () => i18n.t('languages.german_switzerland'),
    flagCode: 'ch',
    locale: de,
    veeValidateCode: 'de',
  },
  {
    id: 'en',
    name: () => i18n.t('languages.english'),
    flagCode: 'us',
    locale: enUS,
    veeValidateCode: 'en',
  },
  {
    id: 'en-GB',
    dbCode: 'en_uk',
    name: () => i18n.t('languages.english_uk'),
    flagCode: 'uk',
    locale: enGB,
    veeValidateCode: 'en',
  },
  {
    id: 'es',
    name: () => i18n.t('languages.spanish'),
    flagCode: 'es',
    locale: es,
    veeValidateCode: 'es',
  },
  {
    id: 'nl',
    name: () => i18n.t('languages.dutch'),
    flagCode: 'nl',
    locale: nl,
    veeValidateCode: 'nl',
  },
]

const loadedLanguages = []

function setI18nLanguage(lang) {
  i18n.locale = lang

  // some tests mock axios, but not fully, so, we just bypass this line,
  // in case it's not mocked
  if (axios.defaults) {
    axios.defaults.headers.common['Accept-Language'] = findLocaleDBCode(lang)
  }

  localStorage.setItem('locale', lang)
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export const findLocaleDBCode = (lang) => {
  const selectedLang = supportedLanguages.find(
    (language) => language.id === lang
  )

  if (selectedLang && selectedLang.dbCode) return selectedLang.dbCode

  return lang
}

export const findLocaleID = (lang) => {
  const selectedLang = supportedLanguages.find(
    (language) => language.dbCode === lang
  )

  if (selectedLang) return selectedLang.id

  return lang
}

export async function loadLanguage(lang) {
  const { veeValidateCode } = supportedLanguages.find((l) => l.id === lang)

  const [translations, validationTranslations] = await Promise.all([
    import(
      /* webpackChunkName: "lang-[request]" */ `@/utils/vendors/i18n/locales/${lang}.json`
    ),
    import(
      /* webpackChunkName: "lang-validation-[request]" */ `vee-validate/dist/locale/${veeValidateCode}.json`
    ),
  ])

  i18n.setLocaleMessage(lang, {
    ...translations,
    validation: validationTranslations.messages,
  })
  loadedLanguages.push(lang)
}

export async function loadAndSwitchLanguage(lang) {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet

  await loadLanguage(lang)
  localeChanged()
  return Promise.resolve(setI18nLanguage(lang))
}

loadAndSwitchLanguage(localStorage.getItem('locale') || APP_LANG)
loadLanguage(APP_LANG_FALLBACK)
