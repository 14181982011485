import api from '../../network/user.api'
import { shortname } from '@/utils/store.js'
import i18n, { loadAndSwitchLanguage } from '@/utils/vendors/i18n'
import { logError, getAppLanguage } from '@/utils/helpers'
import { flattenRecord } from '@/utils/jsonapi/utils'
import * as actionNamesObj from './user.actions.names'
import * as mutationNamesObj from '../mutations/user.mutations.names'
import { installModules, uninstallAllModules } from '@/app/modules/installer'
import { WORKSPACE_ACTION_GET_USER_WORKSPACE } from '@/app/core/workspace/store/actions/workspace.actions.names'
import { WORKSPACE_MUTATION_SET_WORKSPACES_LIST } from '@/app/core/workspace/store/mutations/workspace.mutations.names'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'

import { USER_PREFERENCE_TYPE } from '../../models/user-preferences.model'
import { USER_SUGGESTED_FOLLOWERS_TYPE } from '../../models/user-suggested-followers.model'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.USER_ACTION_GET_CURRENT_USER]: async ({
    commit,
    dispatch,
    rootState,
  }) => {
    const response = await api.getCurrentUser()
    commit(mutationNames.USER_MUTATION_SET_USER, response.user)
    commit(WORKSPACE_MUTATION_SET_WORKSPACES_LIST, response.user.workspaces, {
      root: true,
    })
    await dispatch(
      WORKSPACE_ACTION_GET_USER_WORKSPACE,
      response.user.workspaces,
      { root: true }
    )

    const workspaceResponse = await api.getWorkspaceCurrentUser(
      rootState.workspace.workspace.id
    )
    const newLanguage = getAppLanguage(
      response.user.attributes,
      rootState.workspace.workspace.attributes,
      response.user.account.attributes,
      i18n.locale
    )
    await loadAndSwitchLanguage(newLanguage)

    commit(mutationNames.USER_MUTATION_SET_USER, {
      ...response.user,
      ...workspaceResponse.user,
    })

    await dispatch(actionNames.USER_ACTION_GET_USER_FEATURES)
    await dispatch(actionNames.USER_ACTION_LIST_ROLES)
  },

  [actionNames.USER_ACTION_GET_USER_FEATURES]: async ({
    rootState,
    commit,
  }) => {
    const { features } = await api.listUserFeatures(
      rootState.workspace.workspace.id
    )

    commit(mutationNames.USER_MUTATION_SET_USER_FEATURES, features)

    try {
      await installModules(features)
    } catch (e) {
      logError(e)
    }
  },

  [actionNames.USER_ACTION_LIST_ROLES]: async ({ rootState, commit }) => {
    const { data: response } = await api.listRoles(
      rootState.workspace.workspace.id
    )
    commit(
      mutationNames.USER_MUTATION_SET_ROLE_LIST,
      response.data.map((item) => flattenRecord(item))
    )
  },

  [actionNames.USER_ACTION_LOGOUT]: ({ commit }) => {
    commit(mutationNames.USER_MUTATION_RESET_STATE)
    uninstallAllModules()
  },

  [actionNames.USER_ACTION_SWITCH_ROLE]: async ({
    getters,
    rootState,
    dispatch,
  }) => {
    await api.switchRole(rootState.workspace.workspace.id)

    await dispatch(actionNames.USER_ACTION_GET_CURRENT_USER)

    const isAssumedRoleAdmin =
      getters[shortname(USER_GETTER_IS_ASSUMED_ROLE_ADMIN)]
    Snackbar.openAfterReload(
      isAssumedRoleAdmin
        ? i18n.t('role_switch.admin_view_switch_success')
        : i18n.t('role_switch.personal_view_switch_success')
    )

    location.reload()
  },

  [actionNames.USER_ACTION_UPDATE_USER_LOCALE]: async (
    { commit, dispatch, rootState, state },
    language
  ) => {
    await api.updateUser(rootState.workspace.workspace.id, state.user.id, {
      locale: language.dbCode || language.id,
    })

    commit(mutationNames.USER_MUTATION_SET_USER, {
      ...state.user,
      attributes: {
        ...state.user.attributes,
        locale: language.id,
      },
    })

    // roles are localized in backend, so we need to refetch them
    dispatch(actionNames.USER_ACTION_LIST_ROLES)
  },

  [actionNames.USER_PUBLIC_ACTION_GET_CURRENT_USER_BASE_ATTRIBUTES]: async ({
    commit,
  }) => {
    const response = await api.getCurrentUser()

    commit(mutationNames.USER_MUTATION_SET_USER, response.user)
  },

  [actionNames.USER_PUBLIC_ACTION_LIST_PREFERENCES]: async ({
    commit,
    state,
  }) => {
    const { data: response } = await api.getUserPreferences(state.user.id)
    if (!response.data.length) {
      return
    }
    commit(mutationNames.USER_MUTATION_SET_RECORDS, {
      type: USER_PREFERENCE_TYPE,
      records: response.data,
    })
  },

  [actionNames.USER_PUBLIC_ACTION_UPDATE_PREFERENCES]: async (
    { commit, state },
    { preferenceId, value }
  ) => {
    const { data: response } = await api.updateUserPreferences({
      userId: state.user.id,
      preferenceId,
      value,
    })

    commit(mutationNames.USER_MUTATION_REPLACE_RECORD, {
      type: USER_PREFERENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
  },

  [actionNames.USER_PUBLIC_ACTION_LIST_SUGGESTED_FOLLOWERS]: async (
    { state, rootState, commit },
    { filter }
  ) => {
    const pagination = {
      page: state.suggestedFollowersMeta.current_page + 1,
      items: state.suggestedFollowersMeta.page_items,
    }
    if (filter && filter.username) {
      pagination.page = 1
    }

    if (
      state.suggestedFollowersMeta.total_pages > 0 &&
      pagination.page > state.suggestedFollowersMeta.total_pages
    ) {
      return
    }

    const { data: response } = await api.getUserSuggestedFollowersRequest({
      workspaceId: rootState.workspace.workspace.id,
      pagination,
      filter,
    })

    if (!response.data.length) {
      return
    }

    if (response.meta.current_page > 1) {
      commit(mutationNames.USER_MUTATION_APPEND_RECORDS, {
        type: USER_SUGGESTED_FOLLOWERS_TYPE,
        records: response.data,
      })
    } else {
      commit(mutationNames.USER_MUTATION_SET_RECORDS, {
        type: USER_SUGGESTED_FOLLOWERS_TYPE,
        records: response.data,
      })
    }

    commit(
      mutationNames.USER_MUTATION_SET_SUGGESTED_FOLLOWERS_META,
      response.meta
    )
  },
  [actionNames.USER_PUBLIC_ACTION_CLEAR_SUGGESTED_FOLLOWERS]: ({ commit }) => {
    commit(mutationNames.USER_MUTATION_CLEAR_RECORD, {
      type: USER_SUGGESTED_FOLLOWERS_TYPE,
    })

    commit(mutationNames.USER_MUTATION_RESET_SUGGESTED_FOLLOWERS_META)
  },

  [actionNames.USER_ACTION_UPDATE_CHECKIN_STATE]: async (
    { commit, rootState, state },
    checkinState
  ) => {
    const response = await api.updateUser(
      rootState.workspace.workspace.id,
      state.user.id,
      { checkin_complete: checkinState }
    )

    commit(mutationNames.USER_MUTATION_SET_USER, {
      ...state.user,
      attributes: {
        ...state.user.attributes,
        checkin_complete: checkinState,
      },
    })

    return response
  },

  [actionNames.USER_PUBLIC_ACTION_GET_RECORDS_SUMMARY]: async ({
    commit,
    rootState,
    state,
  }) => {
    const response = await api.getUserRecordsSummary({
      workspaceId: rootState.workspace.workspace.id,
      userId: state.user.id,
    })

    const totalOvertimeInSec =
      response.data.data.attributes.total_overtime_in_sec

    commit(mutationNames.USER_MUTATION_SET_USER, {
      ...state.user,
      attributes: {
        ...state.user.attributes,
        total_overtime_in_sec: totalOvertimeInSec,
      },
    })

    return response
  },

  [actionNames.USER_PUBLIC_ACTION_GET_VACATIONS_SUMMARY]: async ({
    commit,
    rootState,
    state,
  }) => {
    const response = await api.getUserVacationsSummary({
      workspaceId: rootState.workspace.workspace.id,
      userId: state.user.id,
    })

    const hourHolidays = response.data.data.attributes.hour_holidays
    const remainingVacationDays =
      response.data.data.attributes.remaining_vacation_days_this_year

    commit(mutationNames.USER_MUTATION_SET_USER, {
      ...state.user,
      attributes: {
        ...state.user.attributes,
        hour_holidays: hourHolidays,
        remaining_vacation_days_this_year: remainingVacationDays,
      },
    })

    return response
  },

  [actionNames.USER_PUBLIC_ACTION_GET_CURRENT_WORKSPACE]: async ({
    commit,
    rootState,
    state,
  }) => {
    const workspaceResponse = await api.getWorkspaceCurrentUser(
      rootState.workspace.workspace.id
    )

    const mergeUserObjects = {
      ...state.user,
      ...workspaceResponse.user,
    }

    commit(mutationNames.USER_MUTATION_SET_USER, mergeUserObjects)
    return workspaceResponse.user
  },
}
