<template>
  <div class="flex flex-wrap">
    <app-sidebar />
    <div class="app-content-wrapper">
      <app-topbar-container :hidden-elements="$route.meta.hiddenElements" />
      <slot />

      <preview-banner
        v-if="showPreviewBanner && $isLargeDevice"
        @minimize="minimizePreviewBanner()"
      />
    </div>
  </div>
</template>

<script>
import AppTopbarContainer from '@/app/util-modules/ui/topbar/AppTopbarContainer'
import AppSidebar from '@/app/util-modules/ui/app-sidebar/AppSidebar'
import PreviewBanner from '@/app/util-modules/ui/banner/preview-banner/PreviewBanner.vue'
import { mapGetters } from 'vuex'
import {
  USER_GETTER_FEATURE,
  USER_GETTER_USER,
  USER_GETTER_PREVIEW_BANNER,
} from '@/app/core/user/store/getters/user.getters.names'
import VueCookies from '@/utils/vendors/vue-cookies'
import store from '@/store'

export default {
  name: 'DefaultLayout',
  components: {
    AppTopbarContainer,
    AppSidebar,
    PreviewBanner,
  },
  computed: {
    ...mapGetters({
      userFeature: USER_GETTER_FEATURE,
      userPreviewBanner: USER_GETTER_PREVIEW_BANNER,
      user: USER_GETTER_USER,
    }),
    showPreviewBanner() {
      // temporarily disable banner to switch to app.papershift.com
      // return this.$route.query.preview
      return (
        this.userPreviewBanner.visible &&
        this.userFeature('General').attributes.permissions.new_papershift_access
      )
    },
    isMinimized() {
      const isMinimized = localStorage.getItem('minimize')
      return isMinimized === 'true'
    },
  },
  mounted() {
    //check cookies for previewBanner
    const checkPreviewBanner = VueCookies.get('preview_banner')
    if (
      checkPreviewBanner === null ||
      checkPreviewBanner.userId !== this.user.id
    ) {
      store.commit('user/USER_MUTATION_SET_PREVIEW_BANNER', {
        visible: true,
        userId: this.user.id,
      })
    } else {
      store.commit('user/USER_MUTATION_SET_PREVIEW_BANNER', {
        visible: checkPreviewBanner.visible,
        userId: this.user.id,
      })
    }
  },
  methods: {
    minimizePreviewBanner() {
      store.commit('user/USER_MUTATION_SET_PREVIEW_BANNER', {
        visible: false,
        userId: this.user.id,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/style/variables';

.app-content-wrapper {
  @apply w-full;

  @screen md {
    width: calc(100% - #{$app-sidebar-width});
  }
}
</style>
