<template>
  <base-icon
    :icon="adaptedName"
    :rotation="adaptedRotation"
    :type="adaptedType"
  />
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseIconAdapterBuefy',
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: Array,
      required: true,
    },
  },
  computed: {
    adaptedIcon() {
      return this.icon[1].split(',')
    },
    adaptedName() {
      return this.adaptedIcon[0]
    },
    adaptedType() {
      return this.adaptedIcon[1]
    },
    adaptedRotation() {
      return this.adaptedIcon[2] ? parseInt(this.adaptedIcon[2], 10) : undefined
    },
  },
}
</script>
