import store from '@/store'
import { runOrReturn } from '@/utils/helpers'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import { AUTH_GETTER_IS_AUTHENTICATED } from '@/app/core/auth/store/getters/auth.getters.names'
import {
  AUTH_MUTATION_REMOVE_AUTH_TOKEN,
  AUTH_MUTATION_COOKIE_SIGN_IN_STATUS,
} from '@/app/core/auth/store/mutations/auth.mutations.names'
import { AUTH_ACTION_POST_SIGN_IN } from '@/app/core/auth/store/actions/auth.actions.names'

export default async function (to, from, next) {
  const loginRoute = { name: 'auth.login' }
  const checkinRoute = { name: 'auth.checkin' }
  const checkinCompletedRoute = { name: 'auth.checkin-completed' }
  const indexRoute = {
    name: `${TIME_TRACKING_MODULE_NAME}.index`,
    params: { workspaceId: store.state.workspace.workspace.id },
  }

  if (store.getters[AUTH_GETTER_IS_AUTHENTICATED]) {
    const isUserCheckinIncomplete =
      store.state.user.user.attributes.checkin_complete === false

    if (
      to.name &&
      to.name.startsWith('auth') &&
      !isUserCheckinIncomplete &&
      to.name !== checkinCompletedRoute.name &&
      from.name !== 'auth.register'
    ) {
      next(indexRoute)
    } else if (
      to.name &&
      to.name !== checkinRoute.name &&
      isUserCheckinIncomplete
    ) {
      next(checkinRoute)
    } else {
      next()
    }
  } else if (store.state.auth.authToken) {
    // it is possible that valid session cookie may exist, while user data in
    // localStorage not. This is where we check this and complete sign in.
    // this functionality is used for session-sharing between this app and
    // app.papershift.com
    try {
      store.commit(AUTH_MUTATION_COOKIE_SIGN_IN_STATUS, 'in-progress')
      // sign in process includes not only assigning auth token, but also
      // fetching user data and saving it to localStorage. Dispatching
      // following action does it
      await store.dispatch(AUTH_ACTION_POST_SIGN_IN)
      next(to.path || indexRoute)
    } catch (e) {
      store.commit(AUTH_MUTATION_COOKIE_SIGN_IN_STATUS, 'failed')
      store.commit(AUTH_MUTATION_REMOVE_AUTH_TOKEN)
      next(loginRoute)
    }
  } else {
    // skip auth only when `requiresAuth` is explicitly set to `false` for at
    // least one of the matches in current route match stack. Otherwise default to
    // always require auth
    const requiresAuth = !to.matched.some(
      (r) => runOrReturn(r.meta.requiresAuth, to, from) === false
    )

    if (requiresAuth) {
      next(loginRoute)
    } else {
      next()
    }
  }
}
