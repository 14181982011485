import { State, RecordGeneric, RecordIndexMap } from './types'

export function isRecordTypeIndexed(state: State, type: RecordType): boolean {
  return Boolean(state.recordsIndexes?.[type])
}

// creates index in the state for given `type` if not existing and returns it.
// indexes are saved in Map object. They are not meant to be reactive, because
// it's managed manually via jsonapi utils
export function createOrGetIndex(
  state: State,
  type: RecordType
): RecordIndexMap {
  if (!state.recordsIndexes) {
    state.recordsIndexes = {}
  }
  if (!state.recordsIndexes[type]) {
    state.recordsIndexes[type] = new Map()
  }

  return state.recordsIndexes[type]
}

// return index of a record for given type and id. Returns -1 if not existing
export function getRecordIndex(
  state: State,
  type: RecordType,
  id: RecordId
): number {
  // referencing module.exports.function allows us to mock the function
  // for testing
  const indexes = module.exports.createOrGetIndex(state, type)
  const recordIndex = indexes.get(id)

  if (recordIndex == null) {
    return -1
  }
  return recordIndex
}

// changes index of a record for given type and id into specified `index` param
// passing null as `index` deletes index of that record
export function reindexSingle(
  state: State,
  type: RecordType,
  id: RecordId,
  index: number | null
) {
  const indexes = module.exports.createOrGetIndex(state, type)

  if (index == null) {
    indexes.delete(id)
  } else {
    indexes.set(id, index)
  }
}

// runs reindexSingle() for all records starting from `startIndex` and onwards
export function reindexMultiple(
  state: State,
  type: RecordType,
  startIndex: number
) {
  const stateRecords = <RecordGeneric[]>state.records[type]

  for (let i = startIndex; i < stateRecords.length; ++i) {
    const r = stateRecords[i]
    module.exports.reindexSingle(state, type, r.id, i)
  }
}

// removes all indexes of given type
export function clearIndex(state: State, type: RecordType) {
  if (isRecordTypeIndexed(state, type)) {
    state.recordsIndexes[type].clear()
  }
}
