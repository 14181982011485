<template>
  <div
    :class="`${typeClass} ${sizeClass} inline-block font-semibold rounded truncate`"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'BaseBadge',
  props: {
    title: {
      required: true,
      type: String,
    },
    type: {
      type: String,
      required: false,
      validator: (value) =>
        [
          'is-default',
          'is-warning',
          'is-success',
          'is-danger',
          'is-info',
        ].indexOf(value) !== -1,
      default: 'is-default',
    },
    size: {
      type: String,
      validator: (value) => ['is-small', 'is-medium'].indexOf(value) !== -1,
      default: 'is-small',
    },
  },
  computed: {
    typeClass() {
      switch (this.type) {
        case 'is-warning':
          return 'bg-warning-100 text-warning-400'
        case 'is-danger':
          return 'bg-error-100 text-error-400'
        case 'is-success':
          return 'bg-success-100 text-success-400'
        case 'is-info':
          return 'bg-info-100 text-info-400'
        case 'is-default':
          return 'bg-coal-80 text-coal-650'
        default:
          return 'bg-coal-80 text-coal-650'
      }
    },
    sizeClass() {
      switch (this.size) {
        case 'is-small':
          return 'text-sm px-1'
        case 'is-medium':
          return 'text-base px-1.5 py-0.5'
        default:
          return 'text-sm'
      }
    },
  },
}
</script>
