




















import { defineComponent, ref } from '@vue/composition-api'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import UpgradeDialog from '@/app/util-modules/ui/dialog/UpgradeDialog.vue'

export default defineComponent({
  name: 'AppSidebarFooterItemsUpgradeButton',
  components: { BaseButton, UpgradeDialog },
  setup() {
    const isUpgradeDialogShown = ref(false)

    return {
      isUpgradeDialogShown,
    }
  },
})
