import { typesCheck } from '@/utils/exceptions/types-exception-handler'
import BaseRequest from '@/utils/api/base-request'
import { WORKSPACE_ENDPOINT_UPDATE_WORKSPACES } from '../network/workspace.endpoints'

class WorkspaceAttributes {
  constructor(attributes) {
    this.name = typesCheck.isString(attributes.name)
    this.locale = typesCheck.isString(attributes.locale)
    this.employee_invitation_link = typesCheck.isString(
      attributes.employee_invitation_link
    )
  }
}

export class WorkspaceList {
  workspaces = []
  constructor(workspacesList) {
    this.workspaces = workspacesList.map(
      (workspace) => new Workspace(workspace)
    )
  }
}

export class Workspace {
  id = ''
  type = ''
  attributes = {}

  constructor({ id, type, attributes }) {
    this.id = id
    this.type = type
    this.attributes = new WorkspaceAttributes(attributes)
  }
}

export class WorkspaceUpdateRequest extends BaseRequest {
  constructor({ workspaceId, name }) {
    super()

    super.method = 'patch'
    super.url = WORKSPACE_ENDPOINT_UPDATE_WORKSPACES(workspaceId)
    super.type = 'workspace'

    super.attributes = {
      name,
    }
  }
}
