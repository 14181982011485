import BaseRequest from '@/utils/api/base-request'
import {
  USER_ENDPOINT_GET_PREFERENCES,
  USER_ENDPOINT_UPDATE_PREFERENCES,
} from '../network/user.endpoints'

export const USER_PREFERENCE_TYPE = 'preference'

export class GetUserPreferencesRequest extends BaseRequest {
  constructor(userId: string) {
    super()
    super.type = USER_PREFERENCE_TYPE
    super.url = USER_ENDPOINT_GET_PREFERENCES(userId)
  }
}

export class UpdateUserPreferencesRequest extends BaseRequest {
  constructor({
    userId,
    preferenceId,
    value,
  }: {
    userId: RecordId
    preferenceId: RecordId
    value: Record<string, boolean>
  }) {
    super()
    super.method = 'PATCH'
    super.type = USER_PREFERENCE_TYPE
    super.url = USER_ENDPOINT_UPDATE_PREFERENCES({
      userId,
      preferenceId,
    })
    super.attributes = {
      value,
    }
  }
}
