import state from './monitoring.state'
import mutations from './monitoring.mutations'
import actions from './actions/monitoring.actions'
import getters from './getters/monitoring.getters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
