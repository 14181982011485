import store from '@/store'
import { MONITORING_ACTION_SEGMENT_PAGE } from '@/app/util-modules/monitoring/store/actions/monitoring.actions.names'

import { ALLOW_SEGMENT } from '@/app/util-modules/monitoring/store/actions/monitoring.actions.utils'

export default async function (to, from, next) {
  // If segment was not loaded initially and should be initiated
  // for this new page, we call the init action first so, it's
  // loaded before registering page event
  if (ALLOW_SEGMENT(store.getters)) {
    await store.dispatch(MONITORING_ACTION_SEGMENT_PAGE, to)
  }
  next()
}
