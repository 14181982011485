<template>
  <buefy-datepicker
    v-bind="$attrs"
    :focused-date="focusedDate"
    :editable="$attrs.editable"
    :date-formatter="formatter"
    :date-parser="parser"
    :position="$attrs.position"
    :locale="locale"
    :size="size"
    :first-day-of-week="firstDayOfWeek"
    :data-id="`${dataIdPrefix}.datepicker`"
    :min-date="minDate"
    :max-date="maxDate"
    class="base-date-picker"
    v-on="$listeners"
    @change-month="updateMonth($event)"
    @change-year="updateYear($event)"
  >
    <template v-slot:trigger>
      <slot name="trigger" />
    </template>
    <slot />
  </buefy-datepicker>
</template>

<script>
import VueI18n from '@/utils/vendors/i18n'
import BuefyDatepicker from 'buefy/src/components/datepicker/Datepicker'
import range from 'lodash/range'
import { runOrReturn } from '@/utils/helpers'
import { dateParser, formatDateTime } from '@/utils/date-time.utils'
import { addYears, subYears, startOfYear, endOfYear } from 'date-fns'

export default {
  name: 'BaseDatePicker',
  components: {
    BuefyDatepicker,
  },
  props: {
    customFocusedDate: {
      type: [Date, null],
      default: () => null,
    },
    classesCarouselArrows: {
      type: String,
      default: '',
    },
    classesCarouselArrowPrev: {
      type: String,
      default: '',
    },
    classesCarouselArrowNext: {
      type: String,
      default: '',
    },
    dateFormatter: {
      type: Function,
      default: null,
    },
    dateParser: {
      type: Function,
      default: null,
    },
    size: {
      type: String,
      default: 'is-medium',
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    const years = range(1900, 2100)
    const focusedDate = this.customFocusedDate || new Date()

    const today = new Date()

    return {
      selectedMonth: '',
      monthNames: () => [
        this.$t('datepicker.months.january'),
        this.$t('datepicker.months.february'),
        this.$t('datepicker.months.march'),
        this.$t('datepicker.months.april'),
        this.$t('datepicker.months.may'),
        this.$t('datepicker.months.june'),
        this.$t('datepicker.months.july'),
        this.$t('datepicker.months.august'),
        this.$t('datepicker.months.september'),
        this.$t('datepicker.months.october'),
        this.$t('datepicker.months.november'),
        this.$t('datepicker.months.december'),
      ],
      selectedYear: years[focusedDate.getFullYear() - 1900],
      focusedDate,
      years,
      firstDayOfWeek: 1,
      minDate: startOfYear(subYears(today, 7)),
      maxDate: endOfYear(addYears(today, 2)),
    }
  },
  computed: {
    locale() {
      return VueI18n.locale
    },
    months() {
      return runOrReturn(this.monthNames)
    },
    formatter() {
      return this.dateFormatter || formatDateTime
    },
    parser() {
      return this.dateParser || dateParser
    },
  },
  watch: {
    locale() {
      this.selectedMonth = this.months[this.focusedDate.getMonth()]
    },
    customFocusedDate(date) {
      if (!date) return
      this.selectedMonth = this.months[date.getMonth()]
      this.selectedYear = date.getFullYear()
      this.updateFocusedDate()
    },
    selectedMonth(newValue, oldValue) {
      if (
        this.months.indexOf(newValue) === 0 &&
        this.months.indexOf(oldValue) === 11
      ) {
        ++this.selectedYear
      }
      if (
        this.months.indexOf(newValue) === 11 &&
        this.months.indexOf(oldValue) === 0
      ) {
        --this.selectedYear
      }
      this.updateFocusedDate()
    },
  },
  mounted() {
    this.selectedMonth = this.months[this.focusedDate.getMonth()]
  },
  methods: {
    updateFocusedDate() {
      const newFocusedDate = new Date(this.focusedDate)
      newFocusedDate.setMonth(this.months.indexOf(this.selectedMonth))
      newFocusedDate.setFullYear(this.selectedYear)
      this.focusedDate = newFocusedDate
    },
    updateMonth(newMonth) {
      const newMonthName = this.months[newMonth]

      if (this.selectedMonth !== newMonthName) {
        this.selectedMonth = newMonthName
        this.updateFocusedDate()
      }
    },
    updateYear(newYear) {
      if (this.selectedYear !== newYear) {
        this.selectedYear = newYear
        this.updateFocusedDate()
      }
    },
  },
}
</script>

<style lang="scss">
.base-date-picker {
  .datepicker-table {
    border-spacing: 0.5rem;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
  }

  .datepicker-cell {
    @apply w-8 h-8 p-0 mr-1 font-medium #{!important};
  }

  .datepicker-body
    .datepicker-cell:not(.is-first-selected):not(.is-first-hovered):not(.is-last-selected):not(.is-last-hovered):not(.is-within-hovered):not(.is-within-selected) {
    &.is-unselectable {
      @apply text-coal-550  #{!important};
    }

    &.is-selectable {
      @apply text-coal  #{!important};

      &:hover {
        @apply bg-coal-80 text-coal #{!important};
      }
    }

    &.is-selected {
      @apply bg-green-300 text-white-400 #{!important};
    }
  }

  .datepicker-body {
    .datepicker-cell.is-today {
      box-shadow: 0 0 0 1px inset theme('colors.green.300');
      @apply border-0 #{!important};
    }
  }

  .dropdown-content {
    @apply border border-coal-100 pt-0;
  }

  .datepicker-header {
    margin-top: 5px;
    @apply pb-0 #{!important};
    .pagination {
      .pagination-previous,
      .pagination-next {
        @apply p-0 m-0 min-w-0 border-none shadow-none h-10;
      }
    }
  }

  .dropdown-item:focus {
    @apply outline-none;
  }

  .pagination-list {
    .field {
      .field-body {
        .field {
          .control {
            select {
              @apply text-base font-medium text-center;
            }
          }
        }
      }
    }
  }
}
</style>
