<template>
  <div class="border-b border-color-coal last:border-b-0" role="listitem">
    <component
      :is="linkTag"
      :to="route"
      :href="route"
      class="flex text-lg font-medium w-full items-center py-4 text-coal"
    >
      <slot>
        <base-icon
          v-if="icon"
          :icon="icon"
          :type="iconType"
          :size="iconSize"
          :class="iconClass"
        />
        <span>{{ label }}</span>
        <div class="ml-auto flex items-center">
          <slot name="right" />
          <base-icon
            v-if="secondaryIcon !== false"
            :icon="secondaryIcon || 'arrow/arrow-16'"
            size="1rem"
          />
        </div>
      </slot>
    </component>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BottomSheetListItem',
  components: {
    BaseIcon,
  },
  props: {
    route: {
      type: [Object, String],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconType: {
      type: String,
      validator: (value) => ['is-filled', 'is-stroke'].indexOf(value) !== -1,
      default: 'is-stroke',
    },
    label: {
      type: String,
      default: '',
    },
    secondaryIcon: {
      type: [String, Boolean],
      default: '',
    },
    iconSize: {
      type: String,
      default: '1rem',
    },
    iconClass: {
      type: String,
      default: 'mr-3',
    },
  },
  computed: {
    linkTag() {
      return this.route
        ? this.route.startsWith('http')
          ? 'a'
          : 'router-link'
        : 'button'
    },
  },
}
</script>
