<template>
  <div class="base-top-bar-notification w-full">
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTopBarNotification',
}
</script>

<style lang="scss" scoped>
.base-top-bar-notification {
  @apply bg-yellow-100 pl-6 pt-3 pb-4;

  box-shadow: 8px 0 0 0 theme('colors.yellow.400') inset;
}
</style>
