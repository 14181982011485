<template>
  <app-topbar @show-mobile-sidebar="showMobileSidebar()">
    <template v-if="!$isSmallDevice" v-slot:tracking-controls>
      <tracking-controls
        v-if="isTrackingControlsEnabled || showTrackingControlsPopup"
        :show-popup="showTrackingControlsPopup"
        :disabled="
          $actions.updateTracking.isLoading ||
          $actions.fetchActiveTracking.isLoading
        "
      />
    </template>
    <template v-slot:topbar-left>
      <router-view name="topbar-left" />
    </template>
    <template v-slot:topbar-right>
      <router-view name="topbar-right" />
    </template>
    <template v-if="!hiddenElements.includes('areas')" v-slot:area-container>
      <area-container data-id-prefix="app_topbar_container" />
    </template>
    <template v-if="!hiddenElements.includes('tags')" v-slot:tag-container>
      <tag-container data-id-prefix="app_topbar_container" />
    </template>
    <template
      v-if="!hiddenElements.includes('workspace')"
      v-slot:workspace-container
    >
      <workspace-container data-id-prefix="workspace_container" />
    </template>
    <template v-slot:user-menu>
      <user-menu
        data-id-prefix="app_topbar_container"
        class="hidden md:block"
      />
    </template>
    <template v-slot:notification-container>
      <notification-container
        data-id-prefix="app_topbar_container"
        class="hidden md:block"
      />
    </template>
  </app-topbar>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE,
  USER_PUBLIC_GETTER_TRACKING_CONTROLS_POPUP,
} from '@/app/core/user/store/getters/user.getters.names'
import { BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR } from '@/store/mutations/store.mutations.names'
import {
  TIME_TRACKING_ACTION_SHOW_ACTIVE,
  TIME_TRACKING_ACTION_UPDATE_ACTIONS,
} from '@/app/modules/time-tracking/store/actions/time-tracking.actions.names'

import AppTopbar from './AppTopbar'

import AreaContainer from '@/app/core/area/components/area-container/AreaContainer'
import TagContainer from '@/app/core/tag/components/tag-container/TagContainer'
import WorkspaceContainer from '@/app/core/workspace/components/workspace-container/WorkspaceContainer'
import UserMenu from '@/app/core/user/components/user-menu/UserMenu'
import NotificationContainer from '@/app/core/notification/components/notification-list/NotificationContainer'
import isEmpty from 'lodash/isEmpty'
import { TIME_TRACKING_ACTION_POLL_EXPORT } from '@/app/modules/time-tracking/store/actions/time-tracking.actions.names'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'

const TrackingControls = () =>
  import('@/app/util-modules/ui/tracking-controls/TrackingControls')

export default {
  name: 'AppTopbarContainer',
  components: {
    AppTopbar,
    AreaContainer,
    TagContainer,
    UserMenu,
    NotificationContainer,
    TrackingControls,
    WorkspaceContainer,
  },
  trackedActions: {
    fetchActiveTracking: TIME_TRACKING_ACTION_SHOW_ACTIVE,
    updateTracking: TIME_TRACKING_ACTION_UPDATE_ACTIONS,
  },
  props: {
    hiddenElements: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      showTrackingControlsPopup: USER_PUBLIC_GETTER_TRACKING_CONTROLS_POPUP,
    }),
    hasTimeTrackingModule() {
      return this.$store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](
        TIME_TRACKING_MODULE_NAME
      )
    },
    timeTrackingModulePermissions() {
      return this.$store.getters[USER_GETTER_FEATURE_PERMISSIONS](
        TIME_TRACKING_MODULE_NAME
      )
    },
    hasTimeTrackingPermission() {
      return !isEmpty(this.timeTrackingModulePermissions)
    },
    pendingExportIds() {
      return this.hasTimeTrackingPermission
        ? this.$store.state[TIME_TRACKING_MODULE_NAME].pendingExportIds
        : []
    },
    isTrackingControlsEnabled() {
      const { use_browser_punch_clock } = this.timeTrackingModulePermissions

      if (!use_browser_punch_clock) return false
      else return this.hasTimeTrackingModule
    },
  },
  created() {
    if (this.hasTimeTrackingPermission) {
      this.fetchActiveTimeTracking()

      if (this.pendingExportIds.length) {
        const pollingSnackbar = Snackbar.open({
          message: this.$t('export.polling_message'),
          type: 'is-info',
        })

        this.pendingExportIds.forEach((id) => {
          this.pollExport({
            exportId: id,
            closeSnackbar: pollingSnackbar.closeSnackbar,
          })
        })
      }
    }
  },
  methods: {
    ...mapMutations({
      showMobileSidebar: BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR,
    }),
    ...mapActions({
      fetchActiveTimeTracking: TIME_TRACKING_ACTION_SHOW_ACTIVE,
      pollExport: TIME_TRACKING_ACTION_POLL_EXPORT,
    }),
  },
}
</script>
