import { render, staticRenderFns } from "./BaseInputNumber.vue?vue&type=template&id=bc263b32&scoped=true&"
import script from "./BaseInputNumber.vue?vue&type=script&lang=js&"
export * from "./BaseInputNumber.vue?vue&type=script&lang=js&"
import style0 from "./BaseInputNumber.vue?vue&type=style&index=0&id=bc263b32&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_lh6vo7dceoovl5fyh3epe3vcle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc263b32",
  null
  
)

export default component.exports