<template>
  <base-card-modal-footer-wrapper
    :class="hideButtonCancel ? 'justify-end' : 'justify-between'"
  >
    <slot>
      <button
        v-if="!hideButtonCancel"
        type="button"
        class="rounded cursor-pointer text-coal-550 hover:text-coal-750"
        :disabled="disabled"
        :data-id="dataIdPrefix && `${dataIdPrefix}.cancel_button`"
        @click="$emit('close')"
      >
        {{ $t('card_modal.card_modal_form_footer.cancel') }}
      </button>
    </slot>

    <div class="flex items-center justify-center">
      <base-more-options
        v-if="moreOptions.length"
        :data-id-prefix="dataIdPrefix"
        position="is-top-left"
        :options="moreOptions"
        @selected="$emit('selected', $event)"
      />
      <base-button
        class="ml-2"
        :loading="loading"
        :disabled="loading || disabled"
        type="is-filled"
        native-type="button"
        :data-id="dataIdPrefix && `${dataIdPrefix}.submit`"
        @click="$emit('done')"
      >
        {{ buttonSubmitText }}
      </base-button>
    </div>
  </base-card-modal-footer-wrapper>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseMoreOptions from '@/app/util-modules/ui/more-options/BaseMoreOptions'
import BaseCardModalFooterWrapper from './BaseCardModalFooterWrapper'

export default {
  name: 'BaseCardModalFooter',
  components: {
    BaseButton,
    BaseMoreOptions,
    BaseCardModalFooterWrapper,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    buttonSubmitText: {
      type: String,
      required: true,
    },
    moreOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideButtonCancel: {
      type: Boolean,
      default: false,
    },
    // TODO: remove default and set as required
    dataIdPrefix: {
      type: String,
      default: 'base_card_modal_footer',
    },
  },
}
</script>
