<template>
  <component
    :is="!$isSmallDevice ? 'area-dropdown' : 'area-bottom-sheet'"
    v-model="passedValue"
    :is-open.sync="passedIsOpen"
    :items="items"
    :selected-count.sync="passedSelectedCount"
    :searchable="searchable"
    :type-button-new="typeButtonNew"
    :is-loading="isLoading"
    :can-create="canCreate"
    :has-search-query="hasSearchQuery"
    :data-id-prefix="`${dataIdPrefix}.area_list`"
    @edit="$emit('edit', $event)"
    @create="$emit('create')"
    @scroll-end="$emit('scroll-end')"
    @typing="$emit('typing', $event)"
  />
</template>

<script>
import AreaDropdown from '../area-dropdown/AreaDropdown'
import AreaBottomSheet from '../area-bottom-sheet/AreaBottomSheet'

export default {
  name: 'AreaList',
  components: {
    AreaDropdown,
    AreaBottomSheet,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
    },
    typeButtonNew: {
      type: String,
      required: false,
      default: 'Add',
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    hasSearchQuery: {
      type: Boolean,
      required: false,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedSelectedCount: {
      get() {
        return this.selectedCount
      },
      set(selectedCount) {
        this.$emit('update:selectedCount', selectedCount)
      },
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
}
</script>
