<template>
  <li class="w-full py-0 px-xs md:mb-6 md:w-auto md:px-0">
    <slot />
  </li>
</template>

<script>
export default {
  name: 'AppSidebarMenuListItem',
}
</script>
