import state from './notification.state'
import mutations from './mutations/notification.mutations'
import actions from './actions/notification.actions'
import getters from './getters/notification.getters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
