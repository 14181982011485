import { AreasList } from './area.model'
import { AreaMeta } from '../store/area.state'

interface ResponseData {
  data: {
    data: any
    meta: AreaMeta
  }
}
export class SearchAreasResponse {
  results: any
  totalPages: any

  constructor(response: ResponseData) {
    this.results = new AreasList(response.data.data).areas
    this.totalPages = response.data.meta.total_pages
  }
}
