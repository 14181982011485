<template>
  <base-modal has-modal-card @close="$emit('close')">
    <base-card-modal
      :title="$t('sidebar.upgrade_modal.title')"
      @close="$emit('close')"
    >
      <p class="mb-4">
        {{ $t('sidebar.upgrade_modal.text') }}
      </p>
      <div class="flex gap-4">
        <a :href="ORIG_APP_URL" target="_blank">
          {{ $t('sidebar.upgrade_modal.upgrade_link') }}
          <base-icon icon="link-hyperlink/link-hyperlink-16" />
        </a>

        <a :href="CONTACT_DEMO_URL" target="_blank">
          {{ $t('sidebar.upgrade_modal.contact_link') }}
          <base-icon icon="link-hyperlink/link-hyperlink-16" />
        </a>
      </div>
      <div slot="footer" />
    </base-card-modal>
  </base-modal>
</template>

<script>
import { ORIG_APP_URL, CONTACT_DEMO_URL } from '@/constants'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal.vue'
import BaseCardModal from '@/app/util-modules/ui/card-modal/BaseCardModal.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'UpgradeDialog',
  components: {
    BaseModal,
    BaseCardModal,
    BaseIcon,
  },
  data() {
    return { ORIG_APP_URL, CONTACT_DEMO_URL }
  },
}
</script>
