<template>
  <buefy-modal
    has-modal-card
    :active="true"
    :can-cancel="canCancel"
    scroll="clip"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </buefy-modal>
</template>

<script>
import BuefyModal from 'buefy/src/components/modal/Modal'

export default {
  name: 'BaseModal',
  components: {
    'buefy-modal': BuefyModal,
  },
  props: {
    canCancel: {
      type: [Boolean, Array],
      default: () => ['escape', 'outside'],
    },
  },
}
</script>
