import { shortname } from '@/utils/store.js'
import * as getterNamesObj from './auth.getters.names'
import {
  USER_GETTER_USER,
  USER_GETTER_FEATURE_NAMES,
} from '@/app/core/user/store/getters/user.getters.names'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.AUTH_GETTER_IS_AUTHENTICATED]: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    if (!state.authToken) {
      return false
    }
    if (!rootGetters[USER_GETTER_USER]) {
      return false
    }
    if (!rootGetters[USER_GETTER_FEATURE_NAMES].length) {
      return false
    }
    return true
  },

  [getterNames.AUTH_GETTER_TOKEN]: (state) => state.authToken,
}
