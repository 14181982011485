import { EMPLOYEE_ENDPOINT_LIST_EMPLOYEES } from '../network/employee.endpoints'

export const generateUrlAndFilter = ({
  workspaceId,
  query,
  filter,
}: {
  workspaceId: RecordId
  query: string
  filter: Record<string, any>
}) => {
  const url = EMPLOYEE_ENDPOINT_LIST_EMPLOYEES(workspaceId)
  let appliedFilter = null

  if (!filter && !query) {
    return {
      url,
      filter: appliedFilter,
    }
  }

  if (query) {
    appliedFilter = {
      username: `ct:${query}`,
    }
  }

  if (!filter) {
    return {
      url,
      filter: appliedFilter,
    }
  } else if (filter.id_not_in) {
    const { id_not_in, ...restFilters } = filter

    const selectedIdsFilter = id_not_in
      .map((id: RecordId) => `filter[id_not_in][]=${id}`)
      .join('&')

    if (restFilters) {
      appliedFilter = {
        ...appliedFilter,
        ...restFilters,
      }
    }

    return {
      url: `${url}?${selectedIdsFilter}`,
      filter: appliedFilter,
    }
  } else {
    return {
      url,
      filter: {
        ...appliedFilter,
        ...filter,
      },
    }
  }
}
