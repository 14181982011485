<template>
  <div class="list">
    <treeselect-list-item
      v-for="item in items"
      :key="item.id"
      class="item"
      :item="item"
      :field="field"
      :classes="classesItem"
      :size-checkbox="sizeCheckbox"
      :position-checkbox="positionCheckbox"
      :disabled="disabled"
      :data-id-prefix="dataIdPrefix"
      @toggle="$emit('toggle', $event)"
    >
      <template v-slot:label="slotProps">
        <slot name="label" :item="slotProps.item" />
      </template>
      <template v-slot:right="slotProps">
        <slot name="right" :item="slotProps.item" />
      </template>
      <template v-slot:right-hover="slotProps">
        <slot name="right-hover" :item="slotProps.item" />
      </template>
      <template v-slot:item-divider>
        <slot name="item-divider" />
      </template>
    </treeselect-list-item>
  </div>
</template>

<script>
export default {
  name: 'TreeselectList',
  components: {
    'treeselect-list-item': () => import('./TreeselectListItem'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    classesItem: {
      type: String,
      required: false,
      default: '',
    },
    sizeCheckbox: {
      type: String,
      required: false,
      default: 'is-small',
    },
    positionCheckbox: {
      type: String,
      required: false,
      default: 'is-left',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    // TODO: remove default and set as required
    dataIdPrefix: {
      type: String,
      default: 'treeselect_list',
    },
  },
}
</script>
