import trailingSlashInterceptor from './trailing-slash.interceptor'
import authenticationInterceptor from './authentication.interceptor'
import redirectInterceptor from './redirect.interceptor'
import featureAccessInterceptor from './feature-access.interceptor'
import monitoringInterceptor from './monitoring.interceptor'
import workspaceInterceptor from './workspace.interceptor'

// Registered in order, be careful
export default [
  trailingSlashInterceptor,
  authenticationInterceptor,
  redirectInterceptor,
  featureAccessInterceptor,
  monitoringInterceptor,
  workspaceInterceptor,
]
