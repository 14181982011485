import { shortname } from '@/utils/store.js'
import { IS_PRODUCTION_OR_STAGING_ENV } from '@/constants'
import * as actionNamesObj from './auth.actions.names'
import * as mutationNamesObj from '../mutations/auth.mutations.names'

import auth from '../../network/auth.api'
import { MONITORING_ACTION_TRACK_ALL } from '@/app/util-modules/monitoring/store/actions/monitoring.actions.names'

import {
  USER_ACTION_GET_CURRENT_USER,
  USER_ACTION_LOGOUT,
} from '@/app/core/user/store/actions/user.actions.names'
import { TAG_MUTATION_SET_SELECTED_TAGS } from '@/app/core/tag/store/mutations/tag.mutations.names'
import { AREA_MUTATION_SET_SELECTED_AREAS } from '@/app/core/area/store/mutations/area.mutations.names'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

const dispatchMonitoringActionTrackAll = (dispatch) => {
  if (IS_PRODUCTION_OR_STAGING_ENV) {
    return dispatch(MONITORING_ACTION_TRACK_ALL, null, { root: true })
  }
}

export default {
  [actionNames.AUTH_ACTION_SIGN_IN]: async ({ commit, dispatch }, form) => {
    const { token } = await auth.signIn(form)
    commit(mutationNames.AUTH_MUTATION_SET_AUTH_TOKEN, token)
    await dispatch(actionNames.AUTH_ACTION_POST_SIGN_IN)
  },
  [actionNames.AUTH_ACTION_POST_SIGN_IN]: async ({ dispatch }) => {
    // Setting user in user module
    await dispatch(USER_ACTION_GET_CURRENT_USER, null, {
      root: true,
    })
    await dispatchMonitoringActionTrackAll(dispatch)
  },
  [actionNames.AUTH_ACTION_REGISTER]: async ({ commit }, form) => {
    const { token } = await auth.register(form)
    commit(mutationNames.AUTH_MUTATION_SET_AUTH_TOKEN, token)
  },
  [actionNames.AUTH_ACTION_REGISTER_COMPANY]: async (store, form) => {
    await auth.registerCompany(form)
  },
  [actionNames.AUTH_ACTION_RESTORE_PASSWORD]: async (context, form) => {
    await auth.restorePassword(form)
  },
  [actionNames.AUTH_ACTION_CHANGE_PASSWORD]: async (
    { commit, dispatch },
    form
  ) => {
    const { token } = await auth.changePassword(form)
    commit(mutationNames.AUTH_MUTATION_SET_AUTH_TOKEN, token)
    await dispatch(actionNames.AUTH_ACTION_POST_SIGN_IN)
  },
  [actionNames.AUTH_ACTION_LOGOUT]: async ({ commit, dispatch }) => {
    commit(mutationNames.AUTH_MUTATION_REMOVE_AUTH_TOKEN)
    dispatch(USER_ACTION_LOGOUT, null, { root: true })

    // TODO: find out why monitoring action needed on logout
    await dispatchMonitoringActionTrackAll(dispatch)

    // TODO: private mutations of another module shouldn't be called here
    commit(TAG_MUTATION_SET_SELECTED_TAGS, [], { root: true })
    commit(AREA_MUTATION_SET_SELECTED_AREAS, [], { root: true })

    auth.signOut()
    localStorage.clear()
  },
  [actionNames.AUTH_ACTION_VALIDATE_PASSWORD_TOKEN]: async (store, form) => {
    await auth.validatePasswordToken(form)
  },
  [actionNames.AUTH_ACTION_GET_COMPANY_INDUSTRIES]: async () => {
    return auth.getCompanyIndustries()
  },
  [actionNames.AUTH_CHECKIN_ACTION_CREATE_USER]: async (
    { commit, dispatch },
    user
  ) => {
    const { token } = await auth.createUserViaCheckin(user)
    commit(mutationNames.AUTH_MUTATION_SET_AUTH_TOKEN, token)
    await dispatch(actionNames.AUTH_ACTION_POST_SIGN_IN)
  },
  [actionNames.AUTH_CHECKIN_ACTION_UPDATE_USER]: async (
    { commit, dispatch },
    user
  ) => {
    const { token } = await auth.updateUserViaCheckin(user)
    commit(mutationNames.AUTH_MUTATION_SET_AUTH_TOKEN, token)
    await dispatch(actionNames.AUTH_ACTION_POST_SIGN_IN)
  },
}
