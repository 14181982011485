<template>
  <div>
    <base-input-tag
      v-model="selected"
      :options="tagOptions"
      :loading="isTagLoading"
      :name="$t('employee.employee_area_group_search.field_name')"
      v-bind="$attrs"
      :label="$t('employee.employee_area_group_search.label')"
      field="title"
      :validation-rules="$attrs['validation-rules']"
      :validation-mode="$attrs['validation-mode']"
      taggable
      direction="top"
      :readonly="$isSmallDevice"
      :placeholder="$t('employee.employee_area_group_search.placeholder')"
      @typing="tagSearchEmployeeAreaGroup($event)"
      @click-button-trigger-add="overlay = true"
      @click.native="openOverlay()"
    />
    <base-overlay-search
      v-if="overlay"
      v-model="selected"
      :options="overlayOptions"
      :loading="isOverlayLoading"
      field="title"
      :placeholder="$t('employee.employee_area_group_search.placeholder')"
      @typing="overlaySearchEmployeeAreaGroup($event)"
      @click-button-done="overlaySelectionDone()"
    >
      <template v-slot:info-selection>
        <span v-if="selectionByTypeCount.user">
          {{
            $t(
              'employee.employee_area_group_search.mobile.count_selection_employee',
              {
                count: selectionByTypeCount.user,
              }
            )
          }}
        </span>
        <span v-if="selectionByTypeCount.area && selectionByTypeCount.user">
          &middot;
        </span>
        <span v-if="selectionByTypeCount.area">
          {{
            $t(
              'employee.employee_area_group_search.mobile.count_selection_area',
              {
                count: selectionByTypeCount.area,
              }
            )
          }}
        </span>
      </template>
    </base-overlay-search>
  </div>
</template>

<script>
import BaseInputTag from '@/app/util-modules/ui/input-tag/BaseInputTag'
import BaseOverlaySearch from '@/app/util-modules/ui/overlay-search/BaseOverlaySearch'

import api from '../../network/employee.api'

import debounce from 'lodash/debounce'
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@/constants'
import { mapState } from 'vuex'

import { isValidSearchQuery } from '@/utils/helpers'

export default {
  name: 'EmployeeAreaGroupSearch',
  components: {
    BaseInputTag,
    BaseOverlaySearch,
  },
  props: {
    value: {
      type: Array,
      required: true,
      validator: (items) =>
        items.every(
          (item) =>
            (typeof item.id === 'string' || typeof item.id === 'number') &&
            typeof item.type === 'string' &&
            typeof item.title === 'string'
        ),
    },
  },
  data() {
    return {
      isOverlayLoading: false,
      isTagLoading: false,
      overlay: false,
      overlayOptions: [],
      tagOptions: [],
    }
  },
  computed: {
    ...mapState({
      workspaceId: (state) => state.workspace.workspace.id,
    }),
    selected: {
      get() {
        return [...this.encodeUniqueIds(this.value)]
      },
      set(selected) {
        this.$emit('input', this.decodeUniqueIds(selected))
      },
    },
    selectionByTypeCount() {
      return this.selected.reduce(
        (counts, item) => {
          counts[item.type]++
          return counts
        },
        {
          user: 0,
          area: 0,
        }
      )
    },
  },
  methods: {
    encodeUniqueIds(items) {
      return items.map((item) => ({
        ...item,
        originalId: item.id,
        id: `${item.type}-${item.id}`,
      }))
    },
    decodeUniqueIds(items) {
      return items.map((item) => ({ ...item, id: item.originalId }))
    },
    tagSearchEmployeeAreaGroup: debounce(async function (query) {
      if (isValidSearchQuery(query)) {
        this.isTagLoading = true
        const response = await api.searchEmployeesAndAreas(
          this.workspaceId,
          query,
          1
        )
        this.tagOptions = response.results
      } else {
        this.tagOptions = []
      }

      this.isTagLoading = false
    }, DEFAULT_SEARCH_DEBOUNCE_TIME),
    overlaySearchEmployeeAreaGroup: debounce(async function (query) {
      if (isValidSearchQuery(query)) {
        this.isOverlayLoading = true
        const response = await api.searchEmployeesAndAreas(
          this.workspaceId,
          query,
          1
        )
        this.overlayOptions = response.results
      } else {
        this.overlayOptions = []
      }

      this.isOverlayLoading = false
    }, DEFAULT_SEARCH_DEBOUNCE_TIME),
    overlaySelectionDone() {
      this.overlayOptions = []
      this.overlay = false
    },
    openOverlay() {
      if (this.$isSmallDevice && this.selected.length === 0) {
        this.overlay = true
      }
    },
  },
}
</script>
