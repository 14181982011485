<template>
  <router-link
    :to="route"
    :data-id="`${dataIdPrefix}.tabs_topbar_back_button`"
    class="back-button-link"
  >
    <base-icon
      :rotation="180"
      :icon="$isSmallDevice ? 'arrow/arrow-24' : 'arrow/arrow-16'"
      :size="$isSmallDevice ? '1.5rem' : '1rem'"
      class="mr-3"
    />
  </router-link>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'TabsTopbarBackButton',
  components: { BaseIcon },
  props: {
    route: {
      type: Object,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.back-button-link {
  @apply flex items-center h-15 py-4 text-coal md:text-coal-550 hover:text-blue-400;

  &:hover {
    border-bottom-color: transparent;
  }
}

::v-deep svg {
  color: inherit;
}
</style>
