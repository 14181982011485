<template>
  <buefy-dropdown-item
    v-bind="$attrs"
    :aria-role="ariaRole"
    :custom="custom"
    v-on="$listeners"
  >
    <slot />
  </buefy-dropdown-item>
</template>

<script>
import BuefyDropdownItem from 'buefy/src/components/dropdown/DropdownItem'

export default {
  name: 'BaseDropdownItem',
  components: {
    BuefyDropdownItem,
  },
  props: {
    ariaRole: {
      type: String,
      default: 'listitem',
    },
    custom: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
