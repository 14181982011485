<template>
  <buefy-dropdown-item
    class="rounded-lg hover:bg-coal-40"
    :aria-role="ariaRole"
    :custom="custom"
    paddingless
    v-on="$listeners"
  >
    <div class="px-2 py-1.5 flex items-center">
      <div class="flex flex-1 items-center">
        <div v-if="$slots.left" class="flex items-center mr-2">
          <slot name="left" />
        </div>

        <slot />
      </div>

      <div v-if="$slots.right" class="justify-self-end">
        <slot name="right" />
      </div>
    </div>
  </buefy-dropdown-item>
</template>

<script>
import BuefyDropdownItem from 'buefy/src/components/dropdown/DropdownItem'

export default {
  name: 'BaseDropdownTextItem',
  components: {
    BuefyDropdownItem,
  },
  props: {
    ariaRole: {
      type: String,
      default: 'listitem',
    },
    custom: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
