<template>
  <div :class="titleObject.class">
    {{ titleObject.title }}
  </div>
</template>

<script>
export default {
  name: 'BottomSheetHeaderTitle',
  props: {
    title: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    titleObject() {
      if (typeof this.title === 'string') {
        return {
          title: this.title,
        }
      }
      return this.title
    },
  },
}
</script>
