export interface Attachment {
  id?: RecordId
  author: string
  created_at: Date
  updated_at: Date
  file_name: string
  file_content_type: string
  file_url: string
  isDeleted?: boolean
}
// appends note attachment related data to given formData
// `formData` - FormData instance to append data to
// `files` - array of files to upload
// `removedAttachments` - array of attachments with `id`s for removal
export function addAttachments(
  formData: FormData,
  files: Attachment[] = [],
  removedAttachments: Attachment[] = [],
  formDataName = 'note[attachments_attributes]'
) {
  const arr = [...files, ...removedAttachments]

  for (let i = arr.length - 1; i >= 0; --i) {
    const att = arr[i]

    if (att instanceof File) {
      formData.append(`${formDataName}[${i}][file]`, att)
    }
    if (att.isDeleted) {
      formData.append(`${formDataName}[${i}][id]`, `${att.id}`)
      formData.append(`${formDataName}[${i}][_destroy]`, 'true')
    }
  }
}
