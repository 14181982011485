<template>
  <div>
    <user-menu-bottom-sheet-main
      v-if="!isBottomSheetListLanguagesOpen"
      :language-name="currentLanguage.name()"
      :flag-code="currentLanguage.flagCode"
      :is-using-company-language="isUsingCompanyLanguage"
      :profile-route="profileRoute"
      @change-language="isBottomSheetListLanguagesOpen = true"
      @cancel-click="$emit('cancel-click')"
    />

    <user-menu-bottom-sheet-list-languages
      v-if="isBottomSheetListLanguagesOpen"
      :language-list="languageList"
      :current-language-id="currentLanguage.id"
      @select-language="changeLanguage($event)"
      @back="isBottomSheetListLanguagesOpen = false"
      @cancel-click="isBottomSheetListLanguagesOpen = false"
    />
  </div>
</template>

<script>
import UserMenuLocaleSwitchMixin from '@/app/core/user/components/user-menu/UserMenuLocaleSwitch.mixin'
import UserMenuBottomSheetMain from './UserMenuBottomSheetMain'
import UserMenuBottomSheetListLanguages from './UserMenuBottomSheetListLanguages'

export default {
  name: 'UserMenuBottomSheet',
  components: {
    UserMenuBottomSheetMain,
    UserMenuBottomSheetListLanguages,
  },
  mixins: [UserMenuLocaleSwitchMixin],
  props: {
    profileRoute: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBottomSheetListLanguagesOpen: false,
    }
  },
  methods: {
    changeLanguage(language) {
      this.loadAndSwitchLanguage(language)
      this.isBottomSheetListLanguagesOpen = false
    },
  },
}
</script>
