<template>
  <div
    class="text-white-400 flex items-center flex-shrink-0 mt-4.5 md:mt-4 md:justify-center"
  >
    <base-logo
      size="2.125rem"
      class="hidden md:block"
      data-id="sidebar.logo"
      only-emblem
    />
    <div class="md:hidden flex justify-between flex-1 px-6">
      <div class="flex items-center justify-self-start">
        <user-menu data-id-prefix="app_sidebar_header" class="mr-5" />
      </div>
      <div class="flex items-center">
        <!-- TODO: Hiding unused links https://app.asana.com/0/1175527195735767/1200978105052501/f -->
        <!-- <div>
          <button>
            <base-icon
              class="text-white-400 mr-6"
              icon="speech-bubble/speech-bubble-24"
              type="is-filled"
              size="1.75rem"
            />
          </button>
        </div> -->
        <notification-container data-id-prefix="app_siderbar_header" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseLogo from '@/app/util-modules/ui/logo/BaseLogo'
// import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import UserMenu from '@/app/core/user/components/user-menu/UserMenu'
import NotificationContainer from '@/app/core/notification/components/notification-list/NotificationContainer'

export default {
  name: 'AppSidebarHeader',
  components: {
    // BaseIcon,
    BaseLogo,
    UserMenu,
    NotificationContainer,
  },
}
</script>
