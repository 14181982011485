import Vue from 'vue'
import VueCookies from './vue-cookies'
import PortalVue from 'portal-vue'
import Buefy, { registerComponentProgrammatic, ConfigComponent } from './buefy'
import { VueSvgIcon } from '@yzfe/vue-svgicon'

import store from '@/store'
import './global-mixin'
import initVeeValidate from './vee-validate'

import { AUTH_GETTER_IS_AUTHENTICATED } from '@/app/core/auth/store/getters/auth.getters.names'
import {
  MONITORING_ACTION_TRACK_ALL,
  MONITORING_ACTION_INIT_ALL,
} from '@/app/util-modules/monitoring/store/actions/monitoring.actions.names'

import ActionTracker from '@/app/util-modules/action-status/action-tracker'
import { USER_ACTION_GET_USER_FEATURES } from '@/app/core/user/store/actions/user.actions.names'

const init = {
  vueConfigs: () => {
    Vue.config.productionTip = false
    Vue.config.devtools = process.env.VUE_APP_MODE !== 'production'
  },
  vendors: () => {
    Vue.use(VueCookies)
    Vue.use(PortalVue)
    initVeeValidate()

    Buefy.forEach((x) => Vue.use(x))
    registerComponentProgrammatic(Vue, 'config', ConfigComponent)

    Vue.component('SvgIcon', VueSvgIcon)

    init.vueConfigs()
  },
  monitoring: () => {
    if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
      import(/* webpackChunkName: "sentry" */ './monitoring/sentry/sentry')
    }

    if (process.env.VUE_APP_MODE === 'production') {
      store.dispatch(MONITORING_ACTION_INIT_ALL)
      store.dispatch(MONITORING_ACTION_TRACK_ALL)
    }
  },
  plugins: () => {
    Vue.use(ActionTracker)
  },
  modules: () => {
    if (!store.getters[AUTH_GETTER_IS_AUTHENTICATED]) {
      return Promise.resolve()
    }
    return store.dispatch(USER_ACTION_GET_USER_FEATURES)
  },
}

export default init
