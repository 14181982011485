import { shortname } from '@/utils/store'
import { flattenRecord, isValidId } from '@/utils/jsonapi/utils'
import { TAG_ID_NO_TAGS } from '../../models/tag.model'
import * as getterNamesObj from './tag.getters.names'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.TAG_GETTER_TAGS_LIST]: (state) => {
    return state.list.map((id) => state.data[id])
  },
  [getterNames.TAG_GETTER_TAG_DETAILS]: (state) => (id) => {
    return state.data[id]
  },
  [getterNames.TAG_PUBLIC_GETTER_TAG_LIST]: (state) => {
    return Object.keys(state.data).map((id) => flattenRecord(state.data[id]))
  },
  [getterNames.TAG_PUBLIC_GETTER_TAG_LIST_SELECTED]: (state) => {
    return Array.isArray(state.selected)
      ? state.selected.filter(
          (tag) => tag.id === TAG_ID_NO_TAGS || isValidId(tag.id)
        )
      : []
  },
}
