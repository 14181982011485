<template>
  <div class="flex pb-3 px-4 border-b border-color-coal">
    <div class="flex-1">
      <slot name="left">
        <header-action
          v-for="(action, i) in leftActionsOrDefault"
          :key="i"
          :action="action"
          class="justify-self-start text-left"
          v-on="$listeners"
        />
      </slot>
    </div>

    <header-title
      v-if="title"
      :title="title"
      class="text-center px-4 text-lg font-medium truncate"
    />

    <div class="flex-1 flex items-center justify-end">
      <more-options
        v-if="moreOptions && moreOptions.length"
        class="mr-6"
        :options="moreOptions"
        @select="$emit('option-select', $event)"
      />

      <slot name="right">
        <header-action
          v-for="(action, i) in rightActions"
          :key="i"
          :action="action"
          class="text-right"
          v-on="$listeners"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import HeaderTitle from './BottomSheetHeaderTitle'
import HeaderAction from './BottomSheetHeaderAction'
import MoreOptions from './BottomSheetMoreOptions'

export default {
  name: 'BottomSheetHeader',
  components: {
    HeaderTitle,
    HeaderAction,
    MoreOptions,
  },
  props: {
    title: {
      type: [String, Object],
      default: '',
    },
    leftActions: {
      type: Array,
      default: null,
    },
    rightActions: {
      type: Array,
      default: () => [],
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    leftActionsOrDefault() {
      if (!this.leftActions) {
        return [
          {
            title: this.$t('bottom_sheet.cancel'),
            class: 'text-coal-550',
            event: 'cancel-click',
          },
        ]
      }
      return this.leftActions
    },
  },
}
</script>
