class ChangePasswordRequest {
  constructor(form) {
    this.data = {
      type: 'user',
      attributes: {
        password: form.password,
        reset_password_token: form.token,
      },
    }
  }
}

class ChangePasswordResponse {
  constructor(response) {
    const { attributes, ...user } = response.data.data
    this.token = attributes.access_token
    this.user = user
  }
}

export { ChangePasswordRequest, ChangePasswordResponse }
