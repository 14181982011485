import state from './area.state'
import mutations from './mutations/area.mutations'
import actions from './actions/area.actions'
import getters from './getters/area.getters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
