<template>
  <bottom-sheet
    :header-title="$t('topbar.account.language')"
    @cancel-click="$emit('cancel-click')"
  >
    <template v-slot:header-left>
      <button
        data-test-id=""
        class="justify-self-start text-left text-lg text-coal-550"
        @click="$emit('back')"
      >
        <base-icon icon="arrow/arrow-16" :rotation="-180" class="mb-px" />
        {{ $t('topbar.account.language_back') }}
      </button>
    </template>

    <div class="px-4">
      <bottom-sheet-list-item
        v-for="(language, index) in languageList"
        :key="`language-${language.id}-${index}`"
        :label="language.name()"
        :secondary-icon="false"
        :class="{
          'bg-green-100 text-coal': language.id === currentLanguageId,
        }"
        class="border-b border-coal-150"
        @click.native="$emit('select-language', language)"
      >
        <user-menu-locale
          :language-name="language.name()"
          :flag-code="language.flagCode"
          :is-using-company-language="false"
        />
      </bottom-sheet-list-item>
    </div>
  </bottom-sheet>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import BottomSheetListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import UserMenuLocale from '@/app/core/user/components/user-menu/UserMenuLocale'

export default {
  name: 'UserMenuBottomSheetListLanguages',
  components: {
    BottomSheet,
    BottomSheetListItem,
    BaseIcon,
    UserMenuLocale,
  },
  props: {
    languageList: {
      type: Array,
      default: () => [],
    },
    currentLanguageId: {
      type: String,
      default: '',
    },
  },
}
</script>
