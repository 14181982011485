<template>
  <base-dropdown
    :is-open.sync="passedIsOpen"
    v-bind="$attrs"
    position="is-bottom-left"
    class="dropdown-topbar"
    :scrollable="true"
    :max-height="400"
    :data-id="`${dataIdPrefix}.dropdown_topbar`"
    @scroll-end="$emit('scroll-end')"
  >
    <dropdown-topbar-trigger
      slot="trigger"
      slot-scope="{ active }"
      data-id="dropdown-topbar-trigger"
      :active="active"
      :icon="icon"
      :label="label"
      :count="selectedCount"
    />
    <div class="-mx-2">
      <treeselect
        v-model="passedValue"
        data-id="dropdown-topbar-treeselect"
        :items="items"
        :searchable="searchable"
        classes-searchbar="mb-2"
        @change-selected-count="$emit('update:selectedCount', $event)"
        @typing="$emit('typing', $event)"
      >
        <template v-slot:divider>
          <hr class="dropdown-divider mx-2" />
        </template>
        <template v-if="canEdit" v-slot:right-hover="{ item }">
          <button
            v-if="item.editable"
            class="text-coal w-4 ml-2 items-center justify-center"
            @click.stop="$emit('click-button-edit', item)"
          >
            <base-icon
              class="mb-px"
              icon="edit-pencil/edit-pencil-16"
              size="1rem"
            />
          </button>
        </template>
        <template v-slot:right="{ item }">
          <div
            :style="`background-color: ${item.color}; `"
            class="w-3 h-3 rounded-full"
          />
        </template>
        <template v-slot:no-matching-items>
          <div class="text-center text-coal-450 p-3">
            {{ $t('topbar.dropdown.no_matches') }}
          </div>
        </template>
        <template v-slot:no-items>
          <slot name="no-items">
            <div class="text-center text-coal-450 p-3">
              {{ $t('topbar.dropdown.no_items') }}
            </div>
          </slot>
        </template>
      </treeselect>
      <div class="mx--2 bg-white bottom-0 sticky pb-3">
        <template v-if="canCreate">
          <hr class="dropdown-divider mx-2" />
          <dropdown-topbar-button-new
            :type-button-new="typeButtonNew"
            :text-button-new="textButtonNew"
            @click.native="$emit('click-button-new')"
          />
        </template>
      </div>
    </div>
  </base-dropdown>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import Treeselect from '@/app/util-modules/ui/treeselect/Treeselect'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

import DropdownTopbarTrigger from './DropdownTopbarTrigger'
import DropdownTopbarButtonNew from './DropdownTopbarButtonNew'

export default {
  name: 'DropdownTopbar',
  components: {
    BaseDropdown,
    Treeselect,
    BaseIcon,
    DropdownTopbarTrigger,
    DropdownTopbarButtonNew,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholderSearch: {
      type: String,
      required: false,
      default: 'Search',
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    textButtonNew: {
      type: String,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
    },
    typeButtonNew: {
      type: String,
      required: false,
      default: 'basic',
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
}
</script>

<style>
.dropdown-topbar .dropdown-menu {
  min-width: 250px;
}

.dropdown-topbar .dropdown-topbar-fixed-height {
  max-height: 300px;
}

.dropdown-topbar .dropdown-menu .dropdown-content {
  padding: 0.75rem 1rem 0;
}
</style>
