<template>
  <transition name="slide-fade-up">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'SlideUpTransition',
}
</script>

<style lang="scss" scoped>
::v-deep.slide-fade-up {
  &-leave-active {
    @apply transition-all duration-300 delay-1000 z-0 ease-out;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &-leave {
    @apply mt-0;
  }

  &-leave-to {
    @apply -mt-16;
  }
}
</style>
