var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex min-w-0"},[(_vm.showSmallMenu)?_c('module-menu-bottom-sheet',{staticClass:"flex min-w-0",attrs:{"modules":_vm.modules},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var trigger = ref.trigger;
return [(_vm.backButtonRoute)?_c('tabs-topbar-back-button',{attrs:{"route":_vm.backButtonRoute,"data-id-prefix":(_vm.dataIdPrefix + ".tabs_topbar")}}):_vm._e(),_c('tabs-topbar-trigger',{attrs:{"label":_vm.selectedTabLabel,"active":false},nativeOn:{"click":function($event){return trigger()}}})]}},{key:"list",fn:function(){return [_c('div',{staticClass:"px-4"},_vm._l((_vm.tabs),function(tab){return _c('bottom-sheet-list-item',{key:tab.id,attrs:{"icon":tab.smallIcon,"icon-type":tab.smallIconType,"secondary-icon":false,"label":_vm.renderTabLabel(tab.label),"route":_vm.getRouteLink(tab.route)}})}),1)]},proxy:true}],null,false,3222766270)}):(_vm.showMediumMenu)?_c('base-dropdown',{staticClass:"tabs-topbar-dropdown flex min-w-0",scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('tabs-topbar-trigger',{attrs:{"label":_vm.selectedTabLabel,"active":active}})]}}])},_vm._l((_vm.tabs),function(tab){return _c('base-dropdown-text-item',{key:tab.id,staticClass:"w-32",on:{"click":function($event){return _vm.gotoModulePage(tab.route)}}},[_vm._v(" "+_vm._s(_vm.renderTabLabel(tab.label))+" ")])}),1):_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"tabs"},[_c('ul',[(_vm.backButtonRoute)?_c('li',[_c('tabs-topbar-back-button',{attrs:{"route":_vm.backButtonRoute,"data-id-prefix":(_vm.dataIdPrefix + ".tabs_topbar")}})],1):_vm._e(),_vm._l((_vm.tabs),function(tab){return _c('tabs-topbar-item-tab',{key:tab.id,class:{
            'is-active': tab.id === _vm.selectedTab.id && _vm.$isLargeDevice,
            'is-single-tab': _vm.tabs.length === 1,
          },attrs:{"tab":tab,"data-id-prefix":(_vm.dataIdPrefix + ".tabs_topbar." + (tab.id))}})})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }