import { shortname } from '@/utils/store'
import * as mutationNamesObj from './workspace.mutations.names'
import VueCookies from '@/utils/vendors/vue-cookies'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.WORKSPACE_MUTATION_SET_WORKSPACE]: (state, data) => {
    state.workspace = data
    VueCookies.set('company_token', data.attributes.token)
  },
  [mutationNames.WORKSPACE_MUTATION_SET_WORKSPACES_LIST]: (state, data) => {
    state.workspacesList = data
  },
}
