import store from '@/store'
import { ORIG_APP_URL } from '@/constants'
import {
  USER_GETTER_FEATURE,
  USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE,
  USER_GETTER_INDEX_READABLE_FEATURES,
  USER_PUBIC_GETTER_SETTINGS_VISIBLE,
  USER_PUBLIC_GETTER_IS_METABASE_REPORTS_ENABLED,
} from '@/app/core/user/store/getters/user.getters.names'
import { MODULE_NAME_LIST } from '@/app/modules/installer'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'
import { MODULE_NAME as EMPLOYEE_MODULE_NAME } from '@/app/modules/employee/employee.module'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import { MODULE_NAME as SHIFTS_CALENDAR_MODULE_NAME } from '@/app/modules/shifts-calendar/shifts-calendar.module'
import { MODULE_NAME as ABSENCE_CALENDAR_MODULE_NAME } from '@/app/modules/calendar/calendar.module'
import { MODULE_NAME as SETTINGS_MODULE_NAME } from '@/app/modules/settings/settings.module'
import { MODULE_NAME as REPORT_MODULE_NAME } from '@/app/modules/reports/reports.module'

// in following route interceptor, we go through several cases:
// CASE #1: we check if user has read_index as admin or visible as employee for the requested feature
//   if a module is disabled on "Available Menu Items for Employees", they won't be able to access its page,
//   but the admins will, so we have to check the user role and the permission value
// CASE #2: if he doesn't, we find any other feature with such permission
// CASE #3: if any feature with read_index or visible permissions found, we check if that
//   was implemented in the app and redirect to it
// CASE #4: if the feature we found was not implemented in the app, we find URL
//   of the feature for old papershift and redirect to it
//   TODO: This case is temporarily skipped because of Free HR product:
//   Usually, Calendar module is available at this point, but because we
//   don't have it implemented in new frontend and because we don't have
//   session sharing working at the moment, we skip CASE #4 and show
//   Profile page instead (CASE #5). This can be removed when we either have
//   session sharing working (auto login between new and old apps) or Calendar
//   module implemented in new frontend
// CASE #5: at this point, we cannot show anything else then My Profile page, so
//   we redirect to Profile module
// CASE #6: this step is less likely to be reached, but if everything above
//   fails, we simply redirect user to old papershift
export default function (to, _from, next) {
  const hasRequestedTimeTrackingIndex =
    to.name === `${TIME_TRACKING_MODULE_NAME}.index`
  const hasRequestedEmployeeIndex = to.path.includes('/employees')
  const hasRequestedAbsenceIndex = to.name === `${ABSENCE_MODULE_NAME}.index`
  const hasRequestedShiftplanIndex =
    to.name === `${SHIFTS_CALENDAR_MODULE_NAME}.index`
  const hasRequestedAbsenceCalendarIndex =
    to.name === `${ABSENCE_CALENDAR_MODULE_NAME}.index`
  const hasRequestedSettingsIndex = to.name === `${SETTINGS_MODULE_NAME}.index`
  const hasRequestedReportsIndex = to.name === `${REPORT_MODULE_NAME}.index`

  if (
    hasRequestedTimeTrackingIndex ||
    hasRequestedEmployeeIndex ||
    hasRequestedAbsenceIndex ||
    hasRequestedShiftplanIndex ||
    hasRequestedAbsenceCalendarIndex ||
    hasRequestedSettingsIndex ||
    hasRequestedReportsIndex
  ) {
    if (hasRequestedTimeTrackingIndex) {
      const isTimeTrackingReadable = store.getters[
        USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE
      ](TIME_TRACKING_MODULE_NAME)

      // CASE #1
      if (isTimeTrackingReadable) {
        return next()
      }
    }

    if (hasRequestedEmployeeIndex) {
      const isEmployeeReadable =
        store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](
          EMPLOYEE_MODULE_NAME
        )

      // CASE #1
      if (isEmployeeReadable) {
        return next()
      }
    }

    if (hasRequestedAbsenceIndex) {
      const isAbsenceReadble =
        store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](
          ABSENCE_MODULE_NAME
        )

      // CASE #1
      if (isAbsenceReadble) {
        return next()
      }
    }

    if (hasRequestedShiftplanIndex) {
      const isShiftplanReadble = store.getters[
        USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE
      ](SHIFTS_CALENDAR_MODULE_NAME)

      // CASE #1
      if (isShiftplanReadble) {
        return next()
      }
    }

    if (hasRequestedAbsenceCalendarIndex) {
      const isAbsenceCalendarReadble = store.getters[
        USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE
      ](ABSENCE_CALENDAR_MODULE_NAME)

      // CASE #1
      if (isAbsenceCalendarReadble) {
        return next()
      }
    }

    if (hasRequestedSettingsIndex) {
      const isSettingsReadable =
        store.getters[USER_PUBIC_GETTER_SETTINGS_VISIBLE]

      // CASE #1
      if (isSettingsReadable) {
        return next()
      }
    }

    if (hasRequestedReportsIndex) {
      const isReportsReadable =
        store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](
          REPORT_MODULE_NAME
        ) && store.getters[USER_PUBLIC_GETTER_IS_METABASE_REPORTS_ENABLED]

      // CASE #1
      if (isReportsReadable) {
        return next()
      }
    }

    const readableFeatures = store.getters[
      USER_GETTER_INDEX_READABLE_FEATURES
    ].map((feature) => feature.attributes.hint)

    // CASE #2
    if (readableFeatures.length > 0) {
      // get one implemented feature from module list
      const implementedReadableFeature = readableFeatures.find(
        (featureName) => {
          return MODULE_NAME_LIST.includes(featureName)
        }
      )

      // CASE #3
      if (implementedReadableFeature) {
        next({ name: `${implementedReadableFeature}.index`, replace: true })
        return
      }

      /*
       * Temporarily skipping CASE #4 because we don't have session sharing
       * (auto-login) between old and new apps
       *
      const unimplementedReadableFeature = readableFeatures[0]

      // get old papershift URL to the unimplemented feature
      const oldModuleUrl =
        store.getters[BASE_GETTER_OLD_MODULES_URLS][
          unimplementedReadableFeature
        ]

      // CASE #4
      if (oldModuleUrl) {
        window.location.assign(oldModuleUrl)
        return
      }
      */
    }

    // CASE #5
    // normally, Profile module is always present, but we do safety check anyway
    if (store.getters[USER_GETTER_FEATURE](PROFILE_MODULE_NAME)) {
      next({ name: `${PROFILE_MODULE_NAME}.details`, replace: true })
      return
    }

    // CASE #6
    window.location.assign(ORIG_APP_URL)
  } else {
    next()
  }
}
