<template>
  <div class="base-card-modal-form">
    <base-form-validation :action-status="actionStatus" :hide-messages="true">
      <template
        v-slot:content="{ handleSubmit, handleInputChange, disabled, valid }"
      >
        <base-card-modal
          v-bind="$attrs"
          :submit-disabled="disabled"
          :hide-scrollbar="hideScrollbar"
          v-on="$listeners"
          @done="validate(handleSubmit)"
        >
          <template v-slot:header>
            <slot name="header" />
          </template>

          <base-form-validation-messages :action-status="actionStatus" />
          <slot :handle-input-change="handleInputChange" />

          <template v-slot:footer>
            <slot
              name="footer"
              :handle-submit="handleSubmit"
              :footer-action-validate="validate"
              :submit-disabled="disabled"
              :is-valid="valid"
            />
          </template>
        </base-card-modal>
      </template>
    </base-form-validation>
  </div>
</template>

<script>
import BaseCardModal from './BaseCardModal'
import BaseFormValidation from '@/app/util-modules/ui/form/BaseFormValidation'
import BaseFormValidationMessages from '@/app/util-modules/ui/form/BaseFormValidationMessages'

export default {
  name: 'BaseCardModalForm',
  components: {
    BaseCardModal,
    BaseFormValidation,
    BaseFormValidationMessages,
  },
  inheritAttrs: false,
  props: {
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    hideScrollbar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async validate(handleSubmit) {
      try {
        await handleSubmit()
        this.$emit('submit')
      } catch (e) {} /* eslint-disable-line no-empty */
    },
  },
}
</script>
