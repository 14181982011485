<template>
  <dropdown-topbar
    v-model="passedValue"
    data-id="area-dropdown"
    v-bind="$attrs"
    icon="area/area-16"
    :label="label"
    :items="items"
    max-height="400"
    scrollable
    expanded
    :can-create="canCreate"
    :can-edit="true"
    :placeholder-search="$t('area.dropdown.placeholder_search')"
    class="max-w-full"
    :selected-count.sync="passedSelectedCount"
    :is-open.sync="passedIsOpen"
    :text-button-new="$t('area.dropdown.button_new_area')"
    :searchable="searchable"
    :type-button-new="typeButtonNew"
    :is-loading="isLoading"
    :has-search-query="hasSearchQuery"
    :data-id-prefix="`${dataIdPrefix}.area_dropdown`"
    v-on="$listeners"
    @click-button-edit="$emit('edit', $event)"
    @click-button-new="$emit('create')"
    @scroll-end="$emit('scroll-end')"
    @typing="$emit('typing', $event)"
  />
</template>

<script>
import DropdownTopbar from '@/app/util-modules/ui/dropdown-topbar/DropdownTopbar'

export default {
  name: 'AreaDropdown',
  components: {
    DropdownTopbar,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
    },
    typeButtonNew: {
      type: String,
      required: false,
      default: 'Add',
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    hasSearchQuery: {
      type: Boolean,
      required: false,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedSelectedCount: {
      get() {
        return this.selectedCount
      },
      set(selectedCount) {
        this.$emit('update:selectedCount', selectedCount)
      },
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
    label() {
      return this.$isSmallDevice
        ? this.$t('area.dropdown.label_small_device')
        : this.$t('area.dropdown.label_large_device')
    },
  },
}
</script>
