import Area from './area.model'

export interface UpdateAreaForm {
  name: string
  color: string
  userIds: [{ [k: string]: string }]
  areaIds: [{ [k: string]: string }]
}

export class UpdateAreaRequest {
  data = {}
  constructor(form: UpdateAreaForm) {
    this.data = {
      type: 'area',
      attributes: {
        name: form.name,
        color: form.color,
        user_ids: form.userIds.map((user) => user.id),
        area_ids: form.areaIds.map((area) => area.id),
      },
    }
  }
}

export class UpdateAreaResponse {
  area: Area

  constructor(response: Area) {
    this.area = new Area(response)
  }
}
