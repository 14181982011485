import { shortname } from '@/utils/store.js'
import * as getterNamesObj from './action-status.getters.names'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.ACTION_STATUS_GETTER_STATUSES]: (state) => (scope) => {
    const actionAliases = Object.keys(scope)

    return actionAliases.reduce((statuses, alias) => {
      const action =
        typeof scope[alias] === 'string' ? scope[alias] : scope[alias].action

      statuses[alias] = state.data[action] || {}
      return statuses
    }, {})
  },
}
