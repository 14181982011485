<template>
  <base-filter
    :field="field"
    :label="label"
    :default-value="defaultValue"
    :serialized-value="serializedValue"
    :available-fields="availableFields"
    :invalid="invalid"
    :display-value="displayValue"
    dynamic-display-value
    @apply="apply($event)"
    @replace="$emit('replace', $event)"
    @remove="$emit('remove', $event)"
    @close="resetForm()"
  >
    <template v-slot:form>
      <div class="flex flex-col mt-4">
        <base-checkbox
          v-for="option in params.options"
          :key="option.value"
          v-model="form.items"
          :size="$isSmallDevice ? 'is-medium' : 'is-small'"
          :native-value="option.value"
          class="mb-4"
        >
          {{ runOrReturn(option.label) }}
        </base-checkbox>
      </div>
    </template>
  </base-filter>
</template>

<script>
import BaseFilter from './BaseFilter'
import BaseCheckbox from '../checkbox/BaseCheckbox'
import { selectFilter } from '@/utils/filterbar.utils'
import { runOrReturn } from '@/utils/helpers'

export default {
  name: 'BaseFilterSelect',
  components: {
    BaseFilter,
    BaseCheckbox,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    availableFields: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        items: [],
        ...this.defaultValue?.form,
      },
      appliedValue: null,
    }
  },
  computed: {
    filterParam() {
      const { items } = this.form
      return {
        [this.field]: `in:[${items.join(',')}]`,
      }
    },
    serializedValue() {
      return {
        field: this.field,
        form: this.form,
        filter: this.filterParam,
      }
    },
    invalid() {
      return this.form.items.length === 0
    },
    displayValue() {
      return selectFilter.getDisplayedValue(this.appliedValue, this.params)
    },
  },
  methods: {
    runOrReturn,
    resetForm() {
      if (this.appliedValue) {
        this.form = { ...this.appliedValue.form }
      }

      this.$emit('close')
    },
    apply(applied) {
      this.appliedValue = this.serializedValue

      if (applied.emit) {
        this.$emit('apply', this.appliedValue)
      }
    },
  },
}
</script>
