<template>
  <div class="flex pt-2 px-2 justify-between">
    <button
      class="text-sm font-semibold text-coal-550"
      :data-id="`${dataIdPrefix}.notification_list_header.delete_all`"
      @click="$emit('delete-all')"
    >
      {{ $t('topbar.notifications.delete_all') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'NotificationListHeader',
  props: {
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
}
</script>
