export interface AreaMeta {
  total_pages: number
  total_count: number
  can_create_working_areas: boolean
}

export interface AreaState {
  list: string[]
  data: any
  selected: any
  currentPage: number
  meta: AreaMeta
  loadingAreasIds: string[]
}

export const makeEmptyState = (): AreaState => ({
  list: [],
  data: {},
  selected: [],
  currentPage: 0,
  meta: {
    total_count: 0,
    total_pages: 0,
    can_create_working_areas: false,
  },
  loadingAreasIds: [],
})

export default makeEmptyState()
