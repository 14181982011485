





































import { defineComponent, PropType, computed } from '@vue/composition-api'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import BottomSheetListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem.vue'
import BottomSheetTopbarTrigger from '@/app/util-modules/ui/bottom-sheet-topbar/BottomSheetTopbarTrigger.vue'
import store from '@/store'

interface WorkspaceItem {
  attributes: {
    locale: string
    name: string
    time_zone: string
    token: string
  }
  id: string
  relationships: Record<string, unknown>
  type: string
}

export default defineComponent({
  name: 'WorkspaceBottomSheet',
  components: {
    BottomSheet,
    BottomSheetListItem,
    BottomSheetTopbarTrigger,
  },

  props: {
    workspacesList: {
      required: true,
      type: Array as PropType<WorkspaceItem[]>,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const passedValue = computed({
      get() {
        return props.value
      },
      set(value) {
        emit('input', value)
      },
    })
    const workspaceId = computed(() => store.state.workspace.workspace.id)

    const switchWorkspace = (location: WorkspaceItem) => {
      emit('switch-workspace', location)
      emit('cancel-click')
    }

    return {
      passedValue,
      workspaceId,
      switchWorkspace,
    }
  },
})
