import state from './workspace.state'
import mutations from './mutations/workspace.mutations'
import actions from './actions/workspace.actions'
import getters from './getters/workspace.getters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
