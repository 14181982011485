<template>
  <div>
    <div class="label">
      <span>
        {{ $t('filterbar.fields.parameter.label') }}
      </span>
    </div>
    <base-dropdown class="filter-parameter mb-2">
      <template v-slot:trigger>
        <base-input-field
          :value="parameterName"
          :placeholder="$t('filterbar.fields.parameter.label')"
          :name="$t('filterbar.fields.parameter.name')"
          type="text"
          icon-right="arrow"
          :size="$isSmallDevice ? 'is-large' : 'is-small'"
          readonly
        >
        </base-input-field>
      </template>
      <base-dropdown-text-item
        v-for="availableField in availableFields"
        :key="availableField.field"
        :value="availableField.field"
        @click="handleParameter(availableField.field)"
      >
        {{ availableField.label }}
      </base-dropdown-text-item>
    </base-dropdown>
  </div>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'

export default {
  name: 'BaseFilterParameter',
  components: {
    BaseDropdownTextItem,
    BaseInputField,
    BaseDropdown,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    availableFields: {
      type: Array,
      required: true,
    },
    parameterName: {
      type: String,
      default: 'Field',
    },
  },
  methods: {
    handleParameter(args) {
      this.$emit('input', args)
    },
  },
}
</script>

<style scoped>
.filter-parameter {
  @apply block;
}
.filter-parameter >>> .dropdown-menu {
  @apply w-full;
}

.filter-parameter >>> .control svg {
  @apply transform rotate-90;
}

.filter-parameter.is-active >>> .control svg {
  @apply transform -rotate-90;
}
</style>
