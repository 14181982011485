import { shortname } from '@/utils/store'

import * as actionNamesObj from './tag.actions.names'
import * as mutationNamesObj from '../mutations/tag.mutations.names'

import api from '../../network/tag.api'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.TAG_ACTION_LIST_TAGS]: async ({ commit, rootState }, page) => {
    const response = await api.listTags(rootState.workspace.workspace.id, page)

    commit(mutationNames.TAG_MUTATION_UPDATE_PAGE, page)
    commit(
      page === 1
        ? mutationNames.TAG_MUTATION_SET_TAGS
        : mutationNames.TAG_MUTATION_ADD_TAGS,
      response.tags
    )
    commit(mutationNames.TAG_MUTATION_SET_META, response.meta)
  },
  [actionNames.TAG_ACTION_LIST_TAGS_NEXT_PAGE]: ({ state, dispatch }) => {
    if (state.meta.total_pages !== state.currentPage) {
      dispatch(actionNames.TAG_ACTION_LIST_TAGS, state.currentPage + 1)
    }
  },
  [actionNames.TAG_ACTION_CREATE_TAG]: async ({ commit, rootState }, tag) => {
    const response = await api.createTag(rootState.workspace.workspace.id, tag)

    commit(mutationNames.TAG_MUTATION_ADD_TAG, response.tag)
  },
  [actionNames.TAG_ACTION_UPDATE_TAG]: async ({ commit, rootState }, tag) => {
    const response = await api.updateTag(rootState.workspace.workspace.id, tag)

    commit(mutationNames.TAG_MUTATION_UPDATE_TAG, response.tag)
  },
  [actionNames.TAG_ACTION_DELETE_TAG]: async ({ commit, rootState }, tagId) => {
    await api.deleteTag(rootState.workspace.workspace.id, tagId)

    commit(mutationNames.TAG_MUTATION_DELETE_TAG, tagId)
  },

  [actionNames.TAG_PUBLIC_ACTION_LIST_BY_ID]: async (
    { commit, state, rootState },
    ids = []
  ) => {
    const existingIds = [...state.list, ...state.loadingTagsIds]
    const newIds = ids.filter((id) => !existingIds.includes(id))

    if (newIds.length) {
      commit(mutationNames.TAG_MUTATION_ADD_LOADING_TAGS_IDS, newIds)

      const response = await api.listTagsByIds(
        rootState.workspace.workspace.id,
        newIds
      )
      commit(mutationNames.TAG_MUTATION_ADD_TAGS, response.data)
      commit(mutationNames.TAG_MUTATION_REMOVE_LOADING_TAGS_IDS, newIds)
    }
  },
}
