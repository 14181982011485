import DashboardTabsTopbar from '../components/DashboardTabsTopbar'

const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout'
  )

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { layout: DefaultLayout },
    components: {
      'topbar-left': DashboardTabsTopbar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../Dashboard.vue'),
    },
  },
]
