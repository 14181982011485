import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
  BASE_GETTER_DEVICE_SIZE,
  BASE_GETTER_IS_SMALL_DEVICE,
  BASE_GETTER_IS_MEDIUM_DEVICE,
  BASE_GETTER_IS_LARGE_DEVICE,
} from '@/store/getters/store.getters.names'

Vue.mixin({
  name: 'GlobalMixin',
  computed: mapGetters({
    $deviceSize: BASE_GETTER_DEVICE_SIZE,
    $isSmallDevice: BASE_GETTER_IS_SMALL_DEVICE,
    $isMediumDevice: BASE_GETTER_IS_MEDIUM_DEVICE,
    $isLargeDevice: BASE_GETTER_IS_LARGE_DEVICE,
  }),
})
