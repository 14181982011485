import User from '../../user/models/user.model'
import Area from './area.model'

export class GetAreaDetailsRequest {
  constructor() {}
}

export class GetAreaDetailsResponse {
  area: Area
  users: User[]

  constructor(response: any) {
    this.area = new Area(response.data.data)
    this.users = response.data.included
      .filter((entity: any) => entity.type === 'user')
      .map((user: any) => new User(user))
  }
}
