import store from '@/store'
import { USER_GETTER_FEATURE } from '@/app/core/user/store/getters/user.getters.names'

export default function (to, from, next) {
  const match = to.matched.find((r) => r.meta.feature)
  const featureName = match?.meta.feature

  if (!featureName) {
    next()
  } else {
    if (store.getters[USER_GETTER_FEATURE](featureName)) {
      next()
    } else {
      next('/404')
    }
  }
}
