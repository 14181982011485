import Vue from 'vue'
import * as mutationNamesObj from './tag.mutations.names'

import { normalizeCollection } from '@/utils/helpers'
import { shortname } from '@/utils/store'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.TAG_MUTATION_SET_TAGS]: (state, tags) => {
    const { list, data } = normalizeCollection(tags)

    state.list = list
    state.data = { ...state.data, ...data }
  },
  [mutationNames.TAG_MUTATION_ADD_TAGS]: (state, tags) => {
    const { list, data } = normalizeCollection(tags)

    for (const id of list) {
      // replace existing items, instead of duplicating
      if (state.data[id]) {
        state.data[id] = data[id]
      } else {
        Vue.set(state.data, id, data[id])
        state.list.push(id)
      }
    }
  },
  [mutationNames.TAG_MUTATION_UPDATE_PAGE]: (state, page) => {
    state.currentPage = page
  },
  [mutationNames.TAG_MUTATION_SET_META]: (state, meta) => {
    state.meta = { ...meta }
  },
  [mutationNames.TAG_MUTATION_ADD_TAG]: (state, tag) => {
    state.data = {
      ...state.data,
      [tag.id]: tag,
    }
    state.list = [...state.list, tag.id]
  },
  [mutationNames.TAG_MUTATION_UPDATE_TAG]: (state, tag) => {
    state.data = {
      ...state.data,
      [tag.id]: tag,
    }
  },
  [mutationNames.TAG_MUTATION_DELETE_TAG]: (state, removedTagId) => {
    state.list = state.list.filter((tagId) => tagId !== removedTagId)
    state.selected = Array.isArray(state.selected)
      ? state.selected.filter(({ id }) => id !== removedTagId)
      : []

    const { [removedTagId]: removed, ...data } = state.data
    state.data = data
  },
  [mutationNames.TAG_MUTATION_SET_SELECTED_TAGS]: (state, selected) => {
    state.selected = [...selected]
  },
  [mutationNames.TAG_MUTATION_ADD_TAGS_DATA]: (state, tags) => {
    const newData = tags.reduce((tagsData, tag) => {
      tagsData[tag.id] = tag
      return tagsData
    }, {})
    state.data = { ...state.data, ...newData }
  },

  [mutationNames.TAG_MUTATION_ADD_LOADING_TAGS_IDS]: (state, ids) => {
    state.loadingTagsIds.push(...ids)
  },

  [mutationNames.TAG_MUTATION_REMOVE_LOADING_TAGS_IDS]: (state, ids) => {
    state.loadingTagsIds = state.loadingTagsIds.filter(
      (id) => !ids.includes(id)
    )
  },
}
