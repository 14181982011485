<template>
  <base-dropdown
    :is-open.sync="isUserMenuOpen"
    position="is-bottom-left"
    aria-role="menu"
    :data-id="`${dataIdPrefix}.user_menu_dropdown.user_menu`"
  >
    <template v-slot:trigger>
      <base-avatar
        size="is-small"
        :img-url="userAvatarUrl"
        :username="user.attributes.username"
        :text="true"
        avatar-class="bg-green-100 text-green-300"
        :data-id="`${dataIdPrefix}.user_menu_dropdown.avatar`"
        img-class="w-8 h-8"
      />
    </template>
    <div>
      <div class="flex flex-col items-center py-2.5">
        <div class="mb-px text-lg font-semibold">
          {{ user.attributes.username }}
        </div>
        <div class="mt-px text-coal-450 font-medium mr-2">
          {{ $t('topbar.account.label_account_id') }}: {{ user.account.id }}
        </div>
        <div v-if="userRole" class="bg-coal-80 mt-2.5 rounded px-1 text-sm">
          {{ userRole.localized_name }}
        </div>
      </div>

      <hr class="dropdown-divider" />

      <template v-if="profileRoute">
        <router-link :to="profileRoute">
          <user-menu-dropdown-item
            icon="user/user-16"
            :label="$t('topbar.account.profile')"
          />
        </router-link>

        <hr class="dropdown-divider" />
      </template>

      <user-menu-dropdown-role-switch />

      <!-- <user-menu-dropdown-item
        icon="add-ons/add-ons-16"
        :label="$t('topbar.account.subscriptions_addons')"
      /> -->

      <!-- <user-menu-dropdown-item
        icon="user/user-16"
        :label="$t('topbar.account.profile')"
      /> -->

      <!-- <user-menu-dropdown-item
        icon="settings/settings-16"
        :label="$t('topbar.account.account_settings')"
      /> -->

      <!-- <hr class="dropdown-divider" /> -->

      <user-menu-dropdown-locale-switch
        @change-language-start="isUserMenuOpen = false"
      />

      <hr class="dropdown-divider" />

      <!-- <user-menu-dropdown-item
        icon="help-information/help-information-16"
        icon-type="is-filled"
        :label="$t('topbar.account.help_center')"
      >
        <template v-slot:right>
          <div class="text-coal-450">
            <base-icon
              icon="link-hyperlink/link-hyperlink-16"
              size="1rem"
              class="mb-px"
            />
          </div>
        </template>
      </user-menu-dropdown-item> -->

      <action-logout>
        <user-menu-dropdown-item
          data-id="topbar.account.log_out"
          icon="exit-log-out/exit-log-out-16"
          :label="$t('topbar.account.log_out')"
        />
      </action-logout>
    </div>
  </base-dropdown>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
// import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import ActionLogout from '@/app/util-modules/ui/action-logout/ActionLogout'
import BaseAvatar from '@/app/util-modules/ui/avatar/BaseAvatar'
import UserMenuDropdownItem from './UserMenuDropdownItem'

import { mapGetters } from 'vuex'
import {
  USER_GETTER_USER,
  USER_GETTER_HIGHEST_ROLE,
} from '../../store/getters/user.getters.names'
import UserMenuDropdownLocaleSwitch from './UserMenuDropdownLocaleSwitch'
import UserMenuDropdownRoleSwitch from './UserMenuDropdownRoleSwitch'
import { omitDefaultAvatarUrl } from '@/utils/helpers'

export default {
  name: 'UserMenuDropdown',
  components: {
    BaseDropdown,
    // BaseIcon,
    ActionLogout,
    BaseAvatar,
    UserMenuDropdownItem,
    UserMenuDropdownLocaleSwitch,
    UserMenuDropdownRoleSwitch,
  },
  props: {
    dataIdPrefix: {
      type: String,
      required: true,
    },
    profileRoute: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isUserMenuOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      user: USER_GETTER_USER,
      userRole: USER_GETTER_HIGHEST_ROLE,
    }),
    userAvatarUrl() {
      return omitDefaultAvatarUrl(this.user.attributes.avatar_thumb_url)
    },
  },
}
</script>
