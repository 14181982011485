import Area from './area.model'

interface Form {
  name: string
  color: string
  userIds: { id: string }[]
  areaIds: { id: string }[]
}
export class CreateAreaRequest {
  data: {
    type: string
    attributes: {
      name: string
      color: string
      user_ids: string[]
      area_ids: string[]
    }
  }

  constructor(form: Form) {
    this.data = {
      type: 'area',
      attributes: {
        name: form.name,
        color: form.color,
        user_ids: form.userIds.map((user) => user.id),
        area_ids: form.areaIds.map((user) => user.id),
      },
    }
  }
}

export class CreateAreaResponse {
  area: Area

  constructor(response: any) {
    this.area = new Area(response.data.data)
  }
}
