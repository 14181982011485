<template>
  <button
    class="flex user-menu-avatar items-center rounded-full"
    type="button"
    :disabled="disabled"
    v-on="$listeners"
  >
    <img
      v-if="imgUrl"
      :src="imgUrl"
      :alt="`${username} avatar photo`"
      :class="imgClass"
      class="rounded-full"
    />

    <div
      v-else
      :class="[
        'flex justify-center items-center rounded-full text-center font-bold',
        sizeClass,
        colorClass,
      ]"
    >
      {{ initials }}
    </div>
    <div
      v-if="text"
      class="md:hidden flex items-center text-white font-semibold ml-4 text-2xl"
    >
      <div class="mr-2">
        {{ $t('topbar.account.profile') }}
      </div>
      <base-icon
        class="mr-3"
        icon="arrow/arrow-12"
        size="0.875rem"
        :rotation="90"
      />
    </div>
  </button>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseAvatar',
  components: {
    BaseIcon,
  },
  props: {
    text: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'is-small',
    },
    invert: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      required: true,
    },
    avatarClass: {
      type: String,
      default: '',
    },
    imgClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imgUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    initials() {
      return this.username
        .split(' ')
        .map((x) => x.charAt(0))
        .join('')
        .substr(0, 2)
        .toUpperCase()
    },
    sizeClass() {
      switch (this.size) {
        case 'is-extra-large':
          return 'w-20 h-20 text-2xl'
        case 'is-large':
          return 'w-14 h-14 text-2xl'
        case 'is-medium':
          return 'w-10 h-10 text-2xl'
        case 'is-extra-small':
          return 'w-6 h-6 text-xs'
        case 'is-small':
        default:
          return 'w-8 h-8 p-1.5'
      }
    },
    colorClass() {
      if (this.avatarClass !== '') {
        return this.avatarClass
      }

      const mdScreenClass = 'md:text-white-400 md:bg-green-300'
      return this.invert
        ? `text-white-400 bg-green-300 ${mdScreenClass}`
        : `text-green-300 bg-white-400 ${mdScreenClass}`
    },
  },
}
</script>

<style scoped>
/*
TODO White Color with 15% opacity is not in our design system.
*/
.user-menu-avatar {
  background: rgba(255, 255, 255, 0.15);
}
</style>
