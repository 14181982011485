<template>
  <buefy-snackbar
    ref="buefy-snackbar"
    class="base-snackbar"
    :class="snackbarClass"
    :type="type"
    :position="snackbarPosition"
    :duration="duration"
    :indefinite="indefinite"
    :queue="queue"
    :action-text="null"
  >
    <base-snackbar-icon v-if="!$isSmallDevice" :type="type" />

    <div class="flex flex-col flex-1">
      <p
        ref="snackbar-message"
        class="text-lg font-semibold md:text-base"
        :class="messageClass"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-if="renderHtml" v-html="message" />
        <template v-else>
          {{ message }}
        </template>
      </p>

      <p
        v-if="hasInfoText"
        ref="snackbar-info"
        class="text-lg md:text-base font-medium text-coal-550"
        :class="{ 'mb-3': hasActionButton || hasCancelButton }"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-if="renderHtml" v-html="info" />
        <template v-else>
          {{ info }}
        </template>
      </p>

      <div class="flex">
        <base-button
          v-if="hasActionButton"
          class="mr-2"
          :size="actionButtonsSize"
          type="is-filled"
          @click="onActionClick()"
        >
          {{ actionText }}
        </base-button>

        <base-button
          v-if="hasCancelButton"
          :size="actionButtonsSize"
          type="is-filled-grey"
          @click="onCancelAction()"
        >
          {{ cancelText }}
        </base-button>
      </div>
    </div>

    <button class="ml-5" @click="onCloseAction()">
      <base-icon
        :size="!$isLargeDevice ? '1.5rem' : '1rem'"
        class="base-snackbar-close-icon"
        :icon="closeIcon"
      />
    </button>
  </buefy-snackbar>
</template>

<script>
import BuefySnackbar from 'buefy/src/components/snackbar/Snackbar'
import BaseIcon from '../icon/BaseIcon'
import BaseButton from '../button/BaseButton'
import BaseSnackbarIcon from './BaseSnackbarIcon'

export default {
  name: 'BaseSnackbar',
  components: { BuefySnackbar, BaseSnackbarIcon, BaseIcon, BaseButton },
  props: {
    type: {
      type: String,
      validator: (value) =>
        ['is-success', 'is-info', 'is-danger', 'is-warning'].indexOf(value) !==
        -1,
      default: 'is-success',
    },
    position: {
      type: String,
      default: 'is-top-right',
    },
    queue: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 3500,
    },
    indefinite: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
    actionText: {
      type: String,
      default: '',
    },
    onAction: {
      type: Function,
      default: null,
    },
    cancelText: {
      type: String,
      default: '',
    },
    cancelAction: {
      type: Function,
      default: null,
    },
    closeAction: {
      type: Function,
      default: null,
    },
    renderHtml: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textLines: 0,
    }
  },
  computed: {
    hasInfoText() {
      return this.info !== ''
    },
    hasActionButton() {
      return this.actionText !== '' && this.onAction
    },
    hasCancelButton() {
      return this.cancelText !== ''
    },
    actionButtonsSize() {
      return !this.$isSmallDevice ? 'is-small' : 'is-medium'
    },
    snackbarPosition() {
      return !this.$isSmallDevice ? this.position : 'is-bottom'
    },
    snackbarClass() {
      const classes = []

      if (
        this.$isSmallDevice ||
        this.hasActionButton ||
        this.hasCancelButton ||
        this.textLines >= 3
      ) {
        classes.push('additional-text')
      }

      return classes
    },
    messageClass() {
      return {
        'mb-1 md:mb-0.5': this.hasInfoText,
      }
    },
    closeIcon() {
      const iconSize = !this.$isSmallDevice ? '16' : '24'
      return `cross-close/cross-close-${iconSize}`
    },
  },
  mounted() {
    if (
      !this.$isSmallDevice &&
      !this.hasActionButton &&
      !this.hasCancelButton
    ) {
      this.calcTextLines()
    }
  },
  methods: {
    calcTextLines() {
      const interval = setInterval(() => {
        const snackbarMessageEl = this.$refs['snackbar-message']
        const snackbarInfoEl = this.$refs['snackbar-info']

        if (snackbarMessageEl) {
          const messageLines = this.getTextLines(snackbarMessageEl)
          if (!this.hasInfoText) {
            this.textLines = messageLines
            clearInterval(interval)
          } else if (snackbarInfoEl) {
            const infoLines = this.getTextLines(snackbarInfoEl)
            this.textLines = messageLines + infoLines
            clearInterval(interval)
          }
        }
      }, 50)
    },
    getTextLines(element) {
      const textBaseLineHeight = 20
      return element.offsetHeight / textBaseLineHeight
    },
    onActionClick() {
      this.onAction()
      this.closeSnackbar()
    },
    closeSnackbar() {
      this.$refs['buefy-snackbar'].close()
    },
    onCancelAction() {
      if (this.cancelAction) {
        this.cancelAction()
      }
      this.closeSnackbar()
    },
    onCloseAction() {
      if (this.closeAction) {
        this.closeAction()
      }
      this.closeSnackbar()
    },
  },
}
</script>

<style scoped>
.base-snackbar.snackbar {
  @apply p-3 md:p-4 rounded;

  a {
    @apply font-bold;
  }
}
.base-snackbar.additional-text {
  @apply items-start;
}
.base-snackbar-close-icon {
  @apply text-coal-450;
}
</style>
