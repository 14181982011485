<template>
  <buefy-toggle :size="size" v-bind="$attrs" outlined v-on="$listeners">
    <slot />
  </buefy-toggle>
</template>

<script>
import BuefyToggle from 'buefy/src/components/switch/Switch'

export default {
  name: 'BaseToggle',
  components: { 'buefy-toggle': BuefyToggle },
  props: {
    size: {
      type: String,
      validator: (value) => ['is-small', 'is-medium'].indexOf(value) !== -1,
      default: 'is-medium',
    },
  },
}
</script>
