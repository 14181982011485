import axios from '@/utils/vendors/axios'

import { SearchEmployeeAreaResponse } from '../models/employee-search-employee-area.model'
import {
  SearchEmployeesRequest,
  SearchEmployeesResponse,
} from '../models/employee-search-employees.model'
import { EMPLOYEE_ENDPOINT_SEARCH_EMPLOYEE_AREA } from './employee.endpoints'

export default {
  async searchEmployeesAndAreas(workspaceId: RecordId, query: string) {
    const response = await axios.get(
      EMPLOYEE_ENDPOINT_SEARCH_EMPLOYEE_AREA(workspaceId, query)
    )

    return new SearchEmployeeAreaResponse(response)
  },
  async searchEmployees(
    workspaceId: RecordId,
    {
      page,
      query,
      filter,
    }: { page: number; query: string; filter: Record<string, any> }
  ) {
    const response = await axios.request(
      new SearchEmployeesRequest({ workspaceId, page, query, filter })
    )

    return new SearchEmployeesResponse(response)
  },
}
