<template>
  <base-dropdown
    aria-role="menu"
    class="user-menu-dropdown-locale-switch"
    :triggers="['hover']"
  >
    <template v-slot:trigger>
      <base-dropdown-text-item
        aria-role="listitem"
        class="user-menu-dropdown-locale-switch-trigger min-w-max"
      >
        <user-menu-locale
          :language-name="currentLanguage.name()"
          :flag-code="currentLanguage.flagCode"
          :is-using-company-language="isUsingCompanyLanguage"
        />

        <template slot="right">
          <div class="flex items-center justify-content">
            <base-icon icon="arrow/arrow-16" size="1rem" />
          </div>
        </template>
      </base-dropdown-text-item>
    </template>

    <base-dropdown-text-item
      v-for="(language, index) in languageList"
      :key="`language-${index}`"
      :class="{
        'is-active': language.id === currentLanguage.id && index !== 0,
      }"
      aria-role="listitem"
      class="min-w-max"
      @click="loadAndSwitchLanguage(language)"
    >
      <template slot="left">
        <base-flag :country-code="language.flagCode" />
      </template>

      {{ language.name() }}
    </base-dropdown-text-item>
  </base-dropdown>
</template>

<script>
import UserMenuLocaleSwitchMixin from '@/app/core/user/components/user-menu/UserMenuLocaleSwitch.mixin'
import UserMenuLocale from '@/app/core/user/components/user-menu/UserMenuLocale'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'
import BaseFlag from '@/app/util-modules/ui/flag/BaseFlag'

export default {
  name: 'UserMenuDropdownLocaleSwitch',
  components: {
    BaseDropdown,
    BaseIcon,
    BaseDropdownTextItem,
    BaseFlag,
    UserMenuLocale,
  },
  mixins: [UserMenuLocaleSwitchMixin],
}
</script>

<style scoped>
.user-menu-dropdown-locale-switch >>> .dropdown-menu,
.user-menu-dropdown-locale-switch >>> .dropdown-content {
  @apply min-w-57.5;
}

.dropdown.user-menu-dropdown-locale-switch >>> .dropdown-menu {
  @apply -top-2 right-full mr-3;
}

.dropdown.user-menu-dropdown-locale-switch >>> .is-active {
  @apply bg-green-100 text-coal;
}

.user-menu-dropdown-locale-switch-trigger {
  @apply md:w-64 w-full;
}

.user-menu-dropdown-locale-switch-trigger::before {
  content: '';
  @apply absolute w-6 -top-3 -bottom-3 right-full;
}
</style>
