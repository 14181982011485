import * as mutationNamesObj from './notification.mutations.names'

import { normalizeCollection } from '@/utils/helpers'
import { shortname } from '@/utils/store'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.NOTIFICATION_MUTATION_SET_NOTIFICATIONS]: (
    state,
    notifications
  ) => {
    const { list, data } = normalizeCollection(notifications)

    state.list = list
    state.data = { ...state.data, ...data }
  },
  [mutationNames.NOTIFICATION_MUTATION_ADD_NOTIFICATIONS]: (
    state,
    notifications
  ) => {
    const { list, data } = normalizeCollection(notifications)

    state.list = [...state.list, ...list]
    state.data = { ...state.data, ...data }
  },
  [mutationNames.NOTIFICATION_MUTATION_UPDATE_PAGE]: (state, page) => {
    state.currentPage = page
  },
  [mutationNames.NOTIFICATION_MUTATION_DELETE_NOTIFICATION]: (
    state,
    removedNotificationId
  ) => {
    state.list = state.list.filter(
      (notificationId) => notificationId !== removedNotificationId
    )
    const { [removedNotificationId]: removed, ...data } = state.data

    state.data = data
  },
  [mutationNames.NOTIFICATION_MUTATION_SET_META]: (state, meta) => {
    state.meta = { ...meta }
  },
}
