<template>
  <div @click="logout()">
    <slot />
  </div>
</template>

<script>
import { AUTH_ACTION_LOGOUT } from '@/app/core/auth/store/actions/auth.actions.names'

export default {
  name: 'ActionLogout',
  methods: {
    logout() {
      this.$store.dispatch(AUTH_ACTION_LOGOUT)
      this.$router.push({
        name: 'auth.login',
      })
    },
  },
}
</script>
