import { shortname } from '@/utils/store'
import { flattenRecord, isValidId } from '@/utils/jsonapi/utils'
import { AREA_ID_NO_AREAS } from '../../models/area.model'
import * as getterNamesObj from './area.getters.names'
import { AreaState } from '../area.state'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.AREA_GETTER_AREAS_LIST]: (state: AreaState) => {
    return state.list.map((id) => state.data[id])
  },
  [getterNames.AREA_GETTER_AREA_DETAILS]:
    (state: AreaState) => (id: string) => {
      return state.data[id]
    },

  [getterNames.AREA_PUBLIC_GETTER_AREA_LIST]: (state: AreaState) => {
    return Object.keys(state.data).map((id) => flattenRecord(state.data[id]))
  },
  [getterNames.AREA_PUBLIC_GETTER_AREA_LIST_SELECTED]: (state: AreaState) => {
    return Array.isArray(state.selected)
      ? state.selected.filter(
          (area) => area.id === AREA_ID_NO_AREAS || isValidId(area.id)
        )
      : []
  },
  [getterNames.AREA_PUBLIC_GETTER_AREA_LIST_SELECTED_EXCLUDE_WITHOUT_AREAS]: (
    state: AreaState
  ) => {
    return Array.isArray(state.selected)
      ? state.selected.filter((area) => isValidId(area.id))
      : []
  },
}
