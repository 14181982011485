<template>
  <icon-loader :icons="iconsToLoad">
    <svg-icon
      v-if="loaded"
      slot-scope="{ icons, loaded }"
      class="base-icon align-middle flex-shrink-0 transform"
      :height="size || height"
      :width="size || width"
      :data="icons[displayedIconIndex]"
      :original="original || originalColor"
      :class="{
        [classRotation]: rotationProvided,
        original: originalColor,
        [iconType]: true,
      }"
    />
  </icon-loader>
</template>

<script>
import IconLoader from './IconLoader'

const ICON_INDEX = {
  NORMAL: 0,
  HOVER: 1,
}

export default {
  name: 'BaseIcon',
  components: { 'icon-loader': IconLoader },
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconHover: {
      type: String,
      default: undefined,
    },
    showIconHover: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '1em',
    },
    width: {
      type: String,
      default: '1em',
    },
    size: {
      type: String,
      default: undefined,
    },
    rotation: {
      type: Number,
      default: 0,
    },
    original: {
      type: Boolean,
      default: false,
    },
    originalColor: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      validator: (value) => ['is-filled', 'is-stroke'].indexOf(value) !== -1,
      default: 'is-stroke',
    },
    iconHoverType: {
      type: String,
      validator: (value) => ['is-filled', 'is-stroke'].indexOf(value) !== -1,
      default: 'is-stroke',
    },
  },
  computed: {
    hoverIconProvided() {
      return typeof this.iconHover !== 'undefined'
    },
    iconsToLoad() {
      const icons = [this.icon]
      if (this.hoverIconProvided) icons.push(this.iconHover)

      return icons
    },
    displayedIconIndex() {
      if (this.hoverIconProvided && this.showIconHover) return ICON_INDEX.HOVER
      return ICON_INDEX.NORMAL
    },
    rotationProvided() {
      return this.rotation !== 0
    },
    iconType() {
      return this.displayedIconIndex === ICON_INDEX.HOVER
        ? this.iconHoverType
        : this.type
    },
    classRotation() {
      return this.rotation < 0
        ? `-rotate-${Math.abs(this.rotation)}`
        : `rotate-${this.rotation}`
    },
  },
}
</script>

<style scoped lang="scss">
.base-icon:not(.original) {
  fill: none;
  stroke: currentColor;
  &.is-filled {
    fill: currentColor;
    stroke: none;
  }
}
</style>
