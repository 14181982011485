




























import { defineComponent, PropType } from '@vue/composition-api'
import { mapState } from 'vuex'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem.vue'
import DropdownTopbarTrigger from '@/app/util-modules/ui/dropdown-topbar/DropdownTopbarTrigger.vue'

interface WorkspaceItem {
  attributes: {
    locale: string
    name: string
    time_zone: string
    token: string
  }
  id: string
  relationships: Record<string, unknown>
  type: string
}

export default defineComponent({
  name: 'WorkspaceDropdown',
  components: {
    BaseDropdown,
    BaseDropdownTextItem,
    DropdownTopbarTrigger,
  },

  props: {
    workspacesList: {
      required: true,
      type: Array as PropType<WorkspaceItem[]>,
    },
    label: {
      type: String,
      required: true,
    },
  },

  computed: {
    truncatedLabelText(): string {
      return this.label.length > 20
        ? this.label.substring(0, 20) + '...'
        : this.label
    },
    ...mapState({
      workspaceId: (state: any) => state.workspace.workspace.id,
    }),
  },
})
