<template>
  <div>
    <list-header :title="title" />
    <div class="px-4">
      <slot />
    </div>
  </div>
</template>

<script>
import ListHeader from '@/app/util-modules/ui/list-header/ListHeader'

export default {
  name: 'BottomSheetListGroup',
  components: {
    ListHeader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
