<template>
  <base-button
    class="base-snackbar-icon"
    :class="iconAttrs.class"
    :icon-left="iconAttrs.name"
    :icon-type="iconAttrs.type"
    size="is-small"
    disabled
  />
</template>

<script>
import BaseButton from '../button/BaseButton'

export default {
  name: 'BaseSnackbarIcon',
  components: { BaseButton },
  props: {
    type: {
      type: String,
      validator: (value) =>
        ['is-success', 'is-info', 'is-danger', 'is-warning'].indexOf(value) !==
        -1,
      default: 'is-success',
    },
  },
  computed: {
    iconAttrs() {
      const defaultValue = {
        name: 'check-tick',
        class: 'is-success',
        type: 'is-stroke',
      }

      switch (this.type) {
        case 'is-info':
          return {
            name: 'eye',
            class: 'is-info',
            type: 'is-stroke',
          }
        case 'is-success':
          return defaultValue
        case 'is-danger':
          return {
            name: 'warning',
            class: 'is-danger',
            type: 'is-filled',
          }
        case 'is-warning':
          return {
            name: 'help-information',
            class: 'is-warning',
            type: 'is-filled',
          }
        default:
          return defaultValue
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.base-snackbar-icon.button {
  @apply hidden md:flex mr-3 cursor-default;

  &.is-success {
    @apply text-green-400 bg-green-100;
  }

  &.is-info {
    @apply text-blue-400 bg-blue-100;
  }

  &.is-danger {
    @apply text-error-400 bg-error-100;
  }

  &.is-warning {
    @apply text-yellow-400 bg-yellow-100;
  }
}
</style>
