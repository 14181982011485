import unescape from 'lodash/unescape'
import * as Sentry from '@sentry/vue'
import { NOTE_TYPES } from '@/constants'
import { supportedLanguages, findLocaleID } from '@/utils/vendors/i18n'
import { APP_LANG } from '@/constants'
/**
 * If `input` is function, it runs with `args`,
 * else, it returns `input` as-is
 */
export const runOrReturn = (input, ...args) =>
  typeof input === 'function' ? input(...args) : input

/**
 * Picks $attrs staring with given `scope`
 */
export const getScopedAttrs = (attrs, scope) => {
  const picked = {}

  for (const key of Object.keys(attrs)) {
    if (key.startsWith(scope)) {
      picked[key.replace(scope, '')] = attrs[key]
    }
  }
  return picked
}

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect()
  const documentHeight =
    window.innerHeight || document.documentElement.clientHeight
  const documentWidth =
    window.innerWidth || document.documentElement.clientWidth

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= documentHeight &&
    rect.right <= documentWidth
  )
}

export const isTextOutOfBoundaries = (text, container) => {
  if (!text) return false

  const containerWidth = container?.offsetWidth || text.offsetWidth
  const textWidth = text.scrollWidth
  return textWidth > containerWidth || text.scrollHeight > text.offsetHeight
}

// single/central place to control logging errors
export function logError(...args) {
  return process.env.VUE_APP_SENTRY_ENABLED === 'true'
    ? Sentry.captureException(...args)
    : console.error.apply(window.console, args) // eslint-disable-line no-console
}

export function isValidSearchQuery(query) {
  return typeof query === 'string' && query.length >= 2
}

export function isEndOfScroll(elem, tolerance = 0) {
  return (
    elem.clientHeight !== elem.scrollHeight &&
    elem.scrollTop + elem.clientHeight + tolerance >= elem.scrollHeight
  )
}

export function normalizeCollection(items) {
  return items.reduce(
    (normalized, item) => {
      normalized.list.push(item.id)
      normalized.data[item.id] = item
      return normalized
    },
    { list: [], data: {} }
  )
}

// compares 2 File objects
export function isSameFile(a, b) {
  return (
    a.name === b.name && a.size === b.size && a.lastModified === b.lastModified
  )
}

export function unescapeNoteContent(note) {
  if (!note) return ''

  // SystemNotes needs double unescape to work properly
  if (note.type === NOTE_TYPES.SYSTEM) {
    return unescape(unescape(note.content))
  }

  return unescape(note.content)
}

export function checkIfLanguageIsSupported(language) {
  if (!language) return false

  return supportedLanguages.findIndex((lang) => lang.id === language) !== -1
}

export function getAppLanguage(user, workspace, account, currentLocale) {
  if (checkIfLanguageIsSupported(findLocaleID(user.locale))) {
    return findLocaleID(user.locale)
  } else if (checkIfLanguageIsSupported(findLocaleID(currentLocale))) {
    return findLocaleID(currentLocale)
  } else if (checkIfLanguageIsSupported(findLocaleID(workspace.locale))) {
    return findLocaleID(workspace.locale)
  } else if (checkIfLanguageIsSupported(findLocaleID(account.locale))) {
    return findLocaleID(account.locale)
  } else return APP_LANG
}

export function isDefaultAvatarUrl(avatarUrl) {
  if (!avatarUrl) return false
  return avatarUrl.includes('dudegirl')
}

export function omitDefaultAvatarUrl(avatarUrl) {
  return isDefaultAvatarUrl(avatarUrl) ? '' : avatarUrl
}

export const generateDateRangeQueryParam = (dateRange) => {
  if (Array.isArray(dateRange)) {
    if (dateRange[0] && dateRange[1]) {
      const startValue = `gte:${dateRange[0].toISOString()}`

      const newEndDate = new Date(dateRange[1])

      newEndDate.setHours(23, 59, 59, 999)

      const endValue = `lte:${newEndDate.toISOString()}`

      return `?filter[starts_at][]=${startValue}&filter[starts_at][]=${endValue}`
    }

    return ''
  }

  return ''
}
