<template>
  <base-dropdown
    :is-open.sync="isOpen"
    :mobile-modal="false"
    position="is-bottom-left"
  >
    <template v-slot:trigger>
      <button type="button" class="text-coal-450">
        <base-icon icon="more/more-16" size="1.25rem" type="is-filled" />
      </button>
    </template>
    <button
      v-for="option in options"
      :key="option.id"
      type="button"
      class="flex w-32 items-center hover:bg-coal-40 px-2 p-1.5 rounded cursor-pointer"
      :class="option.classes"
      @click="handleClick(option)"
    >
      <base-icon v-if="option.icon" class="mr-2" :icon="option.icon" />
      {{ option.label }}
    </button>
  </base-dropdown>
</template>

<script>
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BottomSheetMoreOptions',
  components: {
    BaseDropdown,
    BaseIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    handleClick(option) {
      this.isOpen = false
      this.$emit('select', option)
    },
  },
}
</script>
