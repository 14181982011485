<template>
  <div
    class="base-banner border-l-8 border-blue-400 h-12 fixed bottom-0 z-8 bg-white"
  >
    <div
      :class="`h-full flex justify-between items-center px-4 py-2 md:px-6 ${dynamicTypeStyling}`"
    >
      <p class="line-clamp-2 text-blue-500 mr-6">
        {{ bodyMessage }}
      </p>

      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseBanner',
  props: {
    type: {
      type: String,
      // Supporting first existing type. Add more later
      validator: (value) => ['is-info'].indexOf(value) !== -1,
      default: 'is-info',
    },
    bodyMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    // Supporting first existing type. Add more later
    dynamicTypeStyling() {
      switch (this.type) {
        case 'is-info':
          return 'bg-info-100'

        default:
          return 'bg-info-100'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.base-banner {
  width: calc(100% - #{$app-sidebar-width});
  @media (max-width: theme('screens.md.min')) {
    @apply w-full;
  }
}
</style>
