function generateChangingNumber(date, prime) {
  const dateofMonth = date.getUTCDate()
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()

  return (year * month * dateofMonth) % prime
}

async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

async function generateChangingAPIKey(secret, date, prime) {
  let suffix = generateChangingNumber(date, prime).toString()

  return await sha256(secret + suffix)
}

async function generateAPIKey(date) {
  return await generateChangingAPIKey(
    process.env.VUE_APP_API_SECRET,
    date,
    parseInt(process.env.VUE_APP_API_PRIME_NUMBER, 10)
  )
}

export { generateAPIKey, generateChangingAPIKey }
