<template>
  <!-- Animation zoom-out using: https://github.com/buefy/buefy/blob/dev/src/scss/utils/_animations.scss -->
  <transition name="zoom-out" appear>
    <div ref="overlay" class="overlay-fullscreen">
      <slot />
    </div>
  </transition>
</template>

<script>
import { isEndOfScroll } from '@/utils/helpers'

export default {
  name: 'BaseOverlayFullscreen',
  mounted() {
    if (this.$listeners['scroll-end']) {
      // the event is attached to element that is within this component's
      // template, so no need to detach it on destroy
      this.getOverlayElem().addEventListener('scroll', this.notifyScrollEnd)
    }
  },
  methods: {
    getOverlayElem() {
      return this.$refs.overlay
    },
    notifyScrollEnd(e) {
      if (isEndOfScroll(e.target)) {
        this.$emit('scroll-end')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.overlay-fullscreen {
  @apply fixed inset-0 bg-white overflow-y-auto;

  z-index: $z-index-overlay;
}
</style>
