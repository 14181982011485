export default {
  list: [],
  data: {},
  selectedCount: 0,
  selected: [],
  currentPage: 0,
  meta: {
    total_count: 0,
    total_pages: 0,
    can_modify_tags: false,
    can_create_tags: false,
  },
  loadingTagsIds: [],
}
