<template>
  <div
    class="group md:w-80 flex md:my-2 md:last:mb-1 pt-3 pb-4 md:p-2 md:rounded-lg border-b md:border-0 last:border-0 border-coal-150 hover:bg-coal-40"
    :class="{
      'opacity-20 pointer-events-none': isDeleting || isDeletingAll,
    }"
  >
    <div class="flex w-full">
      <div
        class="mt-1 md:mt-px rounded w-10 md:w-8 h-10 md:h-8 flex items-center justify-center text-white"
        :class="notificationIcon.class || 'bg-green-350'"
      >
        <base-icon
          :icon="notificationIcon.name"
          :type="notificationIcon.type"
          :size="!$isSmallDevice ? '1rem' : '1.5rem'"
        />
      </div>

      <div class="ml-4 md:ml-3 flex-1">
        <div class="flex items-start justify-between">
          <div class="mr-2.5">
            <div
              class="font-medium md:font-semibold text-lg md:text-base text-coal"
            >
              {{ notification.title }}
            </div>
            <div class="mt-1 font-medium text-base text-coal">
              {{ notification.message }}
            </div>
          </div>

          <button
            class="md:invisible group-hover:visible"
            @click="
              $emit('delete', {
                notificationId: notification.id,
                type: notification.type,
              })
            "
          >
            <base-icon
              class="text-coal-300 md:text-coal-550"
              :icon="crossIcon.icon"
              :size="crossIcon.size"
            />
          </button>
        </div>

        <div class="flex items-center mt-3 md:mt-2">
          <div class="md:text-sm font-medium md:font-semibold">
            {{ notificationCategory }}
          </div>
          <template v-if="notification.username !== ''">
            <div
              class="md:w-1 md:h-1 w-1.5 h-1.5 bg-coal-300 mx-1.5 rounded-full"
            />
            <div class="md:text-sm font-medium md:font-semibold text-green-400">
              {{ notification.username }}
            </div>
          </template>
        </div>
        <div class="mt-0.5">
          <span class="md:text-sm font-medium md:font-semibold text-coal-550">
            {{ notificationTime }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import { formatDate, formatTime } from '@/utils/date-time.utils'

import {
  NOTIFICATION_ACTION_DELETE_NOTIFICATION,
  NOTIFICATION_ACTION_DELETE_ALL_NOTIFICATIONS,
} from '../../store/actions/notification.actions.names'

export default {
  name: 'NotificationListItem',
  components: {
    BaseIcon,
  },
  trackedActions: {
    deleteSingle: {
      action: NOTIFICATION_ACTION_DELETE_NOTIFICATION,
      trackByKey: 'notificationId',
    },
    deleteAll: NOTIFICATION_ACTION_DELETE_ALL_NOTIFICATIONS,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDeleting() {
      const instances = this.$actions.deleteSingle.instances

      if (instances) {
        const instance = instances[this.notification.id]

        if (instance) {
          return instance.isLoading
        }
      }

      return false
    },
    isDeletingAll() {
      return this.$actions.deleteAll.isLoading
    },
    notificationTime() {
      const date = this.notification.createdAt
      return `${formatDate(date)}, ${formatTime(date)}`
    },
    notificationIcon() {
      const size = !this.$isSmallDevice ? 16 : 24

      switch (this.notification.category) {
        case 'absence':
          return {
            name: `absences/absences-${size}`,
            type: 'is-stroke',
          }
        case 'time_tracking':
          return {
            name: `time-tracking/time-tracking-${size}`,
            type: !this.$isSmallDevice ? 'is-filled' : 'is-stroke',
          }
        default:
          return {
            name: `warning/warning-${size}`,
            type: 'is-filled',
            class: 'bg-yellow-350',
          }
      }
    },
    notificationCategory() {
      switch (this.notification.category) {
        case 'absence':
          return this.$t('topbar.notifications.category.absences')
        case 'time_tracking':
          return this.$t('topbar.notifications.category.time_tracking')
        default:
          return this.$t('topbar.notifications.category.default')
      }
    },
    crossIcon() {
      return !this.$isSmallDevice
        ? { icon: 'cross-close/cross-close-16', size: '1rem' }
        : { icon: 'cross-close/cross-close-24', size: '1.5rem' }
    },
  },
}
</script>
