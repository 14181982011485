<template>
  <component
    :is="componentForm"
    :is-loading="$actions.createTag.isLoading"
    :is-open="isOpen"
    @create-tag="createTag(formData)"
    @close="$emit('close')"
  >
    <tag-form v-model="formData" :vertical="$isSmallDevice" />
  </component>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { TAG_ACTION_CREATE_TAG } from '../../store/actions/tag.actions.names'
import TagBottomSheetFormCreate from '../tag-bottom-sheet-form-create/TagBottomSheetFormCreate'
import TagModalFormCreate from '../tag-modal-form-create/TagModalFormCreate'
import TagForm from '../tag-form/TagForm'

export default {
  name: 'TagFormCreate',
  components: {
    TagBottomSheetFormCreate,
    TagModalFormCreate,
    TagForm,
  },
  props: {
    modelTag: {
      type: Object,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  trackedActions: {
    createTag: TAG_ACTION_CREATE_TAG,
  },
  data() {
    return {
      formData: {
        name: this.modelTag?.attributes?.title || '',
        color: this.modelTag?.attributes?.color || '',
      },
    }
  },
  computed: {
    ...mapState({
      workspaceId: (state) => state.workspace.workspace.id,
    }),
    componentForm() {
      return this.$isSmallDevice
        ? 'tag-bottom-sheet-form-create'
        : 'tag-modal-form-create'
    },
  },
  methods: {
    ...mapActions({
      actionCreateTag: TAG_ACTION_CREATE_TAG,
    }),
    async createTag(formData) {
      await this.actionCreateTag(formData)
      this.$buefy.snackbar.open(this.$t('tag.form_create.success_message'))
      this.$emit('close')
    },
  },
}
</script>
