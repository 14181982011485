<template>
  <base-button
    class="base-filter-trigger"
    :class="{ dynamic: dynamicDisplayValue }"
    type="is-outlined-grey"
    size="is-small"
  >
    <div class="flex items-center">
      <div class="base-filter-trigger-text">
        {{ label }}
        <span v-if="displayValue" class="ml-1 text-coal-450">
          {{ displayValue }}
        </span>
      </div>
      <base-icon
        class="ml-2 text-coal-450"
        icon="cross-close/cross-close-16"
        @click.native.stop="$emit('remove')"
      />
    </div>
  </base-button>
</template>

<script>
import BaseButton from '../button/BaseButton'
import BaseIcon from '../icon/BaseIcon'

export default {
  name: 'BaseFilterTrigger',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    displayValue: {
      type: String,
      default: '',
    },
    dynamicDisplayValue: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.base-filter-trigger.dynamic .base-filter-trigger-text {
  @apply max-w-60 overflow-hidden;
}
.base-filter-trigger.dynamic .base-filter-trigger-text::before {
  @apply absolute inset-0 bg-gradient-to-l from-white;

  left: 180px;
  content: '';
}
.base-filter-trigger.dynamic:hover .base-filter-trigger-text::before {
  @apply from-gray-200;
}
</style>
