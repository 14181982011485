import Notification from './notification.model'
import { extractIncluded } from '@/utils/jsonapi/utils'

export class ListNotificationResponse {
  constructor(response) {
    this.notifications = response.data.data.map(
      (notification) => new Notification(notification)
    )
    this.included = extractIncluded(response.data.included)
    this.meta = response.data.meta
  }
}
