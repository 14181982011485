import { USER_GETTER_FEATURE_PERMISSIONS } from '@/app/core/user/store/getters/user.getters.names'

const IS_PRODUCTION = process.env.VUE_APP_MODE === 'production'

export const ALLOW_SEGMENT = (rootGetters) =>
  IS_PRODUCTION &&
  rootGetters[USER_GETTER_FEATURE_PERMISSIONS]('Analytic').allow_segment

export const loadSegmentScript = (params) => {
  const e = (window.analytics = window.analytics || [])
  const a = document.createElement('script')
  ;(a.type = 'text/javascript'),
    (a.async = !0),
    (a.src =
      'https://cdn.segment.com/analytics.js/v1/' +
      process.env.VUE_APP_SEGMENT_AUTH_TOKEN +
      '/analytics.min.js')
  const r = document.getElementsByTagName('script')[0]
  r.parentNode.insertBefore(a, r), (e._loadOptions = params)
}
