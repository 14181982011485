<template>
  <base-field
    :name="name"
    :label="label"
    :hint="hint"
    :validation-id="validationId"
    :validation-rules="validationRules"
    :validation-mode="validationMode"
    v-bind="fieldProps"
  >
    <template v-slot:label>
      <slot name="label" :label="label" />
    </template>
    <base-input
      v-model="modelValue"
      :type="type"
      :name="name"
      :size="size"
      :icon="icon"
      :icon-type="iconType"
      :icon-right="iconRight"
      :disabled="disabled"
      :placeholder="placeholder"
      v-bind="$attrs"
    />
  </base-field>
</template>

<script>
import BaseField from '../field/BaseField'
import BaseInput from './BaseInput'

export default {
  name: 'BaseInputField',
  components: {
    BaseField,
    BaseInput,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'is-medium',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconType: {
      type: String,
      default: 'is-stroke',
    },
    iconRight: {
      type: String,
      default: undefined,
    },
    validationId: {
      type: String,
      default: '',
    },
    validationRules: {
      type: [Object, String],
      default: '',
    },
    validationMode: {
      type: [Object, String],
      default: 'aggressive',
    },
    fieldProps: {
      type: Object,
      default: () => ({}),
    },
    hint: {
      type: String,
      default: '',
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(modelValue) {
        this.$emit('input', modelValue)
      },
    },
  },
}
</script>
