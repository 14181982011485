<template>
  <base-overlay-fullscreen @scroll-end="$emit('scroll-end')">
    <div class="flex h-full flex-col">
      <div class="pt-6 px-4 pb-6 border-b border-coal-100">
        <base-input
          ref="searchInput"
          v-model="query"
          name="query"
          type="search"
          :placeholder="placeholder"
        />
      </div>

      <div class="flex-1">
        <slot name="list">
          <treeselect
            v-model="passedValue"
            :items="relevantOptions"
            :field="field"
            classes-searchbar="mt-2 mb-2 px-3"
            size-searchbar="is-large"
            size-checkbox="is-medium"
            position-checkbox="is-right"
            classes-item="text-lg p-4"
            @change-selected-count="selectedCount = $event"
          >
            <template v-slot:divider>
              <div class="border-t border-coal-100" />
            </template>

            <template v-slot:item-divider>
              <div class="ml-4 border-t border-coal-100" />
            </template>

            <template v-slot:no-matching-items>
              <div class="text-center text-coal-450 p-3">
                {{ $t('overlay_search.overlay_no_matches') }}
              </div>
            </template>

            <template v-slot:no-items>
              <slot name="no-items" />
            </template>
          </treeselect>
        </slot>
      </div>

      <div
        class="border-t sticky bottom-0 bg-white border-coal-100 px-4 pt-4 pb-3"
      >
        <base-button
          type="is-filled"
          size="is-large"
          expanded
          @click="$emit('click-button-done')"
        >
          {{ $t('overlay_search.button_done') }}
        </base-button>
        <div class="mt-2 text-center font-medium text-coal-550">
          <slot name="info-selection" />
        </div>
      </div>
    </div>
  </base-overlay-fullscreen>
</template>

<script>
import BaseOverlayFullscreen from '@/app/util-modules/ui/overlay/BaseOverlayFullscreen'
import BaseInput from '@/app/util-modules/ui/input/BaseInput'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import Treeselect from '@/app/util-modules/ui/treeselect/Treeselect'

export default {
  name: 'BaseOverlaySearch',
  components: {
    BaseOverlayFullscreen,
    BaseInput,
    Treeselect,
    BaseButton,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    field: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    filterOptionsQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedCount: 0,
      query: '',
    }
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    filteredOptions() {
      return this.options.filter(
        (option) =>
          option[this.field]
            .toLowerCase()
            .indexOf(this.filterOptionsQuery.toLowerCase()) > -1
      )
    },
    relevantOptions() {
      return this.filteredOptions
    },
  },
  watch: {
    query(newQuery) {
      this.$emit('typing', newQuery)
    },
  },
  mounted() {
    const searchInputElem = this.$refs.searchInput.$el.querySelector('input')

    if (searchInputElem) {
      searchInputElem.focus()
    }
  },
}
</script>
