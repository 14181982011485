import store from '@/store'

export default async function (to, from, next) {
  if (to.name && to.name.startsWith('auth')) {
    next()
  } else {
    if (to.params.workspaceId === store.state.workspace.workspace.id) {
      next()
    } else {
      next({
        name: to.name,
        params: {
          workspaceId: store.state.workspace.workspace.id,
        },
        query: to.query,
      })
    }
  }
}
