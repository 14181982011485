import Area from './area.model'

export class DeleteAreaRequest {
  constructor() {}
}

export class DeleteAreaResponse {
  area: Area

  constructor(response: any) {
    this.area = new Area(response.data.data)
  }
}
