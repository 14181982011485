export class TimeTrackingSummaryResponse {
  constructor(response) {
    const { total_count, running, unconfirmed, confirmed } = response.data.meta
    this.summary = {
      total_count: total_count,
      total_running: running,
      total_unconfirmed: unconfirmed,
      total_confirmed: confirmed,
    }
  }
}
