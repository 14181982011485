class ForgotPasswordRequest {
  constructor(form) {
    this.data = {
      type: 'user',
      attributes: {
        email: form.email,
      },
    }
  }
}

class ForgotPasswordResponse {
  constructor(response) {
    this.data = response.data
  }
}

export { ForgotPasswordRequest, ForgotPasswordResponse }
