import { shortname } from '@/utils/store.js'
import * as mutationNamesObj from './auth.mutations.names'
import VueCookies from '@/utils/vendors/vue-cookies'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.AUTH_MUTATION_SET_AUTH_TOKEN]: (state, authToken) => {
    state.authToken = authToken
    VueCookies.set('api_jwt_token', authToken)
  },
  [mutationNames.AUTH_MUTATION_REMOVE_AUTH_TOKEN]: (state) => {
    state.authToken = null
    VueCookies.remove('api_jwt_token')

    if (process.env.VUE_APP_MODE === 'production')
      VueCookies.remove('shift_app_session')
    else VueCookies.remove('staging_shift_app_session')
  },
  [mutationNames.AUTH_MUTATION_COOKIE_SIGN_IN_STATUS]: (state, status) => {
    state.cookieSignInStatus = status
  },
}
