<template>
  <buefy-loading :style="style" :is-full-page="isFullPage" :active="active" />
</template>

<script>
import BuefyLoading from 'buefy/src/components/loading/Loading'

export default {
  name: 'BaseLoading',
  components: {
    BuefyLoading,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '2rem',
    },
    opacity: {
      type: Number,
      default: 0.5,
    },
  },
  computed: {
    style() {
      return {
        '--loading-icon-size': this.size,
        '--loading-overlay-opacity': this.opacity,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';

/* overriding ~buefy/src/scss/components/_loading.scss */
::v-deep.loading-overlay .loading-icon::after {
  top: calc(50% - var(--loading-icon-size) * 0.5);
  left: calc(50% - var(--loading-icon-size) * 0.5);
  width: var(--loading-icon-size);
  height: var(--loading-icon-size);
  border-bottom-color: $coal-300;
  border-left-color: $coal-300;
}

::v-deep.loading-overlay .loading-background {
  background: rgba(255, 255, 255, var(--loading-overlay-opacity));
}
</style>
