<template>
  <dropdown-topbar
    ref="dropdown"
    v-model="passedValue"
    data-id="tag-dropdown"
    v-bind="$attrs"
    icon="tag/tag-16"
    :label="$t('tag.dropdown.label')"
    :items="items"
    max-height="400"
    scrollable
    expanded
    :placeholder-search="$t('tag.dropdown.placeholder_search')"
    class="max-w-full"
    :selected-count.sync="passedSelectedCount"
    :is-open.sync="passedIsOpen"
    :text-button-new="$t('tag.dropdown.button_new_tag')"
    :searchable="searchable"
    :type-button-new="typeButtonNew"
    :is-loading="isLoading"
    :has-search-query="hasSearchQuery"
    :can-edit="permissions.update"
    :can-create="permissions.create"
    v-on="$listeners"
    @click-button-edit="$emit('edit', $event)"
    @click-button-new="$emit('create')"
    @scroll-end="$emit('scroll-end')"
    @typing="$emit('typing', $event)"
  />
</template>

<script>
import DropdownTopbar from '@/app/util-modules/ui/dropdown-topbar/DropdownTopbar'

export default {
  name: 'TagDropdown',
  components: {
    DropdownTopbar,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
    },
    typeButtonNew: {
      type: String,
      required: false,
      default: 'Add',
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    hasSearchQuery: {
      type: Boolean,
      required: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    passedSelectedCount: {
      get() {
        return this.selectedCount
      },
      set(selectedCount) {
        this.$emit('update:selectedCount', selectedCount)
      },
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
}
</script>
