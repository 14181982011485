<template>
  <div>
    <div class="mb-6">
      <div :class="!vertical ? 'flex' : 'block'">
        <div :class="{ 'w-9/12': !vertical }">
          <base-input-field
            v-model="form.name"
            validation-rules="required"
            :label="$t('area.form.field_label_name')"
            name="area_name"
            type="text"
            :placeholder="$t('area.form.field_placeholder_name')"
          />
        </div>
        <div :class="!vertical ? 'ml-3 w-3/12' : 'mt-3'">
          <base-dropdown-color
            v-model="form.color"
            name="area_color"
            :position="$isSmallDevice ? 'is-top-left' : 'is-bottom-right'"
            :append-to-body="!$isSmallDevice"
            :label="$t('area.form.field_label_color')"
          />
        </div>
      </div>
    </div>
    <div>
      <employee-area-group-search v-model="relatedEmployeesAreas" />
      <p class="text-coal-550 font-medium text-sm">
        {{ $t('area.form.field_hint_employees') }}
      </p>
    </div>
  </div>
</template>

<script>
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField'
import BaseDropdownColor from '@/app/util-modules/ui/dropdown-color/BaseDropdownColor'
import EmployeeAreaGroupSearch from '@/app/core/employee/components/employee-area-group-search/EmployeeAreaGroupSearch'

export default {
  name: 'AreaForm',
  components: {
    BaseInputField,
    BaseDropdownColor,
    EmployeeAreaGroupSearch,
  },
  props: {
    area: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        name: '',
        color: '',
        userIds: [],
        areaIds: [],
      },
      relatedEmployeesAreas: [],
    }
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      immediate: true,
    },
    relatedEmployeesAreas(options) {
      this.form.userIds = []
      this.form.areaIds = []

      options.forEach((option) => {
        if (option.type === 'user') {
          // TODO: base-input-tag is converting id into number, workaround here
          // is to convert it back, but we should probably fix it in base-input-tag
          if (typeof option.id === 'number') {
            option.id = option.id + ''
          }
          this.form.userIds.push(option)
        } else if (option.type === 'area') {
          this.form.areaIds.push(option)
        }
      })
    },
  },
  created() {
    if (this.area) {
      Object.assign(this.form, this.area.attributes)

      this.form.userIds = this.users.map((user) => ({
        title: user.attributes.username,
        ...user,
      }))
      this.relatedEmployeesAreas = this.form.userIds
    }
  },
}
</script>
