<template>
  <div :class="`flex items-center p-2 ${classes}`">
    <base-icon :icon="icon" :size="sizeIcon" class="text-coal-450 mr-1" />
    <input
      v-model="passedValue"
      :class="`border-0 ml-2 outline-none w-full block ${sizeText}`"
      :placeholder="placeholderSearch"
    />
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'TreeselectSearchbox',
  components: {
    BaseIcon,
  },
  props: {
    placeholderSearch: {
      type: String,
      required: false,
      default: 'Search',
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: 'is-medium',
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    icon() {
      switch (this.size) {
        case 'is-large':
          return 'search/search-16'
        case 'is-medium':
        default:
          return 'search/search-16'
      }
    },
    sizeIcon() {
      switch (this.size) {
        case 'is-large':
          return '1.25rem'
        case 'is-medium':
        default:
          return '1rem'
      }
    },
    sizeText() {
      switch (this.size) {
        case 'is-large':
          return 'text-lg'
        case 'is-medium':
        default:
          return 'text-base'
      }
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
  },
}
</script>
