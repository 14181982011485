import { USER_SUGGESTED_FOLLOWERS_TYPE } from '@/app/core/user/models/user-suggested-followers.model'
export function makeEmptyState() {
  return {
    records: {
      preference: [],
      [USER_SUGGESTED_FOLLOWERS_TYPE]: [],
    },
    user: {
      id: '',
      attributes: {},
      account: {},
      relationships: {},
    },
    userFeatures: {},
    roles: [],

    list: [],
    data: {},

    workspaces: [],

    suggestedFollowersMeta: {
      current_page: 0,
      page_items: 20,
      total_pages: 0,
      total_count: 0,
    },

    previewBanner: {
      visible: null,
    },

    userPersistedLocalStorageKeys: [],
  }
}

export default makeEmptyState()
