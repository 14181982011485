<template>
  <component
    :is="componentForm"
    v-if="modelArea.area.id"
    :is-open.sync="passedIsOpen"
    :preload-action-status="$actions.getArea"
    :status="status"
    :more-options="moreOptions"
    :model-area="modelArea.area"
    :users="modelArea.users"
    @update-area="updateArea($event)"
    @handle-selection="handleSelection($event)"
    @close="$emit('close')"
  />
</template>

<script>
import { mapActions } from 'vuex'
import {
  AREA_ACTION_GET_AREA_DETAILS,
  AREA_ACTION_UPDATE_AREA,
  AREA_ACTION_DELETE_AREA,
} from '../../store/actions/area.actions.names'
import AreaModalFormEdit from '../area-modal-form-edit/AreaModalFormEdit'
import AreaBottomSheetFormEdit from '../area-bottom-sheet-form-edit/AreaBottomSheetFormEdit'

export default {
  name: 'AreaFormEdit',
  components: {
    AreaModalFormEdit,
    AreaBottomSheetFormEdit,
  },
  props: {
    areaId: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  trackedActions: {
    getArea: AREA_ACTION_GET_AREA_DETAILS,
    editArea: AREA_ACTION_UPDATE_AREA,
    deleteArea: AREA_ACTION_DELETE_AREA,
  },
  data() {
    return {
      modelArea: {
        area: {},
        users: [],
      },
      isActionDelete: false,
    }
  },
  computed: {
    componentForm() {
      return !this.$isSmallDevice ? AreaModalFormEdit : AreaBottomSheetFormEdit
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
    status() {
      return this.isActionDelete
        ? this.$actions.deleteArea
        : this.$actions.editArea
    },
    moreOptions() {
      const options = []

      if (this.modelArea.area.attributes?.permissions.create) {
        options.push({
          id: 'duplicate',
          label: this.$t('area.form_edit.option_label_duplicate'),
          icon: 'list/list-16',
        })
      }

      if (this.modelArea.area.attributes?.permissions.destroy) {
        options.push({
          id: 'delete',
          label: this.$t('area.form_edit.option_label_delete'),
          icon: 'delete/delete-16',
          classes: 'text-error-400',
        })
      }
      return options
    },
  },
  async created() {
    this.modelArea = await this.getArea(this.areaId)
  },
  methods: {
    ...mapActions({
      getArea: AREA_ACTION_GET_AREA_DETAILS,
      actionUpdateArea: AREA_ACTION_UPDATE_AREA,
      actionDeleteArea: AREA_ACTION_DELETE_AREA,
    }),
    async updateArea(formData) {
      const updatePayload = {
        ...formData,
        id: this.modelArea.area.id,
      }
      await this.actionUpdateArea(updatePayload)
      this.$buefy.snackbar.open(this.$t('area.form_edit.success_message'))
      this.$emit('updated', updatePayload)
      this.$emit('close')
    },
    async handleSelection(option) {
      if (option.id === 'delete') {
        if (confirm(this.$t('area.form_edit.text_delete_confirmation'))) {
          this.isActionDelete = true
          await this.actionDeleteArea(this.modelArea.area.id)
          this.$buefy.snackbar.open(
            this.$t('area.form_edit.delete_success_message')
          )
          this.$emit('close')
        }
      } else if (option.id === 'duplicate') {
        if (confirm(this.$t('area.form_edit.text_duplicate_confirmation'))) {
          this.$buefy.snackbar.open(
            this.$t('area.form_edit.duplicate_success_message')
          )
          this.$emit('duplicate', this.modelArea)
        }
      }
    },
  },
}
</script>
