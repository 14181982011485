import axios from '@/utils/vendors/axios'

import {
  TAG_ENDPOINT_CREATE_TAG,
  TAG_ENDPOINT_UPDATE_TAG,
  TAG_ENDPOINT_DELETE_TAG,
  TAG_ENDPOINT_LIST_TAGS,
  TAG_ENDPOINT_LIST_TAGS_BY_IDS,
  TAG_ENDPOINT_SEARCH_TAGS,
} from './tag.endpoints'
import { CreateTagRequest, CreateTagResponse } from '../models/create-tag.model'
import { UpdateTagRequest, UpdateTagResponse } from '../models/update-tag.model'
import { DeleteTagRequest, DeleteTagResponse } from '../models/delete-tag.model'
import { ListTagResponse } from '../models/list-tag.model'
import { SearchTagsResponse } from '../models/search-tag.model'

export default {
  async listTags(workspaceId, page) {
    const response = await axios.get(TAG_ENDPOINT_LIST_TAGS(workspaceId, page))

    return new ListTagResponse(response)
  },

  async listTagsByIds(workspaceId, ids) {
    const response = await axios.get(
      TAG_ENDPOINT_LIST_TAGS_BY_IDS(workspaceId, ids)
    )
    return response.data
  },
  /**
   * @param {Object} form
   * @param {string} form.name - The name of the tag.
   * @param {string} form.color - The color of the tag.
   * @returns {Promise<CreateTagResponse>} response
   */
  async createTag(workspaceId, form) {
    const response = await axios.post(
      TAG_ENDPOINT_CREATE_TAG(workspaceId),
      new CreateTagRequest(form)
    )

    return new CreateTagResponse(response)
  },
  /**
   * @param {Object} form
   * @param {string} form.id - The id of the tag.
   * @param {string} form.name - The name of the tag.
   * @param {string} form.color - The color of the tag.
   * @returns {Promise<UpdateTagResponse>} response
   */
  async updateTag(workspaceId, form) {
    const response = await axios.patch(
      TAG_ENDPOINT_UPDATE_TAG(workspaceId, form.id),
      new UpdateTagRequest(form)
    )

    return new UpdateTagResponse(response)
  },

  /**
   * @param {String} tagId
   * @returns {Promise<DeleteTagResponse>} response
   */
  async deleteTag(workspaceId, tagId) {
    const response = await axios.delete(
      TAG_ENDPOINT_DELETE_TAG(workspaceId, tagId),
      new DeleteTagRequest()
    )

    return new DeleteTagResponse(response)
  },

  async searchTags(workspaceId, { page, query }) {
    const response = await axios.get(
      query
        ? TAG_ENDPOINT_SEARCH_TAGS(workspaceId, page, query)
        : TAG_ENDPOINT_LIST_TAGS(workspaceId, page)
    )

    return new SearchTagsResponse(response)
  },
}
