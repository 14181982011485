import { ref } from '@vue/composition-api'

export default function useTableScroll(
  $refs: any,
  ROW_HEIGHT: number,
  ROW_NUMB: number,
  callback: () => void,
  hasUpdatePageMode?: boolean
) {
  const prevScrollOffset = ref(0)

  const handleScroll = () => {
    const currentOffset = $refs.baseTable.getCurrentScrollOffset()
    const rowsOffsetSum = ROW_HEIGHT * ROW_NUMB

    if (currentOffset - prevScrollOffset.value > rowsOffsetSum) {
      prevScrollOffset.value = currentOffset
      callback()
      if (hasUpdatePageMode) $refs.baseTable.updatePageModeFront()
    }
  }

  return { handleScroll, prevScrollOffset }
}
