<template>
  <div class="base-filter-bar flex">
    <component
      :is="containerComponent"
      :spacing="4"
      class="flex md:flex-wrap md:gap-2"
    >
      <base-button
        v-if="$isSmallDevice && availableFilters.length"
        type="is-outlined-grey"
        size="is-small"
        icon-right="plus"
        data-id="filterbar_filter_plus_button"
        @click="addFirstAvailableFilter()"
      />
      <component
        :is="filterComponent(filter.type)"
        v-for="filter in currentSelectedFilters"
        :key="filter.field"
        :field="filter.field"
        :label="runOrReturn(filter.label)"
        :available-fields="availableFields"
        :params="filter.params"
        :default-value="selectedFiltersValues[filter.field]"
        @apply="applyFilter($event)"
        @remove="removeFilter($event)"
        @replace="replaceFilter($event)"
        @close="closeFilter()"
      />
      <base-dropdown
        v-if="!$isSmallDevice && availableFilters.length"
        class="base-filter-bar-dropdown"
        :is-open.sync="isOpen"
      >
        <template v-slot:trigger>
          <base-button
            type="is-outlined-grey"
            size="is-small"
            data-id="filterbar_filter_button"
            :icon-right="triggerIcon"
          >
            <span v-if="noSelectedFilters">{{ label }}</span>
          </base-button>
        </template>
        <div class="py-1">
          <base-dropdown-text-item
            v-for="filter in availableFilters"
            :key="filter.field"
            @click="addFilter(filter)"
          >
            {{ runOrReturn(filter.label) }}
          </base-dropdown-text-item>
        </div>
      </base-dropdown>
    </component>
  </div>
</template>

<script>
import BaseDropdown from '../dropdown/BaseDropdown'
import BaseDropdownTextItem from '../dropdown/BaseDropdownTextItem'
import BaseButton from '../button/BaseButton'

import MobileActionBar from '@/app/util-modules/ui/mobile-action-bar/MobileActionBar'
import BaseFilterString from './BaseFilterString'
import BaseFilterDuration from './BaseFilterDuration'
import BaseFilterSelect from './BaseFilterSelect'
import BaseFilterRadio from './BaseFilterRadio'
import BaseFilterTime from './BaseFilterTime'

import { runOrReturn } from '@/utils/helpers'

export default {
  name: 'BaseFilterbar',
  components: {
    BaseDropdown,
    BaseDropdownTextItem,
    BaseButton,
    BaseFilterString,
    BaseFilterDuration,
    BaseFilterSelect,
    BaseFilterRadio,
    BaseFilterTime,
    MobileActionBar,
  },
  props: {
    label: {
      type: String,
      default: 'Add Filter',
    },
    filters: {
      type: Array,
      required: true,
    },
    selectedFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedFiltersValues: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
      replacedFilter: null,
      currentSelectedFilters: [],
    }
  },
  computed: {
    filterWithDefaults() {
      return this.filters.map((filter) =>
        filter.type === 'time' ? { ...filter, field: 'time' } : filter
      )
    },
    availableFilters() {
      return this.filterWithDefaults.filter(
        (filter) =>
          this.currentSelectedFiltersFields.indexOf(filter.field) === -1
      )
    },
    availableFields() {
      return this.availableFilters.map((filter) => ({
        field: filter.field,
        label: this.runOrReturn(filter.label),
      }))
    },
    currentSelectedFiltersFields() {
      return this.currentSelectedFilters.map((filter) => filter.field)
    },
    selectedFilterCount() {
      return this.currentSelectedFilters.length
    },
    noSelectedFilters() {
      return this.selectedFilterCount === 0
    },
    triggerIcon() {
      return !this.noSelectedFilters ? 'plus' : undefined
    },
    containerComponent() {
      return this.$isSmallDevice ? MobileActionBar : 'div'
    },
  },
  mounted() {
    this.updateCurrentSelectedFilters()
  },
  methods: {
    runOrReturn,
    addFilter(filter) {
      this.currentSelectedFilters = [
        ...this.currentSelectedFilters,
        { ...filter },
      ]
    },
    addFirstAvailableFilter() {
      this.addFilter(this.availableFilters[0])
    },
    filterComponent(type) {
      switch (type) {
        case 'time':
          return BaseFilterTime
        case 'duration':
          return BaseFilterDuration
        case 'select':
          return BaseFilterSelect
        case 'radio':
          return BaseFilterRadio
        case 'string':
        default:
          return BaseFilterString
      }
    },
    removeFilter(removedFilter) {
      this.currentSelectedFilters = this.currentSelectedFilters.filter(
        (filter) => filter.field !== removedFilter.field
      )

      if (!removedFilter.new) {
        this.$emit('remove', removedFilter.field)
      }
    },
    replaceFilter(fields) {
      const newFilter = this.filterWithDefaults.find(
        (filter) => filter.field === fields.new
      )
      this.currentSelectedFilters = this.currentSelectedFilters.map((filter) =>
        filter.field === fields.old ? { ...newFilter } : filter
      )
    },
    applyFilter(filter) {
      if (this.replacedFilter) {
        this.$emit('remove', this.replacedFilter)
        this.replacedFilter = null
      }
      this.$emit('apply', filter)
    },
    closeFilter() {
      if (this.replacedFilter) {
        this.updateCurrentSelectedFilters()
        this.replacedFilter = null
      }
      this.$emit('close')
    },
    updateCurrentSelectedFilters() {
      this.currentSelectedFilters = this.filterWithDefaults.filter(
        (filter) => this.selectedFilters.indexOf(filter.field) > -1
      )
    },
  },
}
</script>

<style scoped>
.base-filter-bar-dropdown >>> .dropdown-menu {
  width: 160px;
}
</style>
