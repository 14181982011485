// synchronously imports all core route files
const requireRouteFile = require.context('../app/core/', true, /\.routes\.js$/)

// helper to apply `callback` on each route in `routes` array,
// recursively going through route children
function eachRoute(routes, callback) {
  routes.forEach((route) => {
    callback(route)

    if (route.children) {
      eachRoute(route.children, callback)
    }
  })
}

let allRoutes = []

// populate `allRoutes` with routes from imported core modules' route-files
requireRouteFile.keys().forEach((filePath) => {
  const moduleRouteFile = requireRouteFile(filePath)
  const fileRoutes = moduleRouteFile.default || moduleRouteFile
  const moduleName = filePath.match(/\/([^/]+)/)[1]

  // add `module` meta key with module name as a value
  eachRoute(fileRoutes, (route) => {
    route.meta = route.meta || {}
    route.meta.module = moduleName
  })

  if (filePath.includes('not-found')) allRoutes = [...allRoutes, ...fileRoutes]
  else allRoutes = [...fileRoutes, ...allRoutes]
})

export default allRoutes
