import { runOrReturn } from '@/utils/helpers'
import BaseTableHeaderCell from './BaseTableHeaderCell.vue'

export default {
  name: 'BaseTableHeader',
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
  },
  render(h) {
    const headerCells = this.columns.map((col) => {
      const data = {
        on: col.header?.events,
        props: {},
      }

      // pre-process props
      for (const [key, value] of Object.entries(col.header.props || {})) {
        data.props[key] = runOrReturn(value)
      }

      if (col.width) {
        data.style = {
          width: runOrReturn(col.width),
        }
      }

      if (col.header?.['class']) {
        data.class = runOrReturn(col.header.class)
      }

      return h(col.header?.element || BaseTableHeaderCell, data)
    })

    return h(
      'div',
      {
        class: `
          flex items-center py-2.5 px-4 md:px-2
          border-b border-t border-coal-150
          bg-coal-80
        `,
      },
      headerCells
    )
  },
}
