<template>
  <div
    class="flex items-center w-full"
    :class="{
      'justify-between': !hideButtonCancel,
      'justify-end': hideButtonCancel,
    }"
  >
    <button
      v-if="!hideButtonCancel"
      type="button"
      class="py-3 rounded text-coal-550 hover:text-coal-750 cursor-pointer"
      :disabled="disabled"
      @click="$emit('close')"
    >
      {{ $t('dialog.dialog_footer.cancel') }}
    </button>
    <div class="flex items-center justify-center">
      <base-more-options
        v-if="moreOptions"
        position="is-top-left"
        :options="moreOptions"
        @selected="$emit('selected', $event)"
      />
      <slot name="footer-actions" />
      <base-button
        class="ml-2"
        :loading="loading"
        :disabled="loading"
        :type="buttonType"
        native-type="button"
        @click="$emit('done')"
      >
        {{ buttonSubmitText }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseMoreOptions from '@/app/util-modules/ui/more-options/BaseMoreOptions'

export default {
  name: 'BaseDialogFooter',
  components: {
    BaseButton,
    BaseMoreOptions,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    buttonSubmitText: {
      type: String,
      required: true,
    },
    moreOptions: {
      type: Array,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideButtonCancel: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'is-filled',
    },
  },
}
</script>
