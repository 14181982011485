import Tag from './tag.model'

export class DeleteTagRequest {
  constructor() {}
}

export class DeleteTagResponse {
  constructor(response) {
    this.tag = new Tag(response.data.data)
  }
}
