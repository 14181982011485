import { mapState, mapActions } from 'vuex'
import i18n, {
  loadAndSwitchLanguage,
  supportedLanguages,
} from '@/utils/vendors/i18n'
import { USER_ACTION_UPDATE_USER_LOCALE } from '../../store/actions/user.actions.names'

export default {
  name: 'LocaleSwitch',
  data() {
    return {
      supportedLanguages,
    }
  },
  computed: {
    ...mapState({
      workspace: (state) => state.workspace.workspace,
    }),
    languageList() {
      const list = [...supportedLanguages]

      if (this.companyLanguage) {
        list.unshift({
          id: this.companyLanguage.id,
          name: () =>
            `${this.$t(
              'locale_switch.company'
            )} (${this.companyLanguage.name()})`,
          flagCode: this.companyLanguage.flagCode,
        })
      }
      return list
    },
    currentLanguage() {
      return this.supportedLanguages.find(
        (language) => language.id === i18n.locale
      )
    },
    companyLanguage() {
      return this.supportedLanguages.find(
        (language) => language.id === this.workspace.attributes.locale
      )
    },
    isUsingCompanyLanguage() {
      return this.currentLanguage.id === this.companyLanguage?.id
    },
  },
  methods: {
    ...mapActions({
      updateUserLocale: USER_ACTION_UPDATE_USER_LOCALE,
    }),
    async loadAndSwitchLanguage(language) {
      this.$emit('change-language-start')
      await loadAndSwitchLanguage(language.id)

      // it isn't necessary to block execution for this
      this.updateUserLocale(language)

      this.$buefy.snackbar.open(
        this.$t('locale_switch.success_message', {
          languageName: language.name(),
        })
      )
      this.$emit('change-language-complete')
    },
  },
}
