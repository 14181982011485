<template>
  <component
    :is="componentForm"
    :status="requestStatus"
    :is-open.sync="passedIsOpen"
    @create-area="createArea()"
    @close="$emit('close')"
  >
    <area-form
      :key="componentKey"
      v-model="formData"
      :area="modelArea.area"
      :loading="requestStatus.isLoading"
      :users="modelArea.users"
      :vertical="$isSmallDevice"
    />
  </component>
</template>

<script>
import { mapActions } from 'vuex'
import { AREA_ACTION_CREATE_AREA } from '../../store/actions/area.actions.names'
import AreaBottomSheetFormCreate from '../area-bottom-sheet-form-create/AreaBottomSheetFormCreate'
import AreaModalFormCreate from '../area-modal-form-create/AreaModalFormCreate'
import AreaForm from '../area-form/AreaForm'

export default {
  name: 'AreaFormCreate',
  components: {
    AreaBottomSheetFormCreate,
    AreaModalFormCreate,
    AreaForm,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    modelArea: {
      type: Object,
      default: () => ({
        area: null,
        users: [],
      }),
    },
  },
  trackedActions: {
    createArea: AREA_ACTION_CREATE_AREA,
  },
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    componentForm() {
      return this.$isSmallDevice
        ? AreaBottomSheetFormCreate
        : AreaModalFormCreate
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
    componentKey() {
      return this.modelArea?.id || ''
    },
    requestStatus() {
      return this.$actions.createArea
    },
  },
  methods: {
    ...mapActions({
      actionCreateArea: AREA_ACTION_CREATE_AREA,
    }),
    async createArea() {
      await this.actionCreateArea(this.formData)
      this.$buefy.snackbar.open(this.$t('area.form_create.success_message'))
      this.$emit('close')
    },
  },
}
</script>
