import {
  format,
  formatISO,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfYear,
  subDays,
  subMonths,
  addMonths,
  isDate,
  isValid,
  isYesterday,
  isToday,
  addDays,
  addYears,
  startOfISOWeek,
  endOfISOWeek,
  formatDistanceStrict,
  parse,
} from 'date-fns'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import VueI18n from '@/utils/vendors/i18n'
import { TIME_FORMAT_TYPE } from '@/constants'
import { supportedLanguages } from '@/utils/vendors/i18n'

export const DATE_FORMAT = 'EEEEEE, dd.MM.yy'

export const getDateTimeLocaleModule = (locale = VueI18n.locale) => {
  const supportedLang = supportedLanguages.find((lang) => lang.id === locale)
  return supportedLang?.locale || ''
}

export const isValidDate = function (val) {
  return isDate(val) && isValid(val)
}

export const formatDate = (
  dateTime = new Date(),
  dateFormat = DATE_FORMAT,
  skipLocaleMessage = false
) => {
  if (typeof dateFormat !== 'string') {
    dateFormat = DATE_FORMAT
  }

  if (isToday(dateTime) && !skipLocaleMessage) {
    return VueI18n.tc('date.today')
  }

  if (isYesterday(dateTime) && !skipLocaleMessage) {
    return VueI18n.tc('date.yesterday')
  }

  return format(dateTime, dateFormat, {
    locale: getDateTimeLocaleModule(),
  })
}

export const formatTime = (dateTime) => {
  return format(dateTime, 'HH:mm', {
    locale: getDateTimeLocaleModule(),
  })
}

export const formatTimeMeridiem = (dateTime) => {
  return format(dateTime, 'HH:mmaaa', {
    locale: getDateTimeLocaleModule(),
  })
}

export const formatToIso = (date = new Date()) => {
  return formatISO(date, {
    locale: getDateTimeLocaleModule(),
  })
}

// TODO: Reevaluate the presence of this function
export const concatenateDateAndTime = (date, time) => {
  const hours = time.hours
  const minutes = time.minutes

  const hourSet = setHours(startOfDay(new Date(date)), hours)
  const newDate = setMinutes(hourSet, minutes)

  return formatISO(newDate, {
    locale: getDateTimeLocaleModule(),
  })
}

export function getDateRangeToday(date = new Date()) {
  return [startOfDay(date), endOfDay(date)]
}

export function getDateRangeThisWeek(date = new Date()) {
  return [startOfISOWeek(date), endOfISOWeek(date)]
}

export function getDateRangeThisMonth(date = new Date()) {
  return [startOfMonth(date), endOfMonth(date)]
}

export function getDateRangeLast30Days(date = new Date()) {
  return [subDays(startOfDay(date, 30), 30), endOfDay(date)]
}

export function getDateRangeNext30Days(date = new Date()) {
  return [startOfDay(date), endOfDay(addDays(date, 30))]
}

export function getDateRangeLastMonth(date = new Date()) {
  const lastMonthDate = subMonths(date, 1)
  return [startOfMonth(lastMonthDate), endOfMonth(lastMonthDate)]
}

export function getDateRangeNextMonth(date = new Date()) {
  const nextMonthDate = addMonths(date, 1)
  return [startOfMonth(nextMonthDate), endOfMonth(nextMonthDate)]
}

export function getDateRangeLast3Months(date = new Date()) {
  return [subMonths(startOfDay(date, 30), 3), endOfDay(date)]
}

export function getDateRangeLast6Months(date = new Date()) {
  return [subMonths(startOfDay(date, 30), 6), endOfDay(date)]
}

export function getDateRangeTomorrow(date = new Date()) {
  return [startOfDay(addDays(date, 1)), endOfDay(addDays(date, 1))]
}

export function getDateRangeUntilFriday(date = new Date()) {
  return [startOfDay(date), subDays(endOfISOWeek(date), 2)]
}

export function getDateRangeNextWeek(date = new Date()) {
  return [addDays(startOfISOWeek(date), 7), addDays(endOfISOWeek(date), 7)]
}

export function getLastWeekMondayToSunday(date = new Date()) {
  const lastWeekMonday = subDays(startOfISOWeek(date), 7)
  const lastWeekSunday = subDays(endOfISOWeek(date), 7)

  return [lastWeekMonday, lastWeekSunday]
}

export function getNextWeekMondayToSunday(date = new Date()) {
  const nextWeekMonday = addDays(startOfISOWeek(date), 7)
  const nextWeekSunday = addDays(endOfISOWeek(date), 7)

  return [nextWeekMonday, nextWeekSunday]
}

export function formatDateFilter(date, dateFormat = 'yyyy-MM-dd') {
  return format(date, dateFormat, {
    locale: getDateTimeLocaleModule(),
  })
}

export function formatDateRangeFilter(dateRange) {
  return `${formatDateFilter(dateRange[0])}_${formatDateFilter(dateRange[1])}`
}

export function formatTimeFilter(time) {
  return `${time.hours.toString().padStart(2, '0')}:${time.minutes
    .toString()
    .padStart(2, '0')}`
}

export function formatTimeRangeFilter(timeRange) {
  return `gte_${formatTimeFilter(timeRange.startTime)}_lte_${formatTimeFilter(
    timeRange.endTime
  )}`
}

export function formatDurationFilter(duration) {
  return formatTimeFilter(duration)
}

export function formatDurationRangeFilter(durationRange) {
  return `${formatDurationFilter(
    durationRange.startTime
  )}_${formatDurationFilter(durationRange.endTime)}`
}

const _normalizeTime = (time) => (time.length === 1 ? `0${time}` : time)

const _getDurationFromHoursInStandardTime = (hoursInDecimal) => {
  const MINUTES_IN_HOUR = 60

  const hours = parseInt(hoursInDecimal, 10)
  const minutes = parseFloat((hoursInDecimal % 1).toFixed(2)) * MINUTES_IN_HOUR

  const normalizedHours = _normalizeTime(String(hours))
  const normalizedMinutes = _normalizeTime(String(Math.round(minutes)))

  return `${normalizedHours}:${normalizedMinutes}`
}

export const getDurationFromSecondsInStandardTime = (seconds) => {
  if (seconds) {
    const h = Math.floor(seconds / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = Math.floor((seconds % 3600) % 60)

    const normalizedHours = _normalizeTime(String(h))
    const normalizedMinutes = _normalizeTime(String(Math.round(m)))
    const normalizedSeconds = _normalizeTime(String(Math.round(s)))

    return `${normalizedHours}:${normalizedMinutes}:${normalizedSeconds}`
  } else {
    return '00:00'
  }
}

export const getDurationBasedOnUserSetting = (
  time,
  userSetting = TIME_FORMAT_TYPE.STANDARD
) => {
  if (!time) return userSetting === TIME_FORMAT_TYPE.DECIMAL ? '0.00' : '00:00'

  if (userSetting === TIME_FORMAT_TYPE.DECIMAL) {
    return time.toFixed(2)
  }

  return _getDurationFromHoursInStandardTime(time)
}
export const getHoursMinutes = (date) => ({
  hours: date.getHours(),
  minutes: date.getMinutes(),
})

export const getDistanceBetweenDatesInDays = (
  start = new Date(),
  end = new Date()
) => {
  let days
  days = formatDistanceStrict(start, end, {
    unit: 'day',
    roundingMethod: 'ceil',
  })

  days = days.split(' ')[0]

  return +days
}

export const getDefaultStartDateTime = () => {
  const startDateTime = new Date()
  startDateTime.setHours(0)
  startDateTime.setMinutes(0)
  return startDateTime
}

export const getDefaultEndDateTime = () => {
  const endDateTime = new Date()
  endDateTime.setHours(0)
  endDateTime.setMinutes(0)
  return endDateTime
}

export const formatDateTime = (dateTime) => {
  return format(dateTime, 'EEEEEE, dd.MM.yy', {
    locale: getDateTimeLocaleModule(),
  })
}

export const formatBreakTime = (date) => {
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return { hours, minutes }
}

export const dateWithSetHours = (date, { hours, minutes }) => {
  const newDate = new Date(date)
  newDate.setHours(hours)
  newDate.setMinutes(minutes)
  newDate.setSeconds(0)
  return newDate
}

export const getHoursAndMinutesFromDate = (date) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return {
    hours,
    minutes,
  }
}

export const combineDateTime = (DateObj, timeObj) =>
  dateWithSetHours(DateObj, getHoursAndMinutesFromDate(timeObj))

export const defaultDateRange = () => [new Date(), new Date()]

export const formatTimeCell = ({ starts_at, ends_at }) => {
  const start = formatTime(new Date(starts_at))
  let end = ends_at || ''
  if (end) {
    end = formatTime(new Date(end))
    end = ` - ${end}`
  }
  return `${start}${end}`
}

export const formatViaRosterTimeCell = ({ starts_at }) => {
  let start = formatTime(new Date(starts_at))

  return VueI18n.t('since', {
    starts_at: start,
  })
}

export const getShortcuts = (date) => {
  return [
    {
      id: 'today',
      name: () => VueI18n.tc('daterangepicker.shortcuts.today'),
      range: getDateRangeToday(date),
    },
    {
      id: 'this_week',
      name: () => VueI18n.tc('daterangepicker.shortcuts.this_week'),
      range: getDateRangeThisWeek(date),
    },
    {
      id: 'this_month',
      name: () => VueI18n.tc('daterangepicker.shortcuts.this_month'),
      range: getDateRangeThisMonth(date),
    },
    {
      id: 'last_30_days',
      name: () => VueI18n.tc('daterangepicker.shortcuts.last_30_days'),
      range: getDateRangeLast30Days(date),
    },
    {
      id: 'last_week',
      name: () => VueI18n.tc('daterangepicker.shortcuts.last_week'),
      range: getLastWeekMondayToSunday(date),
    },
    {
      id: 'last_month',
      name: () => VueI18n.tc('daterangepicker.shortcuts.last_month'),
      range: getDateRangeLastMonth(date),
    },
    {
      id: 'all_time',
      name: () => VueI18n.tc('daterangepicker.shortcuts.all_time'),
    },
  ]
}

export const getAbsenceIndexShortcuts = (date, isAdmin) => {
  const shortcuts = [
    {
      id: 'today',
      name: () => VueI18n.tc('daterangepicker.shortcuts.today'),
      range: getDateRangeToday(date),
    },
    {
      id: 'this_week',
      name: () => VueI18n.tc('daterangepicker.shortcuts.this_week'),
      range: getDateRangeThisWeek(date),
    },
    {
      id: 'this_month',
      name: () => VueI18n.tc('daterangepicker.shortcuts.this_month'),
      range: getDateRangeThisMonth(date),
    },
    {
      id: 'next_30_days',
      name: () => VueI18n.tc('daterangepicker.shortcuts.next_30_days'),
      range: getDateRangeNext30Days(date),
    },
    {
      id: 'next_week',
      name: () => VueI18n.tc('daterangepicker.shortcuts.next_week'),
      range: getNextWeekMondayToSunday(date),
    },
    {
      id: 'next_month',
      name: () => VueI18n.tc('daterangepicker.shortcuts.next_month'),
      range: getDateRangeNextMonth(date),
    },
  ]

  if (!isAdmin) {
    shortcuts.push({
      id: 'this_year',
      name: () => VueI18n.tc('daterangepicker.shortcuts.this_year'),
      range: getStartAndEndOfCurrentYear(date),
    })
  }

  shortcuts.push({
    id: 'all_time',
    name: () => VueI18n.tc('daterangepicker.shortcuts.all_time'),
  })

  return shortcuts
}

export const getTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const getStartAndEndOfCurrentYear = (date = new Date()) => [
  startOfYear(date),
  endOfYear(date),
]

export const getNextYearDate = (date = new Date()) => addYears(date, 1)

export const dateParser = (date) => {
  return parse(date, DATE_FORMAT, new Date())
}
