import { State, RecordGeneric } from './types'
import { logError } from '@/utils/helpers'
import {
  checkTypeExists,
  checkTypeWritable,
  restructureRecord,
  extractIncluded,
} from './utils'
import {
  getRecordIndex,
  reindexSingle,
  reindexMultiple,
  clearIndex,
} from './indexing'
import { deleteRecords } from './_delete.records.mutations'

export { replaceRecord } from './_replace.records.mutations'
export { deleteRecords }

export function addRecords(
  state: State,
  { type, records }: { type: RecordType; records: RecordGeneric[] }
) {
  try {
    checkTypeExists(state, type)
    checkTypeWritable(state, type)

    const toDelete = []
    const toAppend = []

    for (const r of records) {
      const index = getRecordIndex(state, type, r.id)

      if (index > -1) {
        toDelete.push({ id: r.id })
      }
      toAppend.push(r)
    }

    if (toDelete.length) {
      module.exports.deleteRecords(state, { type, matches: toDelete })
    }
    // referencing module.exports.function allows us to mock the function
    // for testing
    module.exports.appendRecords(state, { type, records: toAppend })
  } catch (err) {
    logError(err)
  }
}

// sets given records by overriding current ones. Also runs indexing of data
export function setRecords(
  state: State,
  { type, records }: { type: RecordType; records: RecordGeneric[] }
) {
  try {
    checkTypeExists(state, type)
    checkTypeWritable(state, type)

    state.records[type] = records.map((r, index) => {
      reindexSingle(state, type, r.id, index)
      return restructureRecord(r, state)
    })
  } catch (err) {
    logError(err)
  }
}

// add records to the beginning of record array in the state. Also takes care of
// re-indexing all records. appendRecords() is preferred over this, because
// prepending resets indexes of all existing records in state
export function prependRecords(
  state: State,
  { type, records }: { type: RecordType; records: RecordGeneric[] }
) {
  try {
    checkTypeExists(state, type)
    checkTypeWritable(state, type)

    const stateRecords = <RecordGeneric[]>state.records[type]

    for (const r of records) {
      stateRecords.unshift(restructureRecord(r, state))
    }
    reindexMultiple(state, type, 0)
  } catch (err) {
    logError(err)
  }
}

// adds records to the end of record array in the state. Also runs reindexing
export function appendRecords(
  state: State,
  { type, records }: { type: RecordType; records: RecordGeneric[] }
) {
  try {
    checkTypeExists(state, type)
    checkTypeWritable(state, type)

    const stateRecords = <RecordGeneric[]>state.records[type]

    for (const r of records) {
      const record = restructureRecord(r, state)
      const newLength = stateRecords.push(record)
      reindexSingle(state, type, record.id, newLength - 1)
    }
  } catch (err) {
    logError(err)
  }
}

// assigns given record type to empty array
export function clearRecords(state: State, { type }: { type: RecordType }) {
  try {
    checkTypeExists(state, type)
    checkTypeWritable(state, type)

    state.records[type] = []
    clearIndex(state, type)
  } catch (err) {
    logError(err)
  }
}

// helper function that extracts jsonapi `included` records and runs
// addRecords() on each record type
export function addIncluded(
  state: State,
  { records }: { records: RecordGeneric[] }
) {
  const obj = extractIncluded(records)

  Object.keys(obj).forEach((type) => {
    module.exports.addRecords(state, { type, records: obj[type] })
  })
}
