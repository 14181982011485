<template>
  <div>
    <component
      :is="!$isSmallDevice ? 'tag-dropdown' : 'tag-bottom-sheet'"
      v-model="passedValue"
      :is-open.sync="passedIsOpen"
      :items="items"
      :selected-count.sync="passedSelectedCount"
      :searchable="searchable"
      :type-button-new="typeButtonNew"
      :loading="loading"
      :has-search-query="hasSearchQuery"
      :data-id-prefix="`${dataIdPrefix}.tag_list`"
      :permissions="permissions"
      @edit="$emit('edit', $event)"
      @create="$emit('create')"
      @scroll-end="$emit('scroll-end')"
      @typing="$emit('typing', $event)"
    />
  </div>
</template>

<script>
import TagDropdown from '../tag-dropdown/TagDropdown'
import TagBottomSheet from '../tag-bottom-sheet/TagBottomSheet'

export default {
  name: 'TagList',
  components: {
    TagDropdown,
    TagBottomSheet,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectedCount: {
      type: Number,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
    },
    typeButtonNew: {
      type: String,
      required: false,
      default: 'Add',
    },
    loading: {
      type: Boolean,
      required: false,
    },
    hasSearchQuery: {
      type: Boolean,
      required: false,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    passedSelectedCount: {
      get() {
        return this.selectedCount
      },
      set(selectedCount) {
        this.$emit('update:selectedCount', selectedCount)
      },
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
}
</script>
