import axios from '@/utils/vendors/axios'

import {
  CreateAreaRequest,
  CreateAreaResponse,
} from '../models/create-area.model'

import {
  UpdateAreaRequest,
  UpdateAreaResponse,
} from '../models/update-area.model'

import {
  DeleteAreaRequest,
  DeleteAreaResponse,
} from '../models/delete-area.model'

import {
  GetAreaDetailsRequest,
  GetAreaDetailsResponse,
} from '../models/get-area-details.model'

import { ListAreasResponse } from '../models/list-area.model'

import { SearchAreasResponse } from '../models/search-area.model'

import {
  AREA_ENDPOINT_CREATE_AREA,
  AREA_ENDPOINT_UPDATE_AREA,
  AREA_ENDPOINT_DELETE_AREA,
  AREA_ENDPOINT_LIST_AREAS,
  AREA_ENDPOINT_LIST_AREAS_BY_IDS,
  AREA_ENDPOINT_SEARCH_AREAS,
  AREA_ENDPOINT_GET_AREA_DETAILS,
} from './area.endpoints'

export default {
  async listAreas(workspaceId: RecordId, page: number) {
    const response = await axios.get(
      AREA_ENDPOINT_LIST_AREAS(workspaceId, page)
    )
    return new ListAreasResponse(response)
  },

  async listAreasByIds(workspaceId: RecordId, ids: string[]) {
    const response = await axios.get(
      AREA_ENDPOINT_LIST_AREAS_BY_IDS(workspaceId, ids)
    )
    return response.data
  },

  async createArea(workspaceId: RecordId, form: any) {
    const response = await axios.post(
      AREA_ENDPOINT_CREATE_AREA(workspaceId),
      new CreateAreaRequest(form)
    )

    return new CreateAreaResponse(response)
  },

  async updateArea(workspaceId: RecordId, form: any) {
    const response = await axios.patch(
      AREA_ENDPOINT_UPDATE_AREA(workspaceId, form.id),
      new UpdateAreaRequest(form)
    )

    return new UpdateAreaResponse(response.data)
  },

  async deleteArea(workspaceId: RecordId, AreaId: RecordId) {
    const response = await axios.delete(
      AREA_ENDPOINT_DELETE_AREA(workspaceId, AreaId),
      new DeleteAreaRequest()
    )

    return new DeleteAreaResponse(response)
  },

  async getAreaDetails(workspaceId: RecordId, AreaId: RecordId) {
    const response = await axios.get(
      AREA_ENDPOINT_GET_AREA_DETAILS(workspaceId, AreaId),
      new GetAreaDetailsRequest()
    )

    return new GetAreaDetailsResponse(response)
  },

  async searchAreas(
    workspaceId: RecordId,
    { page, query }: { page: number; query: string | null }
  ) {
    const response = await axios.get(
      query
        ? AREA_ENDPOINT_SEARCH_AREAS(workspaceId, page, query)
        : AREA_ENDPOINT_LIST_AREAS(workspaceId, page)
    )

    return new SearchAreasResponse(response.data)
  },
}
