import axios from '@/utils/vendors/axios'

import { AbsenceSummaryResponse } from '../models/absence-summary.model'
import { TimeTrackingSummaryResponse } from '../models/time-tracking-summary.model'
import { SUMMARY_ENDPOINT_GET_SUMMARY } from './summary.endpoints'

import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'

const responseClassMap = {
  [TIME_TRACKING_MODULE_NAME]: TimeTrackingSummaryResponse,
  [ABSENCE_MODULE_NAME]: AbsenceSummaryResponse,
}

export default {
  getSummary: async ({ workspaceId, resource, scopes }) => {
    const response = await axios.get(
      SUMMARY_ENDPOINT_GET_SUMMARY(workspaceId, resource, scopes)
    )

    return new responseClassMap[resource](response)
  },
}
