<template>
  <component
    :is="componentForm"
    :is-loading="status.isLoading"
    :is-open="isOpen"
    :more-options="moreOptions"
    @update-tag="updateTag(formData)"
    @handle-selection="handleSelection($event)"
    @close="$emit('close')"
  >
    <tag-form v-model="formData" :vertical="$isSmallDevice" />
  </component>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TagModalFormEdit from '../tag-modal-form-edit/TagModalFormEdit'
import TagBottomSheetFormEdit from '../tag-bottom-sheet-form-edit/TagBottomSheetFormEdit'
import {
  TAG_ACTION_UPDATE_TAG,
  TAG_ACTION_DELETE_TAG,
} from '../../store/actions/tag.actions.names'
import TagForm from '../tag-form/TagForm'

export default {
  name: 'TagFormEdit',
  components: {
    TagModalFormEdit,
    TagBottomSheetFormEdit,
    TagForm,
  },
  props: {
    modelTag: {
      type: Object,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  trackedActions: {
    editTag: TAG_ACTION_UPDATE_TAG,
    deleteTag: TAG_ACTION_DELETE_TAG,
  },
  data() {
    return {
      isActionDelete: false,
      formData: {
        name: this.modelTag?.attributes?.title || '',
        color: this.modelTag?.attributes?.color || '',
      },
    }
  },
  computed: {
    ...mapState({
      workspaceId: (state) => state.workspace.workspace.id,
    }),
    componentForm() {
      return !this.$isSmallDevice
        ? 'tag-modal-form-edit'
        : 'tag-bottom-sheet-form-edit'
    },
    status() {
      return this.isActionDelete
        ? this.$actions.deleteTag
        : this.$actions.editTag
    },
    moreOptions() {
      const options = []

      if (this.permissions.create) {
        options.push({
          id: 'duplicate',
          label: this.$t('tag.form_edit.option_label_duplicate'),
          icon: 'list/list-16',
        })
      }

      if (this.permissions.update) {
        options.push({
          id: 'delete',
          label: this.$t('tag.form_edit.option_label_delete'),
          icon: 'delete/delete-16',
          classes: 'text-error-400',
        })
      }

      return options
    },
  },
  methods: {
    ...mapActions({
      actionUpdateTag: TAG_ACTION_UPDATE_TAG,
      actionDeleteTag: TAG_ACTION_DELETE_TAG,
    }),
    async updateTag(formData) {
      const updatePayload = { ...formData, id: this.modelTag.id }
      await this.actionUpdateTag(updatePayload)
      this.$buefy.snackbar.open(this.$t('tag.form_edit.success_message'))
      this.$emit('updated', updatePayload)
      this.$emit('close')
    },
    async handleSelection(option) {
      if (option.id === 'delete') {
        if (confirm(this.$t('tag.form_edit.text_delete_confirmation'))) {
          this.isActionDelete = true
          await this.actionDeleteTag(this.modelTag.id)
          this.$buefy.snackbar.open(
            this.$t('tag.form_edit.delete_success_message')
          )
          this.$emit('close')
        }
      } else if (option.id === 'duplicate') {
        if (confirm(this.$t('tag.form_edit.text_duplicate_confirmation'))) {
          this.$buefy.snackbar.open(
            this.$t('tag.form_edit.duplicate_success_message')
          )
          this.$emit('duplicate', this.modelTag)
        }
      }
    },
  },
}
</script>
