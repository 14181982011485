<template>
  <div>
    <notification-list-trigger
      size="1.75rem"
      dark
      @click.native="passedIsOpen = true"
    />

    <portal v-if="passedIsOpen" to="bottom-sheet">
      <bottom-sheet
        @cancel-click="passedIsOpen = false"
        @scroll-end="$emit('scroll-end')"
      >
        <template v-slot:header>
          <div class="grid grid-cols-3 pb-3 px-4 border-b border-coal-150">
            <button
              class="justify-self-start text-left text-lg text-coal-550"
              @click="passedIsOpen = false"
            >
              {{ $t('topbar.notifications.close') }}
            </button>
            <div class="text-center text-lg font-semibold">
              {{ $t('topbar.notifications.notifications') }}
            </div>
            <div v-if="notifications.length" class="flex justify-end">
              <button
                class="text-lg text-coal-550"
                @click="$emit('delete-all')"
              >
                {{ $t('topbar.notifications.delete_all') }}
              </button>
            </div>
          </div>
        </template>

        <base-loading :active="isLoading" />

        <div v-if="notifications.length" class="px-4" role="list">
          <notification-list-item
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
            aria-role="listitem"
            @delete="$emit('delete', $event)"
          />
        </div>
        <notification-list-empty
          v-if="notifications.length === 0 && !isLoading"
          class="w-full py-14"
        />
      </bottom-sheet>
    </portal>
  </div>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import NotificationListItem from './NotificationListItem'
import NotificationListEmpty from './NotificationListEmpty'
import NotificationListTrigger from './NotificationListTrigger'

export default {
  name: 'NotificationListBottomSheet',
  components: {
    BottomSheet,
    BaseLoading,
    NotificationListItem,
    NotificationListEmpty,
    NotificationListTrigger,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Array,
      default() {
        return []
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
}
</script>
