<template>
  <div class="bottom-sheet">
    <div
      class="overlay z-index-bottom-sheet fixed inset-0 bottom-sheet-bg-color"
      @click="$emit('cancel-click')"
    />
    <div
      ref="content"
      class="bottom-sheet-content z-index-bottom-sheet fixed left-0 right-0 bottom-0 pb-3 rounded-tl-2xl rounded-tr-2xl bg-white-400"
      :class="{ 'full-height': fullHeight }"
    >
      <slot name="header">
        <bottom-sheet-header
          v-bind="headerAttrs"
          v-on="$listeners"
          @option-select="$emit('selected-option', $event)"
        >
          <template v-slot:left>
            <slot name="header-left" />
          </template>
          <template v-slot:right>
            <slot name="header-right" />
          </template>
        </bottom-sheet-header>
      </slot>

      <slot />

      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getScopedAttrs } from '@/utils/helpers'
import {
  BASE_MUTATION_INCREMENT_OVERLAY,
  BASE_MUTATION_DECREMENT_OVERLAY,
} from '@/store/mutations/store.mutations.names'
import BottomSheetHeader from './BottomSheetHeader'
import { isEndOfScroll } from '@/utils/helpers'

export default {
  name: 'BottomSheet',
  components: {
    BottomSheetHeader,
  },
  props: {
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // pick $attrs starting with header-
    headerAttrs() {
      return getScopedAttrs(this.$attrs, 'header-')
    },
  },
  mounted() {
    this.addOverlayCount()

    if (this.$listeners['scroll-end']) {
      this.$refs.content.addEventListener('scroll', this.notifyScrollEnd)
    }
  },
  beforeDestroy() {
    if (this.$listeners['scroll-end']) {
      this.$refs.content.removeEventListener('scroll', this.notifyScrollEnd)
    }
  },
  destroyed() {
    this.removeOverlayCount()
  },
  methods: {
    ...mapMutations({
      addOverlayCount: BASE_MUTATION_INCREMENT_OVERLAY,
      removeOverlayCount: BASE_MUTATION_DECREMENT_OVERLAY,
    }),
    notifyScrollEnd(e) {
      if (isEndOfScroll(e.target)) {
        this.$emit('scroll-end')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables';

.bottom-sheet-content {
  $max-height: calc(100% - 24px);

  max-height: $max-height;
  margin-top: 24px;
  overflow: auto;

  &.full-height {
    height: $max-height;
  }

  // select all headers of the bottom sheet including the default and custom ones
  &::v-deep > div:first-of-type {
    @apply sticky top-0 py-3 z-1 bg-white;
  }
}
.z-index-bottom-sheet {
  z-index: $z-index-bottom-sheet;
}
.bottom-sheet-bg-color {
  background-color: rgba($coal, 0.75);
}
</style>
