<template>
  <div class="app">
    <component :is="$route.meta.layout || 'div'" v-if="isAppRendered">
      <router-view />
    </component>

    <portal-target
      name="modal"
      :transition="$options.components.FadeGroupTransition"
      multiple
    />

    <portal-target
      name="bottom-sheet"
      :transition="$options.components.BottomSlideTransition"
    />

    <portal-target
      name="mobile-overlay"
      :transition="$options.components.FadeGroupTransition"
    />

    <portal-target name="loader" />
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import { config } from '@/utils/vendors/buefy'
import { BASE_MUTATION_UPDATE_WINDOW_WIDTH } from '@/store/mutations/store.mutations.names'
import { AUTH_ACTION_LOGOUT } from '@/app/core/auth/store/actions/auth.actions.names'
import BottomSlideTransition from '@/app/util-modules/ui/transitions/BottomSlideTransition'
import FadeGroupTransition from '@/app/util-modules/ui/transitions/FadeGroupTransition'
import store from '@/store'

export default {
  name: 'App',
  components: {
    BottomSlideTransition,
    FadeGroupTransition,
  },
  data() {
    return {
      isAppRendered: true,
    }
  },
  computed: {
    isUserLoggedIn() {
      return store.state.user.user.id
    },
    isProdOrReview() {
      return (
        process.env.VUE_APP_MODE === 'production' ||
        process.env.VUE_APP_MODE === 'review'
      )
    },
  },
  created() {
    this.$store.subscribeAction((action) => {
      // when logout initiated, we hide app, because data is cleared on logout
      // and while auth.login still not rendered, which results in errors about
      // data missing in UI components. This is especially true, when login page
      // chunk is not in cache and there is small delay navigating to it.
      if (action.type === AUTH_ACTION_LOGOUT) {
        this.isAppRendered = false
      }
    })
    this.$router.afterEach((to) => {
      // once we are at login screen after logout, we can safely render app,
      // because it is going to render the login page only
      if (to.name === 'auth.login') {
        this.isAppRendered = true
      }
    })

    this.setupWindow()
    window.addEventListener('resize', this.throttledSetupWindow)
    this.$buefy.config.setOptions(config)
    this.$buefy.snackbar.showQueuedAfterReload()
  },
  destroyed() {
    window.removeEventListener('resize', this.throttledSetupWindow)
  },
  methods: {
    setupWindow() {
      this.$store.commit(BASE_MUTATION_UPDATE_WINDOW_WIDTH, window.innerWidth)

      // Property --vh gives base unit according to the actual visible
      // browser height CSS-native vh causes problems in Safari and Chrome
      // mobile browsers so, we have to fallback to JS to compute base
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      )
    },
    throttledSetupWindow: throttle(
      function () {
        this.setupWindow()
      },
      150,
      {
        leading: true,
      }
    ),
  },
}
</script>
