<template>
  <buefy-message :type="type">
    <slot />
  </buefy-message>
</template>

<script>
import BuefyMessage from 'buefy/src/components/message/Message'

export default {
  name: 'BaseMessage',
  components: { 'buefy-message': BuefyMessage },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
}
</script>
