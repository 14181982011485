<template>
  <base-button
    size="is-small"
    :type="type"
    class="base-group-selection-toolbar-action-button"
    :icon-left="icon"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </base-button>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'

export default {
  name: 'BaseGroupSelectionToolbarActionButton',
  components: {
    'base-button': BaseButton,
  },
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'is-filled-white',
    },
  },
}
</script>

<style scoped>
.base-group-selection-toolbar-action-button {
  @apply font-semibold text-base;
}
</style>
