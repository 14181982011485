<template>
  <div class="flex items-center justify-content">
    <base-flag :country-code="flagCode" class="mr-3 md:mr-2" />
    <span>{{ name }}</span>
  </div>
</template>

<script>
import BaseFlag from '@/app/util-modules/ui/flag/BaseFlag'

export default {
  name: 'UserMenuLocale',
  components: {
    BaseFlag,
  },
  props: {
    languageName: {
      type: String,
      required: true,
    },
    flagCode: {
      type: String,
      required: true,
    },
    isUsingCompanyLanguage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return this.isUsingCompanyLanguage
        ? `${this.$t('locale_switch.company')} (${this.languageName})`
        : this.languageName
    },
  },
}
</script>
