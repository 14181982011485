<template>
  <div class="text-center text-coal-550">
    <base-icon
      icon="bell-notifications/bell-notifications-32"
      class="mb-3"
      :size="!$isSmallDevice ? '3rem' : '4rem'"
    />
    <div class="font-semibold text-lg md:text-base">
      {{ $t('topbar.notifications.no_notifications') }}
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'NotificationListEmpty',
  components: {
    BaseIcon,
  },
}
</script>
