<template>
  <app-sidebar-menu-list class="mt-12">
    <app-sidebar-menu-list-item
      v-for="app in mainApps"
      :key="app.feature"
      :data-id="`sidebar.${app.dataId}`"
    >
      <app-sidebar-menu-list-item-content
        v-bind="getAppWithSummary(app)"
        @menu-item-click="onMenuItemClick(app)"
      />
    </app-sidebar-menu-list-item>

    <!-- TODO: Hiding unused links https://app.asana.com/0/1175527195735767/1200978105052501/f -->
    <!--app-sidebar-menu-list-item>
      <base-dropdown
        v-if="!$isSmallDevice && moreApps.menuItems.length"
        class="custom-dropdown-is-top-right"
        @active-change="moreApps.linkActive = $event"
      >
        <template v-slot:trigger>
          <app-sidebar-menu-list-item-content
            v-bind="moreApps"
            @click="moreApps.linkActive = !moreApps.linkActive"
          />
        </template>

        <base-dropdown-item
          v-for="item in moreApps.menuItems"
          :key="item.label()"
          class="px-2"
        >
          <component
            :is="getLinkTag(item.route)"
            :to="item.route"
            :href="item.route"
            class="flex items-center text-coal"
          >
            <base-icon :icon="item.icon" size="1rem" class="mr-3" />
            <span>{{ item.label() }}</span>
          </component>
        </base-dropdown-item>
      </base-dropdown>

      <template v-if="$isSmallDevice && moreApps.menuItems.length">
        <app-sidebar-menu-list-item-content
          v-bind="moreApps"
          @click.native="toggleBottomSheetMoreApps()"
        />

        <portal v-if="moreApps.isOpen" to="bottom-sheet">
          <bottom-sheet
            :header-title="$t('sidebar.more_apps.more_apps')"
            @cancel-click="toggleBottomSheetMoreApps()"
          >
            <div class="px-4">
              <bottom-sheet-list-item
                v-for="item in moreApps.menuItems"
                :key="item.label()"
                :label="item.label()"
                v-bind="item"
              />
            </div>

            <template v-slot:footer>
              <div class="border-t border-color-coal">
                <button
                  class="flex py-4 items-center mx-auto text-lg text-coal-450"
                >
                  <base-icon icon="plus/plus-12" size="1rem" class="mr-3" />
                  <span>{{ $t('sidebar.more_apps.add_more_apps') }}</span>
                </button>
              </div>
            </template>
          </bottom-sheet>
        </portal>
      </template>
    </app-sidebar-menu-list-item-->
    <!-- bypass for @intlify/vue-i18n/no-unused-keys eslint rule. To be removed once code above is uncommented -->
    <span style="display: none">
      {{ $t('sidebar.more_apps.add_more_apps') }}
    </span>
  </app-sidebar-menu-list>
</template>

<script>
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '@/app/modules/time-tracking/time-tracking.module'
import { MODULE_NAME as EMPLOYEE_MODULE_NAME } from '@/app/modules/employee/employee.module'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'
import { MODULE_NAME as ABSENCE_CALENDAR_MODULE_NAME } from '@/app/modules/calendar/calendar.module'
import { MODULE_NAME as SHIFTS_CALENDAR_MODULE_NAME } from '@/app/modules/shifts-calendar/shifts-calendar.module'
import { BASE_GETTER_OLD_MODULES_URLS } from '@/store/getters/store.getters.names'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
  USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE,
} from '@/app/core/user/store/getters/user.getters.names'
import { BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR } from '@/store/mutations/store.mutations.names'

import AppSidebarMenuList from '@/app/util-modules/ui/app-sidebar/AppSidebarMenuList'
import AppSidebarMenuListItem from '@/app/util-modules/ui/app-sidebar/AppSidebarMenuListItem'
import AppSidebarMenuListItemContent from '@/app/util-modules/ui/app-sidebar/AppSidebarMenuListItemContent'

import { SUMMARY_ACTION_GET_SUMMARY } from '@/app/util-modules/summary/store/actions/summary.actions.names'

import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'AppSidebarApps',
  components: {
    AppSidebarMenuList,
    AppSidebarMenuListItem,
    AppSidebarMenuListItemContent,
  },
  data() {
    const urls = this.$store.getters[BASE_GETTER_OLD_MODULES_URLS]
    const isAdminView = this.$store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]

    const calendarModules = [
      SHIFTS_CALENDAR_MODULE_NAME,
      ABSENCE_CALENDAR_MODULE_NAME,
    ]

    const visibleCalendarFeatures = calendarModules.filter((module) =>
      this.$store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](module)
    )

    const currentVisibleCalendarFeature = visibleCalendarFeatures.find(
      (feature) => `${feature}.index` === this.$route.name
    )

    const calendarMenu = (module) => {
      return {
        feature: module,
        tooltip: () => this.$t('sidebar.calendar'),
        label: () => this.$t('sidebar.calendar'),
        icon: 'calendar/calendar-26',
        iconHover: 'calendar/calendar-26-hover',
        iconHoverType: 'is-filled',
        route: {
          name: `${module}.index`,
        },
        hidden: false,
        dataId: 'calendar',
      }
    }

    let mainApps = [
      // TODO: Hiding unused links https://app.asana.com/0/1175527195735767/1200978105052501/f
      {
        feature: EMPLOYEE_MODULE_NAME,
        tooltip: () => this.$t('sidebar.employees'),
        label: () => this.$t('sidebar.employees'),
        icon: 'employees/employees-26',
        iconHover: 'employees/employees-26-hover',
        iconHoverType: 'is-filled',
        route: { name: `${EMPLOYEE_MODULE_NAME}.index` },
        hidden: false,
        dataId: 'employees',
      },
      {
        feature: ABSENCE_MODULE_NAME,
        tooltip: () => this.$t('sidebar.absences'),
        label: () => this.$t('sidebar.absences'),
        icon: 'absences/absences-26',
        iconHover: 'absences/absences-26-hover',
        iconHoverType: 'is-filled',
        summaryConfig: {
          enabled: isAdminView,
          scopes: 'pending',
          negative: 'total_pending',
        },
        route: { name: `${ABSENCE_MODULE_NAME}.index` },
        hidden: false,
        dataId: 'absences',
      },
      {
        feature: TIME_TRACKING_MODULE_NAME,
        tooltip: () => this.$t('sidebar.time_tracking'),
        label: () => this.$t('sidebar.time_tracking'),
        icon: 'time-tracking/time-tracking-26',
        iconHover: 'time-tracking/time-tracking-26-hover',
        iconType: 'is-filled',
        iconHoverType: 'is-filled',
        summaryConfig: {
          enabled: isAdminView,
          positive: 'total_running',
          negative: 'total_unconfirmed',
        },
        route: { name: `${TIME_TRACKING_MODULE_NAME}.index` },
        dataId: 'time_tracking',
      },
    ]

    if (visibleCalendarFeatures.length) {
      mainApps = [
        calendarMenu(
          currentVisibleCalendarFeature || visibleCalendarFeatures[0]
        ),
        ...mainApps,
      ]
    }

    const moreAppsMenuItems = [
      {
        feature: 'Payroll',
        label: () => this.$t('sidebar.more_apps.payrolls'),
        icon: 'payroll/payroll-16',
        route: urls.Payroll,
      },
      {
        feature: 'EmployeeReport',
        label: () => this.$t('sidebar.more_apps.employees_reports'),
        icon: 'employee-reports/employee-reports-16',
        route: urls.EmployeeReport,
      },
      {
        feature: 'File',
        label: () => this.$t('sidebar.more_apps.files'),
        icon: 'file-clip/file-clip-16',
        route: urls.File,
      },
      {
        feature: 'Recurrence',
        label: () => this.$t('sidebar.more_apps.recurrences'),
        icon: 'calendar-recurrence/calendar-recurrence-16',
        route: urls.Recurrence,
      },
      {
        feature: 'Event',
        label: () => this.$t('sidebar.more_apps.events'),
        icon: 'calendar-events/calendar-events-16',
        route: urls.Event,
      },
    ]

    return {
      mainApps: mainApps.filter((item) => {
        // TODO: Hiding unused links https://app.asana.com/0/1175527195735767/1200978105052501/f
        return (
          this.$store.getters[USER_GETTER_IS_FEATURE_VISIBLE_FOR_ROLE](
            item.feature
          ) && !item.hidden
        )
      }),
      moreApps: {
        tooltip: () => this.$t('sidebar.more_apps.more_apps'),
        label: () => this.$t('sidebar.more_apps.more_apps'),
        icon: 'more/more-26',
        iconType: 'is-filled',
        iconHover: 'more/more-26-hover',
        iconHoverType: 'is-filled',
        isOpen: false,
        menuItems: moreAppsMenuItems.filter(
          (item) =>
            this.$store.getters[USER_GETTER_FEATURE_PERMISSIONS](item.feature)
              .update
        ),
      },
    }
  },
  computed: {
    ...mapState({
      workspaceId: (state) => state.workspace.workspace.id,
      summaries: (state) => state.summary.summaries,
    }),
  },
  mounted() {
    this.mainApps.forEach((app) => {
      if (app.summaryConfig?.enabled) {
        this.getSummary({
          workspaceId: this.workspaceId,
          resource: app.feature,
          scopes: app.summaryConfig.scopes,
        })
      }
    })
  },
  methods: {
    ...mapActions({
      getSummary: SUMMARY_ACTION_GET_SUMMARY,
    }),
    ...mapMutations({
      hideMobileSidebar: BASE_MUTATION_TOGGLE_MOBILE_SIDEBAR,
    }),
    getNotificationLabel(value) {
      if (!value) return undefined

      if (value > 99) return '99+'

      return value.toString()
    },
    getAppWithSummary(app) {
      const { summaryConfig, feature } = app

      if (summaryConfig?.enabled) {
        const appSummary = this.summaries[feature] || {}
        const notificationPositive = this.getNotificationLabel(
          appSummary[summaryConfig?.positive]
        )
        const notificationNegative = this.getNotificationLabel(
          appSummary[summaryConfig?.negative]
        )

        return {
          ...app,
          notificationPositive,
          notificationNegative,
        }
      }

      return app
    },
    onMenuItemClick(app) {
      this.$emit('module-navigation', app.feature)

      if (this.$isSmallDevice) {
        this.hideMobileSidebar()
      }
    },
    toggleBottomSheetMoreApps() {
      this.moreApps.isOpen = !this.moreApps.isOpen
    },
    getLinkTag(route) {
      return route.startsWith('http') ? 'a' : 'router-link'
    },
  },
}
</script>

<style scoped>
::v-deep .dropdown-content {
  @apply ml-2;
}
</style>
