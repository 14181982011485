import { register } from 'register-service-worker'

if (process.env.VUE_APP_MODE === 'production') {
  register('/sw.js', {
    ready() {
      // eslint-disable-next-line no-console
      console.log('Service worker is active.')
    },
    registered() {
      // eslint-disable-next-line no-console
      console.log('Service worker has been registered.')
    },
    cached() {
      // eslint-disable-next-line no-console
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      // eslint-disable-next-line no-console
      console.log('New content is downloading.')
    },
    updated(registration) {
      // eslint-disable-next-line no-console
      console.log('New content is available; please refresh.')
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    offline() {
      // eslint-disable-next-line no-console
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error('Error during service worker registration:', error)
    },
  })
}
