<template>
  <base-filter
    :field="field"
    :label="label"
    :serialized-value="serializedValue"
    :available-fields="availableFields"
    :invalid="invalid"
    :display-value="displayValue"
    :default-value="defaultValue"
    dynamic-display-value
    @apply="apply($event)"
    @replace="$emit('replace', $event)"
    @remove="$emit('remove', $event)"
    @close="resetForm()"
  >
    <template v-slot:form>
      <div class="mb-3">
        <div class="label">
          <span>
            {{ $t('filterbar.fields.operator.label') }}
          </span>
        </div>
        <base-dropdown class="filter-string-dropdown">
          <template v-slot:trigger>
            <base-input-field
              :value="displayStringValue"
              :placeholder="$t('filterbar.fields.operator.label')"
              :name="$t('filterbar.fields.operator.name')"
              type="text"
              icon-right="arrow"
              :size="$isSmallDevice ? 'is-large' : 'is-small'"
              readonly
            >
            </base-input-field>
          </template>
          <base-dropdown-text-item
            v-for="availableOperator in availableOperators"
            :key="availableOperator.id"
            :value="availableOperator.id"
            @click="handleFilterString(availableOperator)"
          >
            {{ runOrReturn(availableOperator.label) }}
          </base-dropdown-text-item>
        </base-dropdown>
      </div>
      <div class="mb-3">
        <base-input-field
          v-model="form.query"
          :name="inputFieldName"
          :label="inputFieldLabel"
          :placeholder="inputFieldPlaceholder"
          :size="$isSmallDevice ? 'is-large' : 'is-small'"
        />
      </div>
    </template>
  </base-filter>
</template>

<script>
import BaseFilter from './BaseFilter'
import BaseInputField from '../input/BaseInputField'
import { stringFilter } from '@/utils/filterbar.utils'
import { runOrReturn } from '@/utils/helpers'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'

export default {
  name: 'BaseFilterString',
  components: {
    BaseDropdownTextItem,
    BaseDropdown,
    BaseFilter,
    BaseInputField,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({
        valueInputProps: {},
      }),
    },
    availableFields: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        selectedDuration: null,
        operator: 'ct',
        query: '',
        ...this.defaultValue?.form,
      },
      availableOperators: stringFilter.operators,
      appliedValue: null,
    }
  },
  computed: {
    displayStringValue() {
      return this.form.selectedDuration || this.$t('filter_operators.not_set')
    },
    inputFieldName() {
      return (
        (this.params.valueInputProps.name &&
          runOrReturn(this.params.valueInputProps.name)) ||
        this.$t('filterbar.fields.value.name')
      )
    },
    inputFieldLabel() {
      return (
        (this.params.valueInputProps.label &&
          runOrReturn(this.params.valueInputProps.label)) ||
        this.$t('filterbar.fields.value.label')
      )
    },
    inputFieldPlaceholder() {
      return (
        (this.params.valueInputProps.placeholder &&
          runOrReturn(this.params.valueInputProps.placeholder)) ||
        ''
      )
    },
    filterParam() {
      const { operator, query } = this.form
      return {
        [this.field]: `${operator}:${query}`,
      }
    },
    serializedValue() {
      return {
        field: this.field,
        form: { ...this.form },
        filter: this.filterParam,
      }
    },
    invalid() {
      return this.form.query.length === 0
    },

    displayValue() {
      return stringFilter.getDisplayedValue(this.appliedValue)
    },
  },
  mounted() {
    this.form.selectedDuration = runOrReturn(this.availableOperators[0].label)
  },
  methods: {
    runOrReturn,
    resetForm() {
      if (this.appliedValue) {
        this.form = { ...this.appliedValue.form }
      }

      this.$emit('close')
    },
    apply(applied) {
      this.appliedValue = this.serializedValue

      if (applied.emit) {
        this.$emit('apply', this.appliedValue)
      }
    },
    handleFilterString(args) {
      this.form.operator = args.id
      this.form.selectedDuration = this.runOrReturn(args.label)
    },
  },
}
</script>

<style scoped>
.filter-string-dropdown {
  @apply block;
}
.filter-string-dropdown >>> .dropdown-menu {
  @apply w-full;
}
.filter-string-dropdown >>> .control svg {
  @apply transform rotate-90;
}
.filter-string-dropdown.is-active >>> .control svg {
  @apply transform -rotate-90;
}
</style>
