

















import {
  eventcalendar,
  setOptions,
  locale,
  popup,
  MbscCalendarEventData,
} from '@mobiscroll/javascript'
import '@mobiscroll/javascript/dist/css/mobiscroll.min.css'

import {
  defineComponent,
  onMounted,
  onUnmounted,
  computed,
  watch,
  ref,
} from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import i18n, { supportedLanguages } from '@/utils/vendors/i18n'
import { formatDateFilter } from '@/utils/date-time.utils'
import { isEndOfScroll } from '@/utils/helpers'
import { differenceInDays, parse } from 'date-fns'
import { DATE_FORMAT } from '@/constants'
import {
  baseCalendarHeaderDayTemplate,
  baseCalendarEventTemplate,
  baseCalendarResourceTemplate,
} from './BaseCalendarTemplates'
import BaseCalendarEventPopup from './BaseCalendarEventPopup.vue'
import {
  MbscEvent,
  MbscResource,
  CurrentEventInfo,
  popupOnPosition,
  isEventOverlapping,
  updateResourceTooltip,
  getVisibleMonth,
  getTimelineGridScroll,
  removeTimelineGridScroll,
} from './BaseCalendar.utils'

type Language = {
  id: string
  name: () => TranslateResult
}
export default defineComponent({
  name: 'BaseCalendar',
  components: {
    BaseCalendarEventPopup,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    resource: {
      type: Array as () => MbscResource[],
      default: () => [],
    },
    data: {
      type: Array as () => MbscEvent[],
      default: () => [],
    },
    type: {
      type: String as () => 'year' | 'month' | 'week' | 'day',
      default: 'year',
    },
    selectedDate: {
      type: Date,
      default: () => new Date(),
    },
  },
  setup(props, { emit }) {
    const baseCalendar = ref<HTMLElement | null>(null)
    const TIMELINE_GRID_SCROLL_CLASS = '.mbsc-timeline-grid-scroll'
    const TIMELINE_HEADER_MONTH_CLASS = 'mbsc-timeline-header-month'
    const TIMELINE_RESOURCE_TEXT_CONTENT = '.resource-text-content'
    const TOOLTIP_CONTENT_CLASS = 'tooltip-content'
    const EVENT_POPUP_BUTTON_CLOSE_ID = 'event-popup-button-close'
    const MINIMUM_EVENT_DAYS = 3
    let closeEventPopupButton = null
    let baseCalendarInstance = ref<any>(null)

    const ABSENCE_STATUS_CLASS: { [k: string]: string } = {
      rejected: 'bg-error-100 text-error-400 border hover:border-error-400',
      pending:
        'bg-warning-100 text-warning-400 border hover:border-warning-400',
      confirmed:
        'bg-success-100 text-success-400 border hover:border-success-400',
    }

    const ABSENCE_STATUS_CLASS_ACTIVE: { [k: string]: string } = {
      rejected: 'border-error-400',
      pending: 'border-warning-400',
      confirmed: 'border-success-400',
    }

    const scrolledVisibleMonth = ref<string[]>([])

    let eventPopup: any | null = null
    const currentEventInfo = ref<CurrentEventInfo>({
      id: '',
      isOpen: false,
      target: null,
      eventActiveClassList: [],
      resourceName: '',
      datePeriod: '',
      canUpdate: false,
    })

    const calendarId = computed(() => `${props.id}-base-calendar`)
    const popupId = computed(() => `${props.id}-base-popup`)
    const currentLanguage = computed(() => {
      return supportedLanguages.find(
        (language: Language) => language.id === i18n.locale
      )
    })

    const closePopup = () => {
      const eventActiveClassList = currentEventInfo.value.eventActiveClassList
      currentEventInfo.value.target?.classList.remove(...eventActiveClassList)
      currentEventInfo.value.isOpen = false
      eventPopup.close()
    }

    const scrollHandler = (timeLineGrid: HTMLElement) => {
      const visibleMonths = getVisibleMonth(
        timeLineGrid,
        TIMELINE_HEADER_MONTH_CLASS
      )
      for (const newMonthYear of visibleMonths) {
        if (!scrolledVisibleMonth.value.includes(newMonthYear)) {
          const dateObject = parse(newMonthYear, 'MMMM yyyy', new Date())
          emit('current-scroll-date', dateObject)
          scrolledVisibleMonth.value.push(newMonthYear)
        }
      }

      if (isEndOfScroll(timeLineGrid, 1)) {
        emit('scroll-end')
      }
    }

    const renderDay = (args: { date: Date }) => {
      const date = args.date
      const dayName = formatDateFilter(date, 'EEEEEE')
      const dayNumber = formatDateFilter(date, 'd')
      const htmlString = baseCalendarHeaderDayTemplate({ dayName, dayNumber })
      return htmlString
    }

    const formattedDateString = (date: Date | string) =>
      formatDateFilter(new Date(date), DATE_FORMAT)

    const showContent = (
      content: string,
      days: number,
      isOverlapping: boolean
    ) => (days > MINIMUM_EVENT_DAYS && !isOverlapping ? content : '')

    const getEventContent = (data: any) => {
      const eventData = data.original
      const startDate = data.allDay
        ? eventData.start
        : eventData.originalStartDate
      const endDate = data.allDay ? eventData.end : eventData.originalEndDate
      const resource = eventData.resource

      const days = differenceInDays(new Date(endDate), new Date(startDate))
      const isOverlappingEvent = isEventOverlapping(
        resource,
        startDate,
        endDate,
        props.data
      )

      const content = {
        statusClass: ABSENCE_STATUS_CLASS[eventData.confirmationStatus],
        title: showContent(data.title, days, isOverlappingEvent),
        startDate: showContent(
          formattedDateString(startDate),
          days,
          isOverlappingEvent
        ),
        endDate: showContent(
          formattedDateString(endDate),
          days,
          isOverlappingEvent
        ),
      }

      return content
    }

    const renderScheduleEvent = (data: MbscCalendarEventData) => {
      const { statusClass, title, startDate, endDate } = getEventContent(data)
      const htmlString = baseCalendarEventTemplate({
        statusClass,
        title,
        startDate,
        endDate,
      })
      return htmlString
    }

    const renderResource = (resource: MbscResource) => {
      updateResourceTooltip(
        TIMELINE_RESOURCE_TEXT_CONTENT,
        TOOLTIP_CONTENT_CLASS
      )
      const htmlString = baseCalendarResourceTemplate({ name: resource.name })
      return htmlString
    }

    const onEventClick = async (event: MbscEvent) => {
      const eventData = event.event
      const target = event.domEvent.target as HTMLElement

      const eventActiveClass = `border ${
        ABSENCE_STATUS_CLASS_ACTIVE[eventData.confirmationStatus]
      }`
      const eventActiveClassList = eventActiveClass.split(' ')

      if (
        currentEventInfo.value.isOpen &&
        currentEventInfo.value.id === eventData.id
      ) {
        closePopup()
        return
      } else {
        const oldActiveClass = currentEventInfo.value.eventActiveClassList
        currentEventInfo.value.target?.classList.remove(...oldActiveClass)
      }

      const resource = props.resource.find((r) => r.id === eventData.resource)
      const resourceName = resource?.name || ''

      const datePeriod = `${formattedDateString(
        eventData.start
      )} - ${formattedDateString(eventData.end)}`

      target.classList.add(...eventActiveClassList)
      currentEventInfo.value = {
        ...eventData,
        resourceName,
        datePeriod,
        isOpen: true,
        eventActiveClassList,
        target,
      }

      eventPopup.setOptions({ anchor: target })
      eventPopup.open()
    }

    const onPageLoaded = () => {
      if (baseCalendar.value) {
        getTimelineGridScroll(
          baseCalendar.value,
          TIMELINE_GRID_SCROLL_CLASS,
          scrollHandler
        )
      }
    }

    const popupOnInit = () => {
      closeEventPopupButton = document.getElementById(
        EVENT_POPUP_BUTTON_CLOSE_ID
      )
      if (closeEventPopupButton) {
        closeEventPopupButton.addEventListener('click', function () {
          closePopup()
        })
      }
    }

    const handleEventPopupAction = (actionName: string, payload: any) => {
      emit(actionName, payload)
    }

    watch(
      () => currentLanguage.value,
      () => {
        setOptions({
          locale:
            locale[
              currentLanguage.value?.id === 'ch'
                ? 'de'
                : currentLanguage.value?.id || 'en'
            ],
        })
      },
      {
        immediate: true,
      }
    )

    watch(
      () => props.resource,
      () => {
        setOptions({
          resources: props.resource,
        })
      },
      {
        immediate: true,
      }
    )

    watch(
      () => props.data,
      () => {
        const currentActiveEvent: any = props.data.find(
          (event) => event.id === currentEventInfo.value.id
        )
        if (currentActiveEvent) {
          currentEventInfo.value = {
            ...currentEventInfo.value,
            canUpdate: currentActiveEvent.canUpdate,
          }
        }
        setOptions({
          data: props.data,
        })
      },
      {
        immediate: true,
      }
    )

    onMounted(() => {
      setOptions({
        theme: 'windows',
        themeVariant: 'light',
      })
      baseCalendarInstance.value = eventcalendar(`#${calendarId.value}`, {
        selectedDate: props.selectedDate,
        actionableEvents: false,
        showEventTooltip: false,
        firstDay: 1, // Monday
        view: {
          timeline: {
            type: props.type,
            weekNumbers: true,
            rowHeight: 'equal',
            startDay: 1, // Monday
            endDay: 0, // Sunday
          },
        },
        renderDay,
        renderScheduleEvent,
        renderResource,
        onPageLoaded,
        onEventClick,
      })
      eventPopup = popup(`#${popupId.value}`, {
        display: 'anchored',
        touchUi: false,
        showOverlay: false,
        contentPadding: false,
        closeOnOverlayClick: false,
        width: 355,
        scrollLock: true,
        showArrow: false,
        onPosition: (event: MbscEvent, inst: any) =>
          popupOnPosition(event, inst, TIMELINE_GRID_SCROLL_CLASS),
        onInit: popupOnInit,
      })
    })

    onUnmounted(() => {
      setOptions({
        resources: [],
        data: [],
      })
      if (baseCalendar.value) {
        removeTimelineGridScroll(
          baseCalendar.value,
          TIMELINE_GRID_SCROLL_CLASS,
          scrollHandler
        )
      }
      if (currentEventInfo.value.isOpen) {
        closePopup()
      }

      baseCalendarInstance.value.destroy()
      baseCalendarInstance.value = null

      eventPopup.destroy()
      eventPopup = null
    })

    return {
      calendarId,
      popupId,
      baseCalendar,
      currentEventInfo,
      eventPopup,
      baseCalendarInstance,

      renderDay,
      renderResource,
      renderScheduleEvent,
      onPageLoaded,
      scrollHandler,
      formattedDateString,
      showContent,
      getEventContent,
      closePopup,
      onEventClick,
      popupOnInit,
      handleEventPopupAction,
    }
  },
})
