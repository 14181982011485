<template>
  <b-upload v-bind="$attrs" v-on="$listeners">
    <base-button
      :type="$attrs.disabled ? 'is-filled' : 'is-filled-grey'"
      native-type="button"
      :size="$attrs.size"
      icon-right="file-clip"
      :disabled="$attrs.disabled"
      tag="a"
    />
  </b-upload>
</template>

<script>
import BUpload from 'buefy/src/components/upload/Upload'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'

export default {
  name: 'BaseUpload',
  components: {
    BUpload,
    BaseButton,
  },
}
</script>
