import { State, RecordGeneric } from './types'
import { logError } from '@/utils/helpers'
import { checkTypeExists, checkTypeWritable, restructureRecord } from './utils'
import { getRecordIndex, reindexSingle, reindexMultiple } from './indexing'

// replaces record by matching all fields given as `match` param. `replacement`
// can be `null`, which results in deleting matched record. If `match` object
// contains `id` param, this function searches for the record using index,
// which is faster
export function replaceRecord(
  state: State,
  {
    type,
    match,
    replacement,
  }: { type: RecordType; match: RecordGeneric; replacement: RecordGeneric }
) {
  try {
    checkTypeExists(state, type)
    checkTypeWritable(state, type)

    const index = getMatchIndex(state, type, match)

    if (index !== -1) {
      if (!replacement) {
        removeMatch(state, type, index)
      } else {
        replaceMatch(state, type, index, replacement)
      }
    }
  } catch (err) {
    logError(err)
  }
}

function getMatchIndex(
  state: State,
  type: RecordType,
  match: RecordGeneric
): number {
  // when `id` field is in match, we go shorter route
  if (typeof match.id !== 'undefined') {
    return getRecordIndex(state, type, match.id)
  } else {
    const stateRecords = <RecordGeneric[]>state.records[type]
    const matchKeys = Object.keys(match)

    return stateRecords.findIndex((currentItem) => {
      return matchKeys.every((key) => {
        return currentItem[key] === match[key]
      })
    })
  }
}

function removeMatch(state: State, type: RecordType, index: number) {
  const stateRecords = <RecordGeneric[]>state.records[type]
  stateRecords.splice(index, 1)
  reindexMultiple(state, type, index)
}

function replaceMatch(
  state: State,
  type: RecordType,
  index: number,
  replacement: RecordGeneric
) {
  const stateRecords = <RecordGeneric[]>state.records[type]
  const oldRecord = stateRecords[index]
  const newRecord = restructureRecord(replacement, state)

  stateRecords.splice(index, 1, newRecord)
  reindexSingle(state, type, oldRecord.id, null)
  reindexSingle(state, type, newRecord.id, index)
}
