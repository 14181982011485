<template>
  <div>
    <notification-list-dropdown
      v-if="!$isSmallDevice"
      :position="dropdownPosition"
      :notifications="notifications"
      class="lg:mr-6 md:mr-5"
      :is-open.sync="passedIsOpen"
      :is-loading="isLoading"
      :data-id-prefix="`${dataIdPrefix}.notification_list`"
      @delete-all="$emit('delete-all')"
      @delete="$emit('delete', $event)"
      @scroll-end="$emit('scroll-end')"
    />
    <notification-list-bottom-sheet
      v-else
      :notifications="notifications"
      :is-open.sync="passedIsOpen"
      :is-loading="isLoading"
      :data-id-prefix="`${dataIdPrefix}.notification-list-bottom-sheet`"
      @delete-all="$emit('delete-all')"
      @delete="$emit('delete', $event)"
      @scroll-end="$emit('scroll-end')"
    />
  </div>
</template>

<script>
import NotificationListDropdown from './NotificationListDropdown'
import NotificationListBottomSheet from './NotificationListBottomSheet'

export default {
  name: 'NotificationList',
  components: {
    NotificationListDropdown,
    NotificationListBottomSheet,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    notifications: {
      type: Array,
      required: true,
    },
    dropdownPosition: {
      type: String,
      default: 'is-bottom-left',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    passedIsOpen: {
      get() {
        return this.isOpen
      },
      set(isOpen) {
        this.$emit('update:isOpen', isOpen)
      },
    },
  },
}
</script>
