<template>
  <base-icon :icon="displayedIcon" v-bind="$attrs" type="is-filled" />
</template>

<script>
import BaseIcon from '../icon/BaseIcon'

export default {
  name: 'BaseLogo',
  components: {
    BaseIcon,
  },
  props: {
    onlyEmblem: {
      type: Boolean,
      default: false,
    },
    onlyLettering: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayedIcon() {
      return this.onlyEmblem
        ? 'logo-emblem/logo-emblem'
        : this.onlyLettering
        ? 'logo-typography/logo-typography'
        : 'logo/logo'
    },
  },
}
</script>
