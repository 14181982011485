<template>
  <transition appear name="bottom-slide" :duration="300">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'BottomSlideTransition',
}
</script>

<style lang="scss" scoped>
::v-deep.bottom-slide {
  &-enter-active,
  &-leave-active {
    .overlay,
    .bottom-sheet-content {
      @apply transition-all duration-300;
    }
  }

  &-enter,
  &-leave-to {
    .overlay {
      @apply opacity-0;
    }
    .bottom-sheet-content {
      bottom: -100%;
    }
  }
  &-enter-to,
  &-leave {
    .overlay {
      @apply opacity-100;
    }
    .bottom-sheet-content {
      @apply bottom-0;
    }
  }
}
</style>
