<template>
  <base-filter
    :field="field"
    :label="label"
    :serialized-value="serializedValue"
    :available-fields="availableFields"
    :invalid="invalid"
    :display-value="displayValue"
    :default-value="defaultValue"
    @apply="apply($event)"
    @replace="$emit('replace', $event)"
    @remove="$emit('remove', $event)"
    @close="resetForm()"
  >
    <template v-slot:form>
      <div class="flex flex-col mb-3">
        <div class="label">
          <span>
            {{ $t('filterbar.fields.start_time.label') }}
          </span>
        </div>
        <base-dropdown class="filter-start-time mb-2">
          <template v-slot:trigger>
            <base-input-field
              :value="displayStartTime"
              :placeholder="$t('filterbar.fields.start_time.label')"
              :name="$t('filterbar.fields.start_time.name')"
              type="text"
              icon-right="arrow"
              :size="$isSmallDevice ? 'is-large' : 'is-small'"
              readonly
            >
            </base-input-field>
          </template>
          <base-dropdown-text-item
            v-for="availableOperator in availableOperators"
            :key="availableOperator.id"
            :value="availableOperator.id"
            @click="handleStartTime(availableOperator)"
          >
            {{ runOrReturn(availableOperator.label) }}
          </base-dropdown-text-item>
        </base-dropdown>

        <component
          :is="startTimeComponent"
          v-if="form.startTimeOperator !== 'not_set'"
          v-model="form.startTimeQuery"
          :size="$isSmallDevice ? 'is-large' : 'is-small'"
          :errors="[]"
        />
      </div>

      <div class="flex flex-col mb-3">
        <div class="label">
          <span>
            {{ $t('filterbar.fields.end_time.label') }}
          </span>
        </div>
        <base-dropdown class="filter-end-time mb-2">
          <template v-slot:trigger>
            <base-input-field
              :value="displayEndTime"
              :placeholder="$t('filterbar.fields.end_time.label')"
              :name="$t('filterbar.fields.end_time.name')"
              type="text"
              icon-right="arrow"
              :size="$isSmallDevice ? 'is-large' : 'is-small'"
              readonly
            >
            </base-input-field>
          </template>
          <base-dropdown-text-item
            v-for="availableOperator in availableOperators"
            :key="availableOperator.id"
            :value="availableOperator.id"
            @click="handleEndTime(availableOperator)"
          >
            {{ runOrReturn(availableOperator.label) }}
          </base-dropdown-text-item>
        </base-dropdown>

        <component
          :is="endTimeComponent"
          v-if="form.endTimeOperator !== 'not_set'"
          v-model="form.endTimeQuery"
          :size="$isSmallDevice ? 'is-large' : 'is-small'"
          :errors="[]"
        />
      </div>
    </template>
  </base-filter>
</template>

<script>
import BaseFilter from './BaseFilter'

import BaseSelect from '../select/BaseSelect'
import BaseTimePicker from '../time-picker/BaseTimePicker'
import BaseTimeRangePicker from '../time-range-picker/BaseTimeRangePicker'
import {
  formatTimeFilter,
  formatTimeRangeFilter,
} from '../../../../utils/date-time.utils'
import { timeFilter } from '@/utils/filterbar.utils'
import { runOrReturn } from '@/utils/helpers'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'

export default {
  name: 'BaseFilterTime',
  components: {
    BaseDropdownTextItem,
    BaseInputField,
    BaseDropdown,
    BaseFilter,
    BaseSelect,
    BaseTimePicker,
    BaseTimeRangePicker,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    availableFields: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        startTimeOperator: 'not_set',
        endTimeOperator: 'not_set',
        endTimeDisplayValue: null,
        startTimeDisplayValue: null,
        startTimeQuery: {
          hours: 9,
          minutes: 0,
        },
        endTimeQuery: {
          hours: 17,
          minutes: 0,
        },
        ...this.defaultValue?.form,
      },
      availableOperators: timeFilter.operators,
      appliedValue: null,
    }
  },
  computed: {
    filterParam() {
      const {
        startTimeQuery,
        endTimeQuery,
        startTimeOperator,
        endTimeOperator,
      } = this.form

      const filterStack = {}

      if (startTimeOperator !== 'not_set') {
        if (startTimeOperator === 'between') {
          filterStack['with_start_time'] = formatTimeRangeFilter(startTimeQuery)
        } else {
          filterStack[
            'with_start_time'
          ] = `${startTimeOperator}_${formatTimeFilter(startTimeQuery)}`
        }
      }

      if (endTimeOperator !== 'not_set') {
        if (endTimeOperator === 'between') {
          filterStack['with_end_time'] = formatTimeRangeFilter(endTimeQuery)
        } else {
          filterStack['with_end_time'] = `${endTimeOperator}_${formatTimeFilter(
            endTimeQuery
          )}`
        }
      }

      return filterStack
    },
    serializedValue() {
      return {
        field: this.field,
        form: this.form,
        filter: this.filterParam,
      }
    },
    invalid() {
      const {
        startTimeOperator,
        endTimeOperator,
        startTimeQuery,
        endTimeQuery,
      } = this.form

      if (startTimeOperator !== 'not_set') {
        return Object.keys(startTimeQuery).length === 0
      }

      if (endTimeOperator !== 'not_set') {
        return Object.keys(endTimeQuery).length === 0
      }

      return true
    },
    displayValue() {
      return timeFilter.getDisplayedValue(this.appliedValue)
    },
    displayStartTime() {
      return this.form.startTimeDisplayValue
        ? this.form.startTimeDisplayValue
        : this.$t('filter_operators.not_set')
    },
    displayEndTime() {
      return this.form.endTimeDisplayValue
        ? this.form.endTimeDisplayValue
        : this.$t('filter_operators.not_set')
    },
    startTimeComponent() {
      return this.form.startTimeOperator === 'between'
        ? 'base-time-range-picker'
        : 'base-time-picker'
    },
    endTimeComponent() {
      return this.form.endTimeOperator === 'between'
        ? 'base-time-range-picker'
        : 'base-time-picker'
    },
  },
  watch: {
    'form.startTimeOperator': timeFilter.switchOperator('startTimeQuery'),
    'form.endTimeOperator': timeFilter.switchOperator('endTimeQuery'),
  },
  methods: {
    handleStartTime(args) {
      this.form.startTimeOperator = args.id
      this.form.startTimeDisplayValue = this.runOrReturn(args.label)
    },
    handleEndTime(args) {
      this.form.endTimeOperator = args.id
      this.form.endTimeDisplayValue = this.runOrReturn(args.label)
    },
    runOrReturn,
    resetForm() {
      if (this.appliedValue) {
        this.form = { ...this.appliedValue.form }
      }

      this.$emit('close')
    },
    apply(applied) {
      this.appliedValue = this.serializedValue

      if (applied.emit) {
        this.$emit('apply', this.appliedValue)
      }
    },
  },
}
</script>

<style scoped>
.filter-start-time,
.filter-end-time {
  @apply block;
}
.filter-start-time >>> .dropdown-menu {
  @apply w-full;
}
.filter-end-time >>> .dropdown-menu {
  @apply w-full;
}
.filter-start-time >>> .control svg {
  @apply transform rotate-90;
}
.filter-end-time >>> .control svg {
  @apply transform rotate-90;
}
.filter-start-time.is-active >>> .control svg {
  @apply transform -rotate-90;
}
.filter-end-time.is-active >>> .control svg {
  @apply transform -rotate-90;
}
</style>
