<template>
  <div
    :class="[
      'cursor-pointer py-1',
      {
        'text-coal-850': !dark && active,
        'text-coal-450 hover:text-coal-850': !dark,
        'text-white': dark,
      },
    ]"
  >
    <div
      class="relative"
      role="button"
      :aria-label="$t('topbar.notifications.toggle_list')"
    >
      <base-icon icon="bell-notifications/bell-notifications-24" :size="size" />
      <div v-if="count" class="absolute bottom-1/2 left-3">
        <div class="rounded bg-white leading-none">
          <base-badge
            :title="`${count}`"
            class="bg-white md:bg-error-100"
            type="is-danger"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge'

export default {
  name: 'NotificationListTrigger',
  components: {
    BaseIcon,
    BaseBadge,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '1.5rem',
    },
  },
  computed: {
    ...mapState({
      count: (state) => {
        const totalCount = state.notification.meta.total_count
        return totalCount < 1000 ? totalCount : '999+'
      },
    }),
  },
}
</script>
