import BaseRequest from '@/utils/api/base-request'
import User, { USER_TYPE } from './user.model'

import { USER_ENDPOINT_GET_CURRENT_USER } from '../network/user.endpoints'
import { extractIncluded } from '@/utils/jsonapi/utils'

const INCLUDE_PARAMS = 'account,workspaces'

export class GetCurrentUserRequest extends BaseRequest {
  constructor() {
    super()
    super.url = USER_ENDPOINT_GET_CURRENT_USER
    super.method = 'get'
    super.type = USER_TYPE
    super.include = INCLUDE_PARAMS
    super.companyToken = true
  }
}

export class GetCurrentUserResponse {
  constructor(response) {
    const { account, workspace } = extractIncluded(response.data.included)
    this.user = new User(response.data.data, account[0], workspace)
  }
}
