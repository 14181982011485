export const HTTP_HEADER_CLIENT = 'papershift-web'
export const BASE_WEB_URL = 'https://papershift.com' // no trailing slash!
export const ORIG_APP_URL = 'https://app.papershift.com' // no trailing slash!
export const PRE_RELEASE_APP_URL = 'https://pre-release.papershift.com' // no trailing slash!
export const STAGING_APP_URL = 'https://staging.papershift.com' // no trailing slash!
export const CONTACT_DEMO_URL = 'https://www.papershift.com/demo'
export const URL_TERMS_CONDITIONS =
  'https://www.papershift.com/en/terms-and-conditions'
export const URL_DATA_PRIVACY = 'https://www.papershift.com/en/privacy-policy'
export const APP_LANG = process.env.VUE_APP_I18N_LOCALE
export const APP_LANG_FALLBACK = process.env.VUE_APP_I18N_FALLBACK_LOCALE

export const NOTE_TYPES = {
  SYSTEM: 'SystemNote',
  USER: 'UserNote',
}

export const TIME_FORMAT_TYPE = {
  DECIMAL: 'decimal',
  STANDARD: 'standard',
}

export const DEFAULT_SEARCH_DEBOUNCE_TIME = 500

export const IS_PRODUCTION_OR_STAGING_ENV =
  process.env.VUE_APP_MODE === 'production' ||
  process.env.VUE_APP_MODE === 'staging'

export const GET_USER_ENV =
  process.env.VUE_APP_MODE === 'production' ? 'app' : 'staging'

export const CHECKIN_MODULE_NAME = 'UserCheckin'

export const DATE_FORMAT = 'EEEEEE, dd.MM.yy'

export const DATE_FORMAT_DAY_MONTH = 'EEEEEE, dd.MM.'

export const ROUTE_NAMES = {
  TIME_TRACKING: 'TimeTracking',
  ABSENCES: 'Absence',
  WORKSPACE_USER: 'WorkspaceUser',
  SHIFT_CALENDAR: 'ShiftsCalendar',
  ABSENCES_CALENDAR: 'AbsencesCalendar',
}
