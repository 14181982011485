<template>
  <div v-if="isCurrentUserAdmin">
    <user-menu-dropdown-item
      data-id="topbar.account.switch_admin"
      icon="switch/switch-16"
      :label="
        isAssumedRoleAdmin
          ? $t('topbar.account.switch_personal')
          : $t('topbar.account.switch_admin')
      "
      @click.native="switchRole()"
    />

    <hr class="dropdown-divider" />
  </div>
</template>

<script>
import UserMenuDropdownItem from './UserMenuDropdownItem'

import { mapGetters, mapActions } from 'vuex'

import {
  USER_GETTER_IS_ROLE_ADMIN,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
} from '../../store/getters/user.getters.names'
import { USER_ACTION_SWITCH_ROLE } from '../../store/actions/user.actions.names'

export default {
  name: 'UserMenuDropdownRoleSwitch',
  components: {
    UserMenuDropdownItem,
  },
  computed: {
    ...mapGetters({
      isCurrentUserAdmin: USER_GETTER_IS_ROLE_ADMIN,
      isAssumedRoleAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
  },
  methods: {
    ...mapActions({
      actionSwitchRole: USER_ACTION_SWITCH_ROLE,
    }),
    async switchRole() {
      await this.actionSwitchRole()
    },
  },
}
</script>
