import { AxiosRequestConfig, Method } from 'axios'

export interface BaseRequestConfig extends AxiosRequestConfig {
  mockOption?: string
}
export interface BaseRequestPagination {
  items: number
  page: number
}
export interface BaseRequestPermissions {
  update: boolean
  destroy: boolean
  update_account_admin_role?: boolean
  activate_deactivate?: boolean
}
export interface BaseRequestSorting {
  [key: string]: -1 | 0 | 1
}

interface BaseRequestParams {
  permissions?: string
  include?: string
  sort?: string
  // pagination
  items?: number
  page?: number
  fields?: string
  company_token?: boolean
  // mainly used for postman mocks, where `mockOption` param is used to control mock
  // response code and body from postman mock servers
  mock_option?: string
}

// TODO: document usage
export default class BaseRequest implements BaseRequestConfig {
  #url = ''
  #type = ''
  #method: Method = 'get'
  #data = {}
  #params: BaseRequestParams = {}

  get url() {
    return this.#url
  }
  set url(value: string) {
    this.#url = value
  }

  set type(value: string) {
    this.#type = value
  }

  get method() {
    return this.#method
  }
  set method(value: Method) {
    this.#method = value
  }

  get data() {
    return this.#data
  }

  set attributes(obj: Record<string, any>) {
    this.#data = {
      data: {
        type: this.#type,
        attributes: obj,
      },
    }
  }

  set data(obj: Record<string, unknown> | FormData) {
    this.#data = obj
  }

  get params() {
    return filterObject(this.#params)
  }

  set pagination({ items, page }: BaseRequestPagination) {
    this.#params.items = items
    this.#params.page = page
  }

  set permissions(permissions: BaseRequestPermissions) {
    const keys = Object.keys(permissions) as Array<keyof BaseRequestPermissions>
    this.#params.permissions = keys.filter((key) => permissions[key]).join(',')
  }

  set include(value: string) {
    this.#params.include = value
  }

  set sorting(obj: BaseRequestSorting) {
    this.#params.sort = Object.keys(obj)
      .filter((key) => obj[key])
      .map((key) => {
        return obj[key] > 0 ? key : `-${key}`
      })
      .join(',')
  }

  set filter(obj: Record<string, any>) {
    this.#params = {
      ...this.#params,
      ...applyObjectParams(obj, 'filter'),
    }
  }

  set fields(obj: Record<string, any>) {
    this.#params = {
      ...this.#params,
      ...applyObjectParams(obj, 'fields'),
    }
  }

  set companyToken(included: boolean) {
    this.#params.company_token = included
  }

  // mainly used for postman mocks, where `mockOption` param is used to control mock
  // response code and body from postman mock servers
  set mockOption(value: string) {
    this.#params.mock_option = value
  }
}

function isValueEmpty(value: any) {
  return value === '' || value == null
}

// filter out empty-string and nullish params from given object
export function filterObject(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, val]) => !isValueEmpty(val))
  )
}

// handles array to string conversion and filtering out empty values
export function formatValue(value: any) {
  if (Array.isArray(value)) {
    const arr = []

    for (const item of value) {
      if (isValueEmpty(item)) {
        continue
      }
      if (typeof item === 'object') {
        if (!isValueEmpty(item.id)) {
          arr.push(item.id)
        }
      } else {
        arr.push(item)
      }
    }

    return arr.join()
  }
  return value
}

export function applyObjectParams(obj: Record<string, any>, param: string) {
  return Object.keys(obj).reduce((acc: Record<string, any>, key: string) => {
    const value = formatValue(obj[key])

    if (!value) {
      return acc
    }

    acc[`${param}[${key}]`] = value
    return acc
  }, {})
}
