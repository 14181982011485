import api from '../../network/workspace.api'

import { shortname } from '@/utils/store.js'

import * as actionNamesObj from './workspace.actions.names'
import * as mutationNamesObj from '../mutations/workspace.mutations.names'

import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import i18n from '@/utils/vendors/i18n'
import VueCookies from '@/utils/vendors/vue-cookies'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.WORKSPACE_ACTION_GET_USER_WORKSPACE]: async (
    { commit },
    workspaces
  ) => {
    const favouriteWorkspace = workspaces.find(
      (workspace) => workspace.attributes.is_favourite
    )
    const savedWorkspace = workspaces.find(
      (workspace) =>
        workspace.attributes.token === VueCookies.get('company_token')
    )

    if (VueCookies.get('company_token') && savedWorkspace) {
      return commit(
        mutationNames.WORKSPACE_MUTATION_SET_WORKSPACE,
        savedWorkspace
      )
    }

    if (favouriteWorkspace) {
      return commit(
        mutationNames.WORKSPACE_MUTATION_SET_WORKSPACE,
        favouriteWorkspace
      )
    }

    return commit(mutationNames.WORKSPACE_MUTATION_SET_WORKSPACE, workspaces[0])
  },

  [actionNames.WORKSPACE_ACTION_UPDATE_WORKSPACE]: async (
    { state, commit },
    { name }
  ) => {
    const workspace = await api.updateWorkspace(state.workspace.id, name)
    commit(mutationNames.WORKSPACE_MUTATION_SET_WORKSPACE, workspace)
  },

  [actionNames.WORKSPACE_ACTION_SWITCH_WORKSPACE]: async (
    { commit },
    workspace
  ) => {
    commit(mutationNames.WORKSPACE_MUTATION_SET_WORKSPACE, workspace)
    Snackbar.openAfterReload(
      i18n.t('location_switch.success', {
        location: workspace.attributes.name,
      })
    )
    location.reload()
  },
}
