import BaseRequest from '@/utils/api/base-request'
import { generateUrlAndFilter } from './employee.models.utils'

export const EMPLOYEE_TYPE = 'user'

export interface EmployeeSearchRequestParams {
  workspaceId: RecordId
  page: number
  query: string
  filter: Record<string, any>
}

export interface EmployeeSearchResponseItem {
  id: RecordId
  type: string
  attributes: {
    username: string
  }
}

export interface EmployeeSearchResponse {
  data: {
    data: EmployeeSearchResponseItem[]
    meta: {
      total_pages: number
    }
  }
}

export class SearchEmployeesResponse {
  results
  totalPages

  constructor(response: EmployeeSearchResponse) {
    this.results = response.data.data.map(
      (item: EmployeeSearchResponseItem) => {
        return {
          id: item.id,
          type: item.type,
          username: item.attributes.username,
        }
      }
    )
    this.totalPages = response.data.meta.total_pages
  }
}

export class SearchEmployeesRequest extends BaseRequest {
  constructor({
    workspaceId,
    page,
    query,
    filter,
  }: EmployeeSearchRequestParams) {
    super()
    super.type = EMPLOYEE_TYPE
    super.method = 'get'
    super.permissions = {
      update: true,
      destroy: true,
    }
    super.sorting = {
      username: 1,
    }

    super.pagination = {
      page,
      items: 30,
    }

    const { url, filter: filterObject } = generateUrlAndFilter({
      workspaceId,
      query,
      filter,
    })
    super.url = url

    if (filterObject) {
      super.filter = filterObject
    }
  }
}
