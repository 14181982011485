<template>
  <div
    class="px-8 border-t border-coal-150 h-18 flex items-center flex-shrink-0"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCardModalFooterWrapper',
}
</script>
