import { getDateTimeLocaleModule } from '@/utils/date-time.utils'
import { isSameDay, isSameYear, format } from 'date-fns'
import VueI18n from '@/utils/vendors/i18n'
import { runOrReturn } from '@/utils/helpers'

export default {
  data() {
    const now = new Date()

    const customFocusedDate = this.defaultValue ? this.defaultValue[0] : now

    const shortcuts = this.getShortcuts(this.customCurrentDate)

    return {
      shortcuts: this.generateShortcutsWithLabels(shortcuts),
      shortcutSelected: this.findMatchingShortcut(shortcuts, this.defaultValue),
      customFocusedDate,
    }
  },
  computed: {
    shortcutSelectedName() {
      if (this.shortcutSelected) {
        if (this.shortcutSelected === 'custom') {
          return this.$t('daterangepicker.custom')
        }

        const shortcutName =
          this.shortcuts.find(
            (shortcut) => shortcut.id === this.shortcutSelected
          )?.name || ''

        return runOrReturn(shortcutName)
      }

      return ''
    },
    locale() {
      return VueI18n.locale
    },
  },
  methods: {
    formatDate(date) {
      return format(date, 'EEEEEE, dd.MM.yy', {
        locale: getDateTimeLocaleModule(),
      })
    },
    formatRange(range) {
      return isSameDay(range[0], range[1])
        ? this.formatDate(range[0])
        : isSameYear(range[0], range[1])
        ? `${this.formatDate(range[0])} \u2014 ${this.formatDate(range[1])}`
        : `${this.formatDate(range[0], true)} \u2014 ${this.formatDate(
            range[1],
            true
          )}`
    },
    findMatchingShortcut(shortcuts, dateRange) {
      if (!dateRange) {
        return 'all_time'
      }

      const matchingShortcut = shortcuts.find(
        (shortcut) =>
          shortcut.range &&
          isSameDay(shortcut.range[0], dateRange[0]) &&
          isSameDay(shortcut.range[1], dateRange[1])
      )

      if (matchingShortcut) {
        return matchingShortcut.id
      } else {
        return 'custom'
      }
    },
    generateShortcutsWithLabels(shortcuts) {
      return shortcuts.map((shortcut) => ({
        ...shortcut,
        label: shortcut.range
          ? `${this.formatRange(shortcut.range)}`
          : shortcut.id === 'custom'
          ? this.$t('daterangepicker.label_select_date')
          : null,
        name: runOrReturn(shortcut.name),
      }))
    },
  },
  watch: {
    locale() {
      this.shortcuts = this.generateShortcutsWithLabels(
        this.getShortcuts(this.customCurrentDate)
      )
    },
  },
  // TODO: using setTimeout to reload getShortcuts is a hack.
  // the created method should be remove when localeChanged triggers getShortcuts.
  // created() {
  //   setTimeout(() => {
  //     this.shortcuts = this.generateShortcutsWithLabels(
  //       this.getShortcuts(this.customCurrentDate)
  //     )
  //   }, 3000)
  // },
}
