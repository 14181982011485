import state from './auth.state'
import mutations from './mutations/auth.mutations'
import actions from './actions/auth.actions'
import getters from './getters/auth.getters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
