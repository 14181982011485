


























import { defineComponent } from '@vue/composition-api'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'

export default defineComponent({
  name: 'AppSidebarFooterItemsSummaryItem',
  components: { BaseTooltip, BaseIcon },
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      default: 'is-stroke',
    },
    label: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },
})
