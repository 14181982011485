import * as Sentry from '@sentry/vue'
import '@sentry/tracing'

interface Params {
  name: string
  callback: () => Promise<void>
  op: string
  description?: string
  data?: Record<string, unknown>
}

const useTransaction =
  ({ name, callback, ...spanProps }: Params) =>
  async () => {
    const transaction = Sentry.startTransaction({ name })
    const spanTransaction = transaction.startChild(spanProps)

    await callback()

    spanTransaction.finish()
    transaction.finish()
  }

export default useTransaction
