<template>
  <img :src="flag" :class="sizeClass" :alt="countryCode" />
</template>

<script>
export default {
  name: 'BaseFlag',
  props: {
    countryCode: {
      type: String,
      validator: (code) =>
        ['uk', 'us', 'ch', 'es', 'nl', 'de'].indexOf(code) > -1,
      required: true,
    },
    size: {
      type: String,
      validator: (size) => ['is-small'].indexOf(size) > -1,
      default: 'is-small',
    },
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case 'is-small':
        default:
          return 'w-4 h-4'
      }
    },
    flag() {
      switch (this.countryCode) {
        case 'uk':
          return require('@/assets/img/flags/Flag-UK.png')
        case 'us':
          return require('@/assets/img/flags/Flag-USA.png')
        case 'ch':
          return require('@/assets/img/flags/Flag-Switzerland.png')
        case 'es':
          return require('@/assets/img/flags/Flag-Spain.png')
        case 'nl':
          return require('@/assets/img/flags/Flag-Netherlands.png')
        default:
        case 'de':
          return require('@/assets/img/flags/Flag-Germany.png')
      }
    },
  },
}
</script>
