import { shortname } from '@/utils/store'
import * as mutations from './summary.mutations.names'

const mutationNames = shortname(mutations)

export default {
  [mutationNames.SUMMARY_MUTATION_SET_SUMMARY]: (
    state,
    { resource, summary }
  ) => {
    state.summaries = {
      ...state.summaries,
      [resource]: summary,
    }
  },
}
