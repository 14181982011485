export const ACTION_STATUS_MUTATION_WHITELIST_ACTIONS =
  'actionStatus/MUTATION_WHITELIST_ACTIONS'
export const ACTION_STATUS_MUTATION_TRACK_ACTION =
  'actionStatus/MUTATION_TRACK_ACTION'
export const ACTION_STATUS_MUTATION_REMOVE_ACTIONS =
  'actionStatus/MUTATION_REMOVE_ACTIONS'
export const ACTION_STATUS_MUTATION_SET_ACTION_OK =
  'actionStatus/MUTATION_SET_ACTION_OK'
export const ACTION_STATUS_MUTATION_SET_ACTION_OK_RESPONSE =
  'actionStatus/MUTATION_SET_ACTION_OK_RESPONSE'
export const ACTION_STATUS_MUTATION_SET_ACTION_FAILED =
  'actionStatus/MUTATION_SET_ACTION_FAILED'
