<template>
  <portal v-if="isOpen" to="bottom-sheet">
    <bottom-sheet-form-wrapper
      :header-right-actions="bottomSheetActions"
      @done="$emit('create-tag')"
      @close="$emit('close')"
    >
      <div class="p-4">
        <slot />
      </div>
    </bottom-sheet-form-wrapper>
  </portal>
</template>

<script>
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'

export default {
  name: 'TagBottomSheetFormCreate',
  components: {
    BottomSheetFormWrapper,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bottomSheetActions() {
      return [
        {
          title: this.$t('tag.form_create.button_submit'),
          loading: this.isLoading,
          class: 'justify-self-end text-green',
          event: 'create',
        },
      ]
    },
  },
}
</script>
