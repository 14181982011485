<template>
  <buefy-radio :size="size" v-bind="$attrs" v-on="$listeners">
    <slot />
  </buefy-radio>
</template>

<script>
import BuefyRadio from 'buefy/src/components/radio/Radio'

export default {
  name: 'BaseRadio',
  components: { 'buefy-radio': BuefyRadio },
  props: {
    size: {
      type: String,
      validator: (value) => ['is-small', 'is-medium'].indexOf(value) !== -1,
      default: 'is-medium',
    },
  },
}
</script>
