import state from './action-status.state'
import mutations from './mutations/action-status.mutations'
import getters from './getters/action-status.getters'

export * from './mutations/action-status.mutations.names.js'
export * from './getters/action-status.getters.names.js'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
