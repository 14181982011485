import axios from '@/utils/vendors/axios'
import { HTTP_HEADER_CLIENT } from '@/constants'

import { SignInRequest, SignInResponse } from '../models/auth.model'
import {
  RegisterRequest,
  RegisterResponse,
} from '../models/auth-register.model'
import {
  RegisterCompanyRequest,
  RegisterCompanyResponse,
} from '../models/auth-register-company.model'

import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
} from '../models/auth-forgot-password.model'

import {
  ChangePasswordRequest,
  ChangePasswordResponse,
} from '../models/auth-change-password.model'

import {
  ValidatePasswordTokenResponse,
  ValidatePasswordTokenRequest,
} from '../models/auth-validate-password-token.model'

import {
  CheckinCreateUserRequest,
  CheckinCreateUserResponse,
} from '../models/auth-checkin-create-user.model'

import {
  CheckinUpdateUserRequest,
  CheckinUpdateUserResponse,
} from '../models/auth-checkin-update-user.model'

import { GetCompanyIndustriesResponse } from '../models/get-company-industries.model'

import {
  AUTH_ENDPOINT_SIGN_IN,
  AUTH_ENDPOINT_SIGN_OUT,
  AUTH_ENDPOINT_REGISTER,
  AUTH_ENDPOINT_REGISTER_COMPANY,
  AUTH_ENDPOINT_FORGOT_PASSWORD,
  AUTH_ENDPOINT_CHANGE_PASSWORD,
  AUTH_ENDPOINT_VALIDATE_PASSWORD_TOKEN,
  AUTH_ENDPOINT_COMPANY_INDUSTRIES,
  AUTH_CHECKIN_ENDPOINT_LIST_INVITED,
  AUTH_CHECKIN_ENDPOINT_GET_WELCOME_MESSAGE,
  AUTH_CHECKIN_ENDPOINT_CREATE_USER,
  AUTH_CHECKIN_ENDPOINT_GET_INVITED_USER,
  AUTH_CHECKIN_ENDPOINT_UPDATE_USER,
} from './auth.endpoints'

import { generateAPIKey } from '@/utils/vendors/api-key'

export default {
  /**
   * @param {Object} form
   * @param {string} form.email - The email of the user.
   * @param {string} form.password - The password associated with the email.
   * @returns {Promise<SignInResponse>} response
   */
  async signIn(form) {
    const apiKey = await generateAPIKey(new Date())

    const response = await axios.post(
      AUTH_ENDPOINT_SIGN_IN,
      new SignInRequest(form),
      {
        withCredentials: true,
        requestName: 'sign-in',
        headers: {
          'API-Key': apiKey,
          Client: 'papershift-web',
        },
      }
    )

    return new SignInResponse(response)
  },

  signOut() {
    return axios.delete(AUTH_ENDPOINT_SIGN_OUT)
  },

  /**
   * @param {Object} form
   * @param {string} form.username - The name of the user.
   * @param {string} form.email - The email of the user.
   * @param {string} form.password - The password associated with the email.
   * @returns {Promise<RegisterResponse>} response
   */
  async register(form) {
    const response = await axios.post(
      AUTH_ENDPOINT_REGISTER,
      new RegisterRequest(form)
    )

    return new RegisterResponse(response)
  },

  /**
   * @param {Object} form
   * @param {string} form.accountId - The associated account of the user.
   * @param {string} form.data.companyName - The name of the user's company.
   * @param {string} form.data.companyPhone - The phone number of the user's company.
   * @param {string} form.data.companyIndustry - The industry of the user's company
   * @param {string} form.data.companySize - The size of the user's company
   * @returns {Promise<RegisterCompanyResponse>} response
   */
  async registerCompany(form) {
    const response = await axios.patch(
      AUTH_ENDPOINT_REGISTER_COMPANY(form.accountId),
      new RegisterCompanyRequest(form.data)
    )

    return new RegisterCompanyResponse(response)
  },

  /**
   * @param {Object} form
   * @param {string} form.email - The email of the user.
   * @returns {Promise<ForgotPasswordResponse>} response
   */
  async restorePassword(form) {
    const response = await axios.post(
      AUTH_ENDPOINT_FORGOT_PASSWORD,
      new ForgotPasswordRequest(form)
    )

    return new ForgotPasswordResponse(response)
  },

  /**
   * @param {Object} form
   * @param {string} form.token - The password reset token.
   * @param {string} form.password - The new password.
   * @returns {Promise<ChangePasswordResponse>} response
   */
  async changePassword(form) {
    const response = await axios.put(
      AUTH_ENDPOINT_CHANGE_PASSWORD,
      new ChangePasswordRequest(form)
    )

    return new ChangePasswordResponse(response)
  },

  /**
   * @param {Object} form
   * @param {string} form.token - The password reset token.
   * @returns {Promise<ValidatePasswordTokenResponse>} response
   */
  async validatePasswordToken(form) {
    const response = await axios.post(
      AUTH_ENDPOINT_VALIDATE_PASSWORD_TOKEN,
      new ValidatePasswordTokenRequest(form)
    )

    return new ValidatePasswordTokenResponse(response)
  },

  /**
   * @returns {Promise<GetCompanyIndustriesResponse>} response
   */
  async getCompanyIndustries() {
    const response = await axios.get(AUTH_ENDPOINT_COMPANY_INDUSTRIES)

    return new GetCompanyIndustriesResponse(response)
  },

  async listInvitedUsers(inviteToken, page) {
    const apiKey = await generateAPIKey(new Date())

    return axios.get(AUTH_CHECKIN_ENDPOINT_LIST_INVITED(inviteToken), {
      headers: {
        'API-Key': apiKey,
        Client: HTTP_HEADER_CLIENT,
      },
      params: {
        'filter[email]': 'present:false',
        page,
      },
    })
  },

  async getCheckinDetails(inviteToken) {
    const apiKey = await generateAPIKey(new Date())

    return axios.get(AUTH_CHECKIN_ENDPOINT_GET_WELCOME_MESSAGE(inviteToken), {
      headers: {
        'API-Key': apiKey,
        Client: HTTP_HEADER_CLIENT,
      },
    })
  },

  async createUserViaCheckin(user) {
    const apiKey = await generateAPIKey(new Date())

    const response = await axios.post(
      AUTH_CHECKIN_ENDPOINT_CREATE_USER,
      new CheckinCreateUserRequest(user),
      {
        headers: {
          'API-Key': apiKey,
          Client: HTTP_HEADER_CLIENT,
        },
      }
    )

    return new CheckinCreateUserResponse(response)
  },

  async getInvitedUser(inviteToken) {
    const apiKey = await generateAPIKey(new Date())

    return axios.get(AUTH_CHECKIN_ENDPOINT_GET_INVITED_USER(inviteToken), {
      headers: {
        'API-Key': apiKey,
        Client: HTTP_HEADER_CLIENT,
      },
    })
  },

  async updateUserViaCheckin(user) {
    const apiKey = await generateAPIKey(new Date())

    const response = await axios.patch(
      AUTH_CHECKIN_ENDPOINT_UPDATE_USER,
      new CheckinUpdateUserRequest(user),
      {
        headers: {
          'API-Key': apiKey,
          Client: HTTP_HEADER_CLIENT,
        },
      }
    )

    return new CheckinUpdateUserResponse(response)
  },
}
