<template>
  <base-tooltip :label="tooltipLabel" :active="!$isSmallDevice" class="w-full">
    <!-- eslint does not detect dynamic attributes -->
    <!-- eslint-disable-next-line vue/require-component-is -->
    <component v-bind="linkAttrs">
      <div
        class="flex justify-between items-center pl-4 py-4 pr-3 rounded-md cursor-pointer sidebar-menu-item md:h-12 md:p-0 md:justify-center"
        :class="[showNotifications ? 'md:w-16' : 'md:w-12']"
        @mouseover="toggleHoverIcon(true)"
        @mouseleave="toggleHoverIcon(false)"
        @click="$emit('menu-item-click')"
      >
        <div class="flex flex-1 md:flex-initial items-center">
          <base-icon
            class="mr-5"
            :class="[showNotifications ? 'md:mr-1' : 'md:mr-0']"
            :icon="icon"
            :icon-hover="iconHover"
            :size="iconSize"
            :show-icon-hover="showIconHover"
            :type="iconType"
            :icon-hover-type="iconHoverType"
          />
          <span class="md:hidden text-2xl font-semibold sidebar-item-label">
            {{ itemLabel }}
          </span>
        </div>
        <div
          v-if="showNotifications"
          class="flex flex-row justify-between md:flex-col"
        >
          <app-sidebar-notification
            v-if="showNotificationNegative"
            :label="notificationNegative"
            type="negative"
          />
          <app-sidebar-notification
            v-if="showNotificationPositive"
            :label="notificationPositive"
            type="positive"
          />
        </div>
      </div>
    </component>
  </base-tooltip>
</template>

<script>
import AppSidebarNotification from '@/app/util-modules/ui/app-sidebar/AppSidebarNotification'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip'
import { runOrReturn } from '@/utils/helpers'

export default {
  name: 'AppSidebarMenuListItemContent',
  components: {
    AppSidebarNotification,
    BaseTooltip,
    BaseIcon,
  },
  props: {
    tooltip: {
      type: [String, Function],
      default: undefined,
    },
    label: {
      type: [String, Function],
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    iconHover: {
      type: String,
      default: undefined,
    },
    iconSize: {
      type: String,
      default: '1.625rem',
    },
    iconType: {
      type: String,
      default: 'is-stroke',
    },
    iconHoverType: {
      type: String,
      default: 'is-stroke',
    },
    notificationNegative: {
      type: String,
      default: undefined,
    },
    notificationPositive: {
      type: String,
      default: undefined,
    },
    route: {
      type: [String, Object],
      default: undefined,
    },
    linkActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showIconHover: false,
  }),
  computed: {
    showNotificationPositive() {
      return typeof this.notificationPositive !== 'undefined'
    },
    showNotificationNegative() {
      return typeof this.notificationNegative !== 'undefined'
    },
    showNotifications() {
      return this.showNotificationPositive || this.showNotificationNegative
    },
    linkAttrs() {
      const attrs = {
        is: 'div',
        class: {
          'router-link-active': this.linkActive,
          'not-found': this.$route.meta.module === 'not-found',
        },
        role: 'link',
        'aria-label': 'label',
      }

      if (this.route) {
        if (this.route.startsWith?.('http')) {
          attrs.is = 'a'
          attrs.href = this.route
        } else {
          attrs.is = 'router-link'
          attrs.to = this.route
        }
      }

      return attrs
    },
    tooltipLabel() {
      return runOrReturn(this.tooltip)
    },
    itemLabel() {
      return runOrReturn(this.label)
    },
  },
  methods: {
    toggleHoverIcon(show) {
      this.showIconHover = show
    },
  },
}
</script>

<style lang="scss">
.sidebar-menu-item {
  @apply text-white hover:text-white;

  .sidebar-item-label {
    @apply text-white;
  }
}
.router-link-active:not(.not-found) {
  .sidebar-menu-item {
    @apply text-blue-400 bg-white;

    .sidebar-item-label {
      @apply text-coal;
    }
  }
}
</style>
