import Tag from './tag.model'

export class CreateTagRequest {
  constructor(form) {
    this.data = {
      type: 'tag',
      attributes: {
        title: form.name,
        color: form.color,
      },
    }
  }
}

export class CreateTagResponse {
  constructor(response) {
    this.tag = new Tag(response.data.data)
  }
}
