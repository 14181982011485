<template>
  <div class="flex">
    <div class="shortcuts-col">
      <div
        v-for="(shortcut, i) in shortcuts"
        :key="shortcut.id"
        :data-id="`${dataIdPrefix}.date_range_picker_menu.shortcut.${shortcut.id}`"
        :class="{
          'active-shortcut': shortcut.name === shortcutSelected,
          [shortcut.id]: true,
        }"
        @click="$emit('apply-shortcut', shortcut)"
      >
        <div class="shortcut-item py-1.5 px-4 cursor-pointer hover:bg-coal-30">
          <div class="flex items-center h-8 justify-between">
            <div>
              <span class="font-medium mr-2 block">
                {{ shortcut.name }}
              </span>
              <span v-if="shortcut.label" class="font-medium text-coal-550">{{
                shortcut.label
              }}</span>
            </div>
            <span>
              <base-icon
                v-if="shortcut.name === shortcutSelected"
                class="text-green"
                icon="check-tick/check-tick-16"
              />
            </span>
          </div>
        </div>
        <div v-if="i !== shortcuts.length - 1" class="h-px bg-coal-100 ml-4" />
      </div>
      <slot name="footer-left" />
    </div>
    <div class="datepicker-col pl-2 pr-4 pb-4 border-coal-100 border-l">
      <base-date-picker
        v-model="dateRange"
        :range="true"
        :inline="true"
        :date-creator="$attrs['date-creator']"
        :custom-focused-date="customFocusedDate"
        :data-id-prefix="`${dataIdPrefix}.date_range_picker_menu`"
        @range-start="$emit('range-start', $event)"
        @range-end="$emit('range-end', $event)"
      >
        <template v-slot:header>
          <slot name="header" />
        </template>
      </base-date-picker>
      <slot name="footer-right" />
      <div v-if="!hideActionApply">
        <div
          v-if="isSelectingCustomRange || hasSelectedCustomRange"
          class="flex justify-between items-center mt-4 px-2"
        >
          <div class="text-coal-550 pl-2">
            {{ textSelectingDateRange }}
          </div>
          <base-button
            :disabled="isSelectingCustomRange"
            type="is-filled"
            size="is-small"
            :data-id="`${dataIdPrefix}.date_range_picker_menu.apply_button`"
            @click.native="$emit('apply')"
          >
            {{ $t('daterangepicker.button_apply') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseDatePicker from '@/app/util-modules/ui/date-picker/BaseDatePicker.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'BaseDateRangePickerMenu',
  components: {
    BaseDatePicker,
    BaseButton,
    BaseIcon,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: null,
    },
    shortcuts: {
      type: Array,
      required: true,
    },
    shortcutSelected: {
      type: String,
      required: false,
      default: '',
    },
    customFocusedDate: {
      type: Date,
      required: true,
    },
    hideActionApply: {
      type: Boolean,
      default: false,
    },
    isSelectingCustomRange: {
      type: Boolean,
      required: true,
    },
    hasSelectedCustomRange: {
      type: Boolean,
      required: true,
    },
    textSelectingDateRange: {
      type: String,
      required: false,
      default: '',
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    dateRange: {
      get() {
        return this.value
      },
      set(dateRange) {
        this.$emit('input', dateRange)
      },
    },
  },
}
</script>
