export const AUTH_ENDPOINT_SIGN_IN = '/sign_in'
export const AUTH_ENDPOINT_SIGN_OUT = '/sign_out'
export const AUTH_ENDPOINT_REGISTER = '/sign_up'
export const AUTH_ENDPOINT_REGISTER_COMPANY = (accountId) =>
  `/accounts/${accountId}`
export const AUTH_ENDPOINT_FORGOT_PASSWORD = '/password'
export const AUTH_ENDPOINT_CHANGE_PASSWORD = '/password'
export const AUTH_ENDPOINT_VALIDATE_PASSWORD_TOKEN =
  '/password/token_validation'
export const AUTH_ENDPOINT_COMPANY_INDUSTRIES = '/industry_examples'
export const AUTH_CHECKIN_ENDPOINT_LIST_INVITED = (inviteToken) =>
  `/public/users/invited?invite_token=${inviteToken}`
export const AUTH_CHECKIN_ENDPOINT_GET_WELCOME_MESSAGE = (inviteToken) =>
  `/public/users/invitations/${inviteToken}`
export const AUTH_CHECKIN_ENDPOINT_CREATE_USER = '/public/join-workspace'
export const AUTH_CHECKIN_ENDPOINT_GET_INVITED_USER = (inviteToken) =>
  `/public/user-details?invite_token=${inviteToken}`
export const AUTH_CHECKIN_ENDPOINT_UPDATE_USER = '/public/join-workspace'
