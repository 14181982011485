<template>
  <base-form-validation :action-status="actionStatus" :hide-messages="true">
    <template
      v-slot:content="{ handleSubmit, handleInputChange, disabled, valid }"
    >
      <bottom-sheet
        :action-status="actionStatus"
        v-bind="$attrs"
        v-on="$listeners"
        @selected-option="$emit('handle-selection', $event)"
        @create="validate(handleSubmit)"
        @update="validate(handleSubmit)"
        @submit="validate(handleSubmit)"
        @cancel-click="$emit('close')"
      >
        <template v-slot:header>
          <slot name="header" />
        </template>

        <base-form-validation-messages
          :action-status="actionStatus"
          :class="$attrs.validationMessagesClass"
        />
        <slot :handle-input-change="handleInputChange" />

        <template v-slot:footer>
          <slot
            name="footer"
            :handle-submit="handleSubmit"
            :footer-action-validate="validate"
            :submit-disabled="disabled"
            :is-valid="valid"
          />
        </template>
      </bottom-sheet>
    </template>
  </base-form-validation>
</template>

<script>
import BottomSheet from './BottomSheet'
import BaseFormValidation from '@/app/util-modules/ui/form/BaseFormValidation'
import BaseFormValidationMessages from '@/app/util-modules/ui/form/BaseFormValidationMessages'

export default {
  name: 'BottomSheetFormWrapper',
  components: {
    BottomSheet,
    BaseFormValidation,
    BaseFormValidationMessages,
  },
  props: {
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async validate(handleSubmit) {
      try {
        await handleSubmit()
        this.$emit('done')
      } catch (e) {} /* eslint-disable-line no-empty */
    },
  },
}
</script>
