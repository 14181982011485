const validateType = (value, type) => {
  if (type === 'object') {
    return typeof value === 'object' && !Array.isArray(value)
  } else if (type === 'array') {
    return Array.isArray(value)
  } else {
    return typeof value === type
  }
}

const expectType =
  (type, strict = true) =>
  (value) => {
    if (!strict && (value === null || typeof value === 'undefined')) {
      return value
    }

    if (!validateType(value, type)) {
      throw new TypeError(`Expected ${type}, got ${typeof value}`)
    }

    return value
  }

export const typesCheck = {
  isArrayStrict: expectType('array'),
  isStringStrict: expectType('string'),
  isNumberStrict: expectType('number'),
  isBooleanStrict: expectType('boolean'),
  isObjectStrict: expectType('object'),
  isArray: expectType('array', false),
  isString: expectType('string', false),
  isNumber: expectType('number', false),
  isBoolean: expectType('boolean', false),
  isObject: expectType('object', false),
  isObjectBooleans: (object) => {
    Object.values(typesCheck.isObject(object)).forEach((value) =>
      typesCheck.isBoolean(value)
    )

    return object
  },
}
