<template>
  <div
    class="flex justify-center items-center w-6 h-6 text-base rounded md:w-auto md:h-4.5 md:text-xs md:font-bold font-semibold px-1"
    :class="customClassName"
  >
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'AppSidebarNotification',
  props: {
    label: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      validator: (value) => ['positive', 'negative'].indexOf(value) !== -1,
      required: true,
    },
  },
  computed: {
    customClassName() {
      return `sidebar-notification sidebar-notification-${this.type}`
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/style/variables';

.sidebar-notification {
  &:first-child {
    margin-bottom: theme('margin.xxxs');
    @media (max-width: theme('screens.md.min')) {
      margin-right: theme('margin.xxs');
      margin-bottom: theme('margin.0');
    }
  }

  &:last-child {
    margin: theme('margin.0');
  }

  &-negative,
  &-positive {
    background-color: $white;
  }

  &-negative {
    @apply text-error-400;
  }

  &-positive {
    @apply text-green-400;
  }
}

.router-link-active .sidebar-notification {
  &-negative {
    @apply text-warning-400 bg-warning-100;
  }

  &-positive {
    @apply text-green-400 bg-green-100;
  }
}
</style>
