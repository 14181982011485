<template>
  <div>
    <tag-list
      v-model="computedSelected"
      :is-open.sync="isListOpen"
      :items="selectItems"
      :selected-count.sync="selectedCount"
      :searchable="isSearchable"
      :type-button-new="typeButtonNew"
      :is-loading="isListLoading"
      :has-search-query="isSearching"
      :data-id-prefix="`${dataIdPrefix}.tag_container`"
      :permissions="permissions"
      @edit="openEditForm($event)"
      @create="openCreateForm()"
      @scroll-end="handleScrollEnd()"
      @typing="handleTyping($event)"
    />
    <tag-form-create
      v-if="!isEditing && isFormOpen"
      :is-open="isFormOpen"
      :model-tag="modelTag"
      @close="closeForm()"
    />
    <tag-form-edit
      v-if="isEditing && isFormOpen"
      :is-open="isFormOpen"
      :model-tag="modelTag"
      :permissions="permissions"
      @duplicate="openDuplicateForm($event)"
      @updated="handleTagUpdate($event)"
      @close="closeForm()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { TAG_ID_NO_TAGS, TAG_ID_ALL_TAGS } from '../../models/tag.model'
import {
  TAG_GETTER_TAGS_LIST,
  TAG_GETTER_TAG_DETAILS,
} from '@/app/core/tag/store/getters/tag.getters.names'

import {
  TAG_ACTION_LIST_TAGS,
  TAG_ACTION_LIST_TAGS_NEXT_PAGE,
} from '../../store/actions/tag.actions.names'

import {
  TAG_MUTATION_ADD_TAGS_DATA,
  TAG_MUTATION_SET_SELECTED_TAGS,
} from '../../store/mutations/tag.mutations.names'

import TagList from '../tag-list/TagList'
import TagFormCreate from '../tag-form-create/TagFormCreate'
import TagFormEdit from '../tag-form-edit/TagFormEdit'
import { isValidSearchQuery } from '@/utils/helpers'

import TagPaginatedSearchMixin from '../tag-search/TagPaginatedSearch.mixin'

import debounce from 'lodash/debounce'
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@/constants'

export default {
  name: 'TagContainer',
  components: {
    TagList,
    TagFormCreate,
    TagFormEdit,
  },
  mixins: [TagPaginatedSearchMixin],
  props: {
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormOpen: false,
      isListOpen: false,
      isEditing: false,
      modelTag: null,
      searchQuery: '',
      selectedCount: 0,
    }
  },
  trackedActions: {
    listTags: TAG_ACTION_LIST_TAGS,
    listTagsNextPage: TAG_ACTION_LIST_TAGS_NEXT_PAGE,
  },
  computed: {
    ...mapGetters({
      tags: TAG_GETTER_TAGS_LIST,
      getTag: TAG_GETTER_TAG_DETAILS,
    }),
    ...mapState({
      selected: (state) => state.tag.selected,
      permissions: (state) => ({
        create: state.tag.meta.can_create_tags,
        update: state.tag.meta.can_modify_tags,
      }),
    }),
    isListLoading() {
      return this.searchContext.loading || this.$actions.listTags.isLoading
    },
    isSearching() {
      return isValidSearchQuery(this.searchQuery)
    },
    searchTags() {
      return this.searchContext.options
    },
    selectItems() {
      return this.isSearching
        ? this.mapAttributes(this.searchTags)
        : this.tags.length > 0
        ? [
            {
              id: TAG_ID_NO_TAGS,
              label: this.$t('topbar.tags.with_no_tags'),
              searchable: false,
              countAsSelected: true,
              editable: false,
            },
            {
              id: TAG_ID_ALL_TAGS,
              label: this.$t('topbar.tags.all_tags'),
              searchable: false,
              editable: false,
              countAsSelected: false,
              children: this.mapAttributes(this.tags),
            },
          ]
        : [
            {
              id: TAG_ID_NO_TAGS,
              label: this.$t('topbar.tags.with_no_tags'),
              searchable: false,
              countAsSelected: true,
              editable: false,
              disabled: true,
            },
          ]
    },
    computedSelected: {
      get() {
        if (this.$actions.listTags.isLoading) {
          return []
        }
        if (this.tags.length === 0) {
          return [TAG_ID_NO_TAGS]
        } else if (this.selected && this.selected.length) {
          return this.selected
        } else {
          return []
        }
      },
      set(selected) {
        this.setSelected(selected)
      },
    },
    isSearchable() {
      return this.tags.length > 8
    },
    typeButtonNew() {
      return this.tags.length <= 1 ? 'filled' : 'basic'
    },
  },
  created() {
    this.listTags(1)
  },
  methods: {
    ...mapMutations({
      setSelected: TAG_MUTATION_SET_SELECTED_TAGS,
      addTagsData: TAG_MUTATION_ADD_TAGS_DATA,
    }),
    ...mapActions({
      listTags: TAG_ACTION_LIST_TAGS,
      listTagsNextPage: TAG_ACTION_LIST_TAGS_NEXT_PAGE,
    }),
    openForm() {
      this.isListOpen = false
      this.isFormOpen = true
    },
    closeForm() {
      this.isFormOpen = false
    },
    openCreateForm() {
      this.openForm()
      this.isEditing = false
      this.modelTag = null
    },
    openEditForm(editableTag) {
      if (this.isSearching) {
        const editableTagData = this.searchTags.find(
          (tag) => tag.id === editableTag.id
        )
        this.addTagsData([editableTagData])
      }

      this.openForm()
      this.isEditing = true
      this.modelTag = this.getTag(editableTag.id)
    },
    openDuplicateForm(tag) {
      this.isEditing = false
      const existingTag = this.getTag(tag.id)

      this.modelTag = {
        ...existingTag,
        attributes: {
          ...existingTag.attributes,
          title: `${existingTag.attributes.title} (copy)`,
        },
      }
    },
    mapAttributes(tags) {
      return tags.map((tag) => {
        return {
          id: tag.id,
          label: tag.attributes.title,
          active: tag.attributes.active,
          color: tag.attributes.color,
          editable: true,
        }
      })
    },
    handleScrollEnd() {
      if (this.isSearching) {
        this.searchNextPage()
      } else {
        if (!this.$actions.listTags.isLoading) {
          this.listTagsNextPage()
        }
      }
    },
    handleTyping: debounce(async function (query) {
      this.searchQuery = query

      if (isValidSearchQuery(query)) {
        this.search({ query, page: 1 })
      }
    }, DEFAULT_SEARCH_DEBOUNCE_TIME),
    handleTagUpdate(editData) {
      if (this.isSearching) {
        const editedSearchTag = this.searchTags.find(
          (tag) => tag.id === editData.id
        )

        editedSearchTag.attributes.title = editData.name
        editedSearchTag.attributes.color = editData.color
      }
    },
  },
}
</script>
