import state from './user.state'
import mutations from './mutations/user.mutations'
import actions from './actions/user.actions'
import getters from './getters/user.getters'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}
