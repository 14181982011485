<template>
  <buefy-tooltip :label="label" :position="position" v-bind="$attrs">
    <slot />

    <template v-slot:content>
      <slot name="content" />
    </template>
  </buefy-tooltip>
</template>

<script>
import BuefyTooltip from 'buefy/src/components/tooltip/Tooltip'

export default {
  name: 'BaseTooltip',
  components: {
    'buefy-tooltip': BuefyTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      validator: (value) =>
        ['is-top', 'is-bottom', 'is-left', 'is-right'].indexOf(value) !== -1,
      default: 'is-right',
    },
  },
}
</script>
