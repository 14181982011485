import { typesCheck } from '@/utils/exceptions/types-exception-handler'

export const AREA_TYPE = 'area'
export const AREA_ID_NO_AREAS = 'nil'
export const AREA_ID_ALL_AREAS = 'all_areas'

export interface AreaAttribute {
  id: number
  name: string
  status: string
  color: string
  external_id: string
  created_at: string
  updated_at: string
  active: boolean
  permissions: any
}

class AreaAttributes {
  id: number
  name: string
  status: string
  color: string
  external_id: string
  created_at: string
  updated_at: string
  active: boolean
  permissions: any
  constructor({
    id,
    name,
    status,
    color,
    permissions,
    external_id,
    created_at,
    updated_at,
  }: {
    id: number
    name: string
    status: string
    color: string
    external_id: string
    created_at: string
    updated_at: string
    active: boolean
    permissions: any
  }) {
    this.id = typesCheck.isNumber(id)
    this.name = typesCheck.isString(name)
    this.status = typesCheck.isString(status)
    this.color = typesCheck.isString(color)
    this.external_id = typesCheck.isString(external_id)
    this.created_at = typesCheck.isString(created_at)
    this.updated_at = typesCheck.isString(updated_at)
    this.active = this.status === 'active'
    this.permissions = permissions
  }
}

class AreaRelationships {
  users: any[]

  constructor(relationships?: { users?: { data: any[] } }) {
    if (relationships) {
      const { users } = relationships

      this.users = users ? users.data : []
    } else {
      this.users = []
    }
  }
}

export class AreasList {
  areas: Area[]

  constructor(areasList: any[]) {
    this.areas = areasList.map((area) => {
      return new Area(area)
    })
  }
}

export default class Area {
  id: string
  type: string
  attributes: AreaAttributes
  relationships: AreaRelationships

  constructor(area: {
    id: string
    type: string
    attributes: any
    relationships: any
  }) {
    this.id = area.id
    this.type = area.type
    this.attributes = new AreaAttributes(area.attributes)
    this.relationships = new AreaRelationships(area.relationships)
  }
}
